import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import { MinusClose, PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import styles from './RegionCheckbox.module.scss';
import { Props } from './RegionCheckbox.types';

const RegionCheckbox = ({ region, setLocalSelectedAgencies, localSelectedAgencies }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const setSelectedAgency = (agency: string) => {
    localSelectedAgencies.includes(agency)
      ? setLocalSelectedAgencies(localSelectedAgencies.filter(agencyItem => agency !== agencyItem))
      : setLocalSelectedAgencies([...localSelectedAgencies, agency]);
  };
  const setlocalSelectedAgencies = (agencies: string[]) => {
    const localSelectedAgenciesInZone = agencies?.filter(agency =>
      localSelectedAgencies.includes(agency)
    );
    localSelectedAgenciesInZone !== undefined &&
    localSelectedAgenciesInZone.length === agencies.length
      ? setLocalSelectedAgencies(
          localSelectedAgencies.filter(agencyItem => !agencies.includes(agencyItem))
        )
      : setLocalSelectedAgencies([...localSelectedAgencies, ...agencies]);
  };
  const regionAgencies = region.zones?.flatMap(zone =>
    zone.agencies?.map(agency => agency.agencyId ?? '')
  ) as string[];
  const selectedRegionAgencies = regionAgencies?.filter(agency =>
    localSelectedAgencies.includes(agency ?? '')
  );
  return (
    <div className={styles.container}>
      <div className={styles.regionTitle}>
        <Checkbox
          label={`${region.regionId} - ${region.regionTitle}`}
          halfChecked={selectedRegionAgencies !== undefined && selectedRegionAgencies.length > 0}
          checked={
            selectedRegionAgencies !== undefined &&
            selectedRegionAgencies.length === regionAgencies?.length
          }
          onClick={() => setlocalSelectedAgencies(regionAgencies ?? [])}
        />
        {isOpen ? (
          <MinusClose
            onClick={() => {
              setIsOpen(false);
            }}
          />
        ) : (
          <PlusOpen
            role="openingButton"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )}
      </div>
      {isOpen &&
        region.zones?.map(zone => {
          const localSelectedAgenciesInZone = zone.agencies?.filter(agency =>
            localSelectedAgencies.includes(agency.agencyId ?? '')
          );
          return (
            <div className={styles.zoneContainer} key={`${zone.zoneId} - ${zone.zoneTitle}`}>
              <div className={styles.zoneCheckBox}>
                <Checkbox
                  label={`${region.regionId}/${zone.zoneId} - ${zone.zoneTitle}`}
                  halfChecked={
                    localSelectedAgenciesInZone !== undefined &&
                    localSelectedAgenciesInZone.length > 0
                  }
                  checked={
                    localSelectedAgenciesInZone !== undefined &&
                    zone.agencies !== undefined &&
                    localSelectedAgenciesInZone.length === zone.agencies.length
                  }
                  onClick={() =>
                    setlocalSelectedAgencies(
                      zone.agencies?.map(agency => agency.agencyId ?? '') ?? []
                    )
                  }
                />
              </div>
              <div className={styles.grid}>
                {zone.agencies?.map(agency => (
                  <Checkbox
                    key={`${agency.agencyId} - ${agency.agencyTitle}`}
                    label={agency.agencyId}
                    onClick={() => setSelectedAgency(agency.agencyId ?? '')}
                    checked={localSelectedAgencies.includes(agency.agencyId ?? '')}
                  />
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default RegionCheckbox;
