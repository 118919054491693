import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { Diploma } from 'src/Services/API';
import { RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchDiplomasAsDiploma = (
  extraOptions?: UseMutationOptions<Diploma[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchDiplomas],
    async (keyword: string) => {
      return await RepositoryService.diplomasControllerGetDiplomas({
        keyword,
      });
    },
    { ...extraOptions }
  );
};
