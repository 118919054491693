import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectHabilitations from 'src/Containers/Selects/SelectHabilitations';

import styles from './NormalHabilitationModal.module.scss';
import { Props } from './NormalHabilitationModal.types';

const NormalHabilitationModal = ({
  selectedHabilitations,
  setSelectedHabilitations,
  onElectricButtonClick,
}: Props) => {
  return (
    <>
      <WithLightTitle title="sélectionner une habilitation électrique">
        <div className={styles.electricHabilitationButton} onClick={onElectricButtonClick}>
          <PlusCircle className={styles.plusIcon} />
          <p className={styles.buttonText}>ajouter une habilitation électrique</p>
        </div>
      </WithLightTitle>
      <SelectHabilitations
        selectedHabilitations={selectedHabilitations}
        setHabilitations={setSelectedHabilitations}
      />
      {selectedHabilitations.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${selectedHabilitations.length} habilitation(s) ajoutée(s)`}>
            {selectedHabilitations.map((habilitation, idx) => (
              <React.Fragment key={habilitation.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  className={styles.criteriaCard}
                  title={habilitation.label ?? ''}
                  weight={habilitation.wishPriority}
                  onWeightChange={weight => {
                    const newHabilitation = {
                      label: habilitation.label,
                      identifier: habilitation.identifier,
                      wishPriority: weight,
                    };

                    // eslint-disable-next-line prefer-const
                    const newHabilitations = selectedHabilitations.slice();
                    newHabilitations[idx] = newHabilitation;
                    setSelectedHabilitations(newHabilitations);
                  }}
                  onDelete={() => {
                    const habilitationTable = selectedHabilitations.filter(
                      otherHabilitation => otherHabilitation.identifier !== habilitation.identifier
                    );
                    setSelectedHabilitations(habilitationTable);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </>
  );
};

export default NormalHabilitationModal;
