import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { QueryKeys } from 'src/Hooks/types';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidateETests, CandidatesService } from 'src/Services/API';

export const useFetchCandidateETests = (
  paramsCandidateId?: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateETests[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const brandCode = useSelector(getBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateETest, candidateId],
    async () =>
      await CandidatesService.candidatesControllerGetCandidateETests({
        id: candidateId ?? paramsCandidateId,
        brandCode,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: candidateId !== undefined || paramsCandidateId !== undefined,
      ...extraOptions,
    }
  );
};
