import * as z from 'zod';

export const keywordModalSchema = z
  .object({
    currentKeyword: z.string(),
    keywords: z.array(z.string()),
  })
  .refine(
    ({ currentKeyword, keywords }) => {
      return !keywords.includes(currentKeyword);
    },
    {
      message: 'Le mot clé saisi existe déjà',
      path: ['currentKeyword'],
    }
  )
  .refine(
    ({ currentKeyword }) => {
      return currentKeyword !== '';
    },
    {
      message: 'Veuillez saisir un mot valide',
      path: ['currentKeyword'],
    }
  );
