import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { PerimeterState } from './Types';

const getPerimeterState = (state: RootState) => state.perimeter;

export const getBrandCode = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.brandCode
);

export const getCurrentBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentBrand
);

export const getPerimeter = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.perimeter
);

export const getPerimeterUnits = createSelector(
  [getPerimeterState],
  (state: PerimeterState) =>
    state.perimeter?.companies?.flatMap(company => company.agencies ?? []) ?? []
);
