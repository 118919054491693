import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateR1, InterviewStatus, R1InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export interface CandidateR1WithClosed extends CandidateR1 {
  isClosed: boolean;
}

export const useFetchCandidateR1 = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateR1WithClosed, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateR1, id],
    async () => {
      const candidateR1 = await R1InterviewsService.r1InterviewsControllerGetR1({
        r1Id: id,
      });
      return { ...candidateR1, isClosed: candidateR1.status !== InterviewStatus['en cours'] };
    },
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};
