import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateInterviewsSummary, InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateInterviewsIdAndStatus = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateInterviewsSummary, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateInterviewsIdAndStatus, id],
    async () => {
      const candidateInterviewsIdAndStatus =
        await InterviewsService.interviewsControllerGetInterviewsSummary({
          candidateId: id,
        });
      return candidateInterviewsIdAndStatus;
    },
    { ...extraOptions }
  );
};
