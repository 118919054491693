import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchMissionById = ({ missionId }: { missionId?: string }) => {
  const brandCode = useSelector(getBrandCode);

  return useQuery(
    [QueryKeys.fetchMissionById, missionId],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await MissionsService.missionsControllerGetMission({ id: missionId!, brandCode });
    },
    { enabled: missionId !== undefined }
  );
};
