import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateContractInformations, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchLastYearCandidateContracts = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateContractInformations, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchLastYearCandidateContracts, id],
    async () => {
      const candidate =
        await CandidatesService.candidatesControllerGetLastYearCandidateContractsInformations({
          id: id,
        });
      return candidate;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
