import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BinocularFullCut,
  TickCircleGreen,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './LoadingDocumentData.module.scss';
import { DOCUMENT_STATUS, Props } from './LoadingDocumentData.types';

const documentStatus = (status: number) => {
  return [
    { title: 'récupération des données d’identité', verified: status >= DOCUMENT_STATUS.IDENTITY },
    {
      title: 'récupération des informations d’adresse',
      verified: status >= DOCUMENT_STATUS.ADDRESS,
    },
    {
      title: 'récupération des expériences et formations',
      verified: status >= DOCUMENT_STATUS.EXPERIENCES,
    },
  ];
};

const LoadingDocumentData = ({ status }: Props) => {
  return (
    <div className={styles.loadingPage}>
      <div className={styles.title}>analyse en cours...</div>
      <BinocularFullCut className={styles.binocular} />
      <div className={styles.documentStatusContainer}>
        {documentStatus(status || 0).map(item => {
          return (
            <div key={item.title} className={styles.documentStatusItem}>
              {item.verified ? (
                <TickCircleGreen className={styles.tickCircleGreen} />
              ) : (
                <div className={styles.loaderContainer}>
                  <Loader heightInRem={0.75} />
                </div>
              )}
              <div className={styles.documentStatusItemTitle}>{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LoadingDocumentData;
