import { HorizontalCardWithTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import styles from './CandidateDiploma.module.scss';
import { Props } from './CandidateDiploma.types';

const CandidateDiploma = ({ diploma }: Props) => {
  let dateText = '';
  if (diploma.obtentionDate) {
    dateText = `date obtention : ${moment(diploma.obtentionDate).format('DD/MM/YYYY')} `;
    if (diploma.obtentionDate !== diploma.validityDate) {
      dateText =
        dateText + `| date de validité : ${moment(diploma.validityDate).format('DD/MM/YYYY')}`;
    }
  }

  return (
    <HorizontalCardWithTitle
      className={styles.diplomaCard}
      title={diploma.formationName ?? ''}
      subtitles={[dateText]}
      titleClassname={styles.diplomaCardTitle}
    />
  );
};

export default CandidateDiploma;
