import { DOCUMENT_STATUS } from './LoadingDocumentData/LoadingDocumentData.types';
export interface DocumentFile {
  file?: File | undefined;
  base64File?: string;
  documentStatus?: DOCUMENT_STATUS;
}
export interface DropzoneItem {
  id?: string;
  file: File;
  subtitle?: string;
  status: UploadStatus;
}

export enum UploadStatus {
  None,
  InProgress,
  Failed,
  Done,
}

export enum AddNewTalentSteps {
  AddDocuments,
  LoadingPage,
  Forms,
  Conflicts,
}

export const AddNewTalentStepperItems = [
  {
    name: AddNewTalentSteps.AddDocuments,
    title: 'ajout documents',
  },
  {
    name: AddNewTalentSteps.LoadingPage,
    title: 'detection données',
  },
  {
    name: AddNewTalentSteps.Forms,
    title: 'vérification des données',
  },
  {
    name: AddNewTalentSteps.Conflicts,
    title: 'gestion conflict',
  },
];
