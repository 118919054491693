import * as z from 'zod';

export const candidateProjectionsSchema = z.object({
  evolution: z.array(z.object({ id: z.string(), label: z.string() })),
  formation: z.array(z.object({ id: z.string(), label: z.string() })),
  mobility: z.array(z.object({ id: z.string(), label: z.string() })),
  other: z.string().optional(),
});

export type CandidateProjectionsSchemaType = z.infer<typeof candidateProjectionsSchema>;
