import React from 'react';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

import ParametersPopOverMenu from './ParametersPopOverMenu.component';

const MemoParametersPopOverMenu = React.memo(ParametersPopOverMenu);

const EnhancedParametersPopOverMenu = () => {
  const { logout } = useAuth();
  const { givenName, familyName, email } = useAuthenticatedUser();

  return (
    <MemoParametersPopOverMenu
      firstName={givenName}
      lastName={familyName}
      email={email}
      onLogout={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
    />
  );
};

export default EnhancedParametersPopOverMenu;
