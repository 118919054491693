import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React from 'react';

import styles from './ParsedDocumentLabel.module.scss';
import { Props } from './ParsedDocumentLabel.types';

const ParsedDocumentLabel = ({ title, label, containerClassName, titleClassName }: Props) => {
  return (
    <div className={classNames(styles.parsedDocumentLabelContainer, containerClassName)}>
      <WithLightTitle
        title={title}
        titleClassName={classNames(styles.parsedDocumentLabelTitle, titleClassName)}
      >
        <p className={styles.parsedDocumentLabel}>{label}</p>
      </WithLightTitle>
      <Flux className={styles.flux} />
    </div>
  );
};

export default ParsedDocumentLabel;
