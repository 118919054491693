import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Language, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchLanguagesAsLanguage = (
  extraOptions?: UseQueryOptions<unknown, unknown, Language[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchLanguages],
    async () => {
      return await RepositoryService.languagesControllerGetLanguages();
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
