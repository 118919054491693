import { Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';

import styles from './ParametersPopOverMenu.module.scss';
import { Props } from './ParametersPopOverMenu.types';

const ParametersPopOverMenu = (props: Props) => (
  // The props "keys" and "nested" are needed because Flash App is using olf version of reactjs popup
  // TODO: https://gitlab.com/randstad-lean-mobile-factory/build/flash/flash-web/-/issues/109 permet de ne plsu avoir besoin de ces props
  <ParametersPopup
    trigger={
      <div>
        <Button.Tertiary className={styles.gearButton}>
          <Settings variant="fill" />
        </Button.Tertiary>
      </div>
    }
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
    {...props}
  />
);

export default ParametersPopOverMenu;
