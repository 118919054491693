import {
  WithLightTitle,
  DatePickerInputDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useMemo, useState } from 'react';
import { useController } from 'react-hook-form';

import { FETCH_STATUS } from 'src/Redux/Types';

import { replaceItem } from '../../../utils';
import ParsedDocumentLabel from '../../Components/ParsedDocumentLabel/ParsedDocumentLabel.component';
import RemoveItem from '../../Components/RemoveItem/RemoveItem.component';

import styles from './SelectFormation.module.scss';
import { Props } from './SelectFormation.types';

const SelectFormation = ({ control, index, formations, watch }: Props) => {
  const formationsController = useController({
    control,
    name: 'formations',
  });
  const watchFormations = watch('formations');
  const formation = watchFormations[index];
  const [keyword, setKeyword] = useState('');
  const researchedFormations = useMemo(
    () =>
      formations.filter(
        x =>
          x.label?.toLowerCase().includes(keyword.toLowerCase()) &&
          !watchFormations.some(formation => formation.selectedItem.id === x.id)
      ),
    [formations, keyword, watchFormations]
  );

  return (
    <div className={styles.container}>
      {formation.parsedDocumentLabel && (
        <ParsedDocumentLabel
          title={'formation détectée'}
          label={formation.parsedDocumentLabel}
          containerClassName={styles.parsedDocumentLabelContainer}
        />
      )}
      <WithLightTitle
        title={
          formation.parsedDocumentLabel
            ? 'compétence équivalente osmose proposée'
            : `formation ajoutée #${index + 1}`
        }
        className={styles.formationTitle}
      >
        <div className={styles.formationContainer}>
          <ComboBox
            id="formation"
            control={control}
            name={`formations.${index}.selectedItem`}
            keyValueExtractor={searchResult => ({
              key: searchResult.id,
              value: searchResult.label ?? '',
            })}
            onSearch={(word: string) => {
              setKeyword(word);
            }}
            minLengthToSearch={1}
            fetchStatus={FETCH_STATUS.FULFILLED}
            disableClearable
            searchResults={keyword !== '' ? researchedFormations : []}
            placeholder="1 caractère minimum"
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <div className={styles.dateContainer}>
            <WithLightTitle title="date d'obtention">
              <DatePickerInputDeprecated
                onSelectDate={item =>
                  formationsController.field.onChange(
                    replaceItem(watchFormations, index, {
                      ...formation,
                      date: item,
                    })
                  )
                }
                date={formation.date}
              />
            </WithLightTitle>

            <RemoveItem
              onClick={() => {
                formationsController.field.onChange(
                  watchFormations.filter((_, idx) => idx !== index)
                );
              }}
              className={styles.removeItem}
            />
          </div>
        </div>
      </WithLightTitle>
      <div className={styles.separator} />
    </div>
  );
};

export default SelectFormation;
