import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Candidate, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateConflicts = (
  firstName?: string,
  lastName?: string,
  email?: string,
  extraOptions?: UseQueryOptions<
    unknown,
    unknown,
    Candidate[],
    ({ lastName: string; firstName: string; email: string } | QueryKeys)[]
  >
) => {
  const searchBody: { lastName: string; firstName: string; email: string } = {
    lastName: lastName ?? '',
    firstName: firstName ?? '',
    email: email ?? '',
  };
  return useQuery(
    [QueryKeys.fetchCandidateConflicts, searchBody],
    async () => {
      if (!firstName || !lastName || !email) {
        throw new Error("Il n'a pas de nom/prénom ou email");
      }
      const matchingCandidates = await CandidatesService.candidatesControllerGetConflictCandidates(
        searchBody
      );
      return matchingCandidates;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 0,
      ...extraOptions,
    }
  );
};
