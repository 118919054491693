import { TopButton } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect, useRef } from 'react';
import { useParams, useHistory, Router, Switch, Route } from 'react-router';

import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { CandidateRecruitingRoute } from 'src/Services/Routing';

import { getNavigationStructure } from '../CandidateRecruitingProcess.types';

import styles from './CandidateRecruitingForm.module.scss';
import { Props } from './CandidateRecruitingForm.types';
const CandidateRecruitingForm = ({ simplifiedHeader, setSimplifiedHeader }: Props) => {
  const params = useParams<CandidateRecruitingRoute['routerParameters']>();
  const recruitingStatusQuery = useFetchCandidateInterviewsIdAndStatus(params?.candidateId ?? '');
  const history = useHistory();
  const firstSection = params?.firstSection as 'R1' | 'R2';

  const subRoute = getNavigationStructure(recruitingStatusQuery.data)[firstSection]?.routes.find(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  const subRouteIndex = getNavigationStructure(recruitingStatusQuery.data)[
    firstSection
  ]?.routes.findIndex(_ => _.linkTo === `./${params?.secondSection}`);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  useEffect(() => {
    containerRef.current?.scrollTo({ top: 0, left: 0 });
  }, [params?.secondSection]);
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrolled =
          containerRef.current.scrollTop >=
          ((simplifiedHeader ? -10 : 0) + containerRef.current.clientHeight) / 3;
        setSimplifiedHeader(scrolled);
      }
    };
    const currentDivRef = containerRef.current;
    if (
      currentDivRef &&
      !!recruitingStatusQuery.data?.r2Interview &&
      Object.keys(recruitingStatusQuery.data.r2Interview).length
    ) {
      currentDivRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup: remove the event listener
    return () => {
      if (
        currentDivRef &&
        !!recruitingStatusQuery.data?.r2Interview &&
        Object.keys(recruitingStatusQuery.data.r2Interview).length
      ) {
        currentDivRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [simplifiedHeader, setSimplifiedHeader, recruitingStatusQuery.data?.r2Interview]);
  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.routeNumber}>{`${subRouteIndex + 1}.`}</div>
      <div className={styles.formContainer}>
        <div className={styles.titleContainer}>
          <p>{subRoute?.name ?? 'Error'}</p>
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(getNavigationStructure(recruitingStatusQuery.data)).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route key={route.name} path={`${route.path}`} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
      <TopButton onClick={scrollToTop} />
    </div>
  );
};

export default CandidateRecruitingForm;
