import { HorizontalCardFoldable } from '@randstad-lean-mobile-factory/react-components-core';
import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './CACESCategoryList.module.scss';
import { Props } from './CACESCategoryList.types';

const CACESCategoryList = (props: Props) => {
  const selectedCacesIdsInCategory = props.selectedCacesIds?.filter(selectedCacesId =>
    props.cacesCategory.caces.map(cacesItem => cacesItem.identifier).includes(selectedCacesId)
  );
  return (
    <HorizontalCardFoldable
      label={props.cacesCategory.categoryLabel}
      leftIcon={props.cacesCategory.icon}
      isOpen={props.open}
      onRightIconClick={props.onToogleChange}
      containerClassName={styles.cacesCategoryContainer}
      rightComponent={
        selectedCacesIdsInCategory &&
        selectedCacesIdsInCategory.length > 0 && (
          <div
            className={styles.rightSide}
          >{`${selectedCacesIdsInCategory.length} sélectionnée(s)`}</div>
        )
      }
      children={props.cacesCategory.caces.map(cacesItem => (
        <div
          key={cacesItem.identifier}
          onClick={() => props.onItemClick(cacesItem)}
          className={classnames(styles.cacesItem, {
            [styles.selectedCacesItem]: props.selectedCacesIds?.includes(cacesItem.identifier),
          })}
        >
          <div className={styles.label}>{cacesItem.label}</div>
          {props.selectedCacesIds?.includes(cacesItem.identifier) && (
            <Check className={styles.checkIcon} />
          )}
        </div>
      ))}
    />
  );
};

export default CACESCategoryList;
