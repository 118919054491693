import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { Eye } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { HeaderMetier } from 'src/Assets';
import ConsultSkillModal from 'src/Containers/Modals/CandidateFileModals/Qualifications/ConsultSkillModal';

import styles from './CandidateBusinessModal.module.scss';
import { Props } from './CandidateBusinessModal.types';
const CandidateBusinessModal = ({ business, openModal }: Props) => {
  return (
    <ModalDeprecated
      icon={<HeaderMetier />}
      title={business.label}
      iconClassName={styles.icon}
      open={openModal}
      trigger={
        <Button.Tertiary>
          <Eye />
        </Button.Tertiary>
      }
    >
      <div>
        <ConsultSkillModal
          businessSkills={business.candidateBusinessesSkills ?? []}
          qualifications={business.candidateQualifications ?? []}
        />
      </div>
    </ModalDeprecated>
  );
};

export default CandidateBusinessModal;
