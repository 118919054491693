import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Nationality, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchNationalities = (
  extraOptions?: UseQueryOptions<Nationality[], unknown, Nationality[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchNationalities],
    async () => {
      return await RepositoriesService.repositoriesControllerGetNationalities();
    },
    { ...extraOptions }
  );
};
