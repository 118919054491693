import { DraggableLocation } from 'react-beautiful-dnd';

import { Job } from 'src/Services/API';

export const reorder = ({
  list,
  startIndex,
  endIndex,
}: {
  list: Job[];
  startIndex: number;
  endIndex: number;
}) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const move = ({
  sourceJobList,
  destinationJobList,
  droppableSource,
  droppableDestination,
}: {
  sourceJobList: Job[];
  destinationJobList: Job[];
  droppableSource: DraggableLocation;
  droppableDestination: DraggableLocation;
}) => {
  const sourceJobListClone = Array.from(sourceJobList);
  const destJobListClone = Array.from(destinationJobList);
  const [removed] = sourceJobListClone.splice(droppableSource.index, 1);
  destJobListClone.splice(droppableDestination.index, 0, removed);

  return {
    sourceJobListClone,
    destJobListClone,
  };
};
