import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateExperience, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateExperiences = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateExperience[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateExperiences, id],
    async () => {
      const experiences = await CandidatesService.candidatesControllerGetCandidateExperiences({
        id,
      });
      return experiences;
    },
    { ...extraOptions }
  );
};
