import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { Cross } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './SkillsBadges.module.scss';
import { Props } from './SkillsBadges.types';

const SkillsBadges = ({ skills, removeItem }: Props) => {
  return (
    <>
      {skills.length > 0 && (
        <div className={styles.badgesContainer}>
          {skills?.map((skill, index: number) => (
            <BadgeDeprecated
              key={index}
              value={
                skill.parsedDocumentLabel
                  ? `${skill.parsedDocumentLabel} -> ${skill.selectedItem.label}`
                  : skill.selectedItem?.label ?? ''
              }
              rightIcon={<Cross className={styles.cross} />}
              className={styles.badge}
              onRightIconClick={() => removeItem(index)}
            />
          ))}
        </div>
      )}
    </>
  );
};
export default SkillsBadges;
