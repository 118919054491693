import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './CACESCategoryListLoader.module.scss';

const CACESCategoryListLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="4rem" width="100%" uniqueKey="criteriaButton">
      <rect x="2%" y="20" rx="4" ry="4" width="85%" height="20" />
    </ContentLoader>
  </div>
);

export default CACESCategoryListLoader;
