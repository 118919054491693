import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Department, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDepartments = (
  extraOptions?: UseQueryOptions<Department[], unknown, Department[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchDepartments],
    async () => {
      return await RepositoriesService.repositoriesControllerGetDepartments();
    },
    {
      ...extraOptions,
    }
  );
};
