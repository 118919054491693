import * as z from 'zod';

import { ScoringEnum } from 'src/Services/API';

export const candidateProjectionsSchema = z.object({
  otherProjections: z.object({
    responses: z.array(
      z.object({ id: z.number(), label: z.string(), value: z.string().optional() })
    ),
  }),
  evolution: z.array(z.object({ id: z.string(), label: z.string() })),
  formation: z.array(z.object({ id: z.string(), label: z.string() })),
  validations: z.object({
    comment: z.string().optional(),
    responses: z.array(z.object({ id: z.number(), label: z.string(), value: z.boolean() })),
  }),
  professionalProjections: z.object({
    projections: z.string().optional(),
    scoring: z.nativeEnum(ScoringEnum).optional(),
    comment: z.string().optional(),
  }),
});

export type CandidateProjectionsSchemaType = z.infer<typeof candidateProjectionsSchema>;
export type KeyOfCandidateProjectionsSchemaTypeQuestion = keyof CandidateProjectionsSchemaType;
