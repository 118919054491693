import React from 'react';
import { useSelector } from 'react-redux';

import { useFetchMatchingCandidates } from 'src/Hooks/Matching';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';
import { toFetchStatus } from 'src/Services/ReactQuery';

import CandidateResults from './CandidateResults.component';

const MemoCandidateResults = React.memo(CandidateResults);

const EnhancedCandidateResults = () => {
  const queryResult = useFetchMatchingCandidates();
  const matchingCandidates = queryResult.data ?? [];
  const fetchStatus = toFetchStatus(queryResult);
  const displayMode = useSelector(getCardDisplayMode);
  return (
    <MemoCandidateResults
      matchingCandidates={matchingCandidates}
      fetchStatus={fetchStatus}
      displayMode={displayMode}
    />
  );
};

export default EnhancedCandidateResults;
