import React from 'react';

import { WishPriority } from 'src/Services/API';

import BadgePicker from '../BadgePicker';

import styles from './WeightPicker.module.scss';
import { Props } from './WeightPicker.types';

const WeightPicker = ({ selectedWeight, onChange, skip }: Props) => {
  const items = [
    {
      key: WishPriority.Souhaite,
      value: 'souhaité',
      className: styles.optional,
    },
    {
      key: WishPriority.Important,
      value: 'important',
      className: styles.important,
    },
    {
      key: WishPriority.Obligatoire,
      value: 'obligatoire',
      className: styles.mandatory,
    },
  ];

  return (
    <BadgePicker
      selectedItem={selectedWeight}
      items={items.filter(item => !skip?.includes(item.key))}
      onChange={key => onChange(key as WishPriority)}
    />
  );
};

export default WeightPicker;
