import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateAgencyDto } from 'src/Services/API';

import { useFetchCandidateAgencies } from './useFetchCandidateAgencies';

export const useFetchCandidateAgenciesFromURL = (
  isCdi?: boolean,
  extraOptions?: UseQueryOptions<CandidateAgencyDto[], unknown, CandidateAgencyDto[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateAgencies(candidateId, extraOptions, isCdi);
};
