import { IllusRLHabilitationsSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasHabilitations } from 'src/Redux/Search/Selectors';

import styles from './HabilitationButton.module.scss';

const HabilitationButton = () => {
  const _hasHabilitations = useSelector(hasHabilitations);
  const habilitationModal = useModalWithLocation('habilitation');
  return (
    <div>
      <CriteriaButton
        label="habilitations"
        icon={<IllusRLHabilitationsSmall className={styles.icon} />}
        selected={_hasHabilitations}
        onClick={() => habilitationModal.open()}
      />
    </div>
  );
};

export default HabilitationButton;
