import type { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Document, Page, pdfjs } from 'react-pdf';

import styles from './PDFDocumentViewer.module.scss';
import { Props } from './PDFDocumentViewer.types';

const pageDefaultProps = {
  renderAnnotationLayer: false,
  renderTextLayer: false,
  width: 34,
  height: 44,
};

const PDFDocumentViewer = ({ file, height }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);
  const currentPageRef = useRef<HTMLCanvasElement>(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy) {
    setNumPages(nextNumPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      className={styles.pdfDocument}
      loading={
        <ContentLoader height="100%" width="100%" uniqueKey="new-talent-photo">
          <rect x="2%" y="10" rx="4" ry="4" width="90%" height="100%" />
        </ContentLoader>
      }
    >
      <Page
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        canvasRef={currentPageRef}
        height={height}
      />
      <div className={styles.thumbnails}>
        <div className={styles.pdfPages}>
          {pageNumber || (numPages ? 1 : '--')}/{numPages || '--'}
        </div>

        {pageNumber - 1 > 0 && (
          <Page
            {...pageDefaultProps}
            pageNumber={pageNumber - 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            className={styles.otherPageThumbnail}
          />
        )}
        <Page
          {...pageDefaultProps}
          pageNumber={pageNumber}
          className={styles.currentPageThumbnail}
        />
        {pageNumber < (numPages ?? 0) && (
          <Page
            {...pageDefaultProps}
            pageNumber={pageNumber + 1}
            onClick={() => setPageNumber(pageNumber + 1)}
            className={styles.otherPageThumbnail}
          />
        )}
      </div>
    </Document>
  );
};

export default PDFDocumentViewer;
