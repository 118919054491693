import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { RepositoriesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useUploadInterviewsGoals = (
  extraOptions?: UseMutationOptions<void, unknown, File>
) => {
  return useMutation(
    [MutationKeys.uploadInterviewGoals],
    async (file: File) => {
      await RepositoriesService.repositoriesControllerUploadAndStoreInterviewsGoalsCsvFile({
        file,
      });
    },
    { ...extraOptions }
  );
};
