import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { useFetchCandidateDiplomas } from 'src/Hooks/Candidates/useFetchCandidateDiplomas';
import { CandidateDiplomaDto } from 'src/Services/API';

export const useFetchCandidateDiplomasFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateDiplomaDto[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateDiplomas(candidateId, extraOptions);
};
