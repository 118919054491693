import { useQuery } from '@tanstack/react-query';

import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDiplomasList = (keywords: string[]) => {
  return useQuery([QueryKeys.fetchDiplomasList, keywords], async () => {
    return await RepositoryService.diplomasControllerGetDiplomasList({
      keywords,
    });
  });
};
