import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchSkillsList = (
  keywords: string[],
  extraOptions?: UseQueryOptions<
    unknown,
    unknown,
    { id: string; label: string; parsedDocumentLabel: string }[],
    QueryKeys[]
  >
) => {
  return useQuery(
    [QueryKeys.fetchSkillsList],
    async () => {
      return await RepositoryService.skillsControllerGetSkillsList({
        keywords,
      });
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
