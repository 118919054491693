import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IdLabel, MatchingResult, MissionWithoutSchedule } from 'src/Services/API';

import { MissionsState } from './Types';

const initialState: MissionsState = {
  selectedMissions: [],
};

export const { reducer: missionsReducer, actions: missionsActions } = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setMissions: (state, action: PayloadAction<MissionWithoutSchedule[]>) => {
      state.selectedMissions = action.payload;
    },
    setQualification: (state, action: PayloadAction<IdLabel | undefined>) => {
      state.selectedQualification = action.payload;
    },
    setCompany: (state, action: PayloadAction<IdLabel | undefined>) => {
      state.selectedCompany = action.payload;
    },
    setMissionsCandidates: (state, action: PayloadAction<MatchingResult[]>) => {
      state.selectedMissions = state.selectedMissions.map(mission => {
        const missionCandidatesIds = mission?.candidates?.map(candidate => candidate.id);
        const filteredPayloadCandidates = action.payload
          .filter(candidate => !missionCandidatesIds?.includes(candidate.candidateId))
          .map(candidate => ({
            id: candidate.candidateId,
            candidateAdvancement: 'None',
            companyAdvancement: 'None',
            firstName: candidate.firstName,
            lastName: candidate.name,
          }));

        return {
          ...mission,
          candidates: [...(mission?.candidates ?? []), ...filteredPayloadCandidates],
        };
      });
    },
  },
});
