import { IllusRLVillesSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasLocation } from 'src/Redux/Search/Selectors';

import styles from './LocationButton.module.scss';

const LocationButton = () => {
  const locationModal = useModalWithLocation('location');
  const _hasLocation = useSelector(hasLocation);
  return (
    <div>
      <CriteriaButton
        label="villes"
        icon={<IllusRLVillesSmall className={styles.icon} />}
        selected={_hasLocation}
        onClick={() => locationModal.open()}
      />
    </div>
  );
};

export default LocationButton;
