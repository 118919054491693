import { InterviewsStatistics, OpenInterviewsStats } from 'src/Services/API';

export interface Props {
  interviewsStatsArray?: InterviewsStatistics;
  openInterviewsStats?: OpenInterviewsStats;
  signedCDI?: number;
}

export enum TransformationRateLabels {
  CREATED_R1 = 'R1 créé',
  ACCEPTED_R1 = 'R1 validé',
  CREATED_R2 = 'R2 initié',
  ACCEPTED_R2 = 'R2 validé',
  SIGNED_CDI = 'CDI signé',
}

export const transformationRateColorMap = {
  [TransformationRateLabels.CREATED_R1]: '#0f1941',
  [TransformationRateLabels.ACCEPTED_R1]: '#2175d9',
  [TransformationRateLabels.CREATED_R2]: '#6ba5b4',
  [TransformationRateLabels.ACCEPTED_R2]: '#ffb511',
  [TransformationRateLabels.SIGNED_CDI]: '#e74536',
};
