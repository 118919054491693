import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLKeyword } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from 'src/Containers/Modals/RattachementRecapModal/RattachementRecapModal.module.scss';
import { Props } from 'src/Containers/Modals/RattachementRecapModal/RattachementRecapModal.types';
import { useAddCandidatesToMissions } from 'src/Hooks/Missions/useAddCandidatesToMissions';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { MatchingResult } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import {
  transformMissionsWithCandidates,
  transformSelectedCandidates,
  transformSelectedMissions,
} from './RattachementRecapModal.transformers';

const RattachementRecapModal = ({ isOpen, onClose, candidates, missions }: Props) => {
  const dispatch = useDispatch();
  const [isMissionSuccessfullySaved, setIsMissionSuccessfullySaved] = useState(false);
  const associateCandidatesToMissions = useAddCandidatesToMissions();
  const associateCandidatesToMissionsFetchStatus = toFetchStatus(associateCandidatesToMissions);

  const selectedCandidates = transformSelectedCandidates(candidates);
  const selectedMissions = transformSelectedMissions(missions);

  const renewedMissionCandidatesRef = useRef<
    { mission: string; candidates: MatchingResult[] }[] | null
  >(null);

  useEffect(() => {
    if (renewedMissionCandidatesRef.current === null) {
      renewedMissionCandidatesRef.current = transformMissionsWithCandidates(missions, candidates);
    }

    return () => {
      renewedMissionCandidatesRef.current = null;
    };
  }, [candidates, missions]);

  const renewedMissionCandidates = renewedMissionCandidatesRef.current ?? [];

  return (
    <ModalDeprecated
      icon={<IllusRLKeyword className={styles.icon} />}
      nested
      onClose={() => {
        setIsMissionSuccessfullySaved(false);
        onClose();
      }}
      title="confirmation du rattachement"
      open={isOpen}
      footerActionsRight={
        isMissionSuccessfullySaved
          ? missions.length > 1
            ? [<Button.Secondary onClick={onClose}>continuer</Button.Secondary>]
            : [
                <Button.Secondary onClick={onClose}>continuer</Button.Secondary>,
                <Button.Primary
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_PUBLIC_FLASH_URL}/temporaryWorkers/commandes/${missions[0].missionId}`,
                      'flash.grouperandstad.fr'
                    );
                    onClose();
                  }}
                >
                  visualiser la commande
                </Button.Primary>,
              ]
          : [
              <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
              <FetchButton
                fetchStatus={associateCandidatesToMissionsFetchStatus}
                onSuccess={() => {
                  setIsMissionSuccessfullySaved(true);
                  dispatch(missionsActions.setMissionsCandidates(candidates));
                }}
                title="valider"
                errorTitle="réessayer"
                onClick={() => {
                  associateCandidatesToMissions.mutate({ candidates, missions });
                }}
              />,
            ]
      }
    >
      <div className={styles.container}>
        <div
          className={styles.lightGrey}
        >{`${selectedMissions.length} numéro(s) de commande(s)`}</div>
        {selectedMissions.map(commande => (
          <div key={commande.name}>
            {commande.name} - {commande.qualification}
          </div>
        ))}
      </div>
      <div className={styles.container}>
        <div
          className={styles.lightGrey}
        >{`${selectedCandidates.length} profil(s) sélectionné(s)`}</div>
        {selectedCandidates.map(candidate => (
          <div key={candidate.name}>
            {candidate.name} - {candidate.mainQualification}
          </div>
        ))}
      </div>

      <div className={styles.container}>
        {renewedMissionCandidates.length > 0 && (
          <>
            <text className={styles.lightGrey}>
              {isMissionSuccessfullySaved
                ? "Les candidats suivants n'ont pas été rattachés car ils sont déjà présents sur la mission"
                : 'Les candidats suivants ne seront pas rattachés car ils sont déjà présents sur la mission'}
            </text>
            {renewedMissionCandidates.map(({ mission, candidates }) => (
              <div key={mission}>
                {mission}
                {candidates.map(candidate => (
                  <div
                    key={candidate.firstName}
                  >{`\t${candidate.firstName} ${candidate.name}`}</div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </ModalDeprecated>
  );
};

export default RattachementRecapModal;
