import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { WishPriority, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDrivingLicences = (
  extraOptions?: UseQueryOptions<unknown, unknown, MatchingCriteriaItemWithLabel[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchDrivingLicences],
    async () => {
      const drivingLicences = await RepositoryService.drivingLicencesControllerGetDrivingLicences();
      const results = drivingLicences.map(drivingLicence => {
        return {
          identifier: drivingLicence.id,
          label: drivingLicence.label,
          wishPriority: WishPriority.Obligatoire,
        };
      });
      return results;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
