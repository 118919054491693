import { UploadCloudOutline } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

import { useFetchSignedCDI } from 'src/Hooks/Agencies/useFetchSignedCDI';
import { useFetchInterviewsStatistics } from 'src/Hooks/Interviews/useFetchInterviewsStatistics';
import { useFetchOpenInterviewsStatistics } from 'src/Hooks/Interviews/useFetchOpenInterviewsStatistics';
import { useFetchConsultantsEstablishments } from 'src/Hooks/Perimeter/useFetchConsultantsEstablishments';
import { useFetchCDIGoals } from 'src/Hooks/Repositories/useFetchCDIGoals';
import { useFetchCdiGoalsAdministrators } from 'src/Hooks/Repositories/useFetchCdiGoalsAdministrators';
import { useUploadInterviewsGoals } from 'src/Hooks/Repositories/useUploadInterviewsGoals';
import { getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';

import InterviewsBarChart from './InterviewsBarChart';
import InterviewsPieCharts from './InterviewsPieCharts';
import InterviewsStatsPerAgency from './InterviewsStatsPerAgency';
import styles from './InterviewStatistics.module.scss';
import StatsDisplayer from './StatsDisplayer';

const InterviewStatistics = () => {
  const { data, isSuccess, isLoading, isError } = useFetchInterviewsStatistics();
  const {
    data: openData,
    isSuccess: openSuccess,
    isLoading: openLoading,
    isError: openError,
  } = useFetchOpenInterviewsStatistics();
  const signedCDIQuery = useFetchSignedCDI();
  const cdiGoalsQuery = useFetchCDIGoals();
  const interviewsStatsFetchStatus = toFetchStatus({ isLoading, isSuccess, isError });
  const interviewsOpenStatsFetchStatus = toFetchStatus({
    isLoading: openLoading,
    isSuccess: openSuccess,
    isError: openError,
  });
  const consultantEstablishmentsQuery = useFetchConsultantsEstablishments();
  const consultantEstablishmentsFetchStatus = toFetchStatus(consultantEstablishmentsQuery);
  const cdiGoalsFetchStatus = toFetchStatus(cdiGoalsQuery);
  const signedCDIFetchStatus = toFetchStatus(signedCDIQuery);
  const fetchStatus = mergeSeveralFetchStatus([
    interviewsStatsFetchStatus,
    cdiGoalsFetchStatus,
    consultantEstablishmentsFetchStatus,
    interviewsOpenStatsFetchStatus,
    signedCDIFetchStatus,
  ]);
  const cdiGoalsArray = cdiGoalsQuery.data?.map(item => {
    return { cdiGoals: item.cdiGoals ? parseInt(item.cdiGoals) : undefined };
  });
  const existingCdiGoals = cdiGoalsArray?.filter(goal => goal !== undefined);
  const cdiPerimeterGoal =
    existingCdiGoals !== undefined && existingCdiGoals.length > 0
      ? existingCdiGoals.reduce((partialSum, a) => partialSum + (a.cdiGoals ?? 0), 0)
      : undefined;

  //The following part of the code is for the administrator to upload the csv file for the CDI goals
  const perimeter = useSelector(getPerimeter);
  const cdiGoalsUploadMutation = useUploadInterviewsGoals();
  const administratorsQuery = useFetchCdiGoalsAdministrators();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any) => {
    cdiGoalsUploadMutation.mutate(e.target.files[0]);
  };

  return (
    <div className={styles.container}>
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="4.5rem" width="100%" uniqueKey="stats">
          <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
          <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
          <rect x="2%" y="50" rx="4" ry="4" width="80%" height="10" />
        </ContentLoader>
      )}
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.statsContainer}>
            <StatsDisplayer
              title="Nombre total de R1-R2 en cours"
              statToDisplay={
                (data?.interviewsStats.r1['en cours'] ?? 0) +
                (data?.interviewsStats.r2['en cours'] ?? 0) +
                (data?.interviewsStats.r1['accepté sans r2'] ?? 0)
              }
            />
            <StatsDisplayer
              title="Nombre total de R1-R2 finis"
              statToDisplay={
                (data?.interviewsStats.r1.refusé ?? 0) +
                (data?.interviewsStats.r2.accepté ?? 0) +
                (data?.interviewsStats.r2.refusé ?? 0)
              }
            />

            <StatsDisplayer
              textClassName={!cdiPerimeterGoal ? styles.noGoal : undefined}
              title={`Nombre de CDI-I à atteindre en ${moment().year().toString()}`}
              statToDisplay={cdiPerimeterGoal ?? 'Objectif non renseigné'}
              childNode={
                administratorsQuery.isSuccess &&
                administratorsQuery.data.includes(perimeter?.id ?? '') && (
                  <div className={styles.cdiGoalsUploadContainer}>
                    <input
                      type={'file'}
                      id={'csvFileInput'}
                      accept={'.csv'}
                      onChange={handleOnChange}
                      className={styles.cdiGoalsUploadInput}
                    />
                    <label htmlFor="csvFileInput">
                      <UploadCloudOutline className={styles.uploadCloudOutline} />
                    </label>
                  </div>
                )
              }
            />
          </div>
          <InterviewsPieCharts interviewsStatsArray={data?.interviewsStatsArray} />
          {openData?.openR1Count ? (
            <InterviewsBarChart
              interviewsStatsArray={data?.interviewsStatsArray}
              openInterviewsStats={openData}
              signedCDI={signedCDIQuery.data}
            />
          ) : (
            <p className={styles.noOpenR1}>Aucun R1 ouvert sur cette période pour ces unités</p>
          )}

          <InterviewsStatsPerAgency />
        </>
      )}
    </div>
  );
};

export default InterviewStatistics;
