import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CandidateR1, R1InterviewsService, R2InterviewsService } from 'src/Services/API';

import { useCreateCandidateR1 } from '../Candidates/useCreateCandidateR1';
import { MutationKeys, QueryKeys } from '../types';

interface ResetInterviewsParams {
  r1Id: string;
  r2Id?: string;
}

export const useResetCandidateInterviews = (
  candidateId: string,
  extraOptions?: UseMutationOptions<CandidateR1, AxiosError, ResetInterviewsParams, unknown>
) => {
  const queryClient = useQueryClient();
  const createR1Mutation = useCreateCandidateR1(candidateId);
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.resetCandidateInterviews, candidateId],
    async (params: ResetInterviewsParams) => {
      const { r1Id, r2Id } = params;
      await R1InterviewsService.r1InterviewsControllerDeleteR1({
        r1Id,
      });
      if (r2Id) {
        await R2InterviewsService.r2InterviewsControllerDeleteR2({
          r2Id,
        });
      }
      return await createR1Mutation.mutateAsync();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateInterviewsIdAndStatus],
        });
        ReactGA.event('Formulaire R1 initié', {
          brandCode: currentBrand?.brandCodeApiHeader,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      },
      ...extraOptions,
    }
  );
};
