import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Action, ActionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchActionsByIds = (
  actionsIds: string[],
  brandCode: string,
  r1InterviewId: string,
  shouldFetch = false,
  extraOptions?: UseQueryOptions<Action[], unknown>
) => {
  return useQuery<Action[], unknown>(
    [QueryKeys.fetchActions, actionsIds, brandCode],
    async () => {
      if (!actionsIds || !brandCode || actionsIds.length === 0) {
        throw new Error("Le champ actionsIds ou brandCode n'est pas défini");
      } else {
        const actions = await ActionsService.actionsControllerGetActionsByIds({
          actionsIds,
          brandCode,
          r1InterviewId,
        });
        return actions;
      }
    },
    { ...extraOptions, enabled: shouldFetch && actionsIds !== undefined && actionsIds.length > 0 }
  );
};
