import { ExclusiveSelectionGroupProps } from '@randstad-lean-mobile-factory/react-form-fields';
import { FieldPath, FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  ExclusiveSelectionGroupProps<TFieldValues, Gender, string | undefined, TName>,
  'values' | 'getSelectedFormValue' | 'getSelectedValue' | 'getStringValue' | 'getKey'
>;

export enum Gender {
  Male = '1',
  Female = '2',
}
