import { EDUCATION_CLASS, ParsedDocument } from 'src/Hooks/AddNewTalent/useFetchDocumentData';
import { JSONData } from 'src/Services/API';

export const replaceItem = <T>(arr: T[], index: number, element: T): T[] => {
  const newArray = [...arr];
  newArray[index] = element;
  return newArray;
};

export const detectedItemsCount = (arr: { parsedDocumentLabel?: string }[] | undefined) => {
  return arr?.filter(item => item.parsedDocumentLabel !== undefined).length ?? 0;
};

function removeDuplicates<T extends Record<string, unknown>>(arr: T[]): T[] {
  const uniqueItems: T[] = [];
  const jsonSet = new Set();

  arr.forEach(item => {
    const jsonString = JSON.stringify(item);

    if (!jsonSet.has(jsonString)) {
      jsonSet.add(jsonString);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}

export const transformDateFormat = (dateString: string): Date | undefined => {
  if (!dateString) return;
  const parts = dateString.split('/');
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }

  const year = parseInt(parts[2]);
  const month = parseInt(parts[1]) - 1;
  const day = parseInt(parts[0]);

  return new Date(year, month, day, 1);
};

// distance between strings
export const levenshteinDistance = (s1: string, s2: string): number => {
  const matrix: number[][] = [];

  for (let i = 0; i <= s1.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 1; j <= s2.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= s1.length; i++) {
    for (let j = 1; j <= s2.length; j++) {
      const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  return matrix[s1.length][s2.length];
};

const extractNumbers = (inputString: string | undefined) => {
  const numericCharacters = (inputString ?? '').replace(/\D/g, '');
  const paddedNumeric = numericCharacters.padEnd(10, '0');
  const formattedNumber = paddedNumeric.replace(
    /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
    '$1 $2 $3 $4 $5'
  );
  return formattedNumber;
};

export const parserFormatter = (jsonData: JSONData[]): ParsedDocument => {
  const result = jsonData[0]?.content?.result;
  const parsedObject: ParsedDocument = {
    personal: {
      firstName: result?.first_name ?? undefined,
      name: result?.last_name ?? undefined,
      phone: extractNumbers(result?.phone_number),
      email: result?.email ?? undefined,
    },
    address: {
      rawName: result?.address,
      zipCode: result?.zip_code,
      city: result?.city?.toUpperCase(),
      road:
        result?.propertyNumber || result?.street
          ? [result?.propertyNumber ?? '', result?.street ?? ''].join(' ')
          : '',
    },
    skills: {
      languages: removeDuplicates(
        result?.languages?.map((language, index) => {
          return {
            name: language,
            level: result?.languages_level?.[index] ?? undefined,
          };
        }) ?? []
      ),
      hardSkills:
        result?.skills?.map(skill => {
          return { name: skill };
        }) ?? [],
      softSkills: [],
    },
    qualifications: removeDuplicates(
      result?.experience_job?.map(experience => {
        return { name: experience };
      }) ?? []
    ),
    diplomas: [],
    formations: [],
  };
  result.education_class?.forEach((educationClass, index) => {
    switch (educationClass) {
      case EDUCATION_CLASS.FORMATION:
        parsedObject.formations?.push({
          name: result?.education_degree && result?.education_degree[index],
        });
        break;
      case EDUCATION_CLASS.DIPLOMA:
        parsedObject.diplomas?.push({
          name: result?.education_degree && result?.education_degree[index],
        });
        break;
    }
  });

  parsedObject.skills?.softSkills.push({ name: 'a' });

  return parsedObject;
};

export const generateUniqueKey = (items: { uniqueKey?: number }[]) => {
  const ans = items.reduce((cum, cur) => Math.max(cum, cur.uniqueKey ?? 0), 0) + 1;
  return ans;
};
