import {
  ModalDeprecated,
  Button,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLCACES } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import { DefaultIcon } from 'src/Assets';
import CriteriaCard from 'src/Components/CriteriaCard';
import SelectCACES from 'src/Containers/Selects/SelectCACES';
import { useFetchCACES } from 'src/Hooks/Habilitations';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { cacesIconMapping } from 'src/Services/IconMapping';

import CACESCategoryList from './CACESCategoryList';
import styles from './CACESModal.module.scss';
import { Props } from './CACESModal.types';

const CACESModal = ({ onClose, caces, onCacesChange }: Props) => {
  const [selectedCaces, setSelectedCaces] = useState(caces);
  const [openCategory, setOpenCategory] = useState('');
  const { data, isSuccess, isLoading } = useFetchCACES();

  const cacesWithIcon = data?.map(cacesCategory => {
    return {
      caces: cacesCategory.caces,
      categoryLabel: cacesCategory.categoryLabel,
      categoryId: cacesCategory.categoryId,
      icon: cacesIconMapping[cacesCategory.categoryId as keyof typeof cacesIconMapping] ?? (
        <DefaultIcon />
      ),
    };
  });

  const selectedCacesIds = selectedCaces.map(selectedCacesItem => selectedCacesItem.identifier);

  const addOrDeleteCaces = (cacesItem: MatchingCriteriaItemWithLabel) =>
    selectedCacesIds.includes(cacesItem.identifier)
      ? setSelectedCaces(
          selectedCaces.filter(
            selectedCacesItem => selectedCacesItem.identifier !== cacesItem.identifier
          )
        )
      : setSelectedCaces([...selectedCaces, cacesItem]);

  return (
    <ModalDeprecated
      icon={<IllusRLCACES />}
      nested
      onClose={onClose}
      title="CACES"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedCaces(caces);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onCacesChange(selectedCaces);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle
        title="choisir parmi les catégories ci-dessous"
        className={styles.categoryListContainer}
      >
        {isLoading &&
          new Array(6).fill(undefined).map((_, idx) => <CACESCategoryList.Loading key={idx} />)}
        {isSuccess && (
          <>
            {cacesWithIcon?.map(cacesCategoryWithIcon => (
              <CACESCategoryList
                key={cacesCategoryWithIcon.categoryId}
                open={openCategory === cacesCategoryWithIcon.categoryId}
                onToogleChange={
                  openCategory === cacesCategoryWithIcon.categoryLabel
                    ? () => setOpenCategory('')
                    : () => setOpenCategory(cacesCategoryWithIcon.categoryId)
                }
                cacesCategory={cacesCategoryWithIcon}
                selectedCacesIds={selectedCacesIds}
                onItemClick={addOrDeleteCaces}
              />
            ))}
            <div className={styles.separator}></div>
          </>
        )}
      </WithLightTitle>
      <SelectCACES
        selectedCaces={selectedCaces}
        setCaces={setSelectedCaces}
        caces={data !== undefined ? data?.map(cacesCategory => cacesCategory.caces).flat(1) : []}
      />
      {selectedCaces.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${selectedCaces.length} CACES ajoutée(s)`}>
            {selectedCaces.map((caces, idx) => (
              <React.Fragment key={caces.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  className={styles.criteriaCard}
                  title={caces.label ?? ''}
                  weight={caces.wishPriority}
                  onWeightChange={weight => {
                    const newCacesItem = {
                      label: caces.label,
                      identifier: caces.identifier,
                      wishPriority: weight,
                    };
                    const newCaces = selectedCaces.slice();
                    newCaces[idx] = newCacesItem;
                    setSelectedCaces(newCaces);
                  }}
                  onDelete={() => {
                    const cacesTable = selectedCaces.filter(
                      otherCaces => otherCaces.identifier !== caces.identifier
                    );
                    setSelectedCaces(cacesTable);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </ModalDeprecated>
  );
};

export default CACESModal;
