import classnames from 'classnames';
import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './CriteriaButtonLoader.module.scss';
import { Props } from './CriteriaButtonLoader.types';

const CriteriaButtonLoader = ({ className }: Props) => (
  <div className={classnames(styles.container, className)}>
    <ContentLoader height="4.5rem" width="100%" uniqueKey="criteriaButton">
      <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
      <rect x="2%" y="50" rx="4" ry="4" width="80%" height="10" />
    </ContentLoader>
  </div>
);

export default CriteriaButtonLoader;
