import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLSkills } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectSkills from 'src/Containers/Selects/SelectSkills';

import styles from './SkillModal.module.scss';
import { Props } from './SkillModal.types';

const SkillModal = ({ skills, onSkillsChange, onClose }: Props) => {
  const [selectedSkills, setSelectedSkills] = useState(skills);
  return (
    <ModalDeprecated
      icon={<IllusRLSkills />}
      nested
      onClose={onClose}
      title="compétences"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedSkills(skills);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onSkillsChange(selectedSkills);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectSkills selectedSkills={selectedSkills} setSkills={setSelectedSkills} />
      {selectedSkills.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${selectedSkills.length} compétence(s) ajoutée(s)`}>
            {selectedSkills.map((skill, idx) => (
              <React.Fragment key={skill.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  className={styles.criteriaCard}
                  title={skill.label ?? ''}
                  weight={skill.wishPriority}
                  onWeightChange={weight => {
                    const newSkill = {
                      label: skill.label,
                      identifier: skill.identifier,
                      wishPriority: weight,
                    };
                    // eslint-disable-next-line prefer-const
                    const newSkills = selectedSkills.slice();
                    newSkills[idx] = newSkill;
                    setSelectedSkills(newSkills);
                  }}
                  onDelete={() => {
                    const skillTable = selectedSkills.filter(
                      otherSkill => otherSkill.identifier !== skill.identifier
                    );
                    setSelectedSkills(skillTable);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </ModalDeprecated>
  );
};

export default SkillModal;
