import React from 'react';

import ScoringButton from 'src/Components/ScoringButton';
import { ScoringEnum } from 'src/Services/API';

import styles from './ScoringButtonWithDisplayMode.module.scss';
import { OwnProps } from './ScoringButtonWithDisplayMode.types';

const ScoringButtonWithDisplayMode = ({ isDisplayMode, ...props }: OwnProps) => {
  const getStringValue = (num?: ScoringEnum) => {
    switch (num) {
      case ScoringEnum.High:
        return '+++';
      case ScoringEnum.Medium:
        return '+/-';
      case ScoringEnum.Low:
        return '---';
      default:
        return 'info inconnue';
    }
  };
  return (
    <>
      {isDisplayMode ? (
        <div className={styles.answerText}>{getStringValue(props.value)}</div>
      ) : (
        <ScoringButton {...props} />
      )}
    </>
  );
};
export default ScoringButtonWithDisplayMode;
