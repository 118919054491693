import * as z from 'zod';

export const contextInformationSchema = z.object({
  interviewDate: z.date(),
  hiringDate: z.date().optional(),
  agencyName: z.string(),
  cgc: z
    .object({
      id: z.string().optional(),
      label: z.string().optional(),
      societyId: z.string().optional(),
      brandCode: z.string().optional(),
    })
    .optional(),
});
