import { MatchingResult, MissionWithoutSchedule } from 'src/Services/API';

export const transformSelectedCandidates = (candidates: MatchingResult[]) => {
  return candidates.flatMap(candidate => {
    return {
      name: `• ${candidate.firstName} ${candidate.name}`,
      mainQualification: candidate.mainQualification?.label ?? '',
    };
  });
};

export const transformSelectedMissions = (missions: MissionWithoutSchedule[]) => {
  return missions.flatMap(mission => {
    return {
      name: `• ${mission.missionId}` ?? '',
      qualification: mission.qualification?.label ?? '',
    };
  });
};

export const transformMissionsWithCandidates = (
  missions: MissionWithoutSchedule[],
  candidates: MatchingResult[]
) => {
  return missions
    .map(mission => {
      const missionCandidatesIds = mission?.candidates?.map(candidate => candidate.id) ?? [];
      const matchingCandidates = candidates.filter(candidate =>
        missionCandidatesIds.includes(candidate.candidateId)
      );

      return {
        mission: `• ${mission.missionId} - ${mission.qualification?.label}`,
        candidates: matchingCandidates,
      };
    })
    .filter(mission => mission.candidates.length > 0);
};
