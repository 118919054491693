import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckmarkCircleOutline,
  Email,
  Flux,
  ListCheck,
  Share,
  ThreeDotsOther,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

import { redCloud } from 'src/Assets';
import Card from 'src/Components/Card';
import DisplayModeSelector from 'src/Components/DisplayModeSelector';
import ListItem from 'src/Components/ListItem';
import RattachementRecapModal from 'src/Containers/Modals/RattachementRecapModal/RattachementRecapModal.component';
import { getSelectedMissions } from 'src/Redux/Missions/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { MatchingResult } from 'src/Services/API';

import SearchResultsSidebar from '../SearchResultsSidebar';

import styles from './CandidateResults.module.scss';
import { Props } from './CandidateResults.types';
import CandidateResultsLoader from './CandidateResultsLoader';

const CandidateResults = (props: Props) => {
  const selectedMissions = useSelector(getSelectedMissions);
  const [isSharingOpen, setIsSharingOpen] = useState(false);
  const [isRecapModalOpen, setIsRecapModalOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isSelectionModeEnabled, setIsSelectionModeEnabled] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<MatchingResult[]>([]);

  const selectProfileAction = useCallback(
    (isSelected: boolean, candidate: MatchingResult) => {
      const index = selectedCandidates.findIndex(
        selectedCandidate => selectedCandidate.candidateId === candidate.candidateId
      );

      if (isSelected && index < 0) {
        setSelectedCandidates(prevState => [...prevState, candidate]);
      } else if (!isSelected && index >= 0) {
        setSelectedCandidates(
          selectedCandidates.filter(
            candidateInList => candidate.candidateId !== candidateInList.candidateId
          )
        );
      }
    },
    [selectedCandidates]
  );

  return (
    <div className={styles.container}>
      <SearchResultsSidebar />
      <div className={styles.resultsContainer}>
        <div className={styles.titleBar}>
          <div className={styles.title}>Les profils de votre recherche</div>
          {props.fetchStatus === FETCH_STATUS.FULFILLED && (
            <div className={styles.candidatesCount}>{` / ${
              props.matchingCandidates.length
            } résultat${props.matchingCandidates.length > 1 ? 's' : ''}`}</div>
          )}
          {props.fetchStatus === FETCH_STATUS.PENDING && (
            <ContentLoader height="2rem" width="5rem" uniqueKey="candidatesCount">
              <rect x="8%" y="10" rx="4" ry="4" width="100%" height="15" />
            </ContentLoader>
          )}
          <div className={styles.titleButton}>
            {selectedMissions.length > 0 && (
              <Button.Tertiary.XSmall
                className={classNames(
                  { borderRadius: '8px' },
                  { [styles.activeTrigger]: isSelectionModeEnabled }
                )}
                onClick={() => setIsSelectionModeEnabled(!isSelectionModeEnabled)}
                color={isSelectionModeEnabled ? 'green' : 'blue'}
              >
                <ListCheck />
              </Button.Tertiary.XSmall>
            )}
            <DisplayModeSelector />
          </div>
        </div>
        {props.fetchStatus === FETCH_STATUS.PENDING && <CandidateResultsLoader />}
        {props.fetchStatus === FETCH_STATUS.FULFILLED && props.matchingCandidates.length === 0 ? (
          <p>Pas de résultat pour votre recherche</p>
        ) : (
          <div
            className={
              props.displayMode === DISPLAY_MODE.GRID ? styles.resultCardGrid : styles.resultList
            }
          >
            {props.matchingCandidates
              ?.sort((a, b) => (a.firstName && b.firstName && a.firstName < b.firstName ? -1 : 1))
              .map(candidate => {
                return props.displayMode === DISPLAY_MODE.GRID ? (
                  <Card
                    key={candidate.candidateId}
                    candidate={candidate}
                    canBeSelected={isSelectionModeEnabled}
                    isSelected={selectedCandidates.includes(candidate)}
                    onSelectionChange={isSelected => selectProfileAction(isSelected, candidate)}
                  />
                ) : (
                  <ListItem
                    key={candidate.candidateId}
                    candidate={candidate}
                    canBeSelected={isSelectionModeEnabled}
                    isSelected={selectedCandidates.includes(candidate)}
                    onSelectionChange={isSelected => selectProfileAction(isSelected, candidate)}
                  />
                );
              })}
          </div>
        )}
        {props.fetchStatus === FETCH_STATUS.REJECTED && (
          <>
            <img className={styles.errorImg} src={redCloud} alt="error" />
            <p>Erreur</p>
          </>
        )}
      </div>
      {isSelectionModeEnabled && selectedCandidates.length > 0 && (
        <div className={styles.footer}>
          <div className={styles.numberSelectedProfiles}>
            <CheckmarkCircleOutline className={styles.checkMark} />
            <div className={styles.selectedCandidateText}>
              {`${selectedCandidates.length} ${
                selectedCandidates.length > 1 ? 'profils sélectionés' : 'profil sélectioné'
              }`}
            </div>
          </div>

          <div className={styles.centeredButtons}>
            <PopupMenu
              position={'top left'}
              width={230}
              onOpen={() => setIsSharingOpen(true)}
              onClose={() => setIsSharingOpen(false)}
              trigger={
                <Button.Tertiary
                  className={classNames(styles.buttons, { [styles.activeTrigger]: isSharingOpen })}
                >
                  <Share className={styles.buttonsIcons} />
                  partager
                </Button.Tertiary>
              }
            >
              <PopupMenu.Item icon={<Email />} text="envoyer par email" />
            </PopupMenu>

            <PopupMenu
              position={'top left'}
              width={370}
              onOpen={() => setIsActionOpen(true)}
              onClose={() => setIsActionOpen(false)}
              trigger={
                <Button.Tertiary
                  className={classNames(styles.buttons, { [styles.activeTrigger]: isActionOpen })}
                >
                  <ThreeDotsOther className={styles.buttonsIcons} /> actions
                </Button.Tertiary>
              }
            >
              <PopupMenu.Item
                icon={<Flux />}
                text="rattacher à la commande"
                onClick={() => selectedCandidates.length && setIsRecapModalOpen(true)}
              />
            </PopupMenu>
          </div>
          <div className={styles.leftText}>cliquez sur les profils à sélectionner</div>
        </div>
      )}
      <RattachementRecapModal
        isOpen={isRecapModalOpen}
        onClose={() => {
          setIsRecapModalOpen(false);
          setSelectedCandidates([]);
        }}
        missions={selectedMissions}
        candidates={selectedCandidates}
      />
    </div>
  );
};

export default CandidateResults;
