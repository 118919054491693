import * as z from 'zod';

export const experiencesSchemaObject = z.object({
  contractType: z.object({ id: z.string().optional(), label: z.string().optional() }).optional(),
  companyName: z.string().optional(),
  qualification: z.object({ id: z.string().optional(), label: z.string().optional() }).optional(),
  startDate: z.date().optional(),
  duration: z.number().optional(),
  endDate: z.date().optional(),
  isInternalExperience: z.boolean().optional(),
  companyId: z.string().optional(),
  endReason: z.object({ id: z.string().optional(), label: z.string().optional() }).optional(),
});

export const candidateExperiencesSchema = z.object({
  exchanges: z.object({
    importantNeeds: z.array(z.string()).max(10),
    experienceExchange: z.object({
      responses: z.array(
        z.object({ id: z.number(), label: z.string(), value: z.string().optional() })
      ),
    }),
  }),
  experiences: z.array(experiencesSchemaObject),
});

export const currentNeedSchema = (importantNeeds: string[]) =>
  z
    .object({
      currentNeed: z.string().max(50),
    })
    .refine(
      ({ currentNeed }) => {
        return !importantNeeds.includes(currentNeed);
      },
      {
        message: 'Ce besoin a déjà été renseigné',
        path: ['currentNeed'],
      }
    )
    .refine(
      () => {
        return !(importantNeeds.length > 9);
      },
      {
        message: 'Vous ne pouvez renseigner que 10 besoins',
        path: ['currentNeed'],
      }
    )
    .refine(
      ({ currentNeed }) => {
        return currentNeed !== '';
      },
      {
        message: 'Veuillez saisir un mot valide',
        path: ['currentNeed'],
      }
    );

export type CandidateExperiencesSchemaType = z.infer<typeof candidateExperiencesSchema>;
export type KeyOfCandidateExperiencesSchemaTypeQuestion = keyof CandidateExperiencesSchemaType;
export type CandidateExperiencesSchemaExperiencesType = z.infer<typeof experiencesSchemaObject>;
