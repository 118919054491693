import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateBirthInfo, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateBirthInfo = (
  id: string,
  extraOptions?: UseQueryOptions<CandidateBirthInfo, unknown, CandidateBirthInfo, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateBirthInfo, id],
    async () => {
      const candidate = await CandidatesService.candidatesControllerGetBirthInformations({
        id: id,
      });
      return candidate;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
