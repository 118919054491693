import { DatePickerInputDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';

import styles from './DatePickerInputWithDisplayMode.module.scss';
import { OwnProps } from './DatePickerInputWithDisplayMode.types';

const DatePickerInputWithDisplayMode = ({ isDisplayMode, ...props }: OwnProps) => {
  return (
    <>
      {isDisplayMode ? (
        <div className={styles.text}>{moment(props.date).format('L')}</div>
      ) : (
        <DatePickerInputDeprecated {...props} />
      )}
    </>
  );
};
export default DatePickerInputWithDisplayMode;
