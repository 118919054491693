import { ComboBox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchServices } from 'src/Hooks/Service';
import { getCompany } from 'src/Redux/Search/Selectors';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectService.types';

const SelectService = ({ setService, selectedService }: Props) => {
  const [keyword, setKeyword] = useState('');
  const company = useSelector(getCompany);
  const serviceQuery = useFetchServices(company?.identifier ?? '');
  const fetchStatus = toFetchStatus(serviceQuery);
  const researchedServices = serviceQuery.data
    ? serviceQuery.data.filter(x => x.label?.toLowerCase().includes(keyword.toLowerCase()))
    : [];
  return (
    <WithLightTitle
      title={`${researchedServices.length} service${
        researchedServices.length > 1 ? 's' : ''
      } disponible${researchedServices.length > 1 ? 's' : ''}`}
    >
      <ComboBox
        id="services"
        value={selectedService}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={setKeyword}
        onChange={service => {
          if (service !== null) {
            setService(service);
          }
          setKeyword('');
        }}
        searchResults={researchedServices}
        placeholder="1 caractère minimum"
        minLengthToSearch={1}
        fetchStatus={fetchStatus}
      />
    </WithLightTitle>
  );
};

export default SelectService;
