import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLLicense } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

import {
  LicenceA,
  LicenceA1,
  LicenceB,
  LicenceBE,
  LicenceC,
  LicenceC1,
  LicenceC1E,
  LicenceCE,
  LicenceD,
  LicenceD1,
  LicenceD1E,
  LicenceDE,
} from 'src/Assets';
import DrivingLicenceCard from 'src/Components/DrivingLicenceCard';
import { useFetchDrivingLicences } from 'src/Hooks/DrivingLicence';

import styles from './DrivingLicenceModal.module.scss';
import { Props } from './DrivingLicenceModal.types';

const DrivingLicenceModal = ({ drivingLicences, onDrivingLicenceChange, onClose }: Props) => {
  const [selectedDrivingLicences, setSelectedDrivingLicences] = useState(drivingLicences ?? []);
  const { data, isLoading, isSuccess } = useFetchDrivingLicences();
  const licences = data ?? [];
  const iconMapping = {
    A: <LicenceA />,
    A1: <LicenceA1 />,
    B: <LicenceB />,
    C: <LicenceC />,
    D: <LicenceD />,
    EB: <LicenceBE />,
    EC: <LicenceCE />,
    ED: <LicenceDE />,
    C1: <LicenceC1 />,
    C1E: <LicenceC1E />,
    D1E: <LicenceD1E />,
    D1: <LicenceD1 />,
  };

  const licencesWithIcon = licences.map(element => {
    return {
      licenceInformation: element,
      icon: iconMapping[element.identifier as keyof typeof iconMapping],
    };
  });
  return (
    <ModalDeprecated
      icon={<IllusRLLicense />}
      nested
      onClose={onClose}
      title="permis"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedDrivingLicences(drivingLicences);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onDrivingLicenceChange(selectedDrivingLicences);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <div
        className={styles.selectedNumberInfo}
      >{`${selectedDrivingLicences.length} sélectionné(s)`}</div>
      <WithLightTitle title="sélectionner parmi la liste suivante">
        {isLoading &&
          new Array(5).fill(null).map((_, idx) => (
            <ContentLoader key={idx} height="4.5rem" width="100%" uniqueKey="licences">
              <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
              <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
            </ContentLoader>
          ))}
        {isSuccess &&
          licencesWithIcon?.map(licence => {
            const isSelected = selectedDrivingLicences
              .map(drivingLicence => drivingLicence.identifier)
              .includes(licence.licenceInformation.identifier);
            return (
              <DrivingLicenceCard
                key={licence.licenceInformation.identifier}
                icon={licence.icon}
                drivingLicence={licence.licenceInformation}
                selected={isSelected}
                onClick={() => {
                  isSelected
                    ? setSelectedDrivingLicences(
                        selectedDrivingLicences.filter(
                          drivingLicence =>
                            drivingLicence.identifier !== licence.licenceInformation.identifier
                        )
                      )
                    : setSelectedDrivingLicences([
                        ...selectedDrivingLicences,
                        licence.licenceInformation,
                      ]);
                }}
              />
            );
          })}
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default DrivingLicenceModal;
