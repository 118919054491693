import {
  ComboBox,
  DatePickerInputDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ComboBox as ComboBoxFormFields,
  InclusiveSelectionGroup,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import GenderPicker from 'src/Containers/AddNewTalent/FormAddNewTalent/Components/GenderPicker';
import { useFetchCountries } from 'src/Hooks/Locations/useFetchCountries';
import { useFetchDepartments } from 'src/Hooks/Locations/useFetchDepartments';
import { useFetchNationalities } from 'src/Hooks/Locations/useFetchNationalities';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Country, Department, Nationality } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import AddNewTalentTextInputs from '../AddNewTalentTextInputs/AddNewTalentTextInputs.component';
import { formAddNewTalentSchemaType } from '../FormAddNewTalent.types';
import SelectCity from '../SelectCity/SelectCity.component';

import styles from './IdentityStep.module.scss';
import { Props } from './IdentityStep.types';

const textInputIdentityData: { name: keyof formAddNewTalentSchemaType; placeholder: string }[] = [
  { name: 'name', placeholder: 'nom' },
  { name: 'firstName', placeholder: 'prénom' },
  { name: 'birthName', placeholder: 'nom de naissance' },
  { name: 'phone', placeholder: 'téléphone mobile' },
  { name: 'email', placeholder: 'email' },
  { name: 'socialSecurityNumber', placeholder: 'numéro de sécurité sociale' },
];

const contractTypesRef = [
  { key: 'INT', label: 'intérim' },
  { key: 'CDD', label: 'CDD' },
  { key: 'CDI', label: 'CDI' },
  { key: 'VAC', label: 'vacataire' },
  { key: 'LIB', label: 'libéral' },
];

const IdentityStep = ({ control, watch, trigger }: Props) => {
  const units = useSelector(getPerimeterUnits);
  const birthDateController = useController({ control, name: 'birthDate' });
  const birthDepartmentController = useController({ control, name: 'birthDepartment' });

  const [departmentKeyword, setDepartmentKeyword] = useState('');
  const [countryKeyword, setCountryKeyword] = useState('');
  const [nationalityKeyword, setNationalityKeyword] = useState('');
  const [agencyKeyword, setAgencyKeyword] = useState('');
  const departments = useFetchDepartments();
  const countries = useFetchCountries();
  const nationalities = useFetchNationalities();

  const [chosenDepartment, setChosenDepartment] = useState<Department | undefined>(
    departments.data?.find(department => department?.id === birthDepartmentController.field.value)
  );
  return (
    <>
      <div className={styles.formIdentityContainer}>
        <div className={styles.title}> vérification des données d'identité 1/3 </div>
        <WithLightTitle title="civilité">
          <GenderPicker control={control} name="gender" />
        </WithLightTitle>
        <AddNewTalentTextInputs control={control} textInputData={textInputIdentityData} />
        <WithLightTitle title="date de naissance" className={styles.birthDate}>
          <DatePickerInputDeprecated
            onSelectDate={date => birthDateController.field.onChange(date)}
            date={birthDateController.field.value} //TODO: put showYearDropdown when update react-components
          />
        </WithLightTitle>
        <WithLightTitle title="lieu de naissance" className={styles.birthCity}>
          <SelectCity control={control} watch={watch} trigger={trigger} name="birthCity" />
        </WithLightTitle>
        <WithLightTitle title="département de naissance" className={styles.birthDepartment}>
          <ComboBox
            id="birthDepartment"
            value={chosenDepartment}
            keyValueExtractor={(searchResult: Department) => ({
              key: searchResult?.id ?? '',
              value: searchResult?.label ?? '',
            })}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onSearch={(word: string) => {
              setDepartmentKeyword(word);
            }}
            onChange={department => {
              birthDepartmentController.field.onChange(department?.id);
              setChosenDepartment(department ?? undefined);
              setDepartmentKeyword('');
            }}
            searchResults={
              departments?.data?.filter(x =>
                x?.label?.toLowerCase().includes(departmentKeyword.toLowerCase())
              ) ?? []
            }
            placeholder="département de naissance"
            minLengthToSearch={0}
            fetchStatus={toFetchStatus(departments)}
          />
        </WithLightTitle>
        <WithLightTitle title="pays de naissance" className={styles.birthCountry}>
          <ComboBoxFormFields
            id="birthCountry"
            control={control}
            name="birthCountry"
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onSearch={(word: string) => {
              setCountryKeyword(word);
            }}
            minLengthToSearch={0}
            searchResults={
              countries?.data?.filter(x =>
                x?.label?.toLowerCase().includes(countryKeyword.toLowerCase())
              ) ?? []
            }
            useNativeOptionFiltering
            keyValueExtractor={(country: Country) => ({
              key: country?.id?.toString() ?? '',
              value: country?.label ?? '',
            })}
            fetchStatus={FETCH_STATUS.FULFILLED}
            placeholder="choisissez un pays"
          />
        </WithLightTitle>
        <WithLightTitle title="nationalité" className={styles.nationality}>
          <ComboBoxFormFields
            id="nationality"
            control={control}
            name="nationality"
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onSearch={(word: string) => {
              setNationalityKeyword(word);
            }}
            searchResults={
              nationalities?.data?.filter(x =>
                x?.label?.toLowerCase().includes(nationalityKeyword.toLowerCase())
              ) ?? []
            }
            keyValueExtractor={(searchResult: Nationality) => ({
              key: searchResult?.id ?? '',
              value: searchResult?.label ?? '',
            })}
            placeholder="nationalité"
            minLengthToSearch={0}
            fetchStatus={toFetchStatus(nationalities)}
          />
        </WithLightTitle>
      </div>
      <div className={styles.formOtherContainer}>
        <div className={styles.title}> autre </div>
        <WithLightTitle title="types de contract" className={styles.acceptedContracts}>
          <InclusiveSelectionGroup
            name="contractAccepted"
            control={control}
            values={contractTypesRef}
            getKey={({ key }) => key}
            getStringValue={({ label }) => label}
            getSelectedFormValue={values => values.map(({ key }) => key)}
            getSelectedValues={(keys?: string[]) =>
              keys
                ?.map(currenKey => contractTypesRef.find(({ key }) => key === currenKey))
                .filter((value): value is { key: string; label: string } => value !== undefined) ??
              []
            }
          />
        </WithLightTitle>
        <WithLightTitle title="nom de l'agence" className={styles.agency}>
          <ComboBoxFormFields
            id="agencies"
            control={control}
            name="agencyId"
            disableClearable
            isOptionEqualToValue={(option, value) => option === value}
            minLengthToSearch={0}
            searchResults={
              units.filter(x => x?.toLowerCase().includes(agencyKeyword.toLowerCase())) ?? []
            }
            useNativeOptionFiltering
            keyValueExtractor={(agency: string) => ({
              key: agency,
              value: agency ?? '',
            })}
            onSearch={(word: string) => {
              setAgencyKeyword(word);
            }}
            fetchStatus={FETCH_STATUS.FULFILLED}
            placeholder="choisissez une agence"
          />
        </WithLightTitle>
      </div>
    </>
  );
};

export default IdentityStep;
