import { useMutation, useQuery, UseMutationOptions } from '@tanstack/react-query';

import { MatchingCriteriaItemWithLabel, WishPriority, RepositoryService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useFetchHabilitations = (
  extraOptions?: UseMutationOptions<MatchingCriteriaItemWithLabel[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchHabilitations],
    async (keyword: string) => {
      const habilitations = await RepositoryService.habilitationsControllerGetHabilitations({
        keyword,
      });
      const results = habilitations.map(habilitation => {
        return {
          identifier: habilitation.id ?? '',
          label: habilitation.label,
          wishPriority: WishPriority.Obligatoire,
        };
      });
      return results;
    },
    { ...extraOptions }
  );
};

export const useFetchCACES = () => {
  return useQuery(
    [QueryKeys.fetchCaces],
    async () => {
      const caces = await RepositoryService.habilitationsControllerGetCaces();
      const results = caces.map(cacesCategory => {
        return {
          categoryLabel: cacesCategory.categoryLabel,
          categoryId: cacesCategory.categoryId,
          caces: cacesCategory.caces.map(cacesItem => {
            return {
              identifier: cacesItem.id,
              label: cacesItem.label,
              wishPriority: WishPriority.Obligatoire,
            };
          }),
        };
      });
      return results;
    },
    { staleTime: 300000 }
  );
};
export const useFetchElectricHabilitations = () => {
  return useQuery(
    [QueryKeys.fetchElectricHabilitations],
    async () => {
      const electricHabilitations =
        await RepositoryService.habilitationsControllerGetElectricHabilitations();
      const results = electricHabilitations.map(voltage => {
        return {
          currentId: voltage.currentId,
          value: voltage.value,
          levels: voltage.levels.map(electricHabilitationsCategory => {
            return {
              categoryLabel: electricHabilitationsCategory.categoryLabel,
              categoryId: electricHabilitationsCategory.categoryId,
              currents: electricHabilitationsCategory.currents.map(current => {
                return {
                  key: current.key,
                  habilitation: {
                    identifier: current.electricHabilitation.id,
                    label: current.electricHabilitation.label,
                    wishPriority: WishPriority.Obligatoire,
                  },
                };
              }),
            };
          }),
        };
      });
      return results;
    },
    { staleTime: 300000 }
  );
};
