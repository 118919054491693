import { IllusRLKeywordSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasKeywords } from 'src/Redux/Search/Selectors';

import styles from './KeywordButton.module.scss';

const KeywordButton = () => {
  const _hasKeyword = useSelector(hasKeywords);
  const keywordModal = useModalWithLocation('keyword');
  return (
    <div>
      <CriteriaButton
        label="mots clés"
        icon={<IllusRLKeywordSmall className={styles.icon} />}
        selected={_hasKeyword}
        onClick={() => keywordModal.open()}
      />
    </div>
  );
};

export default KeywordButton;
