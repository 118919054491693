import { LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckmarkCircleOutline,
  Contract,
  Pencil,
  People,
  Timing,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

import ActionsButton from 'src/Containers/CandidateResults/ActionsButton/ActionsButton.component';
import {
  CANDIDATE_STATUS_LABEL,
  getCandidateStatusStyle,
} from 'src/Containers/CandidateResults/CandidateResults.types';

import ScoringIndicator from '../ScoringIndicator';

import styles from './ListItem.module.scss';
import { Props } from './ListItem.types';

const ListItem = ({ candidate, canBeSelected, isSelected, onSelectionChange }: Props) => {
  const fullName = `${candidate.firstName} ${candidate.name}`;
  const qualification = candidate.mainQualification?.label ?? '';
  const location = `${candidate.zipCode} ${candidate.city}`;
  const contractEndDate = moment(candidate.availabilityDate).isAfter(moment())
    ? moment(candidate.availabilityDate)
    : undefined;

  const [isActionsOpen, setIsActionsOpen] = useState<boolean>(false);
  return (
    <div
      className={classnames(styles.container, styles.containerUnselected, {
        [styles.containerSelected]: canBeSelected && isSelected,
      })}
      onClick={() => {
        if (canBeSelected) {
          onSelectionChange(!isSelected);
        }
      }}
    >
      <div
        className={classnames(
          styles.leftContainer,
          getCandidateStatusStyle(candidate.candidateStatus)
        )}
      >
        <div>
          <LetterBadge
            text={fullName
              .split(/\s/)
              .map((word: string) => word[0])
              .join('')
              .substring(0, 2)}
            className={classnames({
              [styles.whiteBadge]:
                candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'],
            })}
          />
          <div className={styles.fullName}>{fullName}</div>
          <div className={styles.qualification}>{qualification}</div>
          <div className={styles.location}>{location}</div>
        </div>
        <div className={styles.topContainerSelected}>
          {canBeSelected ? (
            isSelected ? (
              <CheckmarkCircleOutline className={styles.checkMark} />
            ) : (
              <div className={styles.checkMarkEmpty} />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={classnames(styles.rightContainer, {
          [styles.rightContainerSelected]: isActionsOpen,
        })}
      >
        <div className={styles.middleSection}>
          <div className={styles.middleSectionTopPart}>
            <div>
              <div className={styles.contractType}>
                {(() => {
                  switch (candidate.candidateStatus) {
                    case CANDIDATE_STATUS_LABEL.Intérimaire:
                      return 'intérimaire';
                    case CANDIDATE_STATUS_LABEL['CDI Interim']:
                      return 'CDI intérimaire';
                    case CANDIDATE_STATUS_LABEL['Candidat évalué']:
                      return 'candidat';
                  }
                })()}
              </div>
              <div className={styles.origin}>{`origine : ${candidate.origin}`}</div>
            </div>
            {contractEndDate !== undefined && (
              <div className={styles.contractLineContainer}>
                <Contract className={styles.contractIcon} />
                <div className={styles.contractEnd}>en contrat</div>
                <div className={styles.contractEnd}>{`fin ${contractEndDate.format(
                  'DD/MM/YYYY'
                )}`}</div>
              </div>
            )}
          </div>
          <div className={styles.separator}></div>
          <div className={styles.bigScoringContainer}>
            {candidate.scoring !== 0 && candidate.scoring !== undefined && (
              <div className={styles.bigScoring}>
                <div className={styles.scoringSubtitle}>pertinence du profil</div>
                <ScoringIndicator scoring={candidate.scoring} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.rightSectionTop}>
            <div className={styles.imageIndicator}>
              <Timing className={styles.smallIcon} />
              <div className={styles.indicatorText}>{`${candidate.nbHours} hrs`}</div>
            </div>
            <div className={styles.rightSectionSeparator} />
            <div className={styles.imageIndicator}>
              <People className={styles.smallIcon} />
              <div className={styles.indicatorText}>{`${candidate.nbCompany} clients`}</div>
            </div>
            <div className={styles.rightSectionSeparator} />
            <div className={styles.imageIndicator}>
              <Pencil className={styles.smallIcon} />
              <div className={styles.indicatorText}>{`${candidate.nbMissions} contrats`}</div>
            </div>
          </div>
          <div className={styles.rightSectionBottom}>
            <div className={styles.buttonContainer} onClick={event => event.stopPropagation()}>
              <ActionsButton
                candidateId={candidate.candidateId ?? ''}
                candidateName={fullName}
                candidateStatus={candidate.candidateStatus ?? ''}
                canBeSelected={canBeSelected}
                isActionsOpen={isActionsOpen}
                setIsActionsOpen={setIsActionsOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
