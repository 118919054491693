import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getDrivingLicence } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import DrivingLicenceModal from './DrivingLicenceModal.component';

const MemoDrivingLicenceModal = React.memo(DrivingLicenceModal);

const EnhancedDrivingLicenceModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const drivingLicences = useSelector(getDrivingLicence);
  const onDrivingLicenceChange = useCallback(
    (drivingLicences: MatchingCriteriaItemWithLabel[]) =>
      dispatch(searchActions.setDrivingLicences(drivingLicences)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoDrivingLicenceModal
      drivingLicences={drivingLicences}
      onDrivingLicenceChange={onDrivingLicenceChange}
      onClose={onClose}
    />
  );
};

export default EnhancedDrivingLicenceModal;
