import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getHabilitations } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import HabilitationModal from './HabilitationModal.component';

const MemoHabilitationModal = React.memo(HabilitationModal);

const EnhancedHabilitationModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const habilitations = useSelector(getHabilitations);
  const onHabilitationChange = useCallback(
    (habilitations: MatchingCriteriaItemWithLabel[]) =>
      dispatch(searchActions.setHabilitations(habilitations)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <MemoHabilitationModal
      habilitations={habilitations}
      onClose={onClose}
      onHabilitationChange={onHabilitationChange}
    />
  );
};

export default EnhancedHabilitationModal;
