import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { YesNoUnknown } from 'src/Services/API';

import styles from './YesNoUnknown.module.scss';
import { Props } from './YesNoUnknown.types';

const YesNoUnknownComponent = ({ value, label, onChange, isDisplayMode }: Props) => {
  const items = Object.values(YesNoUnknown);
  const getStringValue = (str: string) => {
    switch (str) {
      case YesNoUnknown.Yes:
        return 'oui';
      case YesNoUnknown.No:
        return 'non';
      default:
        return 'info inconnue';
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.question}>
        {isDisplayMode ? (
          <div>{getStringValue(value ?? '')}</div>
        ) : (
          <ExclusiveSelectionGroup
            selected={value}
            values={items}
            getKey={key => key}
            getStringValue={value => getStringValue(value)}
            setSelected={value => onChange(value)}
            canBeReset
          />
        )}
      </div>
    </div>
  );
};

export default YesNoUnknownComponent;
