import { FormFieldProps } from '@randstad-lean-mobile-factory/react-form-fields';
import { FieldPath, FieldValues } from 'react-hook-form';

export type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = FormFieldProps<object, TFieldValues, TName>;

export const behaviorSkillsSuggestions = [
  'assiduité',
  'motivation',
  'dynamisme',
  'ponctualité',
  'fiabilité',
  'présentation',
];

export enum SKILLS_TYPE {
  BUSINESSES_SKILLS = 'compétences métier',
  BEHAVIOR_SKILLS = 'compétences comportementales',
}
