import { useMutation } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { MatchingResult, MissionsService, MissionWithoutSchedule } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useAddCandidatesToMissions = () => {
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.addCandidatesToMissions, brandCode],
    async (props: { candidates: MatchingResult[]; missions: MissionWithoutSchedule[] }) => {
      const results = MissionsService.missionsControllerAddCandidates({
        body: {
          missionIds: props.missions.map(mission => mission.missionId ?? ''),
          candidates: props.candidates,
          qualificationId: props.missions[0].qualification?.id ?? '',
          brandCode,
        },
      });
      return results;
    },
    {
      onSuccess: () => {
        ReactGA.event('TT Rattaché', {
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      },
    }
  );
};
