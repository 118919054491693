import moment from 'moment';
import * as z from 'zod';

import { CandidateExperiencesSchemaExperiencesType } from 'src/Containers/CandidateRecruitingProcess/R1/CandidateExperiences/CandidateExperiences.types';

export interface Props {
  trigger?: JSX.Element;
  onValidate: (experience: CandidateExperiencesSchemaExperiencesType) => void;
  experienceToModify?: CandidateExperiencesSchemaExperiencesType;
  onClose?: () => void;
}

export const experienceModalSchema = z.object({
  currentExperience: z
    .object({
      contractType: z
        .object({ id: z.string().optional(), label: z.string().optional() })
        .optional(),
      companyName: z.string(),
      qualification: z
        .object({ id: z.string().optional(), label: z.string().optional() })
        .optional(),
      startDate: z.date().optional(),
      duration: z.number().optional(),
      endDate: z.date().optional(),
      isInternalExperience: z.boolean().optional(),
      companyId: z.string().optional(),
      endReason: z.object({ id: z.string().optional(), label: z.string().optional() }).optional(),
    })
    .refine(
      ({ companyName }) => {
        return companyName && companyName?.length > 0;
      },
      {
        message: "le nom d'entreprise doit être renseigné",
        path: ['companyName'],
      }
    )
    .refine(
      ({ qualification }) => {
        return qualification && !!qualification.id;
      },
      {
        message: 'la qualification doit être renseignée',
        path: ['qualification.id'],
      }
    )
    .refine(
      ({ startDate, endDate }) => {
        return startDate && endDate && moment(endDate).diff(startDate, 'months') >= 3;
      },
      {
        message: "la durée de l'expérience doit être supérieur à 3 mois",
        path: ['endDate'],
      }
    )
    .refine(
      ({ contractType }) => {
        return !!contractType?.id;
      },
      {
        message: 'le type de contrat doit être renseigné',
        path: ['contractType.id'],
      }
    ),
});
