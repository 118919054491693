import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getService } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import ServiceModal from './ServiceModal.component';

const MemoServiceModal = React.memo(ServiceModal);

const EnhancedServiceModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const service = useSelector(getService);
  const onServiceChange = useCallback(
    (service: MatchingCriteriaItemWithLabel) => dispatch(searchActions.setService(service)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return <MemoServiceModal service={service} onServiceChange={onServiceChange} onClose={onClose} />;
};

export default EnhancedServiceModal;
