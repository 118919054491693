import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { InterviewsExchangeQuestion, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchR1StrengthQuestions = (
  extraOptions?: UseQueryOptions<unknown, unknown, InterviewsExchangeQuestion[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchR1StrengthQuestions],
    async () => await RepositoriesService.repositoriesControllerGetR1StrengthQuestions(),
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};
