import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateBirthInfo } from 'src/Services/API';

import { useFetchCandidateBirthInfo } from './useFetchCandidateBirthInfo';

export const useFetchCandidateBirthInfoFromURL = (
  extraOptions?: UseQueryOptions<CandidateBirthInfo, unknown, CandidateBirthInfo, string[]>
) => {
  const candidateId = useParams<{ candidateId: string }>().candidateId;
  return useFetchCandidateBirthInfo(candidateId, extraOptions);
};
