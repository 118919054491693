import React from 'react';
import ContentLoader from 'react-content-loader';

import { CandidateBusinesses } from 'src/Components/CandidateBusinesses';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinessesFromURL } from 'src/Hooks/Candidates/useFetchCandidateBusinessesFromURL';

const SkillsByJob = () => {
  const businessesQuery = useFetchCandidateBusinessesFromURL();
  return (
    <div>
      {businessesQuery.isLoading &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="diploma">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {businessesQuery.isError && (
        <ErrorMessage message="une erreur est survenue dans la récupération des compétences" />
      )}
      {businessesQuery.isSuccess &&
        businessesQuery.data?.map((business, index) => (
          <CandidateBusinesses key={index} business={business} />
        ))}
    </div>
  );
};

export default SkillsByJob;
