import {
  CandidateInterviewsSummaryStatus,
  R1StatusForStats,
  R2StatusForStats,
} from 'src/Services/API';

import {
  R1PieLabels,
  R2PieLabels,
} from './InterviewStatistics/InterviewsPieCharts/InterviewsPieCharts.types';

export const R1PieLabelsMapping: { [key in R1StatusForStats]: R1PieLabels } = {
  [R1StatusForStats.refusé]: R1PieLabels.R1_REFUSE,
  [R1StatusForStats['accepté sans r2']]: R1PieLabels.R1_SOUMIS,
  [R1StatusForStats['en cours']]: R1PieLabels.R1_EN_COURS,
  [R1StatusForStats['accepté avec un r2']]: R1PieLabels.R1_SOUMIS,
  [R1StatusForStats['refusé par intérimaire']]: R1PieLabels.R1_DENIED,
  [R1StatusForStats['validé par dérogation']]: R1PieLabels.R1_SOUMIS,
};

export const R2PieLabelsMapping: { [key in R2StatusForStats]: R2PieLabels } = {
  [R2StatusForStats.refusé]: R2PieLabels.R2_REFUSE,
  [R2StatusForStats.accepté]: R2PieLabels.R2_VALIDE,
  [R2StatusForStats['en cours']]: R2PieLabels.R2_EN_COURS,
  [R2StatusForStats['refusé par intérimaire']]: R2PieLabels.R2_DENIED,
  [R2StatusForStats['validé par dérogation']]: R2PieLabels.R2_EXEMPTION,
};

export const R1PieToGlobalStatusMapping: {
  [key in R1PieLabels]: CandidateInterviewsSummaryStatus[];
} = {
  [R1PieLabels.R1_EN_COURS]: [CandidateInterviewsSummaryStatus['R1 en cours']],
  [R1PieLabels.R1_SOUMIS]: [
    CandidateInterviewsSummaryStatus['R2 à faire'],
    CandidateInterviewsSummaryStatus['R2 en planification'],
    CandidateInterviewsSummaryStatus['R2 planifié'],
  ],
  [R1PieLabels.R1_DENIED]: [CandidateInterviewsSummaryStatus['refusé par intérimaire']],
  [R1PieLabels.R1_REFUSE]: [CandidateInterviewsSummaryStatus['R1 refusé']],
};

export const R2PieToGlobalStatusMapping: {
  [key in R2PieLabels]: CandidateInterviewsSummaryStatus;
} = {
  [R2PieLabels.R2_EN_COURS]: CandidateInterviewsSummaryStatus['R2 en cours'],
  [R2PieLabels.R2_VALIDE]: CandidateInterviewsSummaryStatus['R2 terminé'],
  [R2PieLabels.R2_DENIED]: CandidateInterviewsSummaryStatus['refusé par intérimaire'],
  [R2PieLabels.R2_REFUSE]: CandidateInterviewsSummaryStatus['R2 refusé'],
  [R2PieLabels.R2_EXEMPTION]: CandidateInterviewsSummaryStatus['validé par dérogation'],
};
