import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { ActionsService, UpdateActionBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useCancelAction = (
  extraOptions?: UseMutationOptions<
    string,
    AxiosError,
    Omit<UpdateActionBody, 'brandCode'>,
    unknown
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.cancelAction],
    async (params: Omit<UpdateActionBody, 'brandCode'>): Promise<string> => {
      const result = await ActionsService.actionsControllerPostCancelAction({
        body: {
          actionId: params.actionId,
          brandCode: currentBrand?.brandCodeApiHeader ?? '',
        },
      });
      return result;
    },
    {
      onSuccess: () => {
        toast.success("L'action a été annulée avec succès");
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchActions] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateR1] });
      },
      onError: () => {
        toast.error("Une erreur a empêché l'annulation de l'action");
      },
      ...extraOptions,
    }
  );
};
