import {
  BadgeDeprecated,
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusRLKeyword,
  PlusCircle,
  WhiteCircleCross,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getKeywords } from 'src/Redux/Search/Selectors';

import { searchActions } from '../../../Redux/Search/Slice';
import { WishPriority } from '../../../Services/API';

import styles from './KeywordModal.module.scss';
import { keywordModalSchema } from './KeywordModal.schema';

const KeywordModal = () => {
  const dispatch = useDispatch();
  const activeKeywords = useSelector(getKeywords);
  const history = useHistory();

  const { control, handleSubmit, setValue } = useFormWithZodResolver({
    schema: keywordModalSchema,
    defaultValues: {
      currentKeyword: '',
      keywords: activeKeywords.map(activeKeyword => activeKeyword.label ?? ''),
    },
  });

  const keywordsResolver = useController({
    name: 'keywords',
    control,
  });

  const currentKeywordSubmit = useCallback(() => {
    handleSubmit(data => {
      keywordsResolver.field.onChange([...data.keywords, data.currentKeyword]);
      setValue('currentKeyword', '');
    })();
  }, [handleSubmit, keywordsResolver.field, setValue]);

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        currentKeywordSubmit();
      }
    },
    [currentKeywordSubmit]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => document.removeEventListener('keydown', handleEnter);
  }, [handleEnter]);

  return (
    <ModalDeprecated
      title="mots clés"
      icon={<IllusRLKeyword />}
      onClose={() => history.goBack()}
      open
      nested
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() =>
            setValue(
              'keywords',
              activeKeywords.map(activeKeyword => activeKeyword.label ?? '')
            )
          }
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => history.goBack()}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            dispatch(
              searchActions.setKeywords(
                keywordsResolver.field.value.map(keyword => {
                  return {
                    identifier: keyword,
                    label: keyword,
                    wishPriority: WishPriority.Obligatoire,
                  };
                })
              )
            );
            history.goBack();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title="vos mots clés">
        <TextInput
          rightIcon={
            <div onClick={currentKeywordSubmit}>
              <PlusCircle />
            </div>
          }
          name={'currentKeyword'}
          placeholder={`saisir un mot et appuyer sur "Entrée" pour l'ajouter`}
          control={control}
        />
      </WithLightTitle>
      {keywordsResolver.field.value.length > 0 && (
        <WithLightTitle
          title={`${keywordsResolver.field.value.length} mot(s) clé(s) ajouté(s)`}
          className={styles.summary}
        >
          {keywordsResolver.field.value.map((keyword, idx) => (
            <BadgeDeprecated
              key={`${idx}-${keyword}`}
              className={styles.activeBadge}
              value={keyword}
              leftIcon={<WhiteCircleCross />}
              onLeftIconClick={() =>
                setValue(
                  'keywords',
                  keywordsResolver.field.value.filter(k => k !== keyword)
                )
              }
            />
          ))}
        </WithLightTitle>
      )}
    </ModalDeprecated>
  );
};

export default KeywordModal;
