import * as z from 'zod';

import { useFetchSMICAmount } from 'src/Hooks/Repositories/useFetchSMICAmount';
import { R1Hours, R1Priority, YesNoChoicesEnum } from 'src/Services/API';

export function useCandidateValidationSchema() {
  const { data } = useFetchSMICAmount();
  const smic = data?.smic.monthlyAmount ?? 0;
  return z.object({
    comment: z.string().optional(),
    validation: z.nativeEnum(YesNoChoicesEnum).optional(),
    hoursPriorities: z
      .array(
        z.object({
          hours: z.nativeEnum(R1Hours),
          priority: z.nativeEnum(R1Priority),
        })
      )
      .optional(),
    gmmr: z
      .string()
      .refine(item => {
        return (
          item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= smic)
        );
      }, `Le montant doit être un nombre supérieur au smic (${smic}euros) `)
      .optional(),
    maxMobility: z
      .object({
        maxMobilityDistance: z
          .string()
          .refine(item => {
            return (
              item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= 0)
            );
          }, 'La distance doit être un nombre supérieur à 0')
          .optional(),
        maxMobilityTime: z
          .string()
          .refine(item => {
            return (
              item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= 0)
            );
          }, 'Le temps doit être un nombre supérieur à 0')
          .optional(),
      })
      .optional(),
    isCDI: z.boolean().optional(),
    jobs: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        contractCount: z.number(),
        companyCount: z.number(),
        qualifications: z.array(z.string()),
      })
    ),
    selectedJobs: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        contractCount: z.number(),
        companyCount: z.number(),
        qualifications: z.array(z.string()),
      })
    ),
    otherJobs: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        contractCount: z.number(),
        companyCount: z.number(),
        qualifications: z.array(z.string()),
      })
    ),
    mainQualificationId: z.string().optional(),
  });
}
