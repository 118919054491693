import { IllusRLLicenseSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasDrivingLicence } from 'src/Redux/Search/Selectors';

import styles from './DrivingLicenceButton.module.scss';

const DrivingLicenceButton = () => {
  const _hasDrivingLicence = useSelector(hasDrivingLicence);
  const drivingLicenceModal = useModalWithLocation('driving-licence');
  return (
    <div>
      <CriteriaButton
        label="permis"
        icon={<IllusRLLicenseSmall className={styles.icon} />}
        selected={_hasDrivingLicence}
        onClick={() => drivingLicenceModal.open()}
      />
    </div>
  );
};

export default DrivingLicenceButton;
