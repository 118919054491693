import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { MissionsService, MissionWithoutSchedule } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchMissions = (
  extraOptions?: UseMutationOptions<
    MissionWithoutSchedule[],
    unknown,
    { companyId: string; qualificationId: string }
  >
) => {
  const units =
    useSelector(getPerimeter)
      ?.companies?.map(company => company.agencies ?? [])
      .flat(1) ?? ([] as string[]);
  return useMutation(
    [MutationKeys.searchMissions],
    async (params: { companyId: string; qualificationId: string }) => {
      if (
        params.companyId === undefined ||
        params.qualificationId === undefined ||
        params.companyId === '' ||
        params.qualificationId === ''
      ) {
        throw new Error("le champ company ou qualification n'est pas défini");
      } else {
        const results = MissionsService.missionsControllerGetMissionsWithoutSchedule({
          body: {
            companyId: params.companyId,
            qualificationId: params.qualificationId,
            agencies: units,
          },
        });
        return results;
      }
    },
    { ...extraOptions }
  );
};
