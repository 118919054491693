import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CandidateInterviewsSummaryStatus } from 'src/Services/API';

import UserPreferencesState, { DISPLAY_MODE, CARD_FILTERS } from './Types';

export const initialState: UserPreferencesState = {
  cardDisplayMode: DISPLAY_MODE.GRID,
  dashboardStatus: Object.values(CandidateInterviewsSummaryStatus),
  cardFilters: [],
};

const { reducer, actions } = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setCardDisplayMode: (state, action: PayloadAction<DISPLAY_MODE>) => {
      state.cardDisplayMode = action.payload;
    },
    setDashboardStatus: (state, action: PayloadAction<CandidateInterviewsSummaryStatus[]>) => {
      state.dashboardStatus = action.payload;
    },
    setCardFilters: (state, action: PayloadAction<CARD_FILTERS[]>) => {
      state.cardFilters = action.payload;
    },
  },
});

export const userPreferencesReducer = reducer;
export const userPreferencesActions = actions;
