import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLQualification } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectQualifications from 'src/Containers/Selects/SelectQualifications';

import styles from './QualificationModal.module.scss';
import { Props } from './QualificationModal.types';

const QualificationModal = ({ qualifications, onQualificationChange, onClose }: Props) => {
  const [selectedQualifications, setSelectedQualifications] = useState(qualifications);

  return (
    <ModalDeprecated
      icon={<IllusRLQualification />}
      nested
      onClose={onClose}
      title="qualifications"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedQualifications(qualifications);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onQualificationChange(selectedQualifications);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectQualifications
        selectedQualifications={selectedQualifications}
        setQualifications={setSelectedQualifications}
      />
      {selectedQualifications.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${selectedQualifications.length} qualification(s) ajoutée(s)`}>
            {selectedQualifications.map((qualification, idx) => (
              <React.Fragment key={qualification.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  notMandatory={selectedQualifications.length > 1}
                  className={styles.criteriaCard}
                  title={qualification.label ?? ''}
                  weight={qualification.wishPriority}
                  onWeightChange={weight => {
                    const newQualification = {
                      label: qualification.label,
                      identifier: qualification.identifier,
                      wishPriority: weight,
                    };

                    // eslint-disable-next-line prefer-const
                    const newQualifications = selectedQualifications.slice();
                    newQualifications[idx] = newQualification;
                    setSelectedQualifications(newQualifications);
                  }}
                  onDelete={() => {
                    const qualificationTable = selectedQualifications.filter(
                      otherQualification =>
                        otherQualification.identifier !== qualification.identifier
                    );
                    setSelectedQualifications(qualificationTable);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </ModalDeprecated>
  );
};

export default QualificationModal;
