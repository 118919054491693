import { Kanban } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';
import ContentLoader from 'react-content-loader';

import { FETCH_STATUS } from 'src/Redux/Types';
import { CandidateInterviewsSummaryStatus } from 'src/Services/API';

import DetailButton from '../DetailButton';

import styles from './InterviewSection.module.scss';
import { Props } from './InterviewSection.types';

const toStyle = (state: CandidateInterviewsSummaryStatus) => {
  switch (state) {
    case CandidateInterviewsSummaryStatus['R1 en cours']:
      return styles.pendingR1;
    case CandidateInterviewsSummaryStatus['R2 à faire']:
    case CandidateInterviewsSummaryStatus['R2 en planification']:
      return styles.acceptedR1;
    case CandidateInterviewsSummaryStatus['R2 en cours']:
      return styles.pendingR2;
    case CandidateInterviewsSummaryStatus['R2 terminé']:
      return styles.acceptedR2;
    case CandidateInterviewsSummaryStatus['R1 refusé']:
      return styles.refused;
    case CandidateInterviewsSummaryStatus['R2 refusé']:
      return styles.refused;
    case CandidateInterviewsSummaryStatus['refusé par intérimaire']:
      return styles.refused;
    case CandidateInterviewsSummaryStatus['validé par dérogation']:
      return styles.acceptedR2;
    case CandidateInterviewsSummaryStatus['R2 planifié']:
      return styles.inPlanningR2;
    default:
      return '';
  }
};

const InterviewSection = ({ title, interviews, fetchStatus, showReassignCGC }: Props) => {
  return (
    <div className={styles.container}>
      {fetchStatus === FETCH_STATUS.PENDING &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="5rem" width="100%" uniqueKey="dashboard-todo">
            <rect x="2%" y="10" rx="4" ry="4" width="90%" height="200" />
          </ContentLoader>
        ))}
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <Kanban
          title={title}
          className={styles.kanban}
          children={interviews.map(interview => {
            const createdDate = interview.r2Interview?.createdAt
              ? moment(interview.r2Interview?.createdAt).format('L')
              : interview.r1Interview?.createdAt
              ? moment(interview.r1Interview?.createdAt).format('L')
              : '';
            const updatedDate = interview.r2Interview?.updatedAt
              ? moment(interview.r2Interview?.updatedAt).format('L')
              : interview.r1Interview?.updatedAt
              ? moment(interview.r1Interview?.updatedAt).format('L')
              : '';
            const hiringDate = interview?.r2Interview?.hiringDate
              ? moment(interview.r2Interview?.hiringDate).format('L')
              : interview?.r1Interview?.hiringDate
              ? moment(interview.r1Interview?.hiringDate).format('L')
              : undefined;
            const subtitle = `unité ${interview.agencyId} ${
              interview.cgcId ? ` | cgc ${interview.cgcId}` : ''
            } | 
        créé le ${createdDate}  | modifié le ${updatedDate} ${
              hiringDate ? " | date prévisionnelle d'embauche le " + hiringDate : ''
            }`;
            const statusBageElement = {
              badgeTitle: interview.status,
              badgeClassName: toStyle(interview.status),
            };
            return (
              <Kanban.Item
                key={interview.candidate?.id}
                title={`${interview.candidate?.name} ${interview.candidate?.firstName}`}
                subtitle={subtitle}
                badges={
                  interview.isCDI
                    ? [
                        statusBageElement,
                        { badgeTitle: 'CDI signé', badgeClassName: styles.signedCDIBadge },
                      ]
                    : [statusBageElement]
                }
                actionButton={
                  <DetailButton
                    candidateId={interview.candidate?.id ?? ''}
                    candidateIdFetchStatus={fetchStatus}
                    candidateInterviewsIdAndStatus={interview}
                    showReassignCGC={showReassignCGC}
                  />
                }
              />
            );
          })}
        />
      )}
    </div>
  );
};

export default InterviewSection;
