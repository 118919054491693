import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKeys } from 'src/Hooks/types';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidateDetailedBusiness, CandidatesService } from 'src/Services/API';

export const useFetchCandidateBusinesses = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateDetailedBusiness[], string[]>
) => {
  const brandCode = useSelector(getBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateBusinesses, id],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateBusiness({
        id,
        brandCode,
      });
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
