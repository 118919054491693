import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CandidateR2, R2InterviewsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCreateCandidateR2 = (
  id: string,
  extraOptions?: UseMutationOptions<CandidateR2, AxiosError, void, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.createCandidateR2, id],
    async () => {
      const candidateR2 = await R2InterviewsService.r2InterviewsControllerCreateCandidateR2({
        candidateId: id,
      });

      return candidateR2;
    },
    {
      onSuccess: () => {
        ReactGA.event('Formulaire R2 initié', {
          brandCode: currentBrand?.brandCodeApiHeader,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      },
      onError: error => {
        if (error.response?.status === 409) {
          toast.error('Un R2 existe déjà sur ce candidat');
        } else {
          toast.error('Une erreur a empêché la création du R2');
        }
      },
      ...extraOptions,
    }
  );
};
