import classnames from 'classnames';
import React from 'react';

import SearchSummary from 'src/Containers/SearchSummary';

import SearchCriteriaSelection from '../SearchCriteriaSelection';

import styles from './Home.module.scss';

const Home = () => (
  <div className={styles.container}>
    <SearchCriteriaSelection className={classnames(styles.section, styles.left)} />
    <SearchSummary className={styles.section} />
  </div>
);

export default Home;
