import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CandidateExternalExperience, CandidatesService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateExternalExperience = (
  id: string,
  extraOptions?: UseMutationOptions<void, AxiosError, CandidateExternalExperience[], unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.updateExternalExperience, id],
    async (experiences: CandidateExternalExperience[]) => {
      await CandidatesService.candidatesControllerUpdateCandidateExternalExperience({
        id,
        body: { experiences },
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateExperiences] });
      },
      ...extraOptions,
    }
  );
};
