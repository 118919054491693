import 'moment/locale/fr';
import moment from 'moment';

import { OSMCommonModelDateDto } from 'src/Services/API';

moment.locale('fr');

export const dayTimeDifference = (startDate?: Date, endDate?: Date) => {
  if (startDate === undefined || endDate === undefined) {
    return {
      days: 0,
      months: 0,
    };
  }
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  return {
    days: endMoment.diff(startMoment, 'days'),
    months: endMoment.diff(startMoment, 'month'),
  };
};

export function toDate(date: OSMCommonModelDateDto | undefined): Date | undefined {
  if (date === undefined) {
    return undefined;
  }
  return new Date(
    date.year || new Date().getUTCFullYear(),
    (date.month || new Date().getUTCMonth() + 1) - 1,
    date.day
  );
}
