import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidateService, Criteria } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchMissionCriteria = (
  extraOptions?: UseMutationOptions<Criteria, unknown, string>
) => {
  const brandCode = useSelector(getBrandCode);
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.fetchMissionCriteria],
    async (missionId: string) => {
      const result = await CandidateService.matchingControllerGetCriteriaFromMission({
        missionId,
        brandCode,
      });
      return result;
    },
    {
      onSuccess: (...args) => {
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
