import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getLocation } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaLocationWithLabel } from 'src/Services/API';

import LocationModal from './LocationModal.component';

const MemoLocationModal = React.memo(LocationModal);

const EnhancedLocationModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useSelector(getLocation);
  const onLocationChange = useCallback(
    (location: MatchingCriteriaLocationWithLabel) => dispatch(searchActions.setLocation(location)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoLocationModal onClose={onClose} location={location} onLocationChange={onLocationChange} />
  );
};

export default EnhancedLocationModal;
