import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { IdLabel, RepositoriesService } from 'src/Services/API';
import { RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchCitiesAndDepartments = (
  extraOptions?: UseMutationOptions<IdLabel[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchCitiesAndDepartments],
    async (keyword: string) => {
      const [cities, departments] = await Promise.all([
        RepositoryService.citiesControllerGetCities({
          keyword,
        }),
        RepositoriesService.repositoriesControllerGetDepartments(),
      ]);
      const results = cities
        .map(city => {
          return { id: city.uId ?? '', label: city.name ?? '' };
        })
        .concat(
          departments
            .filter(department => department.label?.toLowerCase().includes(keyword.toLowerCase()))
            .map(department => {
              return { id: department.id ?? '', label: department.label?.toUpperCase() ?? '' };
            })
        )
        .sort((a, b) => (a.label && b.label && a.label < b.label ? -1 : 1));
      return results as IdLabel[];
    },
    { ...extraOptions }
  );
};
