import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { ActionsService, Action, CreateActionBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useCreateAction = (
  extraOptions?: UseMutationOptions<
    (Action | undefined)[],
    AxiosError,
    Omit<
      CreateActionBody & {
        r2AppointmentStartDate?: Date;
        r2AppointmentEndDate?: Date;
      },
      'brandCode' | 'agencyId'
    >,
    unknown
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createAction],
    async (
      params: Omit<
        CreateActionBody & {
          r2AppointmentStartDate?: Date;
          r2AppointmentEndDate?: Date;
        },
        'brandCode'
      >
    ): Promise<(Action | undefined)[]> => {
      const actions = await ActionsService.actionsControllerPostCreateAction({
        body: {
          brandCode: currentBrand?.brandCodeApiHeader ?? '',
          targetId: params.targetId,
          type: params.type,
          agencyId: params.agencyId,
          startDate: params.startDate,
          endDate: params.endDate,
          report: params.report,
          r2AppointmentStartDate: params.r2AppointmentStartDate,
          r2AppointmentEndDate: params.r2AppointmentEndDate,
          syncGoogleCal: params.syncGoogleCal,
          title: params.title,
        },
      });
      return actions;
    },
    {
      onSuccess: actions => {
        toast.success(
          `l'action Osmose correspondant à votre évènement a été créée${
            actions[1]?.syncGoogleCal ? ' et a été ajoutée à votre agenda Google' : ''
          }`
        );

        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchActions] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateR1] });
      },
      onError: () => {
        toast.error("une erreur a empêché la création de l'action Osmose");
      },
      ...extraOptions,
    }
  );
};
