import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';

import { useFetchCandidateBirthInfoFromURL } from 'src/Hooks/Candidates/useFetchCandidateBirthInfoFromURL';
import { toDate } from 'src/Utils/moment';

import { ifEmpty } from '../Visualization/Visualization.component';

import styles from './BirthInformations.module.scss';

const BirthInformations = () => {
  const candidateBirthInfo = useFetchCandidateBirthInfoFromURL().data;
  return (
    <div>
      <div className={styles.birthInformation}>
        <WithLightTitle title="né le" className={styles.birthDate}>
          {`${
            candidateBirthInfo?.birthDate
              ? moment(toDate(candidateBirthInfo?.birthDate)).format('L')
              : 'non renseigné'
          } ${
            candidateBirthInfo?.birthCountry ? `- ${candidateBirthInfo?.birthCountry.label}` : ''
          }`}
        </WithLightTitle>
        <WithLightTitle title="à" className={styles.withLightTitleContainer}>
          {`${ifEmpty(candidateBirthInfo?.birthDepartment?.id)} - ${ifEmpty(
            candidateBirthInfo?.birthCity
          )} `}
        </WithLightTitle>
      </div>
      <div className={styles.nationality}>
        <WithLightTitle className={styles.nationalityComponent} title="nationalité">
          {ifEmpty(candidateBirthInfo?.nationality?.label?.toLocaleLowerCase())}
        </WithLightTitle>
        <WithLightTitle className={styles.nationalityComponent} title="numéro de sécurité sociale">
          {ifEmpty(
            `${candidateBirthInfo?.socialSecurityId} ${candidateBirthInfo?.socialSecurityKey}`
          )}
        </WithLightTitle>
      </div>
    </div>
  );
};
export default BirthInformations;
