import { IllusRLLanguageSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasLanguages } from 'src/Redux/Search/Selectors';

import styles from './LanguageButton.module.scss';

const LanguageButton = () => {
  const _hasLanguages = useSelector(hasLanguages);
  const languageModal = useModalWithLocation('language');
  return (
    <div>
      <CriteriaButton
        label="langues"
        icon={<IllusRLLanguageSmall className={styles.icon} />}
        selected={_hasLanguages}
        onClick={() => languageModal.open()}
      />
    </div>
  );
};

export default LanguageButton;
