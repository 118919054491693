import { FETCH_STATUS } from 'src/Redux/Types';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { MatchingResult } from 'src/Services/API';

import styles from './CandidateResults.module.scss';

export interface Props {
  matchingCandidates: MatchingResult[];
  fetchStatus: FETCH_STATUS;
  displayMode: DISPLAY_MODE;
}

export enum CANDIDATE_STATUS_LABEL {
  'Intérimaire' = 'Intérimaire',
  'CDI Interim' = 'CDI Interim',
  'Candidat évalué' = 'Candidat évalué',
  'Candidat' = 'Candidat',
}

export const getCandidateStatusStyle = (status?: string) => {
  switch (status) {
    case CANDIDATE_STATUS_LABEL.Intérimaire:
      return styles.interimaireHeader;
    case CANDIDATE_STATUS_LABEL['CDI Interim']:
      return styles.cdiInterimaireHeader;
    default:
      return styles.candidatHeader;
  }
};
