import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { CandidateR2, R2InterviewsService, UpdateCandidateR2Body } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateCandidateR2 = (
  id: string,
  extraOptions?: UseMutationOptions<CandidateR2, unknown, UpdateCandidateR2Body, unknown>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updateCandidateR2, id],
    async (body: UpdateCandidateR2Body) => {
      const candidateR2 = await R2InterviewsService.r2InterviewsControllerUpdateCandidateR2({
        r2Id: id,
        body,
      });
      return candidateR2;
    },
    {
      onSuccess: (...args) => {
        const [candidateR2] = args;
        queryClient.setQueryData([QueryKeys.fetchCandidateR2, id], candidateR2);
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
