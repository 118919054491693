import * as z from 'zod';

import { ParsedDocument } from 'src/Hooks/AddNewTalent/useFetchDocumentData';
import { LanguageLevel } from 'src/Services/API';

import { AddNewTalentSteps } from '../AddNewTalent.types';

const telephoneRegex = /^0[1-9](?:\s?\d{2}){4}$/;

const requiredFieldLabel = 'ce champ est obligatoire';
const checkEmptyString = (s: string | undefined): boolean => !!s && !!s.length;
const checkEmptyArray = (arr: Array<string | undefined>): boolean => !!arr && !!arr.length;
const notFollowsRegex = (s: string, reg: RegExp): boolean => reg.test(s);
const notUndefined = (item: unknown): boolean => item !== undefined;

const nonEmptyString = z.string().refine(checkEmptyString, requiredFieldLabel);
function isStringInEnum(inputString: string, enumObject: Record<string, unknown>) {
  const enumValues = Object.values(enumObject);
  return enumValues.includes(inputString);
}

const requiredSelectedItem = z
  .object({
    id: z.string(),
    label: z.string().optional(),
  })
  .refine(item => notUndefined(item?.id) && checkEmptyString(item?.id), 'ce champ est obligatoire');
export const formAddNewTalentSchema = z.object({
  gender: nonEmptyString,
  firstName: nonEmptyString,
  name: nonEmptyString,
  birthName: z.string().optional(),
  phone: z.string().refine(s => notFollowsRegex(s, telephoneRegex), 'mauvais format'),
  email: z.string().email(),
  birthDate: z.date().optional(),
  birthCountry: z
    .object({
      id: z.number().optional(),
      labell: z.string().optional(),
    })
    .optional(),
  nationality: z
    .object({
      id: z.string().optional(),
      labell: z.string().optional(),
    })
    .optional(),
  birthCity: z
    .object({
      label: z.string().optional(),
      zipCode: z.string().optional(),
      inseeCode: z.string().optional(),
    })
    .optional(),
  birthDepartment: z.string().optional(),
  socialSecurityNumber: z.string().optional(),
  contractAccepted: z
    .array(z.string())
    .refine(checkEmptyArray, 'veuillez choisir au moins un type de contrat'),
  apartmentNumber: z.string().optional(),
  buildingName: z.string().optional(),
  road: nonEmptyString,
  locality: z.string().optional(),
  city: z
    .object({
      label: z.string().optional(),
      zipCode: z.string().optional(),
      inseeCode: z.string().optional(),
    })
    .optional(),
  country: z.string().optional(),
  agencyId: nonEmptyString,
  languages: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      level: z
        .object({
          key: z.string().optional(),
          value: z.string().optional(),
        })
        .optional()
        .refine(
          level => notUndefined(level) && isStringInEnum(level?.key ?? '', LanguageLevel),
          'veuillez choisir un niveau de langue'
        ),
      parsedDocumentLabel: z.string().optional(),
      uniqueKey: z.number().optional(),
    })
  ),
  diplomas: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      obtained: z.boolean(),
      main: z.boolean(),
      date: z.date().optional(),
      parsedDocumentLabel: z.string().optional(),
      uniqueKey: z.number().optional(),
    })
  ),
  hardSkills: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      parsedDocumentLabel: z.string().optional(),
    })
  ),
  softSkills: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      parsedDocumentLabel: z.string().optional(),
    })
  ),
  qualifications: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      main: z.boolean().optional(),
      parsedDocumentLabel: z.string().optional(),
      uniqueKey: z.number().optional(),
    })
  ),
  formations: z.array(
    z.object({
      selectedItem: requiredSelectedItem,
      date: z.date().optional(),
      parsedDocumentLabel: z.string().optional(),
      uniqueKey: z.number().optional(),
    })
  ),
});

export type formAddNewTalentSchemaType = z.infer<typeof formAddNewTalentSchema>;

export enum FormSteps {
  Identity,
  Address,
  Experiences,
  Conflicts,
  Confirmation,
}
export const formFields: {
  [key in
    | FormSteps.Identity
    | FormSteps.Address
    | FormSteps.Experiences]: (keyof formAddNewTalentSchemaType)[];
} = {
  [FormSteps.Identity]: [
    'gender',
    'firstName',
    'name',
    'birthName',
    'phone',
    'email',
    'birthDate',
    'nationality',
    'birthCity',
    'birthCountry',
    'birthDepartment',
    'socialSecurityNumber',
    'contractAccepted',
    'agencyId',
  ],
  [FormSteps.Address]: ['apartmentNumber', 'buildingName', 'road', 'locality', 'city'],
  [FormSteps.Experiences]: [
    'languages',
    'diplomas',
    'softSkills',
    'hardSkills',
    'formations',
    'qualifications',
  ],
};

export interface Props {
  parsedDocument?: ParsedDocument;
  step: AddNewTalentSteps;
  setStep: (step: AddNewTalentSteps) => void;
  formStep: FormSteps;
  setFormStep: (formStep: FormSteps) => void;
}
