import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateAllExperiencesByCompany, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateExperiencesByCompany = (
  id: string,
  getAllExperiences?: boolean,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateAllExperiencesByCompany, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateExperiencesByCompany, id],
    async () => {
      const experiences =
        await CandidatesService.candidatesControllerGetCandidateExperiencesByCompany({
          id,
          getAllExperiences,
        });
      return experiences;
    },
    { ...extraOptions }
  );
};
