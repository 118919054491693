import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { getCompany } from 'src/Redux/Search/Selectors';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { CompaniesService, WishPriority } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchServices = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, MatchingCriteriaItemWithLabel[], string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const company = useSelector(getCompany);
  return useQuery(
    [QueryKeys.fetchService, company?.label ?? ''],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const services = await CompaniesService.companiesControllerGetDepartments({
        id: id,
      });
      const results = services.map(service => {
        return {
          identifier: service.serviceId ?? '',
          label: service.name ?? '',
          wishPriority: WishPriority.Obligatoire,
        };
      });
      return results;
    },
    { staleTime: 300000, refetchOnWindowFocus: false, refetchOnReconnect: false, ...extraOptions }
  );
};
