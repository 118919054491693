import {
  HorizontalTextIconCard,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle, Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './KeywordListInput.module.scss';
import { Props } from './KeywordListInput.types';

export const KeywordListInput = ({
  id,
  title,
  keywords,
  isDisplayMode,
  onAppend,
  onRemove,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [isError, setIsError] = useState(false);
  const [keywordList, setKeywordList] = useState([] as string[]);
  useEffect(() => setKeywordList(keywords), [keywords]);
  return (
    <WithLightTitle title={title} className={styles.container}>
      {!isDisplayMode && (
        <>
          <TextInput
            containerClassName={classnames(
              { [styles.strengthInputError]: isError },
              styles.strengthInput
            )}
            value={inputValue}
            onChange={event => setInputValue(event.currentTarget.value)}
            onKeyUp={event => {
              setIsError(keywordList.includes(inputValue));
              if (
                event.key === 'Enter' &&
                inputValue.length > 1 &&
                !keywordList.includes(inputValue)
              ) {
                onAppend(inputValue);
                setInputValue('');
              }
            }}
            rightIcon={
              <PlusCircle
                onClick={() => {
                  setIsError(keywordList.includes(inputValue));
                  if (inputValue.length > 1 && !keywordList.includes(inputValue)) {
                    onAppend(inputValue);
                    setInputValue('');
                  }
                }}
              />
            }
          />
          {isError && <div className={styles.errorMessage}>Vous avez déjà ajouté ce mot</div>}
        </>
      )}
      <WithLightTitle
        className={styles.strengthsContainer}
        title={keywordList.length > 0 ? pluralFormat(keywordList.length, 'point ajouté') : ''}
      >
        {keywordList.map((keyword, index) => (
          <div key={`keyword-${id}-${index}`}>
            {index === 0 && <div className={styles.separator} />}
            <HorizontalTextIconCard
              disabled={isDisplayMode}
              label={keyword}
              rightIcon={<Trashcan />}
              onRightIconClick={() => {
                onRemove(index);
              }}
            />
            <div className={styles.separator} />
          </div>
        ))}
      </WithLightTitle>
    </WithLightTitle>
  );
};
