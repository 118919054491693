import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';

const useInitializeAnalytics = () => {
  const brandCode = useSelector(getBrandCode);
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
  }, []);
  useEffect(() => {
    ReactGA.set({ user_properties: { brandCode } });
  }, [brandCode]);
};

export default useInitializeAnalytics;
