import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { MatchingCriteriaItemWithLabel, QualificationsService } from 'src/Services/API';
import { WishPriority } from 'src/Services/API';

import { MutationKeys } from '../types';
import { useThrottleFunction } from '../utils/useThrottleFunction';

export const useFetchQualifications = (
  extraOptions?: UseMutationOptions<MatchingCriteriaItemWithLabel[], unknown, string>
) => {
  const societyCode = '001';
  return useMutation(
    [MutationKeys.fetchQualifications],
    async (keyword: string) => {
      const qualifications = await QualificationsService.qualificationsControllerGetQualifications({
        keywords: [keyword],
        societyCode,
      });
      const results = qualifications[0].matchingQualifications.map(qualification => {
        return {
          identifier: qualification.qualificationId ?? '',
          label: qualification.label,
          wishPriority: WishPriority.Obligatoire,
        };
      });
      return results;
    },
    { ...extraOptions }
  );
};

export const useFetchQualificationsWithThrottle = () => {
  const qualificationsMutation = useFetchQualifications();
  const throttledQualificationMethod = useThrottleFunction(qualificationsMutation.mutate);
  return {
    ...qualificationsMutation,
    mutate: (keyword: string) => throttledQualificationMethod(keyword),
  };
};
