import { Button, Checkbox, Table } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { ConflictsIcon } from 'src/Assets';
import { Candidate } from 'src/Services/API';

import styles from './ConflictsStep.module.scss';
import { Props } from './ConflictsStep.types';

const tableData = (
  data: Candidate[],
  selectedCandidate: string,
  setSelectedCandidate: (id: string) => void
) => {
  return {
    headers: [
      { label: 'choisir', width: '3rem' },
      { label: 'nom et prénom' },
      { label: 'email' },
      { label: 'qualification' },
    ],
    data: data.map(data => [
      <div className={styles.checkBox} key={data.id}>
        <Checkbox
          onClick={() => setSelectedCandidate(data.id)}
          checked={selectedCandidate === data.id}
        />
      </div>,
      <p key={`${data.id}name`}>
        {data.name} {data.firstName}
      </p>,
      <p key={`${data.id}mail`}>{data.email}</p>,
      <p key={`${data.id}qualification`}>{data.qualification?.label}</p>,
    ]),
  };
};

const ConflictsStep = ({ watch, createCandidate, replaceCandidate, matchedCandidates }: Props) => {
  const name = `${watch('firstName')} ${watch('name')}`;
  const [selectedCandidate, setSelectedCandidate] = useState<string>('');
  return (
    <div className={styles.conflictsContainer}>
      <div className={styles.titleConflict}> gestion conflit </div>
      <ConflictsIcon className={styles.conflictIcon} />
      <div className={styles.optionsContainer}>
        <p>Le profil de {name} semble déjà existant sur Osmose, que souhaitez-vous faire ?</p>

        <Table
          {...tableData(matchedCandidates, selectedCandidate, setSelectedCandidate)}
          className={styles.table}
        />
        <div className={styles.optionsButtonsContainer}>
          <Button.Primary
            onClick={() => {
              replaceCandidate(selectedCandidate);
            }}
            disabled={!selectedCandidate}
          >
            remplacer l'ancient profil
          </Button.Primary>
          <Button.Secondary
            onClick={() => {
              createCandidate();
            }}
            disabled={matchedCandidates.some(
              candidate => candidate.email === (watch('email') as unknown as string)
            )}
          >
            créer un nouveau profil
          </Button.Secondary>
        </div>
      </div>
    </div>
  );
};

export default ConflictsStep;
