import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCaces } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import CACESModal from './CACESModal.component';

const MemoCACESModal = React.memo(CACESModal);

const EnhancedCACESModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const caces = useSelector(getCaces);
  const onCacesChange = useCallback(
    (caces: MatchingCriteriaItemWithLabel[]) => dispatch(searchActions.setCaces(caces)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return <MemoCACESModal caces={caces} onClose={onClose} onCacesChange={onCacesChange} />;
};

export default EnhancedCACESModal;
