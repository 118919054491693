import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { QueryKeys } from 'src/Hooks/types';
import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CloseCandidateR2Body, R2InterviewsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCloseCandidateR2 = (
  id: string,
  extraOptions?: UseMutationOptions<
    unknown,
    AxiosError,
    { candidateR2: CloseCandidateR2Body; exemptionComment?: string; exemptionMakerName?: string },
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.closeCandidateR2, id],
    async (params: {
      candidateR2: CloseCandidateR2Body;
      exemptionComment?: string;
      exemptionMakerName?: string;
    }) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      await R2InterviewsService.r2InterviewsControllerCloseCandidateR2({
        r2Id: id,
        body: {
          ...params.candidateR2,
          brandCode: currentBrand?.brandCodeApiHeader,
          exemptionComment: params.exemptionComment,
          exemptionMakerName: params.exemptionMakerName,
        },
      });
      if (params.candidateR2.validation?.isCDI) {
        ReactGA.event('Formulaire R2 validé', {
          brandCode: currentBrand?.brandCodeApiHeader,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      }
    },
    {
      ...extraOptions,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchInterviews] });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateInterviewsIdAndStatus],
        });
      },
    }
  );
};
