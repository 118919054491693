import React from 'react';

import ExclusiveSelectionGroupWithDisplayMode from '../WithDisplayMode/ExclusiveSelectionGroupWithDisplayMode';

import styles from './AssessmentQuestion.module.scss';
import { Props } from './AssessmentQuestion.types';

const AssessmentQuestion = ({ response, onChange, isDisplayMode }: Props) => {
  const items = Array(3)
    .fill(undefined)
    .map((_, index) => index + 1);
  return (
    <div className={styles.container}>
      <div className={styles.label}>{response.label}</div>
      <div className={styles.question}>
        <ExclusiveSelectionGroupWithDisplayMode
          selected={response.value}
          isDisplayMode={isDisplayMode}
          values={items}
          getKey={key => key.toString()}
          getStringValue={value => value.toString()}
          setSelected={value => onChange({ ...response, value })}
          canBeReset
          large
        />
      </div>
    </div>
  );
};

export default AssessmentQuestion;
