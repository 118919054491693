import {
  BadgeDeprecated,
  CardCheckable,
  CardContent,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CircleError,
  CloseOld,
  PlusInCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useState } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import styles from './BehaviorSkills.module.scss';
import { behaviorSkillsSuggestions, Props } from './BehaviorSkills.types';

const BehaviorSkills = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  control,
  name,
}: Props<TFieldValues, TName>) => {
  const { field } = useController({
    name,
    control,
  });

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      field.onChange([...field.value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index: number) => {
    field.onChange(field.value.filter((_: string, i: number) => i !== index));
  };

  return (
    <>
      <WithLightTitle
        title="Ajouter des compétences comportementales (10 max)"
        titleClassName={styles.title}
        className={styles.searchBar}
      >
        <TextInput
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleAddItem();
              event.preventDefault();
            }
          }}
          rightIcon={
            <>
              <CloseOld className={styles.cross} onClick={() => setInputValue('')} />
              <PlusInCircle className={styles.plus} onClick={handleAddItem} />
            </>
          }
          disabled={field.value.length >= 10}
        />
      </WithLightTitle>
      {field.value.length > 0 && (
        <WithLightTitle title="compétences ajoutées" titleClassName={styles.title}>
          {field.value.map((item: string, index: number) => (
            <BadgeDeprecated
              className={styles.badge}
              key={index}
              value={item}
              rightIcon={
                <CircleError
                  className={styles.deleteIcon}
                  onClick={() => handleRemoveItem(index)}
                />
              }
            />
          ))}
        </WithLightTitle>
      )}
      <WithLightTitle
        title="suggestions de compétences comportementales"
        titleClassName={styles.suggestionTitle}
      >
        <div className={styles.gridContainer}>
          {behaviorSkillsSuggestions.map((skill, idx) => (
            <CardCheckable
              key={idx}
              color="beige"
              checked={field.value.includes(skill)}
              onChange={checked => {
                if (checked) {
                  field.onChange([...field.value, skill]);
                } else {
                  field.onChange(
                    field.value.filter((selectedSkill: string) => selectedSkill !== skill)
                  );
                }
                field.onBlur();
              }}
            >
              <CardContent title={skill} titleClassName={styles.cardTitle} />
            </CardCheckable>
          ))}
        </div>
      </WithLightTitle>
    </>
  );
};

export default BehaviorSkills;
