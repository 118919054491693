/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Button,
  PopupActions,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ThreeDots,
  Trashcan,
  LightPencil,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import ExternalExperienceModal from 'src/Containers/Modals/ExternalExperienceModal';

import styles from './ExperienceActionButton.module.scss';
import { Props } from './ExperienceActionButton.types';

const ExperienceActionButton = ({ onValidate, experienceToModify, onDelete }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = React.useRef<PopupActions>(null);
  return (
    <>
      <ExternalExperienceModal
        ref={modalRef}
        experienceToModify={experienceToModify}
        onValidate={onValidate}
        onClose={() => {
          setIsOpen(false);
          modalRef.current?.close();
        }}
      />
      <PopupMenu
        position="bottom right"
        width={250}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={
          <div>
            <Button.Tertiary
              className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}
            >
              <ThreeDots className={styles.icon} />
            </Button.Tertiary>
          </div>
        }
      >
        <PopupMenu.Item
          keepOpenOnClick
          text="consulter / modifier"
          icon={<LightPencil />}
          onClick={modalRef.current?.open}
        />

        <PopupMenu.Item text="supprimer" onClick={onDelete} icon={<Trashcan />} />
      </PopupMenu>
    </>
  );
};

export default ExperienceActionButton;
