import {
  BadgeDeprecated,
  Button,
  CardCheckable,
  CardContent,
  Loader,
  Message,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinesses } from 'src/Hooks/Candidates/useFetchCandidateBusinesses';
import { useFetchCandidateExperiences } from 'src/Hooks/Candidates/useFetchCandidateExperiences';
import { dayTimeDifference } from 'src/Utils/moment';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Qualifications.module.scss';
import { QualificationProps } from './Qualifications.types';

export const Qualifications = ({
  control,
  candidateId,
  setAllQualificationsSelected,
}: QualificationProps) => {
  const businessSkills = useFetchCandidateBusinesses(candidateId);
  const experiences = useFetchCandidateExperiences(candidateId);

  const { field, fieldState } = useController({ control, name: 'qualifications' });

  const refetch = useCallback(() => {
    if (!businessSkills.data) businessSkills.refetch();
    if (!experiences.data) experiences.refetch();
  }, [businessSkills, experiences]);
  const isError = businessSkills.isError || experiences.isError;

  const qualifications = useMemo(
    () =>
      businessSkills.data
        ?.flatMap(
          skill =>
            skill.candidateQualifications?.map(qualification => ({
              id: qualification.qualificationId ?? '',
              label: qualification.label ?? '',
              main: qualification.main ?? false,
              skills: skill.candidateBusinessesSkills?.length ?? 0,
            })) ?? []
        )
        .filter(qualification => qualification.id && qualification.label)
        .map(qualification => {
          const relatedExperiences =
            experiences.data?.filter(
              experience => experience.qualification?.id === qualification.id
            ) ?? [];
          const { experienceDays, experienceMonths } = relatedExperiences.reduce(
            (acc, experience) => {
              const { days, months } = dayTimeDifference(experience.startDate, experience.endDate);
              acc.experienceDays += days;
              acc.experienceMonths += months;
              return acc;
            },
            { experienceDays: 0, experienceMonths: 0 }
          );
          return { ...qualification, experienceDays, experienceMonths };
        }),
    [businessSkills.data, experiences.data]
  );

  useEffect(() => {
    if (qualifications && qualifications.length === field.value.length) {
      setAllQualificationsSelected(true);
    } else {
      setAllQualificationsSelected(false);
    }
  }, [field.value.length, qualifications, setAllQualificationsSelected]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {`sur ${pluralFormat(field.value.length, 'quelle', false)} 
        ${pluralFormat(
          field.value.length,
          'qualification',
          false
        )} souhaitez-vous créer le cv randstad ?`}
      </h3>
      {isError ? (
        <>
          <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
          <div>
            <Button.Medium type="button" onClick={() => refetch()}>
              réessayer
            </Button.Medium>
          </div>
        </>
      ) : !qualifications ? (
        <Loader heightInRem={2} darkMode />
      ) : (
        <>
          {fieldState.error && (
            <Message.Warning>
              <ul style={{ marginLeft: '1rem' }}>
                <li>{fieldState.error.message}</li>
              </ul>
            </Message.Warning>
          )}
          <div className={styles.grid}>
            {qualifications.map(qualification => (
              <CardCheckable
                key={qualification.id}
                color="beige"
                checked={field.value.includes(qualification.id)}
                onChange={checked => {
                  if (checked) {
                    field.onChange([...field.value, qualification.id]);
                  } else {
                    field.onChange(
                      field.value.filter(qualificationId => qualificationId !== qualification.id)
                    );
                  }
                  field.onBlur();
                }}
              >
                <CardContent
                  overline={qualification.main ? 'qualification principale' : undefined}
                  title={qualification.label}
                  supportingLines={[
                    qualification.experienceMonths > 0
                      ? `nombre de mois : ${qualification.experienceMonths} mois`
                      : qualification.experienceDays > 0
                      ? `nombre de jours : ${qualification.experienceDays} jours`
                      : '',
                  ]}
                />
                {qualification.skills > 0 && (
                  <BadgeDeprecated value={pluralFormat(qualification.skills, 'compétence')} />
                )}
              </CardCheckable>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
