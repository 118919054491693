import * as z from 'zod';

import { R2PlanningContactChannel, R2PlanningEventStatus } from 'src/Services/API';

export interface Props {
  open: boolean;
  candidateId: string;
  close: () => void;
  agencyId?: string;
  r1InterviewId?: string;
}

export const r2PlanningSchema = z.object({
  r2PlanningContactChannel: z.nativeEnum(R2PlanningContactChannel),
  exchangeSummary: z.string(),
  r2PlanningEventStatus: z.nativeEnum(R2PlanningEventStatus),
  r2AppointmentDate: z.date(),
  r2AppointmentTimeStart: z.string(),
  r2AppointmentTimeEnd: z.string(),
  r2PlanningEventDate: z.date(),
});
