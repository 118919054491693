import { Binocular } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import { CandidateTests } from 'src/Components/CandidateTests';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateETestsFromURL } from 'src/Hooks/Candidates/useFetchCandidateETestsFromURL';
import { useFetchCandidateTestsFromURL } from 'src/Hooks/Candidates/useFetchCandidateTestsFromURL';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';

import styles from './Tests.module.scss';
import { TestsQuery } from './Tests.types';

const Tests = () => {
  const candidateETests = useFetchCandidateETestsFromURL();
  const candidateTests = useFetchCandidateTestsFromURL();

  const queries: Record<string, TestsQuery> = useMemo(() => {
    return {
      'tests e-testing': candidateETests,
      'autres tests': candidateTests,
    };
  }, [candidateETests, candidateTests]);

  const fetchStatus = mergeFetchStatus(
    ...Object.values(queries).map(query => toFetchStatus(query))
  );

  const [testsNumber, setTestsNumber] = useState(0);

  useEffect(() => {
    let totalTestsNumber = 0;

    Object.values(queries).forEach(query => {
      if (query.isSuccess && query.data) {
        totalTestsNumber += query.data.length;
      }
    });

    setTestsNumber(totalTestsNumber);
  }, [queries]);

  return (
    <div>
      {fetchStatus === FETCH_STATUS.PENDING &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="test">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {fetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="une erreur est survenue dans la récupération des tests" />
      )}
      {fetchStatus === FETCH_STATUS.FULFILLED && testsNumber > 0 && (
        <>
          {Object.entries(queries).map(([testType, query]) => (
            <div className={styles.testType} key={testType}>
              {Array.isArray(query.data) && (
                <>
                  <div className={styles.title}>
                    <p>{testType}</p>
                  </div>
                  <div>
                    {query.isSuccess &&
                      query.data.length > 0 &&
                      query.data.map((test, index) => (
                        <div key={index} className={styles.test}>
                          <CandidateTests test={test} />
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </>
      )}
      {fetchStatus === FETCH_STATUS.FULFILLED && testsNumber === 0 && (
        <>
          <div className={styles.emptyIcon}>
            <Binocular />
          </div>
          <div className={styles.text}>aucun test connu pour ce talent</div>
        </>
      )}
    </div>
  );
};

export default Tests;
