import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Establishments, EstablishmentsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchConsultantsEstablishments = (
  extraOptions?: UseQueryOptions<unknown, unknown, Establishments, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchConsultantsEstablishments],
    async () => await EstablishmentsService.establishmentsControllerGetConsultantEstablishments(),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 3600 * 24,
      staleTime: 1000 * 3600 * 24,
      ...extraOptions,
    }
  );
};
