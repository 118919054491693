import {
  FetchButton,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextAreaWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithDisplayMode';
import { useFetchCandidateR2FromURL } from 'src/Hooks/CandidateR2/useFetchCandidateR2FromURL';
import { useFetchR2ExchangeQuestions } from 'src/Hooks/CandidateR2/useFetchR2ExchangeQuestions';
import { useUpdateCandidateR2 } from 'src/Hooks/CandidateR2/useUpdateCandidateR2';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateExchanges.module.scss';
import { candidateExchangesSchema } from './CandidateExchanges.types';

const CandidateExchanges = () => {
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    R2Id: string;
  }>();
  const {
    data: candidateR2Data,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
    isError: isErrorCandidateR2,
  } = useFetchCandidateR2FromURL();
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
    isError: isErrorQuestions,
  } = useFetchR2ExchangeQuestions();
  const candidateR2Update = useUpdateCandidateR2(params.R2Id);
  const candidateR2FetchStatus = toFetchStatus({
    isError: isErrorCandidateR2,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
  });
  const questionsFetchStatus = toFetchStatus({
    isError: isErrorQuestions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
  });
  const fetchStatus = mergeSeveralFetchStatus([candidateR2FetchStatus, questionsFetchStatus]);
  const updateFetchStatus = toFetchStatus(candidateR2Update);
  const responses = questions?.map(question => {
    return {
      id: question.id,
      label: question.label,
      value:
        candidateR2Data?.exchanges?.responses.find(response => response.id === question.id)
          ?.value ?? '',
    };
  });

  const { control, setValue, handleSubmit, formState, reset } = useFormWithZodResolver({
    schema: candidateExchangesSchema,
    defaultValues: {
      exchanges: { responses },
    },
  });

  const canModifyR2 = useMemo(
    () => candidateR2Data && units.includes(candidateR2Data.cgcId) && !candidateR2Data.isClosed,
    [candidateR2Data, units]
  );

  const exchangesHandleSubmit = () =>
    handleSubmit(values => {
      candidateR2Data &&
        canModifyR2 &&
        candidateR2Update.mutate({
          ...candidateR2Data,
          exchanges: values.exchanges,
        });
      reset(values);
    });

  useEffect(() => {
    if (isCandidateR2Success && isQuestionsSuccess)
      setValue('exchanges.responses', responses ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR2Success, isQuestionsSuccess]);

  const { fields, update } = useFieldArray({
    control,
    name: 'exchanges.responses',
    keyName: 'key',
    shouldUnregister: true,
  });

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: exchangesHandleSubmit,
      fetchStatus: updateFetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, updateFetchStatus]);

  return (
    <>
      <div className={styles.title}>support d'aide à la décision agence - R1</div>
      {(fetchStatus === FETCH_STATUS.PENDING || !fields.length) &&
        new Array(3).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="13.5rem" width="100%" uniqueKey="r2exchange">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="160" />
          </ContentLoader>
        ))}
      {fetchStatus === FETCH_STATUS.FULFILLED &&
        !!fields.length &&
        fields.map((field, index) => (
          <WithLightTitle
            key={field.label}
            title={field.label}
            className={styles.container}
            rightTitleComponent={
              <div className={styles.textLength}>
                {pluralFormat(field.value?.length ?? 0, 'caractère')}
              </div>
            }
          >
            <TextAreaWithDisplayMode
              title={field.label}
              isDisplayMode={candidateR2Data?.isClosed}
              className={styles.textAreaWidth}
              placeholder={questions?.find(question => question.label === field.label)?.placeHolder}
              value={field.value}
              onChange={event => {
                update(index, {
                  id: field.id,
                  label: field.label,
                  value: (event.target as HTMLTextAreaElement).value,
                });
              }}
            />
          </WithLightTitle>
        ))}
      {canModifyR2 && (
        <FetchButton
          title="valider échanges"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : updateFetchStatus}
          className={styles.button}
          onClick={exchangesHandleSubmit()}
          onSuccess={() => toast.success('Votre R2 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R2")}
        />
      )}
      {Object.keys(formState.errors).length !== 0 && (
        <div className={styles.errorMessage}>
          il y a des erreurs ou des champs non remplis dans le formulaire
        </div>
      )}
    </>
  );
};

export default CandidateExchanges;
