import { HorizontalCardWithTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import CandidateBusinessModal from 'src/Components/CandidateBusinessModal';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateBusinesses.module.scss';
import { Props } from './CandidateBusinesses.types';

const CandidateBusinesses = ({ business }: Props) => {
  const subTitle = `pour ce métier : ${pluralFormat(
    business.candidateBusinessesSkills?.length ?? 0,
    'compétence'
  )}
   | ${pluralFormat(business.candidateQualifications?.length ?? 0, 'qualification')}`.replaceAll(
    ' 0 ',
    ' aucune '
  );
  const [openModal, setOpenModal] = useState(false);

  return (
    <HorizontalCardWithTitle
      className={styles.businessCard}
      title={business.label ?? ''}
      subtitles={[subTitle]}
      titleClassname={styles.businessCardTitle}
      onClick={() => {
        setOpenModal(!openModal);
      }}
      rightActions={<CandidateBusinessModal business={business} openModal={openModal} />}
    />
  );
};

export default CandidateBusinesses;
