import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { LightPencil, ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';

import JobModal from 'src/Containers/Modals/JobModal';

import styles from './JobActionButton.module.scss';
import { Props } from './JobActionButton.types';

const JobActionButton = ({
  allJobs,
  onEditModalValidation,
  jobToModify,
  mainQualification,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PopupMenu
      position="left top"
      width={260}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
      }}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <ThreeDots className={styles.icon} />
        </Button.Tertiary>
      }
    >
      <JobModal
        trigger={
          <PopupMenu.Item icon={<LightPencil />} text="consulter / modifier" keepOpenOnClick />
        }
        selectedJobs={allJobs}
        onValidate={onEditModalValidation}
        jobToModify={jobToModify}
        mainQualification={mainQualification}
      />
    </PopupMenu>
  );
};
export default JobActionButton;
