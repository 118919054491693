import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import { Sms, Telephone } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { R2PlanningContactChannel } from 'src/Services/API';

import styles from './ChannelSelectionButton.module.scss';
import { Props } from './ChannelSelectionButton.types';

const ChannelSelectionButtonButton = ({ value, onChange, disabled }: Props) => {
  const items = Object.values(R2PlanningContactChannel);

  return (
    <div className={styles.container}>
      <ExclusiveSelectionGroup
        disabled={disabled}
        selected={value}
        buttonClassName={styles.button}
        values={items}
        getKey={key => key.toString()}
        getStringValue={value => value}
        setSelected={value => onChange(value)}
        canBeReset
        leftIcons={[<Telephone className={styles.leftIcon} />, <Sms className={styles.leftIcon} />]}
      />
    </div>
  );
};

export default ChannelSelectionButtonButton;
