import * as z from 'zod';

export const candidateExchangesSchema = z.object({
  exchanges: z.object({
    responses: z.array(
      z.object({ id: z.number(), label: z.string(), value: z.string().optional() })
    ),
  }),
});

export type CandidateExchangesSchemaType = z.infer<typeof candidateExchangesSchema>;
export type KeyOfCandidateExchangesSchemaTypeQuestion = keyof CandidateExchangesSchemaType;
