import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './ConsultSkillModal.module.scss';
import { Props } from './ConsultSkillModal.types';
const ConsultSkillModal = ({ qualifications, businessSkills }: Props) => {
  return (
    <>
      {qualifications.length !== 0 && (
        <div className={styles.subtitle}>
          {`${pluralFormat(qualifications.length, 'qualification acquise')} pour ce métier`}
        </div>
      )}
      {qualifications.map(qualification => (
        <li className={styles.listElement}>{qualification.label}</li>
      ))}
      {businessSkills.length !== 0 && (
        <div className={styles.subtitle}>{`${pluralFormat(
          businessSkills.length,
          'compétence acquise'
        )} pour ce métier`}</div>
      )}
      {businessSkills.map(businessSkill => (
        <li className={styles.listElement}>{businessSkill.label}</li>
      ))}
    </>
  );
};
export default ConsultSkillModal;
