import {
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import ContentLoader from 'react-content-loader';

import { languageIconMapping } from 'src/Services/IconMapping';

import LevelPicker from '../LevelPicker';

import styles from './R1LanguageList.module.scss';
import { Props } from './R1LanguageList.types';

const R1LanguageList = ({
  values,
  onChange,
  title,
  isFetching,
  isSuccess,
  emptyText,
  singularTitle,
  isDisplayMode,
  cardContainerClassName,
}: Props) => {
  return (
    <>
      <div className={styles.title}>{title}</div>
      {isFetching && (
        <ContentLoader height="5rem" width="100%" uniqueKey={title.replace(/ /g, '')}>
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
        </ContentLoader>
      )}
      {isSuccess && (
        <>
          {values.length ? (
            <WithLightTitle
              title={`${values.length} ${values.length > 1 ? title : singularTitle}`}
              className={cardContainerClassName}
            >
              {values.map(language => {
                return (
                  <HorizontalTextIconCard
                    key={language.identifier}
                    rightComponent={
                      language.level ? (
                        <LevelPicker
                          disabled={isDisplayMode}
                          selectedLevel={language.level}
                          onChange={level =>
                            onChange(
                              values.map(value =>
                                value.identifier === language.identifier
                                  ? { ...value, level }
                                  : value
                              )
                            )
                          }
                        />
                      ) : undefined
                    }
                    leftIcon={
                      language.icon ??
                      languageIconMapping[
                        `${language.label?.charAt(0).toUpperCase()}${language.label
                          ?.substring(1)
                          .toLowerCase()}` as keyof typeof languageIconMapping
                      ]
                    }
                    label={language.label ?? ''}
                    rightIcon={
                      !isDisplayMode && (
                        <Trashcan
                          onClick={() =>
                            onChange(
                              values.filter(value => value.identifier !== language.identifier)
                            )
                          }
                        />
                      )
                    }
                    containerClassName={styles.skillCard}
                  />
                );
              })}
            </WithLightTitle>
          ) : (
            <div className={styles.noSkill}>{emptyText}</div>
          )}
        </>
      )}
    </>
  );
};

export default R1LanguageList;
