import {
  Button,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { DrawingPencil } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextArea, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { useCloseInterviewByTT } from 'src/Hooks/Candidates/useCloseInterviewByTT';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './InterviewClosedByTTModal.module.scss';
import { interviewClosingSchema, Props } from './InterviewClosedByTTModal.types';

const InterviewClosedByTTModal = ({ isOpen, close, status, interviewId }: Props) => {
  const { control, handleSubmit, reset } = useFormWithZodResolver({
    schema: interviewClosingSchema,
    defaultValues: { comment: '' },
  });
  const { mutate, ...mutation } = useCloseInterviewByTT();
  return (
    <ModalDeprecated
      open={isOpen}
      title="interruption du recrutement à l'initiative du candidat"
      icon={<DrawingPencil className={styles.modalIcon} />}
      onClose={() => {
        reset();
        close();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={() => close()}>annuler</Button.Secondary>,
        <FetchButton
          fetchStatus={toFetchStatus(mutation)}
          title="valider"
          onClick={handleSubmit(({ comment }) => {
            mutate({ id: interviewId, status, comment });
          })}
          onSuccess={() => close()}
        />,
      ]}
    >
      <WithLightTitle title="détails sur la fermeture du process">
        <TextArea
          control={control}
          name="comment"
          className={styles.comment}
          placeholder="décrivez les raisons du refus du candidat"
        />
      </WithLightTitle>
    </ModalDeprecated>
  );
};
export default InterviewClosedByTTModal;
