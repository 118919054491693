import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Country, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCountries = (
  extraOptions?: UseQueryOptions<Country[], unknown, Country[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchCountries],
    async () => {
      return await RepositoriesService.repositoriesControllerGetCountries();
    },
    { ...extraOptions }
  );
};
