import {
  SegmentedMenu,
  Animation,
  Loader,
  SimplifiedHeader,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  GoBackArrow,
  IllusEscalierYellow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import {
  FormStatusContext,
  useFormNavigationContextElements,
} from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getCandidateFileUrl } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { getRecruitingEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import NavigationWarningModal from '../Modals/NavigationWarningModal';

import CandidateRecruitingForm from './CandidateRecruitingForm';
import styles from './CandidateRecruitingProcess.module.scss';
import { getNavigationStructure } from './CandidateRecruitingProcess.types';
import RecruitingSideBar from './RecruitingSideBar';

const CandidateRecruitingProcess = () => {
  const history = useHistory<{ origin?: string } | undefined>();
  const params = useParams<{
    id: string;
    firstSection: 'R1' | 'R2';
    candidateId: string;
    secondSection: string;
  }>();
  const candidateDetailsQuery = useFetchCandidateDetailsFromURL();
  const candidateR1Query = useFetchCandidateR1FromURL();
  const recruitingStatusQuery = useFetchCandidateInterviewsIdAndStatus(params.candidateId);
  const fetchStatus = mergeFetchStatus(
    toFetchStatus(candidateR1Query),
    toFetchStatus(recruitingStatusQuery)
  );
  const destination =
    history.location.state?.origin ?? getCandidateFileUrl({ candidateId: params.candidateId });
  const navContextElement = useFormNavigationContextElements();
  const [simplifiedHeader, setSimplifiedHeader] = useState(false);

  return (
    <FormStatusContext.Provider
      value={{
        hasFormBeenTouched: navContextElement.hasFormBeenTouched,
        setHasFormBeenTouched: navContextElement.setHasFormBeenTouched,
        destination: navContextElement.destination,
        openNavWarningModal: navContextElement.openNavWarningModal,
        closeNavWarningModal: navContextElement.closeNavWarningModal,
        handleSubmit: navContextElement.handleSubmit,
        updateValidationStatus: navContextElement.updateValidationStatus,
        fetchStatus: navContextElement.fetchStatus,
        setFetchStatus: navContextElement.setFetchStatus,
        navWarningModalState: navContextElement.navWarningModalState,
        formState: navContextElement.formState,
        setFormState: navContextElement.setFormState,
      }}
    >
      <div className={styles.container}>
        <NavigationWarningModal
          open={navContextElement.navWarningModalState}
          onClose={() => navContextElement.closeNavWarningModal()}
        />
        {!simplifiedHeader && (
          <div className={styles.topBar}>
            <button
              className={styles.goBack}
              onClick={() => {
                if (navContextElement.hasFormBeenTouched) {
                  navContextElement.openNavWarningModal({ destination });
                  navContextElement.setFetchStatus(FETCH_STATUS.FULFILLED);
                } else {
                  history.push(destination);
                }
              }}
            >
              <GoBackArrow />
              <p className={styles.goBackText}>
                {destination.includes(params.candidateId)
                  ? 'retour au dossier du candidat'
                  : 'retour aux entretiens'}
              </p>
            </button>
          </div>
        )}
        {fetchStatus === FETCH_STATUS.FULFILLED && simplifiedHeader && (
          <SimplifiedHeader
            fields={Object.entries(getNavigationStructure(recruitingStatusQuery.data)).map(item => {
              return item[1].name;
            })}
            selectedkey={Object.entries(getNavigationStructure(recruitingStatusQuery.data)).map(
              item => {
                return item[0];
              }
            )}
            selected={params.firstSection}
            onSelectionChange={section => {
              const destinationRoute = getRecruitingEditionRoute({
                candidateId: params.candidateId,
                section: section,
                id:
                  section === 'R1'
                    ? recruitingStatusQuery.data?.r1Interview?.interviewId
                    : recruitingStatusQuery.data?.r2Interview?.interviewId,
              });
              if (navContextElement.hasFormBeenTouched) {
                navContextElement.openNavWarningModal({ destination: destinationRoute });
                navContextElement.setFetchStatus(FETCH_STATUS.FULFILLED);
              } else {
                history.push(destinationRoute);
              }
            }}
          />
        )}
        {fetchStatus === FETCH_STATUS.FULFILLED && !simplifiedHeader && (
          <div className={styles.tabsContainer}>
            <SegmentedMenu
              controls={Object.entries(getNavigationStructure(recruitingStatusQuery.data))}
              selected={params.firstSection}
              keyValueExtractor={([section, { name }]) => ({ key: section, value: name })}
              onSelectionChange={([section]) => {
                const destinationRoute = getRecruitingEditionRoute({
                  candidateId: params.candidateId,
                  section: section,
                  id:
                    section === 'R1'
                      ? recruitingStatusQuery.data?.r1Interview?.interviewId
                      : recruitingStatusQuery.data?.r2Interview?.interviewId,
                });
                if (navContextElement.hasFormBeenTouched) {
                  navContextElement.openNavWarningModal({ destination: destinationRoute });
                  navContextElement.setFetchStatus(FETCH_STATUS.FULFILLED);
                } else {
                  history.push(destinationRoute);
                }
              }}
            />
          </div>
        )}
        <div className={styles.tabContainer}>
          <div className={styles.sideBarContainer}>
            <Animation.Unroll visible={candidateDetailsQuery.data !== undefined}>
              <div className={styles.candidateName}>
                <p>vous êtes sur le profil de :</p>
                {`${candidateDetailsQuery.data?.firstName ?? ''} ${
                  candidateDetailsQuery.data?.name ?? ''
                }`}
              </div>
              <div className={styles.candidateContract}>{`${
                candidateDetailsQuery.data?.statusCode ?? 'type de contrat non renseigné'
              } | ${pluralFormat(candidateDetailsQuery.data?.nbHoursWorked ?? 0, 'heure')} `}</div>
            </Animation.Unroll>
            <RecruitingSideBar />
          </div>
          {fetchStatus === FETCH_STATUS.PENDING && (
            <div className={styles.loader}>
              <Loader heightInRem={8} />
            </div>
          )}

          {fetchStatus === FETCH_STATUS.REJECTED && (
            <div className={styles.errorContainer}>
              <div className={styles.errorYellowLadder}>
                <IllusEscalierYellow />
              </div>
              <p className={styles.errorTitle}>erreur serveur, connexion perdue</p>
              <p className={styles.errorDescription}>
                dès que vous retrouverez la connexion, vous pourrez recharger la page
              </p>
            </div>
          )}

          {fetchStatus === FETCH_STATUS.FULFILLED && (
            <CandidateRecruitingForm
              simplifiedHeader={simplifiedHeader}
              setSimplifiedHeader={setSimplifiedHeader}
            />
          )}
        </div>
      </div>
    </FormStatusContext.Provider>
  );
};

export default CandidateRecruitingProcess;
