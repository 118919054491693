import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import TextInputWithDisplayMode from '../WithDisplayMode/TextInputWithDisplayMode';

import styles from './MaxMobilityFields.module.scss';
import { Props } from './MaxMobilityFields.types';

const MaxMobilityFields = ({
  control,
  maxMobilityTime,
  maxMobilityDistance,
  isDisplayMode,
}: Props) => (
  <div className={styles.maxMobilityContainer}>
    <WithLightTitle title="distance maximum en km" className={styles.maxMobilityInput}>
      <TextInputWithDisplayMode
        control={control}
        name="maxMobility.maxMobilityDistance"
        defaultValue={40}
        isDisplayMode={isDisplayMode}
      />
      {maxMobilityDistance &&
        parseInt(maxMobilityDistance) > 0 &&
        parseInt(maxMobilityDistance) < 40 && (
          <div className={styles.warningMessage}>
            la distance contractuelle souhaitée est de 40km minimum
          </div>
        )}
    </WithLightTitle>
    <WithLightTitle title="temps maximum en minutes" className={styles.maxMobilityInput}>
      <TextInputWithDisplayMode
        control={control}
        name="maxMobility.maxMobilityTime"
        defaultValue={75}
        isDisplayMode={isDisplayMode}
      />
      {maxMobilityTime && parseInt(maxMobilityTime) > 0 && parseInt(maxMobilityTime) < 75 && (
        <div className={styles.warningMessage}>
          la durée contractuelle souhaitée est de 75min minimum
        </div>
      )}
    </WithLightTitle>
  </div>
);

export default MaxMobilityFields;
