import * as z from 'zod';

import { CandidateInterviewsSummaryStatus } from 'src/Services/API';

export interface Props {
  isOpen: boolean;
  close: () => void;
  status: CandidateInterviewsSummaryStatus;
  interviewId: string;
}

export const r1ReopenSchema = z.object({
  comment: z.string(),
});
