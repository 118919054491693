import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { ScoringEnum } from 'src/Services/API';

import styles from './ScoringButton.module.scss';
import { Props } from './ScoringButton.types';

const ScoringButtonComponent = ({ value, onChange }: Props) => {
  const items = Object.values(ScoringEnum);
  const getStringValue = (num: ScoringEnum) => {
    switch (num) {
      case ScoringEnum.High:
        return '+++';
      case ScoringEnum.Medium:
        return '+/-';
      case ScoringEnum.Low:
        return '---';
      default:
        return 'info inconnue';
    }
  };
  return (
    <div className={styles.container}>
      <ExclusiveSelectionGroup
        selected={value}
        values={items}
        getKey={key => key.toString()}
        getStringValue={value => getStringValue(value)}
        setSelected={value => onChange(value)}
        canBeReset
      />
    </div>
  );
};

export default ScoringButtonComponent;
