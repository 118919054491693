import { CandidateInterviewsSummary } from 'src/Services/API';

import CandidateAssessment from './R1/CandidateAssessment';
import CandidateExperiences from './R1/CandidateExperiences';
import CandidateInformations from './R1/CandidateInformations';
//import CandidateJobs from './R1/CandidateJobs';
import CandidateProjections from './R1/CandidateProjections';
import CandidateStrength from './R1/CandidateStrength';
import ContextInformations from './R1/ContextInformations';
import CandidateExchanges from './R2/CandidateExchanges';
import R2ContextInformations from './R2/ContextInformations';
import R2CandidateProjections from './R2/R2CandidateProjections';
import R2Validation from './R2/R2Validation';

interface CandidateRecruitingProcessNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: { name: string; linkTo: string; component?: () => JSX.Element; path: string }[];
}

type CandidateRecruitingProcessNav = { [key: string]: CandidateRecruitingProcessNavigationNode };

const baseRoute = '/candidate/:candidateId/recruit';

const r1Menu = {
  name: 'formulaire R1',
  default: './context',
  path: `${baseRoute}/R1/:id`,
  routes: [
    {
      name: 'information contexte',
      linkTo: './context',
      path: `${baseRoute}/R1/:id/context`,
      component: ContextInformations,
    },
    {
      name: 'profil candidat',
      linkTo: './profil',
      path: `${baseRoute}/R1/:id/profil`,
      component: CandidateInformations,
    },
    {
      name: 'projection professionnelle',
      linkTo: './projection',
      path: `${baseRoute}/R1/:id/projection`,
      component: CandidateProjections,
    },
    {
      name: 'résumé des dernières expériences professionnelles',
      linkTo: './resume',
      path: `${baseRoute}/R1/:id/resume`,
      component: CandidateExperiences,
    },
    {
      name: "points forts et axes d'amélioration",
      linkTo: './strength',
      path: `${baseRoute}/R1/:id/strength`,
      component: CandidateStrength,
    },
    //TODO: uncomment the following lines to work on the candidate experience
    // {
    //   name: 'expériences sur les 3 métiers du contrat CDI-I',
    //   linkTo: './jobsExperience',
    //   path: `${baseRoute}/R1/:id/jobsExperience`,
    //   component: CandidateJobs,
    // },
    {
      name: "bilan de l'entretien",
      linkTo: './final',
      path: `${baseRoute}/R1/:id/final`,
      component: CandidateAssessment,
    },
  ],
};

const r2Menu = {
  name: 'formulaire R2',
  default: './context',
  path: `${baseRoute}/R2/:R2Id`,
  routes: [
    {
      name: 'informations contexte',
      linkTo: './context',
      path: `${baseRoute}/R2/:R2Id/context`,
      component: R2ContextInformations,
    },
    {
      name: 'échanges',
      linkTo: './exchange',
      path: `${baseRoute}/R2/:R2Id/exchange`,
      component: CandidateExchanges,
    },
    {
      name: 'projections',
      linkTo: './projection',
      path: `${baseRoute}/R2/:R2Id/projection`,
      component: R2CandidateProjections,
    },
    {
      name: 'validation',
      linkTo: './validation',
      path: `${baseRoute}/R2/:R2Id/validation`,
      component: R2Validation,
    },
  ],
};

export const getNavigationStructure = (
  statuses?: CandidateInterviewsSummary
): CandidateRecruitingProcessNav => {
  if (statuses?.r2Interview?.interviewId === undefined) {
    return { R1: r1Menu };
  } else {
    return { R1: r1Menu, R2: r2Menu };
  }
};
