import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSkills } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import SkillModal from './SkillModal.component';

const MemoSkillModal = React.memo(SkillModal);

const EnhancedSkillModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const skills = useSelector(getSkills);
  const onSkillsChange = useCallback(
    (skills: MatchingCriteriaItemWithLabel[]) => dispatch(searchActions.setSkills(skills)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return <MemoSkillModal skills={skills} onSkillsChange={onSkillsChange} onClose={onClose} />;
};

export default EnhancedSkillModal;
