import { Binocular } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';

import { CandidateAgency } from 'src/Components/CandidateAgency';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateAgenciesFromURL } from 'src/Hooks/Candidates/useFetchCandidateAgenciesFromURL';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { CandidateStatuses } from 'src/Services/API';

import styles from './Agency.module.scss';

const Agencies = () => {
  const candidate = useFetchCandidateDetailsFromURL().data;
  const isCDI = candidate?.statusCode === CandidateStatuses['CDI'];
  const agenciesQuery = useFetchCandidateAgenciesFromURL(isCDI);
  return (
    <div>
      {agenciesQuery.isLoading &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="agency">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {agenciesQuery.isError && (
        <ErrorMessage message="une erreur est survenue dans la récupération des agences d'inscription" />
      )}
      {agenciesQuery.isSuccess && agenciesQuery.data.length === 0 && (
        <div className={styles.noResult}>
          <Binocular />
          <p className={styles.noResultText}>le TT n'est rattachée à aucune agence ouverte</p>
        </div>
      )}
      {agenciesQuery.isSuccess &&
        agenciesQuery.data.length > 0 &&
        agenciesQuery.data?.map(agency => <CandidateAgency agency={agency} />)}
    </div>
  );
};

export default Agencies;
