import {
  WithLightTitle,
  AddButton,
  DatePickerInputDeprecated,
  Checkbox,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import React, { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';

import { useFetchDiplomasAsDiploma } from 'src/Hooks/Diplomas/useFetchDiplomasAsDiploma';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { detectedItemsCount, generateUniqueKey, replaceItem } from '../../utils';
import ParsedDocumentLabel from '../Components/ParsedDocumentLabel/ParsedDocumentLabel.component';
import RemoveItem from '../Components/RemoveItem/RemoveItem.component';

import styles from './SelectDiplomas.module.scss';
import { Props } from './SelectDiplomas.types';

const SelectDiplomas = ({ control, watch }: Props) => {
  const diplomaController = useController({
    control,
    name: 'diplomas',
  });
  const mutation = useFetchDiplomasAsDiploma();
  const [lastIndexChanged, setLastIndexChanged] = useState(0);
  const watchDiplomas = watch('diplomas');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  const [_, setKeywords] = useState(
    diplomaController?.field?.value?.map(diploma => diploma.selectedItem.label)
  );
  const detectedDiplomasCount = detectedItemsCount(watchDiplomas);

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        diplômes ({pluralFormat(detectedDiplomasCount, 'détectée')})
      </div>

      {diplomaController.field?.value?.map((diploma, index) => {
        return (
          <div className={styles.containerParsedDocumentLabel} key={diploma.uniqueKey}>
            {diploma.parsedDocumentLabel && (
              <ParsedDocumentLabel
                title={'diplôme détecté'}
                label={diploma.parsedDocumentLabel}
                titleClassName={styles.parsedDocumentLabelTitle}
              />
            )}
            <div className={styles.diplomaContainer}>
              <WithLightTitle
                title={
                  diploma.parsedDocumentLabel
                    ? 'compétence équivalente osmose proposée'
                    : `diplôme #${index + 1}`
                }
                className={styles.diplomaTitle}
              >
                <div className={styles.comboBoxContainer}>
                  <ComboBox
                    id="diplomas"
                    control={control}
                    name={`diplomas.${index}.selectedItem`}
                    keyValueExtractor={searchResult => ({
                      key: searchResult.id,
                      value: searchResult.label,
                    })}
                    placeholder="sélectionner"
                    onSearch={keyword => {
                      setKeywords(keywords => {
                        keywords.splice(index, 1, keyword);
                        return [...keywords];
                      });
                      keyword !== '' && throttledMethod(keyword);
                      setLastIndexChanged(index);
                    }}
                    disableClearable
                    minLengthToSearch={1}
                    searchResults={
                      mutation.data?.filter(
                        item => !watchDiplomas.some(diploma => diploma.selectedItem.id === item.id)
                      ) ?? []
                    }
                    className={styles.comboBox}
                    fetchStatus={
                      lastIndexChanged === index ? toFetchStatus(mutation) : FETCH_STATUS.FULFILLED
                    }
                  />
                  <RemoveItem
                    onClick={() => {
                      diplomaController.field.onChange(
                        watchDiplomas.filter((_, idx) => idx !== index)
                      );
                    }}
                    className={styles.removeItem}
                  />
                </div>
              </WithLightTitle>
            </div>
            <div className={styles.dateContainer}>
              <WithLightTitle title="date d'obtention">
                <DatePickerInputDeprecated
                  onSelectDate={item =>
                    diplomaController.field.onChange(
                      replaceItem(watchDiplomas, index, {
                        ...watchDiplomas[index],
                        selectedItem: { ...watch('diplomas')[index].selectedItem },
                        date: item,
                      })
                    )
                  }
                  date={watchDiplomas[index]?.date}
                />
              </WithLightTitle>
              <WithLightTitle title="diplôme principal" className={styles.mainDiploma}>
                <div className={styles.mainDiplomaCheckBox}>
                  <Checkbox
                    checked={watchDiplomas[index].main}
                    label="définir comme diplôme principal"
                    onChange={check =>
                      diplomaController.field.onChange(
                        watchDiplomas.map((diploma, idx) => {
                          return {
                            ...diploma,
                            selectedItem: { ...diploma.selectedItem },
                            main: check ? idx === index : false,
                          };
                        })
                      )
                    }
                  />
                </div>
              </WithLightTitle>
            </div>
            <div className={styles.separator} />
          </div>
        );
      })}
      <AddButton
        icon={<PlusOpen />}
        text="ajouter un diplôme"
        className={styles.addButton}
        onClick={() =>
          diplomaController.field.onChange([
            ...watchDiplomas,
            {
              selectedItem: { id: '', label: '' },
              main: false,
              obtained: true,
              uniqueKey: generateUniqueKey(watchDiplomas),
            },
          ])
        }
      />
    </div>
  );
};
export default SelectDiplomas;
