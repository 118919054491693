import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateSkillsEntity } from 'src/Services/API';

import { useFetchCandidateSkills } from './useFetchCandidateSkills';

export const useFetchCandidateSkillsFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateSkillsEntity, string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateSkills(candidateId, extraOptions);
};
