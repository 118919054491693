import { WithLightTitle, AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import { Props } from './SelectCACES.types';

const SelectCACES = ({ selectedCaces, setCaces, caces }: Props) => {
  const [keyword, setKeyword] = useState('');
  const researchedCaces = caces.filter(x => x.label?.toLowerCase().includes(keyword.toLowerCase()));
  return (
    <WithLightTitle title="ou rechercher parmi l'ensemble des caces">
      <AutoSuggest
        id="caces"
        value={selectedCaces ?? []}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(searchedValue: string) => setKeyword(searchedValue)}
        onChange={(caces: readonly MatchingCriteriaItemWithLabel[]) => {
          setCaces([...caces]);
          setKeyword('');
        }}
        searchResults={researchedCaces}
        placeholder="1 caractère minimum"
      />
    </WithLightTitle>
  );
};

export default SelectCACES;
