import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

export interface Props {
  habilitations: MatchingCriteriaItemWithLabel[];
  onHabilitationChange: (habilitations: MatchingCriteriaItemWithLabel[]) => void;
  onClose: () => void;
}

export enum HABILITATION_MODALS_STEP {
  NORMAL_HABILITATION,
  ELECTRIC_HABILITATION,
}
