import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateETests } from 'src/Services/API';

import { useFetchCandidateETests } from '../CandidateR2/useFetchCandidateETests';

export const useFetchCandidateETestsFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateETests[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateETests(candidateId, extraOptions);
};
