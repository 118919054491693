import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PieChartWithTitle from 'src/Components/PieChartWithTitle';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { R1StatusForStats } from 'src/Services/API';

import { DASHBOARD_MENU } from '../../InterviewDashboard.component';
import { InterviewDashboardContext } from '../../InterviewTraceBoardsContext';
import {
  R1PieLabelsMapping,
  R1PieToGlobalStatusMapping,
  R2PieLabelsMapping,
  R2PieToGlobalStatusMapping,
} from '../../utils';

import styles from './InterviewsPieCharts.module.scss';
import {
  Props,
  r1PieColorMap,
  R1PieLabels,
  r2PieColorMap,
  R2PieLabels,
} from './InterviewsPieCharts.types';

const InterviewsPieCharts = ({ interviewsStatsArray }: Props) => {
  const dispatch = useDispatch();
  const interviewDashboardContext = useContext(InterviewDashboardContext);
  const history = useHistory();
  const r1ArrayForPie = interviewsStatsArray?.r1Interviews
    .filter(category => category.status !== R1StatusForStats['accepté avec un r2'])
    .map(interview => {
      const status = R1PieLabelsMapping[interview.status];
      return {
        key: status,
        value: interview.count,
        color: r1PieColorMap[status],
      };
    });
  const r2ArrayForPie = interviewsStatsArray?.r2Interviews.map(interview => {
    const status = R2PieLabelsMapping[interview.status];
    return {
      key: status,
      value: interview.count,
      color: r2PieColorMap[status],
    };
  });
  return (
    <div className={styles.container}>
      <PieChartWithTitle
        title="répartition des différents R1"
        data={r1ArrayForPie ?? []}
        className={styles.chartItemContainer}
        onCellClick={key => {
          dispatch(
            userPreferencesActions.setDashboardStatus(
              R1PieToGlobalStatusMapping[key as R1PieLabels]
            )
          );
          interviewDashboardContext.setSegmentedControlKey(
            interviewDashboardContext.segmentedControlKey + 1
          );
          history.push(DASHBOARD_MENU.FOLLOW);
        }}
      />
      <PieChartWithTitle
        title="répartition des différents R2"
        data={r2ArrayForPie ?? []}
        className={styles.chartItemContainer}
        onCellClick={key => {
          dispatch(
            userPreferencesActions.setDashboardStatus([
              R2PieToGlobalStatusMapping[key as R2PieLabels],
            ])
          );
          interviewDashboardContext.setSegmentedControlKey(
            interviewDashboardContext.segmentedControlKey + 1
          );
          history.push(DASHBOARD_MENU.FOLLOW);
        }}
      />
    </div>
  );
};

export default InterviewsPieCharts;
