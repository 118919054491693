import { FetchButton, toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AutosuggestWithList from 'src/Components/AutosuggestWithList';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { useFetchHabilitationsAndDiploma } from 'src/Hooks/Habilitations/useFetchHabilitationsAndDiploma';
import { useFetchCitiesAndDepartments } from 'src/Hooks/Locations/useFetchCitiesAndDepartments';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { useFetchQualificationsWithThrottle } from 'src/Hooks/Qualifications/useFetchQualifications';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './CandidateProjections.module.scss';
import { candidateProjectionsSchema } from './CandidateProjections.types';

const CandidateProjections = () => {
  const units = useSelector(getPerimeterUnits);

  const params = useParams<{
    id: string;
  }>();
  const {
    data: candidateR1Data,
    isLoading: isLoadingCandidateR1,
    isSuccess: isCandidateR1Success,
    isFetching: isFetchingCandidateR1,
  } = useFetchCandidateR1FromURL();
  const [qualificationKeyword, setQualificationKeyword] = useState('');
  const [mobilityKeyword, setMobilityKeyword] = useState('');
  const [formationKeyword, setFormationKeyword] = useState('');
  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  const locationMutation = useFetchCitiesAndDepartments();
  const formationMutation = useFetchHabilitationsAndDiploma();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledLocationMethod = useCallback(throttle(locationMutation.mutate, 500), [
    locationMutation.mutate,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFormationMethod = useCallback(throttle(formationMutation.mutate, 500), [
    formationMutation.mutate,
  ]);
  const locationFetchStatus = toFetchStatus(locationMutation);
  const formationFetchStatus = toFetchStatus(formationMutation);
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const fetchStatus = toFetchStatus(candidateR1Update);

  const { control, setValue, watch, handleSubmit, reset, getValues } = useFormWithZodResolver({
    schema: candidateProjectionsSchema,
    defaultValues: {
      mobility: candidateR1Data?.projections?.mobility ?? [],
      formation: candidateR1Data?.projections?.formation ?? [],
      evolution: candidateR1Data?.projections?.evolution ?? [],
      other: candidateR1Data?.projections?.other,
    },
  });

  const isDisplayMode = useMemo(
    () =>
      candidateR1Data && (candidateR1Data.isClosed || !units.includes(candidateR1Data.agencyName)),
    [candidateR1Data, units]
  );

  const projectionsHandleSubmit = () =>
    handleSubmit(values => {
      candidateR1Data &&
        !isDisplayMode &&
        candidateR1Update.mutate({
          ...candidateR1Data,
          projections: values,
        });
      reset(values, { keepValues: true });
    });

  useEffect(() => {
    if (isCandidateR1Success) {
      setValue('mobility', candidateR1Data?.projections?.mobility ?? []);
      setValue('formation', candidateR1Data?.projections?.formation ?? []);
      setValue('evolution', candidateR1Data?.projections?.evolution ?? []);
      setValue('other', candidateR1Data?.projections?.other);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR1Success, isFetchingCandidateR1, isLoadingCandidateR1]);
  const evolutionController = useController({ control, name: 'evolution' });
  const mobilityController = useController({ control, name: 'mobility' });
  const formationController = useController({ control, name: 'formation' });

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful, dirtyFields } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: projectionsHandleSubmit,
      fetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, fetchStatus]);
  return (
    <>
      <div className={styles.title}>échanges</div>
      {isCandidateR1Success && !isFetchingCandidateR1 && (
        <>
          <div className={styles.autosuggestContainer}>
            <AutosuggestWithList
              isDisplayMode={isDisplayMode}
              values={evolutionController.field.value}
              onChange={qualifications => evolutionController.field.onChange(qualifications)}
              dataExtractor={(item: { id: string; label: string }) => {
                return { key: item.id, label: item.label };
              }}
              title="qualifications"
              isFetching={isLoadingCandidateR1 || isFetchingCandidateR1}
              isSuccess={isCandidateR1Success && !isFetchingCandidateR1}
              emptyText=""
              singularTitle="qualification"
              cardContainerClassName={styles.cardsContainer}
              autosuggestFetchStatus={toFetchStatus(qualificationThrottledMutation)}
              autosuggestTitle="Souhaits de qualifications pour évoluer (10 maximum)"
              results={
                qualificationKeyword !== ''
                  ? qualificationThrottledMutation.data
                      ?.map(qualification => {
                        return { id: qualification.identifier, label: qualification.label ?? '' };
                      })
                      .filter(
                        qualification =>
                          !evolutionController.field.value
                            .map(item => item.id)
                            .includes(qualification.id)
                      ) ?? []
                  : []
              }
              onSearch={(word: string) => {
                setQualificationKeyword(word);
                word !== '' && qualificationThrottledMutation.mutate(word);
              }}
              disabled={evolutionController.field.value.length >= 10}
            />
          </div>
          <div className={styles.autosuggestContainer}>
            <AutosuggestWithList
              isDisplayMode={isDisplayMode}
              values={mobilityController.field.value}
              onChange={locations => mobilityController.field.onChange(locations)}
              dataExtractor={(item: { id: string; label: string }) => {
                return { key: item.id, label: item.label };
              }}
              title="lieux ajoutés"
              isFetching={isLoadingCandidateR1 || isFetchingCandidateR1}
              isSuccess={isCandidateR1Success && !isFetchingCandidateR1}
              emptyText=""
              singularTitle="lieu ajouté"
              cardContainerClassName={styles.cardsContainer}
              autosuggestFetchStatus={locationFetchStatus}
              autosuggestTitle="Souhaits des lieux de mobilité (10 maximum)"
              results={
                mobilityKeyword !== ''
                  ? locationMutation.data?.filter(
                      location =>
                        !mobilityController.field.value.map(item => item.id).includes(location.id)
                    ) ?? []
                  : []
              }
              onSearch={(word: string) => {
                setMobilityKeyword(word);
                word !== '' && throttledLocationMethod(word);
              }}
              disabled={mobilityController.field.value.length >= 10}
            />
          </div>
          <div className={styles.autosuggestContainer}>
            <AutosuggestWithList
              isDisplayMode={isDisplayMode}
              values={formationController.field.value}
              onChange={formations => formationController.field.onChange(formations)}
              dataExtractor={(item: { id: string; label: string }) => {
                return { key: item.id, label: item.label };
              }}
              title="formations ajoutées"
              isFetching={isLoadingCandidateR1 || isFetchingCandidateR1}
              isSuccess={isCandidateR1Success && !isFetchingCandidateR1}
              emptyText=""
              singularTitle="formation ajoutée"
              cardContainerClassName={styles.cardsContainer}
              autosuggestFetchStatus={formationFetchStatus}
              autosuggestTitle="Souhaits de formation (10 maximum)"
              results={
                formationKeyword !== ''
                  ? formationMutation.data?.filter(
                      formation =>
                        !formationController.field.value.map(item => item.id).includes(formation.id)
                    ) ?? []
                  : []
              }
              onSearch={(word: string) => {
                setFormationKeyword(word);
                word !== '' && throttledFormationMethod(word);
              }}
              disabled={formationController.field.value.length >= 10}
            />
          </div>
          <TextAreaWithLightTitleWithDisplayMode
            key="other"
            isDisplayMode={isDisplayMode}
            title="autres retours"
            placeHolder="en quelques mots..."
            control={control}
            name="other"
            valueLength={watch('other')?.length}
            containerClassName={styles.questionSpace}
            textAreaClassName={styles.question}
          />
        </>
      )}
      {(isLoadingCandidateR1 || isFetchingCandidateR1) &&
        new Array(3).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="5rem" width="100%" uniqueKey="r1projections">
            <rect x="2%" y="10" rx="4" ry="4" width="90%" height="48" />
          </ContentLoader>
        ))}
      {!isDisplayMode && (
        <FetchButton
          title="valider projection professionnelle"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : fetchStatus}
          className={styles.button}
          onClick={projectionsHandleSubmit()}
          onSuccess={() => {
            toast.success('Votre R1 a été modifié');
            reset(getValues(), { keepValues: true });
          }}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R1")}
        />
      )}
    </>
  );
};

export default CandidateProjections;
