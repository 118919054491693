import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidateContract, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateLastContract = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateContract, string[]>
) => {
  const brandCode = useSelector(getBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateLastContract, id, brandCode],
    async () => {
      const contracts = await CandidatesService.candidatesControllerGetCandidateLastContract({
        id,
        brandCode,
      });
      return contracts[0];
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
