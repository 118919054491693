import { IllusRLCACESSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasCaces } from 'src/Redux/Search/Selectors';

import styles from './CACESButton.module.scss';

const CACESButton = () => {
  const _hasCaces = useSelector(hasCaces);
  const cacesModal = useModalWithLocation('caces');
  return (
    <div>
      <CriteriaButton
        label="caces"
        icon={<IllusRLCACESSmall className={styles.icon} />}
        selected={_hasCaces}
        onClick={() => cacesModal.open()}
      />
    </div>
  );
};

export default CACESButton;
