import { AddButton } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useController } from 'react-hook-form';

import { pluralFormat } from 'src/Utils/pluralFormat';

import { detectedItemsCount, generateUniqueKey } from '../../utils';

import SelectQualification from './SelectQualification/SelectQualification.component';
import styles from './SelectQualifications.module.scss';
import { Props } from './SelectQualifications.types';

const SelectQualifications = ({ control, watch }: Props) => {
  const qualificationsController = useController({
    control,
    name: 'qualifications',
  });

  const detectedQualificationsCount = detectedItemsCount(watch('qualifications'));

  const watchQualifications = watch('qualifications');

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        qualifications ({pluralFormat(detectedQualificationsCount, 'détectée')})
      </div>
      {watchQualifications.map((qualification, index) => (
        <SelectQualification
          control={control}
          watch={watch}
          index={index}
          key={(qualification.selectedItem?.id ?? '').concat(index.toString())}
        />
      ))}
      <AddButton
        icon={<PlusOpen />}
        text="ajouter une qualification"
        onClick={() =>
          qualificationsController.field.onChange([
            ...watchQualifications,
            {
              selectedItem: { id: '', label: '' },
              uniqueKey: generateUniqueKey(watchQualifications),
              main: false,
            },
          ])
        }
        className={styles.addButton}
      />
    </div>
  );
};
export default SelectQualifications;
