import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CGC, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCGC = (
  extraOptions?: UseQueryOptions<unknown, unknown, CGC[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchCGC],
    async () => await RepositoriesService.repositoriesControllerGetInhouseCgc(),
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};
