import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateDocument, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateDocuments = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateDocument[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateDocuments, id],
    async () => {
      const documents = await CandidatesService.candidatesControllerGetCandidateDocuments({
        id,
      });
      return documents;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
