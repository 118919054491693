import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchInterviewsStatisticsPerAgency = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);
  return useQuery(
    [QueryKeys.fetchInterviewsStatisticsPerAgency, agencyIds],
    async () => {
      const interviewsStatsPerAgency =
        await InterviewsService.interviewsControllerGetInterviewsStatsPerAgency({
          body: {
            agencyIds: agencyIds,
          },
        });

      return interviewsStatsPerAgency;
    },
    { enabled: agencyIds !== undefined }
  );
};
