import { IllusRLCompanySmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasCompany } from 'src/Redux/Search/Selectors';

import styles from './CompanyButton.module.scss';

const CompanyButton = () => {
  const _hasCompany = useSelector(hasCompany);
  const companyModal = useModalWithLocation('company');
  return (
    <div>
      <CriteriaButton
        label="comptes"
        icon={<IllusRLCompanySmall className={styles.icon} />}
        selected={_hasCompany}
        onClick={() => companyModal.open()}
      />
    </div>
  );
};

export default CompanyButton;
