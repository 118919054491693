import classnames from 'classnames';
import React from 'react';

import styles from './HourCard.module.scss';
import { Props } from './HourCard.types';
import StatusPicker from './StatusPicker';

const HourCard = (props: Props) => (
  <div className={classnames(styles.container, props.className)}>
    <div className={styles.leftSide}>
      <div className={styles.icon}>{props.icon}</div>
      <div className={styles.text}>{props.text}</div>
    </div>
    <div className={styles.rightSide}>
      <StatusPicker
        isDisplayMode={props.isDisplayMode}
        onChange={props.onPriorityChanged}
        selectedPriority={props.priority}
      />
    </div>
  </div>
);

export default HourCard;
