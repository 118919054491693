import {
  CardCheckable,
  CardContent,
  TextArea,
} from '@randstad-lean-mobile-factory/react-components-core';

import styles from './TextAreaCard.module.scss';
import { TextAreaCardProps } from './TextAreaCard.types';

export const TextAreaCard = ({
  title,
  checked,
  onCheckedChange,
  text,
  onTextChange,
  placeholder,
  maxLength,
}: TextAreaCardProps) => (
  <CardCheckable color="beige" checked={checked} onChange={onCheckedChange}>
    <CardContent title={title} supportingLines={[`${text.length}/${maxLength} caractères`]}>
      <TextArea
        placeholder={placeholder}
        maxLength={maxLength}
        onClick={event => event.stopPropagation()}
        onKeyDown={event => event.stopPropagation()}
        onKeyUp={event => event.stopPropagation()}
        onMouseDown={event => event.stopPropagation()}
        value={text}
        onChange={event => onTextChange(event.currentTarget.value)}
        className={styles.textArea}
      />
    </CardContent>
  </CardCheckable>
);
