import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { MatchingCriteriaLocationWithLabel, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCities = (
  keyword: string,
  extraOptions?: UseQueryOptions<unknown, unknown, MatchingCriteriaLocationWithLabel[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchCities],
    async () => {
      const locations = await RepositoryService.citiesControllerGetCities({
        keyword,
      });
      const results = locations.map(location => {
        return {
          zipCode: location.zipCode ?? '',
          label: location.name,
          inseeCode: location.insee ?? '',
          radius: 0,
        };
      });
      return results;
    },
    {
      ...extraOptions,
    }
  );
};
