import classnames from 'classnames';
import React from 'react';

import styles from './CriteriaButton.module.scss';
import { Props } from './CriteriaButton.types';

const CriteriaButton = ({
  icon,
  label,
  selected,
  disabled,
  onClick,
  className,
  iconClassName,
  labelClassName,
}: Props) => (
  <div
    className={classnames(
      styles.container,
      {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      },
      className
    )}
    onClick={() => {
      if (!disabled) {
        onClick();
      }
    }}
  >
    <div className={classnames(styles.icon, iconClassName)}>{icon}</div>
    <div className={classnames(styles.label, labelClassName)}>{label}</div>
  </div>
);

export default CriteriaButton;
