import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFetchMissionById } from 'src/Hooks/Missions/useFetchMissionById';
import { useFetchMissionCriteria } from 'src/Hooks/Missions/useFetchMissionCriteria';
import { QueryKeys } from 'src/Hooks/types';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { searchActions } from 'src/Redux/Search/Slice';

export const useWatchForFlashURLParam = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [currentFlashMissionId, setCurrentFlashMissionId] = useState<string>();
  const { data: mission } = useFetchMissionById({ missionId: currentFlashMissionId });
  const fetchMissionDetail = useFetchMissionCriteria({
    onSuccess: criteria => {
      dispatch(searchActions.setCriterion(criteria));
    },
  });
  useEffect(() => {
    const flashMissionId = new URLSearchParams(window.location.search).get('missionId') ?? '';

    if (flashMissionId && flashMissionId !== currentFlashMissionId) {
      setCurrentFlashMissionId(flashMissionId);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchMissionById] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  useEffect(() => {
    if (currentFlashMissionId && mission) {
      dispatch(missionsActions.setMissions([mission]));
      dispatch(
        missionsActions.setCompany({
          id: mission.company?.id ?? '',
          label: mission.company?.name ?? '',
        })
      );
      dispatch(
        missionsActions.setQualification({
          id: mission.qualification?.id ?? '',
          label: mission.qualification?.label ?? '',
        })
      );

      fetchMissionDetail.mutate(currentFlashMissionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlashMissionId, mission]);
};
