import { useIsMutating } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSearchMode } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { SEARCH_MODE } from 'src/Redux/Search/Types';

import SearchCriteriaSelection from './SearchCriteriaSelection.component';
import { OwnProps } from './SearchCriteriaSelection.types';

const MemoSearchCriteriaSelection = React.memo(SearchCriteriaSelection);

const EnhancedSearchCriteriaSelection = (props: OwnProps) => {
  const dispatch = useDispatch();
  const searchMode = useSelector(getSearchMode);
  const loading = useIsMutating(['searchCriteriasFromEDP']);
  const changeSearchMode = useCallback(
    (searchMode: SEARCH_MODE) => {
      dispatch(searchActions.changeSearchMode(searchMode));
    },
    [dispatch]
  );
  return (
    <MemoSearchCriteriaSelection
      className={props.className}
      searchMode={searchMode}
      loading={!!loading}
      changeSearchMode={changeSearchMode}
    />
  );
};

export default EnhancedSearchCriteriaSelection;
