import {
  BadgeDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { useFetchCandidateSkillsFromURL } from 'src/Hooks/Candidates/useFetchCandidateSkillsFromURL';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Qualifications.module.scss';
import { Props } from './Qualifications.types';

const Qualifications = ({ mainQualificationId }: Props) => {
  const qualifications = useFetchCandidateSkillsFromURL().data?.qualifications;

  return (
    <div className={styles.container}>
      <div className={styles.title}>information complémentaire</div>
      <WithLightTitle title={pluralFormat(qualifications?.length ?? 0, 'qualification')}>
        {qualifications?.map(qualification => (
          <div key={qualification.id} className={styles.qualificationName}>
            {qualification.label}
            {qualification.id === mainQualificationId && (
              <BadgeDeprecated value="principale" className={styles.gellule} />
            )}
          </div>
        ))}
      </WithLightTitle>
    </div>
  );
};

export default Qualifications;
