import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './ExclusiveSelectionGroupWithDisplayMode.module.scss';
import { OwnProps } from './ExclusiveSelectionGroupWithDisplayMode.types';

function ExclusiveSelectionGroupWithDisplayMode<T>({ isDisplayMode, ...props }: OwnProps<T>) {
  return (
    <>
      {isDisplayMode ? (
        <div className={styles.answerText}>{(props.selected as string) ?? 'non renseigné'}</div>
      ) : (
        <ExclusiveSelectionGroup {...props} />
      )}
    </>
  );
}

export default ExclusiveSelectionGroupWithDisplayMode;
