import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSelectedAgencies, getStatsSearchPeriod } from 'src/Redux/InterviewsStats/Selector';
import { AgenciesService } from 'src/Services/API';

import { searchDateMapping } from '../Interviews/useFetchInterviewsStatistics';
import { QueryKeys } from '../types';

export const useFetchSignedCDI = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const searchPeriod = useSelector(getStatsSearchPeriod);
  return useQuery(
    [QueryKeys.fetchSignedCDI, agencyIds, searchPeriod],
    async () => {
      const searchDate = searchDateMapping[searchPeriod];
      const signedCDI = await AgenciesService.agenciesControllerGetSignedCdiCount({
        body: {
          agencyIds: agencyIds,
          startDate: searchDate,
        },
      });

      return signedCDI;
    },
    { enabled: agencyIds !== undefined }
  );
};
