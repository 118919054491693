import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateR2WithClosed, useFetchCandidateR2 } from './useFetchCandidateR2';

export const useFetchCandidateR2FromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateR2WithClosed, string[]>
) => {
  const id = useParams<{ R2Id: string }>().R2Id;
  return useFetchCandidateR2(id, extraOptions);
};
