import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { Candidate } from 'src/Services/API';

import { useFetchCandidateDetails } from './useFetchCandidateDetails';

export const useFetchCandidateDetailsFromURL = (
  extraOptions?: UseQueryOptions<Candidate, unknown, Candidate, string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateDetails(candidateId, extraOptions);
};
