import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { CandidatesService, CandidateSkillsDto } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateCandidateSkills = (
  id: string,
  extraOptions?: UseMutationOptions<unknown, unknown, CandidateSkillsDto, unknown>
) => {
  const queryClient = useQueryClient();
  const { ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updateCandidateSkills, id],
    async (body: CandidateSkillsDto) => {
      return await CandidatesService.candidatesControllerUpdateCandidateSkills({
        id,
        body,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateR1] });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateSkills] });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateDetails] });
      },
      ...rest,
    }
  );
};
