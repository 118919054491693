import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getAvailabilityDate } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import AvailabilityModal from './AvailabilityModal.component';

const MemoAvailabilityModal = React.memo(AvailabilityModal);

const EnhancedAvailabilityModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const availabilityDate = useSelector(getAvailabilityDate);
  const saveSelectedDate = useCallback(
    (date: Date) => dispatch(searchActions.setAvailability(date)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoAvailabilityModal
      availabilityDate={availabilityDate}
      saveAvailibilityDate={saveSelectedDate}
      onClose={onClose}
    />
  );
};

export default EnhancedAvailabilityModal;
