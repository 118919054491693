import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';

export const getCandidateFileUrl = ({ candidateId }: { candidateId: string }) =>
  `/candidate/${candidateId}/file/synthese/identity`;

export const useOpenCandidateFile = () => {
  const history = useHistory();
  const perimeter = useSelector(getPerimeter);
  const brandCode = useSelector(getBrandCode);
  return {
    openCandidateFile: ({
      candidateId,
      emitAnalyticsEvent,
    }: {
      candidateId?: string;
      emitAnalyticsEvent?: boolean;
    }) => {
      if (candidateId === undefined) {
        toast.error("Erreur lors de l'ouverture du dossier de cet intérimaire");
        return;
      }
      if (emitAnalyticsEvent) {
        ReactGA.event('Consultation de dossier interimaire', {
          brandCode,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      }
      history.push(getCandidateFileUrl({ candidateId }));
    },
  };
};
