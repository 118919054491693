import classnames from 'classnames';
import React from 'react';

import styles from './ExperienceDisplayer.module.scss';
import { Props } from './ExperienceDisplayer.types';

const ExperienceDisplayer = ({ title, value, textClassName }: Props) => (
  <div className={styles.container}>
    <p className={styles.title}>{title}</p>
    <div className={classnames(styles.value, textClassName)}>{value}</div>
  </div>
);

export default ExperienceDisplayer;
