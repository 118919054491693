import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getQualifications } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import QualificationModal from './QualificationModal.component';

const MemoQualificationModal = React.memo(QualificationModal);

const EnhancedQualificationModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const qualifications = useSelector(getQualifications);
  const onQualificationChange = useCallback(
    (qualifications: MatchingCriteriaItemWithLabel[]) =>
      dispatch(searchActions.setQualifications(qualifications)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <MemoQualificationModal
      qualifications={qualifications}
      onQualificationChange={onQualificationChange}
      onClose={onClose}
    />
  );
};

export default EnhancedQualificationModal;
