import { Recherche } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  CardCheckable,
  CardContent,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import BehaviorSkills from 'src/Containers/CandidateResume/BusinessSkills/BehaviorSkills/BehaviorSkills.component';
import { useFetchCandidateBusinesses } from 'src/Hooks/Candidates/useFetchCandidateBusinesses';

import { SKILLS_TYPE } from './BehaviorSkills/BehaviorSkills.types';
import styles from './BusinessesSkills.module.scss';
import { BusinessSkillsProps } from './BusinessesSkills.types';

export const BusinessesSkills = ({ control, candidateId, watch }: BusinessSkillsProps) => {
  const businessSkills = useFetchCandidateBusinesses(candidateId);

  const { field: businessSkillsField } = useController({ control, name: 'skills.businessSkills' });
  const qualifications = watch('qualifications');

  const [skillsType, setSkillsType] = useState<SKILLS_TYPE>(SKILLS_TYPE.BUSINESSES_SKILLS);

  const filteredBusinessSkillsField = useMemo(
    () =>
      businessSkills.data
        ?.filter(business =>
          business.candidateQualifications?.some(
            qualif => qualif.qualificationId && qualifications.includes(qualif.qualificationId)
          )
        )
        .flatMap(business => business.candidateBusinessesSkills)
        .filter(Boolean)
        .map(skill => ({ ...skill, id: skill.id ?? '', label: skill.label ?? '' }))
        .filter(
          (item, index, self) =>
            index === self.findIndex(qualification => qualification.id === item.id)
        )
        .filter(skill => skill.id && skill.label),
    [businessSkills.data, qualifications]
  );

  const transformedBusinessSkillsField =
    filteredBusinessSkillsField?.reduce((acc, element) => {
      const { typeCodeLabel } = element;
      const existingGroup = acc.find(group => group.typeCodeLabel === typeCodeLabel);

      if (existingGroup) {
        existingGroup.items.push(element);
      } else {
        acc.push({ typeCodeLabel, items: [element] });
      }

      return acc;
    }, [] as { typeCodeLabel: string; items: (typeof filteredBusinessSkillsField)[0][] }[]) || [];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>sélectionnez les compétences à mettre en avant</h3>
      <ToggleMenu value={skillsType} onChange={setSkillsType}>
        <ToggleMenuItem value={SKILLS_TYPE.BUSINESSES_SKILLS}>compétences métier</ToggleMenuItem>
        <ToggleMenuItem value={SKILLS_TYPE.BEHAVIOR_SKILLS}>
          compétences comportementales
        </ToggleMenuItem>
      </ToggleMenu>
      {skillsType === SKILLS_TYPE.BUSINESSES_SKILLS ? (
        businessSkills.isError ? (
          <>
            <ErrorMessage message="Une erreur est survenue dans la récupération des compétences" />
            <div>
              <Button.Medium type="button" onClick={() => businessSkills.refetch()}>
                réessayer
              </Button.Medium>
            </div>
          </>
        ) : !filteredBusinessSkillsField ? (
          new Array(5).fill(null).map((_, idx) => (
            <ContentLoader key={'load_' + idx} height="3rem" width="100%">
              <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
            </ContentLoader>
          ))
        ) : transformedBusinessSkillsField.length === 0 ? (
          <div className={styles.noInfo}>
            <Recherche />
            <span>
              désolé,
              <br />
              aucune compétence sur{' '}
              {qualifications.length > 1
                ? 'les qualifications sélectionnées '
                : 'la qualification sélectionnée '}
              en amont
            </span>
          </div>
        ) : (
          transformedBusinessSkillsField.map(labelObj => (
            <div key={labelObj.typeCodeLabel}>
              <div className={styles.typeCodeLabel}>{labelObj.typeCodeLabel}</div>
              <div className={styles.gridContainer}>
                {labelObj.items.map(skill => (
                  <CardCheckable
                    key={skill.id}
                    color="beige"
                    checked={businessSkillsField.value.includes(skill.id)}
                    onChange={checked => {
                      if (checked) {
                        businessSkillsField.onChange([...businessSkillsField.value, skill.id]);
                      } else {
                        businessSkillsField.onChange(
                          businessSkillsField.value.filter(
                            qualificationId => qualificationId !== skill.id
                          )
                        );
                      }
                      businessSkillsField.onBlur();
                    }}
                  >
                    <CardContent
                      title={
                        skill.label.charAt(0).toUpperCase() + skill.label.slice(1).toLowerCase()
                      }
                      titleClassName={styles.cardTitle}
                    />
                  </CardCheckable>
                ))}
              </div>
            </div>
          ))
        )
      ) : (
        <BehaviorSkills control={control} name="skills.behaviourSkills" />
      )}
    </div>
  );
};
