import { AutoSuggest, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchPotentialPositions } from 'src/Hooks/PotentialPosition';
import { getCompany } from 'src/Redux/Search/Selectors';
import { MatchingCriteriaItemWithLabelWithoutWeight } from 'src/Redux/Search/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectPotentialPositions.types';

const SelectPotentialPositions = ({ selectedPotentialPositions, setPotentialPositions }: Props) => {
  const [keyword, setKeyword] = useState('');
  const company = useSelector(getCompany);
  const { data, isLoading, isSuccess, isError } = useFetchPotentialPositions(
    company?.identifier ?? ''
  );
  const researchedPotentialPositions = data
    ? data.filter(x => x.label?.toLowerCase().includes(keyword.toLowerCase()))
    : [];
  const fetchStatus = toFetchStatus({ isSuccess, isLoading, isError });

  return (
    <WithLightTitle title={`${researchedPotentialPositions.length} pré-affectations disponibles`}>
      <AutoSuggest
        id="pré-affectations"
        value={selectedPotentialPositions ?? []}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabelWithoutWeight) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          setKeyword(word);
        }}
        onChange={potentialPosition => {
          setPotentialPositions([...potentialPosition]);
          setKeyword('');
        }}
        searchResults={researchedPotentialPositions}
        placeholder="1 caractère minimum"
        fetchStatus={fetchStatus}
      />
    </WithLightTitle>
  );
};

export default SelectPotentialPositions;
