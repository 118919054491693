import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { SearchState, SEARCH_MODE } from './Types';

const getSearchState = (state: RootState) => state.search;

export const getSearchMode = createSelector(
  [getSearchState],
  (state: SearchState) => state.searchMode
);

export const getFirstName = createSelector(
  [getSearchState],
  (state: SearchState) => state.firstName
);

export const hasLastName = createSelector(
  [getSearchState],
  (state: SearchState) => state.lastName !== undefined && state.lastName !== ''
);

export const getSearchCriteria = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria
);

export const getQualifications = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.qualifications
);

export const hasQualifications = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.qualifications.length > 0
);

export const getAvailabilityDate = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.availabilityDate
);

export const hasAvailabilityDate = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.availabilityDate !== undefined
);

export const getSkills = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.skills
);

export const hasSkills = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.skills.length > 0
);

export const getLocation = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.location
);

export const hasLocation = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.location !== undefined
);

export const getDiplomas = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.diplomas
);

export const hasDiplomas = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.diplomas.length > 0
);

export const getHabilitations = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.habilitations
);

export const hasHabilitations = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.habilitations.length > 0
);

export const getCaces = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.caces
);

export const hasCaces = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.caces.length > 0
);

export const getDrivingLicence = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.drivingLicences
);

export const hasDrivingLicence = createSelector(
  [getSearchState],
  (state: SearchState) => (state.criteria.drivingLicences || []).length > 0
);

export const getLanguages = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.languages
);

export const hasLanguages = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.languages.length > 0
);

export const getCompany = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.company
);

export const hasCompany = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.company !== undefined
);

export const getService = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.service
);

export const hasService = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.service !== undefined
);

export const getPotentialPositions = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.potentialPositions
);

export const hasPotentialPosition = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.potentialPositions.length > 0
);

export const getKeywords = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.keywords
);

export const hasKeywords = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.keywords.length > 0
);

export const getCandidateStatuses = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria.candidateStatuses
);

export const getResultFilters = createSelector(
  [getSearchState],
  (state: SearchState) => state.resultFilters
);

export const hasFilter = createSelector(
  [getSearchState],
  (state: SearchState) =>
    state.criteria.candidateStatuses.length > 0 || state.resultFilters.workingHours.length > 0
);

export const getCriterion = createSelector(
  [getSearchState],
  (state: SearchState) => state.criteria
);

export const canDoCriteriaSearch = createSelector(
  [
    hasQualifications,
    hasAvailabilityDate,
    hasSkills,
    hasLocation,
    hasDiplomas,
    hasHabilitations,
    hasDrivingLicence,
    hasLanguages,
    hasCompany,
    hasKeywords,
    hasCaces,
  ],
  (...criterias) => criterias.some(criteria => criteria)
);

export const getCanSearch = createSelector(
  [getSearchMode, hasLastName, canDoCriteriaSearch],
  (searchMode, lastNameIsDefined, canSearchWithCriteria) =>
    searchMode === SEARCH_MODE.BY_CRITERIA ? canSearchWithCriteria : lastNameIsDefined
);

export const getLastName = createSelector([getSearchState], (state: SearchState) => state.lastName);
