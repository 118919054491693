import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Loupe } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import DocumentSupressionModal from 'src/Containers/Modals/AddNewTalentModals/DocumentSupressionModal/DocumentSupressionModal.component';

import PDFDocumentViewer from '../PDFDocumentViewer/PDFDocumentViewer.component';

import styles from './DocumentConfirmation.module.scss';
import { Props } from './DocumentConfirmation.types';

const DocumentConfirmation = ({ confirm, deleteDocument, documentFile }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <Button.Primary
          leftIcon={<Loupe />}
          className={styles.confirmButton}
          text="lancer la détection des données"
          onClick={confirm}
        />
        <DocumentSupressionModal onSuccess={deleteDocument} />
      </div>
      <div className={styles.documentContainer}>
        {documentFile?.file &&
        !['image/png', 'image/jpg', 'image/jpeg'].some(
          photoType => photoType === documentFile?.file?.type
        ) ? (
          <PDFDocumentViewer height={window.innerHeight - 346} file={documentFile.file} />
        ) : (
          <img src={documentFile?.base64File} className={styles.photo} alt="CV" />
        )}
      </div>
    </div>
  );
};

export default DocumentConfirmation;
