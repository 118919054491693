import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React from 'react';

import styles from './RemoveItem.module.scss';
import { Props } from './RemoveItem.types';

const RemoveItem = ({ onClick, className }: Props) => {
  return <Trashcan className={classNames([styles.trashCan, className])} onClick={onClick} />;
};

export default RemoveItem;
