import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';

import { IllusWarning } from 'src/Assets';

import { ConfirmExitModalProps } from './ConfirmExitModal.types';

export const ConfirmExitModal = ({ onClose, open, onConfirm }: ConfirmExitModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent title="retour à la recherche" header={<IllusWarning />}>
        Si vous quittez la génération de synthèse, vos modifications seront perdues
      </ModalContent>
      <ModalActions side="right">
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        <Button.Primary onClick={onConfirm}>quitter</Button.Primary>
      </ModalActions>
    </Modal>
  );
};
