import * as z from 'zod';

export interface Props {
  isOpen: boolean;
  close: () => void;
  interviewId: string;
  candidateId: string;
}

export const interviewExemptionValidationSchema = z.object({
  comment: z.string(),
  decisionMaker: z.string(),
});
