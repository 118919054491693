import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';

import styles from './MultiCheckboxCard.module.scss';
import { MultiCheckboxCardProps } from './MultiCheckboxCard.types';

export const MultiCheckboxCard = ({ title, data, color }: MultiCheckboxCardProps) => {
  const checked = data.some(({ selected }) => selected);
  return (
    <div
      className={classnames(styles.container, styles[color], {
        [styles.checked]: checked,
      })}
    >
      <div className={styles.title}>{title}</div>
      {data.map((item, checkboxIdx) => (
        <label className={styles.itemContainer} key={checkboxIdx}>
          <Checkbox
            checked={item.selected}
            onChange={() => item.onSelectionChange(!item.selected)}
          />
          <div className={styles.description}>
            {item.subtitles.map((subtitle, subtitleIdx) => {
              return <div key={subtitleIdx}>{subtitle}</div>;
            })}
          </div>
        </label>
      ))}
    </div>
  );
};
