import { SidebarMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Redirect, Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import { navigation } from '../CandidateFile.types';

import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const history = useHistory();
  const params = useParams<{
    secondSection: string;
  }>();
  return (
    <div className={styles.container}>
      <div className={styles.linkContainer}>
        <Router history={history}>
          <Switch>
            {Object.values(navigation).map(node => (
              <Route key={node.name} path={`${node.path}(.*)`}>
                <Route exact path={node.path}>
                  <Redirect to={node.default} />
                </Route>
                <SidebarMenu
                  controls={node.routes}
                  keyValueExtractor={route => {
                    return {
                      key: route.name,
                      value: route.linkTo,
                    };
                  }}
                  onItemClick={item => history.push(item.linkTo)}
                  selectedMenuValue={params.secondSection}
                />
              </Route>
            ))}
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Sidebar;
