import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { BusinessService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchJobs = (
  extraOptions?: UseQueryOptions<unknown, unknown, { id?: string; label?: string }[], string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchJobs, currentBrand?.brandCodeApiHeader ?? ''],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return (await BusinessService.businessControllerGetBusinesses()).map(business => {
        return { id: business.id, label: business.label };
      });
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
