import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPotentialPositions } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabelWithoutWeight } from 'src/Redux/Search/Types';

import PotentialPositionModal from './PotentialPositionModal.component';

const MemoPotentialPositionModal = React.memo(PotentialPositionModal);

const EnhancedPotentialPositionModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const potentialPositions = useSelector(getPotentialPositions);
  const onPotentialPositionChange = useCallback(
    (potentialPositions: MatchingCriteriaItemWithLabelWithoutWeight[]) =>
      dispatch(searchActions.setPotientialPositions(potentialPositions)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoPotentialPositionModal
      potentialPositions={potentialPositions}
      onPotentialPositionChange={onPotentialPositionChange}
      onClose={onClose}
    />
  );
};

export default EnhancedPotentialPositionModal;
