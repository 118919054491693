import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateR2, InterviewStatus, R2InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';
export interface CandidateR2WithClosed extends CandidateR2 {
  isClosed: boolean;
}
export const useFetchCandidateR2 = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateR2WithClosed, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateR2, id],
    async () => {
      const candidateR2 = await R2InterviewsService.r2InterviewsControllerGetR2({
        r2Id: id,
      });
      return { ...candidateR2, isClosed: candidateR2.status !== InterviewStatus['en cours'] };
    },
    { refetchOnWindowFocus: false, enabled: id !== undefined, ...extraOptions }
  );
};
