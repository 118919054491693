import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CandidatesService, CreateCandidateBody } from 'src/Services/API';

import { MutationKeys } from '../types';

interface MutationParams {
  candidateId: string;
  body: CreateCandidateBody;
}

export const useUpdateCandidate = (
  extraOptions?: UseMutationOptions<string, AxiosError, MutationParams, unknown>
) => {
  return useMutation(
    [MutationKeys.updateCandidate],
    async ({ candidateId, body }: MutationParams) => {
      return await CandidatesService.candidatesControllerUpdateCandidate({
        id: candidateId,
        body,
      });
    },
    {
      ...extraOptions,
    }
  );
};
