import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Qualification, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchJobQualifications = (
  businessId?: string,
  extraOptions?: UseQueryOptions<unknown, unknown, Qualification[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchJobQualifications, businessId ?? ''],
    async () => {
      if (!businessId) {
        return Promise.reject('No businessId');
      }
      return await RepositoriesService.repositoriesControllerGetBusinessQualifications({
        businessIds: [businessId],
      });
    },
    {
      staleTime: 86400000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
