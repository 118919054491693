import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';

import { IllusWarning } from 'src/Assets';

import { ConfirmExitModalProps } from './ConfirmAnonymityModal.types';

export const ConfirmAnonymityModal = ({ open, onClose, onConfirm }: ConfirmExitModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent title="message d'alerte" header={<IllusWarning />}>
        Vous vous apprêtez à générer un cv non anonymisé. Ce document doit uniquement être destiné à
        un usage interne au groupe Randstad. Auquel cas, vous prenez le risque que votre talent soit
        directement contacté par le destinataire du CV (client ou prospect).
        <div>Êtes-vous bien sûr d'afficher l'identité du candidat sur le cv ?</div>
      </ModalContent>
      <ModalActions side="right">
        <Button.Secondary onClick={() => onClose()}>je rends le cv anonyme</Button.Secondary>
        <Button.Primary onClick={() => onConfirm()}>
          oui, je garde l'identité visible
        </Button.Primary>
      </ModalActions>
    </Modal>
  );
};
