import { AutoSuggest, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { Skill } from 'src/Services/API';

import styles from './SelectSoftSkillsAutoSuggest.module.scss';
import { Props } from './SelectSoftSkillsAutoSuggest.types';

const SelectSkills = ({
  control,
  setSkills,
  otherSkills,
  chosenSkills,
  setChosenSkills,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const skills = useController({ control, name: 'softSkills' })?.field?.value;
  const researchedOtherSkills = otherSkills.filter(
    x =>
      x.label?.toLowerCase().includes(keyword.toLowerCase()) &&
      !skills?.some(skill => x.id === skill.selectedItem.id)
  );

  return (
    <WithLightTitle
      title={'compléter si nécessaire les compétences comportementales'}
      className={styles.softSkillTitle}
    >
      <AutoSuggest
        id="otherSkills"
        value={chosenSkills}
        keyValueExtractor={(searchResult: Skill) => ({
          key: searchResult.id ?? '',
          value: searchResult.label ?? '',
        })}
        onSearch={setKeyword}
        onChange={skills => {
          setKeyword('');
          setSkills(
            skills.map(skill => {
              return { id: skill.id, label: skill.label };
            })
          );
          setChosenSkills([...skills]);
        }}
        searchResults={keyword.length ? researchedOtherSkills : []}
        placeholder="1 caractère minimum"
      />
    </WithLightTitle>
  );
};

export default SelectSkills;
