import { Criteria, LanguageLevel, WishPriority } from 'src/Services/API';

export interface MatchingCriteriaItemWithLabelWithoutWeight {
  identifier: string;
  label?: string;
}
export interface LanguageCriteria {
  identifier: string;
  label?: string;
  isTopLanguage?: boolean;
  level?: LanguageLevel;
  wishPriority?: WishPriority;
  icon?: JSX.Element;
}

export enum SEARCH_MODE {
  BY_NAME = 'BY_NAME',
  BY_CRITERIA = 'BY_CRITERIA',
}

export enum EnumFilterResultWorkingHours {
  UNDER_400 = 'UNDER_400',
  UNDER_1000 = 'UNDER_1000',
  OVER_1000 = 'OVER_1000',
}

export interface IntervalFilter {
  id: EnumFilterResultWorkingHours;
  min?: number;
  max?: number;
}
export interface ResultFilters {
  workingHours: IntervalFilter[];
}
export interface SearchState {
  firstName?: string;
  lastName?: string;
  searchMode: SEARCH_MODE;
  criteria: Criteria;
  resultFilters: ResultFilters;
}
