import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateTests } from 'src/Services/API';

import { useFetchCandidateTests } from './useFetchCandidateTests';

export const useFetchCandidateTestsFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateTests[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateTests(candidateId, extraOptions);
};
