import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { Skill } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import SkillsBadges from '../Components/SkillsBadges/SkillsBadges.component';

import SelectSoftSkillComboBox from './SelectSoftSkillComboBox/SelectSoftSkillComboBox.component';
import styles from './SelectSoftSkills.module.scss';
import { Props } from './SelectSoftSkills.types';
import SelectSoftSkillsAutoSuggest from './SelectSoftSkillsAutoSuggest/SelectSoftSkillsAutoSuggest.component';

const SelectSoftSkills = ({ control, otherSkills, watch }: Props) => {
  const softSkillsController = useController({
    control,
    name: 'softSkills',
  });

  const watchSoftSkills = watch('softSkills');

  const detectedSoftSkills = watchSoftSkills?.reduce((count, skill) => {
    return skill.parsedDocumentLabel ? count + 1 : count;
  }, 0);

  const [chosenSkills, setChosenSkills] = useState<Skill[] | undefined>([]);

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        compétences comportementales ({pluralFormat(detectedSoftSkills, 'détectée')})
      </div>
      {watchSoftSkills?.map((skill, index) => {
        return (
          skill.parsedDocumentLabel && (
            <SelectSoftSkillComboBox
              control={control}
              otherSkills={otherSkills}
              index={index}
              key={skill.parsedDocumentLabel}
            />
          )
        );
      })}
      <SelectSoftSkillsAutoSuggest
        control={control}
        setSkills={skills =>
          softSkillsController.field.onChange([
            ...watchSoftSkills.splice(0, detectedSoftSkills),
            ...skills.map(skill => {
              return {
                ...skill,
                selectedItem: {
                  id: skill.id,
                  label: skill?.label ?? '',
                },
              };
            }),
          ])
        }
        chosenSkills={chosenSkills ?? []}
        setChosenSkills={setChosenSkills}
        otherSkills={otherSkills}
      />

      <SkillsBadges
        skills={watchSoftSkills}
        removeItem={index => {
          softSkillsController.field.onChange(watchSoftSkills.filter((_, idx) => idx !== index));
        }}
      />
    </div>
  );
};
export default SelectSoftSkills;
