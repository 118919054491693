import { TextArea } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { OwnProps } from './TextAreaWithDisplayMode.types';

const TextAreaWithDisplayMode = ({ isDisplayMode, ...props }: OwnProps) => {
  return (
    <>
      {isDisplayMode ? (
        <>{props.value?.toString().length ? props.value : "champ non rempli lors de l'entretien"}</>
      ) : (
        <TextArea {...props} />
      )}
    </>
  );
};

export default TextAreaWithDisplayMode;
