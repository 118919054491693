import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { ExportService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useExportInterviewsData = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const { email } = useAuthenticatedUser();

  return useMutation(
    [MutationKeys.exportInterviewsData, agencyIds],
    async (params: { startDate: Date }) => {
      await ExportService.exportControllerGetExportData({
        body: { agencyIds: agencyIds, consultantMail: email ?? '', startDate: params.startDate },
      });
    }
  );
};
