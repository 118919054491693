import { TopButton } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect, useRef } from 'react';
import { useParams, useHistory, Router, Switch, Route } from 'react-router';

import { CandidateRoute } from 'src/Services/Routing';

import { navigation } from '../CandidateFile.types';

import styles from './CandidateFileForm.module.scss';
import { Props } from './CandidateFileForm.types';
const CandidateFileForm = ({ simplifiedHeader, setSimplifiedHeader }: Props) => {
  const params = useParams<CandidateRoute['routerParameters']>();
  const history = useHistory();

  const firstSection = params?.firstSection ?? '';

  const subRoute = navigation[firstSection]?.routes.find(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  const subRouteIndex = navigation[firstSection]?.routes.findIndex(
    _ => _.linkTo === `./${params?.secondSection}`
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  useEffect(() => {
    containerRef.current?.scrollTo({ top: 0, left: 0 });
  }, [params?.secondSection]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrolled =
          containerRef.current.scrollTop >=
          ((simplifiedHeader ? -20 : 0) + containerRef.current.clientHeight) / 3;
        setSimplifiedHeader(scrolled);
      }
    };
    const currentDivRef = containerRef.current;
    if (currentDivRef) {
      currentDivRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup: remove the event listener
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [simplifiedHeader, setSimplifiedHeader]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.routeNumber}>{`${subRouteIndex + 1}.`}</div>
      <div className={styles.formContainer}>
        <div className={styles.titleContainer}>
          <p>{subRoute?.name ?? 'Error'}</p>
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(navigation).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route key={route.name} path={`${route.path}`} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
      <TopButton onClick={scrollToTop} />
    </div>
  );
};

export default CandidateFileForm;
