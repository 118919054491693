import { WithLightTitle, AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { useFetchQualificationsWithThrottle } from 'src/Hooks/Qualifications/useFetchQualifications';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { WishPriority } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectQualifications.types';

const SelectQualifications = ({ selectedQualifications, setQualifications }: Props) => {
  const [keyword, setKeyword] = useState('');
  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  return (
    <WithLightTitle
      title={`${
        keyword !== '' && qualificationThrottledMutation.data
          ? qualificationThrottledMutation.data.length
          : '-'
      } qualifications disponibles`}
    >
      <AutoSuggest
        id="qualifications"
        value={selectedQualifications}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          setKeyword(word);
          word !== '' && qualificationThrottledMutation.mutate(word);
        }}
        onChange={qualifications => {
          if (qualifications.length > 1) {
            const newQualifications = qualifications.map(qualification => {
              return {
                label: qualification.label,
                identifier: qualification.identifier,
                wishPriority:
                  qualification.wishPriority === WishPriority.Obligatoire
                    ? WishPriority.Important
                    : qualification.wishPriority,
              };
            });
            setQualifications(newQualifications);
          } else {
            setQualifications([...qualifications]);
          }
          setKeyword('');
        }}
        searchResults={keyword !== '' ? qualificationThrottledMutation.data ?? [] : []}
        placeholder="1 caractère minimum"
        fetchStatus={toFetchStatus(qualificationThrottledMutation)}
      />
    </WithLightTitle>
  );
};

export default SelectQualifications;
