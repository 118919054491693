import {
  FlagGermany,
  FlagArabia,
  FlagTurkey,
  FlagSweden,
  FlagPoland,
  FlagCzech,
  FlagHungary,
  FlagSwiss,
  FlagRussia,
  FlagSpain,
  FlagChineseMandarin,
  FlagVietnam,
  FlagRomania,
  FlagUK,
  FlagUkrain,
  FlagPortugal,
  FlagDanemark,
  FlagSerbia,
  FlagJapan,
  FlagChineseCantonese,
  FlagMalaysia,
  FlagThailand,
  FlagNetherlands,
  FlagChina,
  FlagNorway,
  FlagItaly,
  FlagFinland,
  FlagKorea,
  FlagFrance,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import {
  CacesChantier,
  CacesChariot,
  CacesGrue,
  CacesNacelle,
  CacesEchafaudage,
  CacesPontsRoulants,
  Alimentaire,
  Automobile,
  Autre,
  BTP,
  Hotellerie,
  Logistique,
  Pharmaceutique,
  Service,
} from 'src/Assets';

export const languageIconMapping = {
  Allemand: <FlagGermany />,
  Arabe: <FlagArabia />,
  Turc: <FlagTurkey />,
  Suédois: <FlagSweden />,
  Polonais: <FlagPoland />,
  Tchèque: <FlagCzech />,
  Hongrois: <FlagHungary />,
  'Suisse allemand': <FlagSwiss />,
  Russe: <FlagRussia />,
  Espagnol: <FlagSpain />,
  'Chinois mandarin': <FlagChineseMandarin />,
  Vietnamien: <FlagVietnam />,
  Roumain: <FlagRomania />,
  Anglais: <FlagUK />,
  Ukrainien: <FlagUkrain />,
  Portugais: <FlagPortugal />,
  Danois: <FlagDanemark />,
  'Serbo-croate': <FlagSerbia />,
  Japonais: <FlagJapan />,
  'Chinois cantonais': <FlagChineseCantonese />,
  Malais: <FlagMalaysia />,
  Thaïlandais: <FlagThailand />,
  Néerlandais: <FlagNetherlands />,
  Chinois: <FlagChina />,
  Norvégien: <FlagNorway />,
  Italien: <FlagItaly />,
  Finlandais: <FlagFinland />,
  Coréen: <FlagKorea />,
  Français: <FlagFrance />,
};

export const cacesIconMapping = {
  'Engins de chantier': <CacesChantier />,
  'Chariots élévateurs': <CacesChariot />,
  Grues: <CacesGrue />,
  'Nacelles élévatrices': <CacesNacelle />,
  Echafaudages: <CacesEchafaudage />,
  'Ponts roulants': <CacesPontsRoulants />,
};

export const sectorIconMapping: { [sector: string]: JSX.Element } = {
  automobile: <Automobile />,
  'agro-alimentaire': <Alimentaire />,
  BTP: <BTP />,
  'commerce et service': <Service />,
  logistique: <Logistique />,
  'hôtellerie restauration': <Hotellerie />,
  autre: <Autre />,
  pharmaceutique: <Pharmaceutique />,
};
