import classnames from 'classnames';
import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';

import { renderPieCustomizedLabel } from 'src/Utils/pieCharts';

import styles from './PieChartWithTitle.module.scss';
import { Props } from './PieChartWithTitle.types';

const renderLegendText = (value: string, listClick?: (value: string) => void) => {
  return (
    <span className={styles.labelContainer}>
      <div className={styles.space} />
      <div className={styles.legendContainer}>
        <span className={styles.legendLabel}>{value}</span>
        <span onClick={() => listClick?.(value)} className={styles.listDisplay}>
          voir la liste
        </span>
      </div>
    </span>
  );
};

const PieChartWithTitle = ({ title, data, className, onCellClick }: Props) => {
  return (
    <div className={classnames(styles.chartContainer, className)}>
      <p className={styles.chartTitle}>{title}</p>
      {data.length > 0 ? (
        <PieChart width={688} height={347}>
          <Pie
            cx={180}
            cy={169}
            data={data}
            dataKey="value"
            nameKey="key"
            innerRadius={87}
            outerRadius={173}
            labelLine={false}
            stroke="none"
            label={params =>
              renderPieCustomizedLabel({ labelClassName: styles.pieChartLabel, ...params })
            }
            legendType="circle"
          >
            {data?.map(item => (
              <Cell
                key={item.key}
                fill={item.color}
                onClick={() => {
                  onCellClick?.(item.key);
                }}
              />
            ))}
          </Pie>
          <Legend
            wrapperStyle={{ top: 0, left: 420 }}
            layout="vertical"
            formatter={value => renderLegendText(value, () => onCellClick?.(value))}
            iconSize={16}
          />
        </PieChart>
      ) : (
        <div className={styles.chartTitle}> Aucune donnée à afficher</div>
      )}
    </div>
  );
};

export default PieChartWithTitle;
