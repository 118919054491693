import { TextInput, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './NameSearch.module.scss';
import { Props } from './NameSearch.types';

export const NameSearch = ({ firstName, lastName, onFirstNameChange, onLastNameChange }: Props) => {
  return (
    <>
      <WithLightTitle title={'nom'}>
        <TextInput
          value={lastName}
          onChange={event => onLastNameChange((event.target as HTMLInputElement).value)}
          placeholder="saisir un nom de famille (2 caractères mini)"
        />
      </WithLightTitle>
      <div className={styles.separator} />
      <WithLightTitle title={'prénom'}>
        <TextInput
          value={firstName}
          onChange={event => onFirstNameChange((event.target as HTMLInputElement).value)}
          placeholder="saisir un prénom (2 caractères mini)"
        />
      </WithLightTitle>
    </>
  );
};
