import { InterviewsStatistics } from 'src/Services/API';

export interface Props {
  interviewsStatsArray?: InterviewsStatistics;
}

export enum R1PieLabels {
  R1_EN_COURS = 'R1 en cours',
  R1_REFUSE = 'R1 refusé',
  R1_SOUMIS = 'R1 soumis',
  R1_DENIED = "R1 refusé par l'intérimaire",
}

export enum R2PieLabels {
  R2_EN_COURS = 'R2 en cours',
  R2_REFUSE = 'R2 refusé',
  R2_VALIDE = 'R2 validé',
  R2_DENIED = "R2 refusé par l'intérimaire",
  R2_EXEMPTION = 'R2 en dérogation',
}

export const r1PieColorMap = {
  [R1PieLabels.R1_EN_COURS]: '#2175d9',
  [R1PieLabels.R1_SOUMIS]: '#0f1941',
  [R1PieLabels.R1_REFUSE]: '#e74536',
  [R1PieLabels.R1_DENIED]: '#000000',
};

export const r2PieColorMap = {
  [R2PieLabels.R2_EN_COURS]: '#90baec',
  [R2PieLabels.R2_VALIDE]: '#ffda88',
  [R2PieLabels.R2_REFUSE]: '#ee9184',
  [R2PieLabels.R2_DENIED]: '#d7d7d7',
  [R2PieLabels.R2_EXEMPTION]: '#a8c8d3',
};
