import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { CandidateR1, R1InterviewsService, UpdateCandidateR1Body } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateCandidateR1 = (
  id: string,
  extraOptions?: UseMutationOptions<CandidateR1, unknown, UpdateCandidateR1Body, unknown>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updateCandidateR1, id],
    async (body: UpdateCandidateR1Body) => {
      const candidateR1 = await R1InterviewsService.r1InterviewsControllerUpdateCandidateR1({
        r1Id: id,
        body,
      });
      return candidateR1;
    },
    {
      onSuccess: async (...args) => {
        const [candidateR1] = args;
        queryClient.setQueryData([QueryKeys.fetchCandidateR1, id], candidateR1);
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchInterviews] });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateSkills] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
