import {
  FetchButton,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { KeywordListInput } from 'src/Components/KeywordListInput/KeywordListInput.component';
import TextAreaWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithDisplayMode';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useFetchR1StrengthQuestions } from 'src/Hooks/Candidates/useFetchR1StrengthQuestions';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { AnswerTypeEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './CandidateStrength.module.scss';
import { candidateStrengthSchema } from './CandidateStrength.types';

const CandidateStrength = () => {
  const units = useSelector(getPerimeterUnits);

  const params = useParams<{
    id: string;
  }>();
  const {
    data: candidateR1Data,
    isLoading: isLoadingCandidateR1,
    isSuccess: isCandidateR1Success,
    isFetching: isFetchingCandidateR1,
  } = useFetchCandidateR1FromURL();
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
  } = useFetchR1StrengthQuestions();
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const fetchStatus = toFetchStatus(candidateR1Update);
  const textResponses = questions
    ?.filter(question => question.questionType === AnswerTypeEnum.TEXT)
    .map(question => {
      return {
        id: question.id,
        label: question.label,
        value:
          candidateR1Data?.cdiStrengthExchange?.responses.find(
            response => response.id === question.id
          )?.value ?? '',
      };
    });
  const listResponses = questions
    ?.filter(question => question.questionType === AnswerTypeEnum.LIST)
    .map(question => {
      return {
        id: question.id,
        label: question.label,
        keywords:
          candidateR1Data?.personalStrengthExchange?.responses.find(
            response => response.id === question.id
          )?.keywords ?? [],
      };
    });
  const emptyTextResponses =
    questions?.map(question => ({
      id: question.id,
      label: question.label,
      value: '',
    })) ?? [];
  const emptyListResponses =
    questions?.map(question => ({
      id: question.id,
      label: question.label,
      keywords: [],
    })) ?? [];

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful, errors },
    reset,
  } = useFormWithZodResolver({
    schema: candidateStrengthSchema,
    defaultValues: {
      strength: {
        textResponses: textResponses ?? emptyTextResponses,
        listResponses: listResponses ?? emptyListResponses,
      },
    },
  });

  const isDisplayMode = useMemo(
    () =>
      !!candidateR1Data &&
      (candidateR1Data.isClosed || !units.includes(candidateR1Data.agencyName)),
    [candidateR1Data, units]
  );

  const strengthHandleSubmit = () =>
    handleSubmit(values => {
      if (candidateR1Data) {
        candidateR1Update.mutate({
          ...candidateR1Data,
          personalStrengthExchange: {
            responses: values.strength.listResponses.map(response => {
              return { id: response.id, label: response.label, keywords: response.keywords };
            }),
          },
          cdiStrengthExchange: {
            responses: values.strength.textResponses,
          },
        });
      }
      reset(values);
    });

  useEffect(() => {
    if (isCandidateR1Success && isQuestionsSuccess) {
      setValue('strength.textResponses', textResponses ?? emptyTextResponses);
      setValue('strength.listResponses', listResponses ?? emptyListResponses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR1Success, isQuestionsSuccess]);

  const { fields: textFields, update: textUpdate } = useFieldArray({
    control,
    name: 'strength.textResponses',
    keyName: 'key',
  });

  const { fields: listFields, update: listUpdate } = useFieldArray({
    control,
    name: 'strength.listResponses',
    keyName: 'key',
  });

  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: strengthHandleSubmit,
      fetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSubmitted,
    isSubmitting,
    isSubmitSuccessful,
    fetchStatus,
    isLoadingCandidateR1,
    isCandidateR1Success,
  ]);

  return (
    <>
      <div className={styles.title}>échanges</div>
      {(isLoadingCandidateR1 ||
        isFetchingCandidateR1 ||
        isLoadingQuestions ||
        isFetchingQuestions) && (
        <ContentLoader height="46rem" width="100%" uniqueKey="strength">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
        </ContentLoader>
      )}
      {isCandidateR1Success &&
        !isFetchingCandidateR1 &&
        isQuestionsSuccess &&
        !isFetchingQuestions && (
          <>
            {listFields &&
              listFields.length > 0 &&
              listFields.map((field, i) => (
                <KeywordListInput
                  key={field.label}
                  id={field.id}
                  title={field.label}
                  keywords={field.keywords ?? []}
                  isDisplayMode={!!isDisplayMode}
                  onAppend={keyword => {
                    const keywords = [...(field.keywords ?? [])];
                    keywords.push(keyword);
                    listUpdate(i, { ...field, keywords });
                  }}
                  onRemove={index => {
                    const keywords = [...(field.keywords ?? [])];
                    keywords.splice(index, 1);
                    listUpdate(i, { ...field, keywords });
                  }}
                />
              ))}
            {textFields &&
              textFields.length > 0 &&
              textFields.map((field, index) => (
                <WithLightTitle key={field.label} title={field.label} className={styles.container}>
                  <TextAreaWithDisplayMode
                    isDisplayMode={isDisplayMode}
                    title={field.label}
                    className={styles.textAreaWidth}
                    placeholder={
                      questions?.find(question => question.label === field.label)?.placeHolder
                    }
                    value={field.value}
                    onChange={event => {
                      textUpdate(index, {
                        id: field.id,
                        label: field.label,
                        value: (event.target as HTMLTextAreaElement).value,
                      });
                    }}
                  />
                </WithLightTitle>
              ))}
          </>
        )}
      {!isDisplayMode && (
        <FetchButton
          title="valider points forts et axes d'améliorations"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : fetchStatus}
          className={styles.button}
          onClick={strengthHandleSubmit()}
          onSuccess={() => toast.success('Votre R1 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R1")}
        />
      )}
      {Object.keys(errors).length !== 0 && (
        <div className={styles.errorMessage}>
          il y a des erreurs ou des champs non remplis dans le formulaire
        </div>
      )}
    </>
  );
};

export default CandidateStrength;
