import { useEffect, useState } from 'react';

import { useFetchSearchCriteriasFromEDP } from './useFetchSearchCriteriasFromEDP';

export const useWatchForEdpURLParam = () => {
  const [currentEdpId, setCurrentEdpId] = useState<string>();
  const { mutate } = useFetchSearchCriteriasFromEDP();
  useEffect(() => {
    const edpId = new URLSearchParams(window.location.search).get('edpId');
    if (edpId && edpId !== currentEdpId) {
      setCurrentEdpId(edpId);
      mutate(edpId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);
};
