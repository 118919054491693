import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { DocumentsService } from 'src/Services/API';

export const useFetchDocument = () => {
  const brandCode = useSelector(getBrandCode);
  return useMutation(['fetchDocument'], async ({ documentId }: { documentId: string }) => {
    const document = await DocumentsService.documentsControllerGetDocument({
      id: documentId,
      brandCode,
    });
    const win = window.open();
    win?.document.write(
      `<iframe src="data:application/pdf;base64,${document.document?.file}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
    if (win) {
      win.document.title = `${document.document?.title ?? 'document intérimaire'}`;
    }
  });
};
