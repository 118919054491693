import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import styles from './GenderPicker.module.scss';
import { Gender, Props } from './GenderPicker.types';

const GenderPicker = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
}: Props<TFieldValues, TName>) => {
  const items = [
    {
      key: Gender.Male,
      value: 'M. ',
    },
    {
      key: Gender.Female,
      value: 'Mme.',
    },
  ];

  return (
    <ExclusiveSelectionGroup
      name={name}
      control={control}
      values={items}
      getKey={item => item.key}
      getStringValue={item => item.value}
      getSelectedValue={value => {
        return items.find(({ key }) => key === value);
      }}
      getSelectedFormValue={item => item?.key}
      buttonClassName={styles.item}
      selectedClassName={styles.selectedItem}
    />
  );
};

export default GenderPicker;
