import { AddButton } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useController } from 'react-hook-form';

import { pluralFormat } from 'src/Utils/pluralFormat';

import { detectedItemsCount, generateUniqueKey } from '../../utils';

import SelectFormation from './SelectFormation/SelectFormation.component';
import styles from './SelectFormations.module.scss';
import { Props } from './SelectFormations.types';

const SelectFormations = ({ control, formations, watch }: Props) => {
  const formationController = useController({
    control,
    name: 'formations',
  });

  const watchFormations = watch('formations');

  const detectedFormations = detectedItemsCount(watch('formations'));

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        formations ({pluralFormat(detectedFormations, 'détectée')})
      </div>

      {watchFormations.map((formation, index) => (
        <SelectFormation
          control={control}
          index={index}
          key={formation.uniqueKey}
          formations={formations}
          watch={watch}
        />
      ))}
      <AddButton
        icon={<PlusOpen />}
        text="ajouter une formation"
        onClick={() =>
          formationController.field.onChange([
            ...watchFormations,
            {
              selectedItem: { id: '', label: '' },
              uniqueKey: generateUniqueKey(watchFormations),
            },
          ])
        }
        className={styles.addButton}
      />
    </div>
  );
};
export default SelectFormations;
