import { TextArea } from '@randstad-lean-mobile-factory/react-form-fields';
import { useController } from 'react-hook-form';

import styles from './Description.module.scss';
import { DescriptionProps } from './Description.types';

export const Description = ({ control }: DescriptionProps) => {
  const { field } = useController({
    control,
    name: 'description',
  });
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          que pouvez-vous dire pour mettre en avant ce talent ?
        </div>
        <span className={styles.charCount}>{field.value.length}/400 caractères</span>
      </div>
      <TextArea
        control={control}
        name="description"
        className={styles.text}
        placeholder={
          'le mot du consultant : en quelques mots, décrivez pourquoi le talent sera parfait pour sa future mission (savoir-faire, savoir-être, motivation, etc.)'
        }
        maxLength={400}
      />
    </div>
  );
};
