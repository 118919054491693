import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { CandidatesService, JSONData } from 'src/Services/API';

import { QueryKeys } from '../types';

export interface ParsedAddress {
  apartmentNumber?: string;
  road?: string;
  city?: string;
  zipCode?: string;
  rawName?: string;
  country?: string;
  suburb?: string;
  stateDistrict?: string;
  locationCoordinates?: string;
}
export interface parsedPersonalData {
  firstName?: string;
  name?: string;
  phone?: string;
  qualification?: string;
  skills?: string;
  email?: string;
  personalProbability?: number;
}

export interface ParsedSkills {
  languages: { name?: string; level?: string }[];
  hardSkills: { name?: string }[];
  softSkills: { name?: string }[];
}

export interface ParsedDiploma {
  name: string | undefined;
  startDate?: string;
  endDate?: string;
}
export interface parsedQualification {
  name?: string;
}
export interface parsedFormation {
  name?: string;
}

export class ParsedDocument {
  personal?: parsedPersonalData;
  address?: ParsedAddress;
  skills?: ParsedSkills;
  diplomas?: ParsedDiploma[];
  qualifications?: parsedQualification[];
  formations?: parsedFormation[];
}

export const enum EDUCATION_CLASS {
  FORMATION = 'formation',
  DIPLOMA = 'diplome',
}

export const useFetchDocumentData = (
  document: File | undefined,
  extraOptions?: UseQueryOptions<JSONData[], unknown, unknown, (string | undefined | number)[]>
) => {
  return useQuery(
    [QueryKeys.fetchDocumentData, document?.name, document?.size],
    async () => {
      if (!document) {
        throw new Error("Il n'a pas de document");
      } else {
        const actions = await CandidatesService.candidatesControllerAddNewTalentCvParser({
          file: document,
        });
        return actions;
      }
    },
    {
      enabled: false,
      ...extraOptions,
    }
  );
};
