import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { RepositoriesService, SMIC } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchSMICAmount = (
  extraOptions?: UseQueryOptions<unknown, unknown, SMIC, QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchSMICAmount],
    async () => {
      return RepositoriesService.repositoriesControllerGetSmic();
    },
    {
      staleTime: 86400000,
      ...extraOptions,
    }
  );
};
