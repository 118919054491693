import React from 'react';
import { useController } from 'react-hook-form';

import SelectSkills from 'src/Containers/Selects/SelectSkills/SelectSkills.component';
import { WishPriority } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { detectedItemsCount } from '../../utils';
import SkillsBadges from '../Components/SkillsBadges/SkillsBadges.component';

import SelectHardSkillComboBox from './SelectHardSkillComboBox/SelectHardSkillComboBox.component';
import styles from './SelectHardSkills.module.scss';
import { Props } from './SelectHardSkills.types';

const SelectHardSkills = ({ control, watch }: Props) => {
  const hardSkillsController = useController({
    control,
    name: 'hardSkills',
  });

  const watchHardSkills = watch('hardSkills');

  const detectedHardSkillsCount = detectedItemsCount(watchHardSkills);

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        compétences techniques ({pluralFormat(detectedHardSkillsCount, 'détectée')})
      </div>
      {watchHardSkills?.map(
        (skill, index) =>
          skill.parsedDocumentLabel && (
            <SelectHardSkillComboBox
              control={control}
              index={index}
              key={skill.parsedDocumentLabel}
            />
          )
      )}
      <SelectSkills
        selectedSkills={watchHardSkills?.map(skill => {
          return {
            identifier: skill.selectedItem.id,
            label: skill?.selectedItem?.label ?? '',
            wishPriority: WishPriority.Important,
          };
        })}
        setSkills={skills =>
          hardSkillsController.field.onChange(
            skills?.map(skill => {
              return {
                ...skill,
                selectedItem: { id: skill.identifier, label: skill?.label ?? '' },
              };
            })
          )
        }
        customTitle={'compléter si nécessaire les compétences techniques'}
      />

      <SkillsBadges
        skills={watchHardSkills}
        removeItem={index => {
          hardSkillsController.field.onChange(watchHardSkills.filter((_, idx) => idx !== index));
        }}
      />
    </div>
  );
};
export default SelectHardSkills;
