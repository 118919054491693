import React from 'react';
import ContentLoader from 'react-content-loader';

import { NoInterviewsEye } from 'src/Assets';
import InterviewSection from 'src/Components/InterviewSection';
import { useFetchInterviews } from 'src/Hooks/Interviews/useFetchInterviews';
import { CandidateInterviewsSummaryStatus } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './InterviewTraceBoards.module.scss';

const InterviewTraceBoards = () => {
  const interviewsQuery = useFetchInterviews();
  const pendingInterviews =
    interviewsQuery.data?.filter(({ status }) =>
      [
        CandidateInterviewsSummaryStatus['R1 en cours'],
        CandidateInterviewsSummaryStatus['R2 en cours'],
        CandidateInterviewsSummaryStatus['R2 en planification'],
        CandidateInterviewsSummaryStatus['R2 planifié'],
      ].includes(status)
    ) ?? [];
  const toDoInterviews =
    interviewsQuery.data?.filter(({ status }) =>
      [CandidateInterviewsSummaryStatus['R2 à faire']].includes(status)
    ) ?? [];
  const doneInterviews =
    interviewsQuery.data?.filter(({ status }) =>
      [
        CandidateInterviewsSummaryStatus['R1 refusé'],
        CandidateInterviewsSummaryStatus['R2 refusé'],
        CandidateInterviewsSummaryStatus['R2 terminé'],
        CandidateInterviewsSummaryStatus['validé par dérogation'],
        CandidateInterviewsSummaryStatus['refusé par intérimaire'],
      ].includes(status)
    ) ?? [];
  return (
    <>
      {interviewsQuery.data && interviewsQuery.data?.length !== 0 && (
        <div className={styles.container}>
          <InterviewSection
            title="à faire"
            interviews={toDoInterviews}
            fetchStatus={toFetchStatus(interviewsQuery)}
            showReassignCGC
          />
          <InterviewSection
            title="en cours"
            interviews={pendingInterviews}
            fetchStatus={toFetchStatus(interviewsQuery)}
            showReassignCGC
          />
          <InterviewSection
            title="fait"
            interviews={doneInterviews}
            fetchStatus={toFetchStatus(interviewsQuery)}
          />
        </div>
      )}
      {interviewsQuery.data?.length === 0 && (
        <div className={styles.noResult}>
          <NoInterviewsEye className={styles.noResultImage} />
          <div className={styles.noResultText}>
            aucun entretien sur la liste des unités sélectionnées
          </div>
        </div>
      )}
      {interviewsQuery.isFetching && !interviewsQuery.isFetched && (
        <ContentLoader height="15rem" width="100%" uniqueKey="fetchingInterviews">
          <rect x="0" y="10" rx="4" ry="4" width="100%" height="100" />
          <rect x="0" y="120" rx="4" ry="4" width="100%" height="100" />
        </ContentLoader>
      )}
    </>
  );
};
export default InterviewTraceBoards;
