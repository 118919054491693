import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { R1InterviewsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useDeleteCandidateR1 = (
  id: string,
  extraOptions?: UseMutationOptions<void, AxiosError, void, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deleteCandidateR1, id],
    async () => {
      await R1InterviewsService.r1InterviewsControllerDeleteR1({
        r1Id: id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateInterviewsIdAndStatus],
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchInterviews] });
        toast.success('Votre R1 a été supprimé');
      },
      onError: () => {
        toast.error('Erreur lors de la suppression de votre R1');
      },
      ...extraOptions,
    }
  );
};
