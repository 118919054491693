import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import styles from './InterviewStatsTable.module.scss';
import { Props } from './InterviewStatsTable.types';

const InterviewStatsTable = ({ region }: Props) => (
  <div className={styles.container}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classNames(styles.tableFontStyle, styles.header, styles.leftText)}>
            zone / agence
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.header)}>
            R1 validés sur {moment().format('MMMM')}
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.header)}>
            R2 validés sur {moment().format('MMMM')}
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.header)}>
            total zone / agence depuis janvier
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {region?.zones?.map(zone => {
          const zoneOpenR1 = zone.agencies
            ?.map(agency => agency.closedR1WithinMonth)
            ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0);
          const zoneOpenR2 = zone.agencies
            ?.map(agency => agency.closedR2WithinMonth)
            ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0);
          const zoneClosedR2 = zone.agencies
            ?.map(agency => agency.closedR2)
            ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0);
          return (
            <>
              <TableRow
                key={zone.zoneTitle}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: '#ffecc3',
                  '&:hover': { outline: 'none' },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classNames(styles.tableFontStyle, styles.body, styles.leftText)}
                >
                  {zone.zoneTitle}
                </TableCell>
                <TableCell
                  align="center"
                  className={classNames(styles.tableFontStyle, styles.body)}
                >
                  {zoneOpenR1}
                </TableCell>
                <TableCell
                  align="center"
                  className={classNames(styles.tableFontStyle, styles.body)}
                >
                  {zoneOpenR2}
                </TableCell>
                <TableCell
                  align="center"
                  className={classNames(styles.tableFontStyle, styles.body)}
                >
                  {zoneClosedR2}
                </TableCell>
              </TableRow>
              {zone.agencies?.map(agency => {
                return (
                  <TableRow
                    key={agency.agencyId}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': { outline: 'none' },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classNames(styles.tableFontStyle, styles.body, styles.leftText)}
                    >{`${zone.zoneTitle} / ${agency.agencyId}`}</TableCell>
                    <TableCell
                      align="center"
                      className={classNames(styles.tableFontStyle, styles.body)}
                    >
                      {agency.closedR1WithinMonth}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classNames(styles.tableFontStyle, styles.body)}
                    >
                      {agency.closedR2WithinMonth}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classNames(styles.tableFontStyle, styles.body)}
                    >
                      {agency.closedR2}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        })}
        <TableRow
          key={region?.regionTitle}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            backgroundColor: '#0f1941',
            '&:hover': { outline: 'none' },
          }}
        >
          <TableCell
            component="th"
            scope="row"
            className={classNames(styles.tableFontStyle, styles.total, styles.leftText)}
          >
            total région
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.total)}>
            {region?.zones
              ?.map(zone => zone.agencies?.map(agency => agency.closedR1WithinMonth))
              .flat(1)
              ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0)}
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.total)}>
            {region?.zones
              ?.map(zone => zone.agencies?.map(agency => agency.closedR2WithinMonth))
              .flat(1)
              ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0)}
          </TableCell>
          <TableCell align="center" className={classNames(styles.tableFontStyle, styles.total)}>
            {region?.zones
              ?.map(zone => zone.agencies?.map(agency => agency.closedR2))
              .flat(1)
              ?.reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

export default InterviewStatsTable;
