import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CandidateAgencyDto, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateAgencies = (
  id: string,
  extraOptions?: UseQueryOptions<CandidateAgencyDto[], unknown, CandidateAgencyDto[], string[]>,
  isCdi?: boolean
) => {
  const brandCode = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCandidateAgencies, id],
    async () => {
      const candidateAgencies = await CandidatesService.candidatesControllerGetCandidateAgencies({
        id: id,
        brandCode: brandCode?.brandCodeApiHeader,
        isCdi: isCdi,
      });
      return candidateAgencies;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
