import { useQuery } from '@tanstack/react-query';

import { RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCdiGoalsAdministrators = () => {
  return useQuery(
    [QueryKeys.fetchCdiGoalsAdministrators],
    async () => await RepositoriesService.repositoriesControllerGetCdiGoalsAdministrators(),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 3600 * 24,
      staleTime: 1000 * 3600 * 24,
    }
  );
};
