import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../RootReducer';

import { InterviewsStatsState } from './Types';

const getInterviewsStatsState = (state: RootState) => state.interviewsStats;

export const getStatsSearchPeriod = createSelector(
  [getInterviewsStatsState],
  (state: InterviewsStatsState) => state.searchPeriod
);

export const getStatsSelectedAgencies = createSelector(
  [getInterviewsStatsState],
  (state: InterviewsStatsState) => state.statsSelectedAgencies
);
