import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Candidate, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateDetails = (
  id: string,
  extraOptions?: UseQueryOptions<Candidate, unknown, Candidate, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateDetails, id],
    async () => {
      const candidate = await CandidatesService.candidatesControllerGetCandidate({ id: id });
      return candidate;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
