import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { R1AssessmentQuestion, RepositoriesService } from 'src/Services/API';

export const useFetchR1AssessmentQuestions = (
  extraOptions?: UseQueryOptions<unknown, unknown, R1AssessmentQuestion[], string[]>
) => {
  return useQuery(
    ['fetchR1AssessmentQuestions'],
    async () => await RepositoriesService.repositoriesControllerGetR1AssessmentQuestions(),
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};
