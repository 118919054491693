import React from 'react';
import ContentLoader from 'react-content-loader';

import { CandidateDiploma } from 'src/Components/CandidateDiploma';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateDiplomasFromURL } from 'src/Hooks/Candidates/useFetchCandidateDiplomasFromURL';

const Diplomas = () => {
  const diplomasQuery = useFetchCandidateDiplomasFromURL();
  return (
    <div>
      {diplomasQuery.isLoading &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="diploma">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {diplomasQuery.isError && (
        <ErrorMessage message="une erreur est survenue dans la récupération des diplômes et habilitations" />
      )}
      {diplomasQuery.isSuccess &&
        diplomasQuery.data?.map(diploma => <CandidateDiploma diploma={diploma} />)}
    </div>
  );
};

export default Diplomas;
