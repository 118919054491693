import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSearchPeriod, getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { getCardFilters, getDashboardStatus } from 'src/Redux/UserPreferences/Selectors';
import { CARD_FILTERS } from 'src/Redux/UserPreferences/Types';
import { InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';

import { searchDateMapping } from './useFetchInterviewsStatistics';

export const useFetchInterviews = () => {
  const brandCode = useSelector(getBrandCode);
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const statuses = useSelector(getDashboardStatus);
  const searchPeriod = useSelector(getStatsSearchPeriod);
  const cardFilters = useSelector(getCardFilters);
  const searchDate = searchDateMapping[searchPeriod];

  return useQuery(
    [QueryKeys.fetchInterviews, brandCode, agencyIds, statuses, searchDate, cardFilters],
    async () => {
      const interviews = await InterviewsService.interviewsControllerGetInterviews({
        body: {
          agencyIds,
          brandCode,
          statuses,
          startDate: searchDate,
          isEligibleToFreeJobs: cardFilters.includes(
            CARD_FILTERS['uniquement éligibles à emplois francs']
          ),
          hideCDICandidates: cardFilters.includes(CARD_FILTERS['masquer les CDIs signés']),
        },
      });
      return interviews;
    }
  );
};
