import { DropDown, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { HighVoltage, LowVoltage } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useImperativeHandle, useState } from 'react';

import CriteriaButton from 'src/Components/CriteriaButton/CriteriaButton.component';
import { useFetchElectricHabilitations } from 'src/Hooks/Habilitations';

import styles from './ElectricHabilitationModal.module.scss';
import { ElectricHabilitationModalRef, Props } from './ElectricHabilitationModal.types';

const ElectricHabilitationModal = React.forwardRef<ElectricHabilitationModalRef, Props>(
  ({ setElectricHabilitation }, ref) => {
    const [selectedVoltage, setSelectedVoltage] = useState<string | undefined>(undefined);
    const [selectedCurrent, setSelectedCurrent] = useState<string | undefined>(undefined);
    const [selectedCurrentType, setselectedCurrentType] = useState<string | undefined>(undefined);
    useImperativeHandle(ref, () => ({
      reset: () => {
        setSelectedCurrent(undefined);
        setSelectedVoltage(undefined);
        setselectedCurrentType(undefined);
      },
    }));
    const { data } = useFetchElectricHabilitations();
    const proposedCurrents = data?.find(item => item.currentId === selectedVoltage)?.levels;
    const proposedHabilitationTypes = proposedCurrents
      ?.find(item => item.categoryLabel === selectedCurrent)
      ?.currents.map(current => current.key);
    return (
      <>
        <WithLightTitle title="choix de la tension">
          <div className={styles.voltageSelection}>
            {data?.map(voltage => {
              const isSelected = selectedVoltage === voltage.currentId;
              return (
                <CriteriaButton
                  key={voltage.currentId}
                  label={voltage.value}
                  icon={voltage.currentId === 'B' ? <LowVoltage /> : <HighVoltage />}
                  selected={isSelected}
                  onClick={() => {
                    setSelectedVoltage(voltage.currentId);
                    setSelectedCurrent(undefined);
                    setselectedCurrentType(undefined);
                    setElectricHabilitation(undefined);
                  }}
                  className={classnames(styles.voltageButton, {
                    [styles.selected]: isSelected,
                  })}
                  iconClassName={styles.voltageIconContainer}
                />
              );
            })}
          </div>
        </WithLightTitle>
        <div className={styles.dropDowns}>
          {selectedVoltage && (
            <>
              <WithLightTitle title="niveau d'habilitation éléctriques">
                <div className={styles.dropDown}>
                  <DropDown
                    placeholder="choisir un niveau d'habilitation éléctriques"
                    items={proposedCurrents?.map(level => level.categoryLabel) ?? []}
                    onSelectItem={(item: string | undefined) => {
                      setSelectedCurrent(item);
                      setselectedCurrentType(undefined);
                      setElectricHabilitation(undefined);
                    }}
                    keyValueExtractor={(searchResult: string) => ({
                      key: searchResult,
                      value: searchResult,
                    })}
                    selectedItem={selectedCurrent}
                  />
                </div>
              </WithLightTitle>
              <WithLightTitle title="type de courant">
                <div className={styles.dropDown}>
                  <DropDown
                    disabled={selectedCurrent === undefined}
                    placeholder="choisir un courant"
                    items={proposedHabilitationTypes ?? []}
                    onSelectItem={(item: string | undefined) => {
                      setselectedCurrentType(item);
                      setElectricHabilitation(
                        proposedCurrents
                          ?.find(
                            currentCategory => currentCategory.categoryLabel === selectedCurrent
                          )
                          ?.currents.find(current => current.key === item)?.habilitation
                      );
                    }}
                    keyValueExtractor={(searchResult: string) => ({
                      key: searchResult,
                      value: searchResult,
                    })}
                    selectedItem={selectedCurrentType}
                  />
                </div>
              </WithLightTitle>
            </>
          )}
        </div>
      </>
    );
  }
);

export default ElectricHabilitationModal;
