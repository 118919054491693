import React from 'react';

import AvailabilityButton from '../CriteriaButtons/AvailabilityButton';
import CACESButton from '../CriteriaButtons/CACESButton';
import CompanyButton from '../CriteriaButtons/CompanyButton';
import DiplomaButton from '../CriteriaButtons/DiplomaButton';
import DrivingLicenceButton from '../CriteriaButtons/DrivingLicenceButton';
import HabilitationButton from '../CriteriaButtons/HabilitationButton';
import KeywordButton from '../CriteriaButtons/KeywordButton';
import LanguageButton from '../CriteriaButtons/LanguageButton';
import LocationButton from '../CriteriaButtons/LocationButton';
import PotentialPositionButton from '../CriteriaButtons/PotentialPositionButton';
import QualificationButton from '../CriteriaButtons/QualificationButton';
import ServiceButton from '../CriteriaButtons/ServiceButton';
import SkillButton from '../CriteriaButtons/SkillButton';

import styles from './CriteriaSearch.module.scss';

const CriteriaSearch = () => {
  const criteriaList = [
    <QualificationButton key={'QualificationButton'} />,
    <AvailabilityButton key={'AvailabilityButton'} />,
    <KeywordButton key={'KeywordButton'} />,
    <SkillButton key={'SkillButton'} />,
    <LocationButton key={'LocationButton'} />,
    <DiplomaButton key={'DiplomaButton'} />,
    <LanguageButton key={'LanguageButton'} />,
    <HabilitationButton key={'HabilitationButton'} />,
    <CACESButton key={'CACESButton'} />,
    <CompanyButton key={'CompanyButton'} />,
    <DrivingLicenceButton key={'DrivingLicenceButton'} />,
    <ServiceButton key={'ServiceButton'} />,
    <PotentialPositionButton key={'PotentialPositionButton'} />,
  ];

  return <div className={styles.criteriaCardGrid}>{criteriaList.map(criteria => criteria)}</div>;
};

export default CriteriaSearch;
