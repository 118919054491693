import { IllusRLPotentialSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasCompany, hasPotentialPosition } from 'src/Redux/Search/Selectors';

import styles from './PotentialPositionButton.module.scss';

const PotentialPositionButton = () => {
  const _hasCompany = useSelector(hasCompany);
  const _hasPotentialPosition = useSelector(hasPotentialPosition);
  const potentialPositionModal = useModalWithLocation('potential-position');
  return (
    <div>
      <CriteriaButton
        label="pré-affectations"
        icon={<IllusRLPotentialSmall className={styles.icon} />}
        selected={_hasPotentialPosition}
        disabled={!_hasCompany}
        onClick={() => potentialPositionModal.open()}
      />
    </div>
  );
};

export default PotentialPositionButton;
