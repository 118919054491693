import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { R1StatusForStats, R2StatusForStats } from 'src/Services/API';
import { renderPercentBarCustomizedLabel } from 'src/Utils/pieCharts';

import styles from './InterviewsBarChart.module.scss';
import {
  Props,
  transformationRateColorMap,
  TransformationRateLabels,
} from './InterviewsBarChart.types';

const renderLegendText = (value: string) => {
  return (
    <span className={styles.labelContainer}>
      <div className={styles.space} />
      <div className={styles.legendContainer}>
        <span className={styles.legendLabel}>{value}</span>
      </div>
    </span>
  );
};

const InterviewsBarChart = ({ interviewsStatsArray, openInterviewsStats, signedCDI }: Props) => {
  const barChartStats = [
    {
      key: TransformationRateLabels.CREATED_R1,
      valeur: 100,
      color: transformationRateColorMap[TransformationRateLabels.CREATED_R1],
    },
    {
      key: TransformationRateLabels.ACCEPTED_R1,
      valeur: (
        (((interviewsStatsArray?.r1Interviews.find(
          interview => interview.status === R1StatusForStats['accepté avec un r2']
        )?.count ?? 0) +
          (interviewsStatsArray?.r1Interviews.find(
            interview => interview.status === R1StatusForStats['accepté sans r2']
          )?.count ?? 0)) /
          (openInterviewsStats?.openR1Count ?? 1)) *
        100
      ).toFixed(2),
      color: transformationRateColorMap[TransformationRateLabels.ACCEPTED_R1],
    },
    {
      key: TransformationRateLabels.CREATED_R2,
      valeur: (
        ((openInterviewsStats?.openR2Count ?? 0) / (openInterviewsStats?.openR1Count ?? 1)) *
        100
      ).toFixed(2),
      color: transformationRateColorMap[TransformationRateLabels.CREATED_R2],
    },
    {
      key: TransformationRateLabels.ACCEPTED_R2,
      valeur: (
        (((interviewsStatsArray?.r2Interviews.find(
          interview => interview.status === R2StatusForStats['accepté']
        )?.count ?? 0) +
          (interviewsStatsArray?.r2Interviews.find(
            interview => interview.status === R2StatusForStats['validé par dérogation']
          )?.count ?? 0)) /
          (openInterviewsStats?.openR1Count ?? 1)) *
        100
      ).toFixed(2),
      color: transformationRateColorMap[TransformationRateLabels.ACCEPTED_R2],
    },
    {
      key: TransformationRateLabels.SIGNED_CDI,
      valeur: (((signedCDI ?? 0) / (openInterviewsStats?.openR1Count ?? 1)) * 100).toFixed(2),
      color: transformationRateColorMap[TransformationRateLabels.SIGNED_CDI],
    },
  ];
  return (
    <div className={styles.container}>
      <p className={styles.chartTitle}>taux de transformation</p>
      <ResponsiveContainer width="90%" height={368}>
        <BarChart data={barChartStats}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="key" hide />
          <YAxis />
          <Tooltip formatter={(value: number) => `${value} %`} />
          <Bar
            dataKey="valeur"
            label={params =>
              renderPercentBarCustomizedLabel({
                labelClassName: styles.barChartLabel,
                ...params,
              })
            }
          >
            {barChartStats.map(item => (
              <Cell key={item.key} fill={item.color} />
            ))}
          </Bar>
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            payload={barChartStats.map(item => ({
              id: item.key,
              type: 'circle',
              value: item.key,
              color: item.color,
            }))}
            formatter={value => renderLegendText(value)}
            iconSize={16}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InterviewsBarChart;
