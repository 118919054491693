import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CandidateR1, R1InterviewsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCreateCandidateR1 = (
  id: string,
  extraOptions?: UseMutationOptions<CandidateR1, AxiosError, void, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.createCandidateR1, id],
    async () => {
      const candidateR1 = await R1InterviewsService.r1InterviewsControllerCreateCandidateR1({
        candidateId: id,
        body: {
          agencyName: perimeter?.defaultAgencyId ?? '',
        },
      });
      return candidateR1;
    },
    {
      onSuccess: () => {
        ReactGA.event('Formulaire R1 initié', {
          brandCode: currentBrand?.brandCodeApiHeader,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      },
      onError: error => {
        if (error.response?.status === 409) {
          toast.error('Un R1 existe déjà sur ce candidat');
        } else {
          toast.error('Une erreur a empêché la création du R1');
        }
      },
      ...extraOptions,
    }
  );
};
