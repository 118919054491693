import {
  AddButton,
  DragDropListMultiSections,
  HorizontalCardWithTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';

import JobModal from 'src/Containers/Modals/JobModal/JobModal.component';
import { OnValidateParams } from 'src/Containers/Modals/JobModal/JobModal.types';
import { Job } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import JobItem from './JobItem/JobItem.component';
import styles from './JobsSelection.module.scss';
import { GetUpdatedJobOrderParams, Props } from './JobsSelection.type';
import { move, reorder } from './utils';

const JobsSelection = ({
  isLoading,
  isSuccessful,
  isDisplayMode,
  jobs,
  selectedJobs,
  otherJobs,
  mainQualification,
  setJobs,
  setSelectedJobs,
  setOtherJobs,
  setMainQualification,
}: Props) => {
  const allJobs = [...selectedJobs, ...otherJobs];

  const getUpdatedJobOrder = (
    params: GetUpdatedJobOrderParams
  ): { newSelectedJobs: Job[]; newOtherJobs: Job[] } => {
    const { source, destination } = params.dropResult;
    let newSelectedJobs = params.previousSelectedJobs;
    let newOtherJobs = params.previousOtherJobs;
    if (!destination) return { newSelectedJobs, newOtherJobs };
    const mainJob = params.previousSelectedJobs.find(selectedJob =>
      selectedJob.qualifications.includes(params.newMainQualification ?? '')
    );

    // Sorting in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'selectedJobs') {
        if (
          mainJob &&
          params.previousSelectedJobs.findIndex(selectedJob => selectedJob.id === mainJob.id) ===
            source.index
        ) {
          // Moving the main job
          newSelectedJobs = [
            mainJob,
            ...params.previousSelectedJobs.filter(job => job.id !== mainJob.id),
          ];
        } else {
          // Not moving the main job
          const reorderedList = mainJob ? [mainJob] : [];
          reorderedList.push(
            ...reorder({
              list: params.previousSelectedJobs.filter(
                selectedJob => selectedJob.id !== mainJob?.id
              ),
              startIndex: Math.max(source.index - reorderedList.length, 0),
              endIndex: Math.max(destination.index - reorderedList.length, 0),
            })
          );
          newSelectedJobs = reorderedList;
        }
      }
      if (source.droppableId === 'otherJobs') {
        const reorderedList = reorder({
          list: params.previousOtherJobs,
          startIndex: source.index,
          endIndex: destination.index,
        });
        newOtherJobs = reorderedList;
      }
    }
    // Interlist movement
    else {
      if (source.droppableId === 'selectedJobs') {
        const jobsList = move({
          sourceJobList: params.previousSelectedJobs.filter(job => job.id !== mainJob?.id),
          destinationJobList: params.previousOtherJobs,
          droppableSource: { ...source, index: Math.max(source.index - 1, 0) },
          droppableDestination: destination,
        });
        jobsList.sourceJobListClone = mainJob
          ? [mainJob, ...jobsList.sourceJobListClone]
          : jobsList.sourceJobListClone;
        newOtherJobs = jobsList.destJobListClone;
        newSelectedJobs = jobsList.sourceJobListClone;
      }
      if (source.droppableId === 'otherJobs') {
        const selectedJobsWithoutMainJob = params.previousSelectedJobs.filter(
          job => job.id !== mainJob?.id
        );
        const jobsList = move({
          sourceJobList: params.previousOtherJobs,
          destinationJobList: selectedJobsWithoutMainJob,
          droppableSource: source,
          droppableDestination: {
            ...destination,
            index: destination.index - (mainJob ? 1 : 0),
          },
        });
        jobsList.destJobListClone = mainJob
          ? [mainJob, ...jobsList.destJobListClone]
          : jobsList.destJobListClone;
        if (jobsList.destJobListClone.length > 3) {
          const [jobToRemove] = jobsList.destJobListClone.splice(3, 1);
          jobsList.sourceJobListClone.splice(0, 0, jobToRemove);
        }
        newSelectedJobs = jobsList.destJobListClone;
        newOtherJobs = jobsList.sourceJobListClone;
      }
    }
    return { newSelectedJobs, newOtherJobs };
  };

  const getSourceOfExistingJob = (jobId: string): DraggableLocation => {
    const jobToModifyIdx = selectedJobs.findIndex(job => job.id === jobId);
    return jobToModifyIdx !== -1
      ? { droppableId: 'selectedJobs', index: jobToModifyIdx }
      : {
          droppableId: 'otherJobs',
          index: otherJobs.findIndex(job => job.id === jobId),
        };
  };

  const updateJobs = (params: GetUpdatedJobOrderParams & { newJobs: Job[] }) => {
    if (params.newMainQualification !== mainQualification) {
      setMainQualification(params.newMainQualification);
      const { newSelectedJobs, newOtherJobs } = getUpdatedJobOrder(params);
      setSelectedJobs(newSelectedJobs);
      setOtherJobs(newOtherJobs);
      setJobs([...newSelectedJobs, ...newOtherJobs]);
    } else {
      setJobs(params.newJobs);
    }
  };

  const sectionsDetails = [
    {
      sectionId: 'selectedJobs',
      sectionLabel: pluralFormat(selectedJobs.length, 'métier retenu'),
      jobs: selectedJobs,
      draggableFunction: (job: Job) => !job.qualifications.includes(mainQualification ?? ''),
    },
    {
      sectionId: 'otherJobs',
      sectionLabel: 'autres métiers',
      jobs: otherJobs,
      draggableFunction: (_job: Job) => true,
    },
  ];

  const itemsBySection = sectionsDetails.map(sectionDetails => ({
    section: sectionDetails.sectionId,
    sectionLabel: sectionDetails.sectionLabel,
    items: sectionDetails.jobs.map(itemJob => ({
      id: itemJob.id,
      draggable: isDisplayMode ? false : sectionDetails.draggableFunction(itemJob),
      item: (
        <JobItem
          job={itemJob}
          allJobs={allJobs}
          mainQualification={mainQualification}
          isDisplayMode={isDisplayMode}
          onEdit={(params: OnValidateParams) =>
            updateJobs({
              newJobs: params.jobs,
              dropResult: {
                source: getSourceOfExistingJob(params.job.id),
                destination: { droppableId: 'selectedJobs', index: 0 },
              } as DropResult,
              previousSelectedJobs: selectedJobs,
              previousOtherJobs: otherJobs,
              newMainQualification: params.mainQualification,
            })
          }
        />
      ),
    })),
  }));

  return (
    <>
      <div className={styles.title}>métiers</div>
      <div className={styles.jobsDescription}>
        3 métiers retenus minimum sont demandés pour le CDI de ce candidat. Le premier métier
        contient toujours la qualification principale. Vous pouvez déplacer chacune des cartes
        métiers par la poignée ou par son menu action.
      </div>

      {isLoading &&
        Array(3)
          .fill(undefined)
          .map((_, idx) => <HorizontalCardWithTitle.Loading key={idx} />)}

      {isSuccessful && (
        <DragDropListMultiSections
          itemsBySection={isDisplayMode ? itemsBySection.splice(0, 1) : itemsBySection}
          emptySectionPlaceholder={
            <div className={styles.dragDropPlaceholder}>
              <p className={styles.dragDropPlaceholderText}>glisser / déposer ici</p>
            </div>
          }
          onDragEnd={(dropResult: DropResult) => {
            const { newSelectedJobs, newOtherJobs } = getUpdatedJobOrder({
              dropResult,
              previousSelectedJobs: selectedJobs,
              previousOtherJobs: otherJobs,
              newMainQualification: mainQualification ?? '',
            });
            setSelectedJobs(newSelectedJobs);
            setOtherJobs(newOtherJobs);
          }}
        />
      )}
      {!isDisplayMode && (
        <JobModal
          trigger={
            <AddButton icon={<PlusOpen className={styles.icon} />} text="ajouter un métier" />
          }
          selectedJobs={jobs}
          onValidate={(params: OnValidateParams) => {
            updateJobs({
              newJobs: params.jobs,
              dropResult: {
                source: { droppableId: 'otherJobs', index: 0 },
                destination: { droppableId: 'selectedJobs', index: 0 },
              } as DropResult,
              previousSelectedJobs: selectedJobs,
              previousOtherJobs: [params.job, ...otherJobs],
              newMainQualification: params.mainQualification,
            });
          }}
          mainQualification={mainQualification}
        />
      )}
    </>
  );
};

export default JobsSelection;
