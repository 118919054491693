import React from 'react';

import BadgePicker from '../BadgePicker';

import styles from './RadiusPicker.module.scss';
import { Props } from './RadiusPicker.types';

export enum RADIUS {
  KM25 = 25,
  KM50 = 50,
  KM75 = 75,
  KM100 = 100,
}

const RadiusPicker = ({ selectedRadius, onChange }: Props) => {
  return (
    <BadgePicker
      selectedItem={selectedRadius}
      items={[
        {
          key: RADIUS.KM25,
          value: '25km',
          className: styles.km25,
        },
        {
          key: RADIUS.KM50,
          value: '50km',
          className: styles.km50,
        },
        {
          key: RADIUS.KM75,
          value: '75km',
          className: styles.km75,
        },
        {
          key: RADIUS.KM100,
          value: '100km',
          className: styles.km100,
        },
      ]}
      onChange={key => onChange(key as number)}
    />
  );
};

export default RadiusPicker;
