import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import UserPreferencesState from './Types';

export const getUserPreferencesState = (state: RootState) => state.userPreferences;

export const getCardDisplayMode = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.cardDisplayMode
);

export const getDashboardStatus = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.dashboardStatus
);

export const getCardFilters = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.cardFilters
);

export default getUserPreferencesState;
