import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { LanguageCriteria } from 'src/Redux/Search/Types';
import { WishPriority, RepositoryService, LanguageLevel } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchLanguages = (
  extraOptions?: UseQueryOptions<unknown, unknown, LanguageCriteria[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchLanguages],
    async () => {
      const languages = await RepositoryService.languagesControllerGetLanguages();
      const results = languages.map(language => {
        return {
          identifier: language.id ?? '',
          label: language.label,
          wishPriority: WishPriority.Obligatoire,
          level: LanguageLevel.OccasionalPractice,
          isTopLanguage: language.isTopLanguage,
        };
      });
      return results;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
