import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Criteria,
  MatchingCriteriaItemWithLabel,
  CandidateStatuses,
  WishPriority,
} from 'src/Services/API';

import { EnumFilterResultWorkingHours, IntervalFilter, SearchState, SEARCH_MODE } from './Types';

export const workingHoursFiltersList: IntervalFilter[] = [
  { id: EnumFilterResultWorkingHours.UNDER_400, max: 400 },
  { id: EnumFilterResultWorkingHours.UNDER_1000, min: 400, max: 1000 },
  { id: EnumFilterResultWorkingHours.OVER_1000, min: 1000 },
];

const initialState: SearchState = {
  searchMode: SEARCH_MODE.BY_NAME,
  lastName: '',
  firstName: '',
  criteria: {
    qualifications: [],
    skills: [],
    diplomas: [],
    drivingLicences: [],
    potentialPositions: [],
    languages: [],
    habilitations: [],
    caces: [],
    keywords: [],
    candidateStatuses: [],
  },
  resultFilters: {
    workingHours: [],
  },
};

function setMatchingCriteria<K extends keyof Criteria>(criteriaLabel: K) {
  return (state: SearchState, action: PayloadAction<Criteria[K]>) => {
    state.criteria[criteriaLabel] = action.payload;
  };
}

function removeMatchingCriteria(
  criteriaLabel: keyof Omit<
    Criteria,
    'availabilityDate' | 'location' | 'company' | 'service' | 'candidateStatuses'
  >
) {
  return (state: SearchState, action: PayloadAction<string>) => {
    state.criteria[criteriaLabel] = (
      state.criteria[criteriaLabel] as MatchingCriteriaItemWithLabel[]
    ).filter(criteria => criteria.identifier !== action.payload);
  };
}

function changeMatchingCriteriaWeight(
  criteriaLabel: keyof Omit<
    Criteria,
    | 'availabilityDate'
    | 'location'
    | 'company'
    | 'service'
    | 'potentialPositions'
    | 'candidateStatuses'
  >
) {
  return (
    state: SearchState,

    action: PayloadAction<{ id: string; weight: WishPriority }>
  ) => {
    const item = state.criteria[criteriaLabel].find(item => item.identifier === action.payload.id);

    if (item) {
      item.wishPriority = action.payload.weight;
    }
  };
}

export const { reducer: searchReducer, actions: searchActions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    changeSearchMode: (state, action: PayloadAction<SearchState['searchMode']>) => {
      state.searchMode = action.payload;
    },
    reset: state => {
      const searchMode = state.searchMode;
      return { ...initialState, searchMode: searchMode };
    },
    setAvailability: setMatchingCriteria('availabilityDate'),
    setQualifications: setMatchingCriteria('qualifications'),
    setSkills: setMatchingCriteria('skills'),
    setDiplomas: setMatchingCriteria('diplomas'),
    setLocation: setMatchingCriteria('location'),
    setDrivingLicences: setMatchingCriteria('drivingLicences'),
    setCompany: setMatchingCriteria('company'),
    setService: setMatchingCriteria('service'),
    setPotientialPositions: setMatchingCriteria('potentialPositions'),
    setLanguages: setMatchingCriteria('languages'),
    setHabilitations: setMatchingCriteria('habilitations'),
    setCaces: setMatchingCriteria('caces'),
    setKeywords: setMatchingCriteria('keywords'),
    setCandidateStatuses: (state, action: PayloadAction<CandidateStatuses>) => {
      state.criteria.candidateStatuses = [
        ...state.criteria.candidateStatuses.filter(item => item !== action.payload),
        action.payload,
      ];
    },
    resetAvailability: state => {
      state.criteria.availabilityDate = undefined;
    },
    removeQualification: removeMatchingCriteria('qualifications'),
    removeSkill: removeMatchingCriteria('skills'),
    removeDiploma: removeMatchingCriteria('diplomas'),
    removeDrivingLicence: removeMatchingCriteria('drivingLicences'),
    removePotentialPosition: removeMatchingCriteria('potentialPositions'),
    removeLanguage: removeMatchingCriteria('languages'),
    removeHabilitation: removeMatchingCriteria('habilitations'),
    removeCaces: removeMatchingCriteria('caces'),
    removeKeyword: removeMatchingCriteria('keywords'),
    removeLocation: state => {
      state.criteria.location = undefined;
    },
    removeCompany: state => {
      state.criteria.company = undefined;
      state.criteria.service = undefined;
      state.criteria.potentialPositions = [];
    },
    removeService: state => {
      state.criteria.service = undefined;
    },
    removeCandidateStatuses: (state, action: PayloadAction<CandidateStatuses>) => {
      state.criteria.candidateStatuses = state.criteria.candidateStatuses.filter(
        item => item !== action.payload
      );
    },
    resetCandidateStatuses: state => {
      state.criteria.candidateStatuses = [];
    },
    updateQualificationWeight: changeMatchingCriteriaWeight('qualifications'),
    updateSkillWeight: changeMatchingCriteriaWeight('skills'),
    updateDiplomaWeight: changeMatchingCriteriaWeight('diplomas'),
    updateDrivingLicenceWeight: changeMatchingCriteriaWeight('drivingLicences'),
    updateLanguageWeight: changeMatchingCriteriaWeight('languages'),
    updateHabilitationWeight: changeMatchingCriteriaWeight('habilitations'),
    updateCacesWeight: changeMatchingCriteriaWeight('caces'),
    updateKeywordWeight: changeMatchingCriteriaWeight('keywords'),
    updateLocationRadius: (state, action: PayloadAction<number>) => {
      if (state.criteria.location) {
        state.criteria.location.radius = action.payload;
      }
    },
    updateCompanyWeight: (state, action: PayloadAction<WishPriority>) => {
      if (state.criteria.company) {
        state.criteria.company.wishPriority = action.payload;
      }
    },
    updateServiceWeight: (state, action: PayloadAction<WishPriority>) => {
      if (state.criteria.service) {
        state.criteria.service.wishPriority = action.payload;
      }
    },
    addWorkingHoursFilter: (state, action: PayloadAction<EnumFilterResultWorkingHours>) => {
      state.resultFilters.workingHours = [
        ...state.resultFilters.workingHours.filter(item => item.id !== action.payload),
        ...workingHoursFiltersList.filter(
          workingHoursFilterElement => workingHoursFilterElement.id === action.payload
        ),
      ];
    },
    removeWorkingHoursFilter: (state, action: PayloadAction<EnumFilterResultWorkingHours>) => {
      state.resultFilters.workingHours = state.resultFilters.workingHours.filter(
        item => item.id !== action.payload
      );
    },
    resetWorkingHoursFilter: state => {
      state.resultFilters.workingHours = [];
    },
    setCriterion: (state, action: PayloadAction<Criteria>) => {
      state.criteria = action.payload;
    },
  },
});
