import { IllusRLSkillsSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasSkills } from 'src/Redux/Search/Selectors';

import styles from './SkillButton.module.scss';

const SkillButton = () => {
  const _hasSkills = useSelector(hasSkills);
  const skillModal = useModalWithLocation('skill');
  return (
    <div>
      <CriteriaButton
        label="compétences"
        icon={<IllusRLSkillsSmall className={styles.icon} />}
        selected={_hasSkills}
        onClick={() => skillModal.open()}
      />
    </div>
  );
};

export default SkillButton;
