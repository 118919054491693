import React, { useState } from 'react';

import { FETCH_STATUS } from 'src/Redux/Types';

export const FormStatusContext = React.createContext({
  hasFormBeenTouched: false,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setHasFormBeenTouched: (hasFormBeenTouched: boolean) => {},
  destination: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  handleSubmit: () => Promise.resolve(),
  fetchStatus: FETCH_STATUS.FULFILLED,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setFetchStatus: (fetchStatus: FETCH_STATUS) => {},
  navWarningModalState: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateValidationStatus: (params: {
    handleSubmit: () => () => Promise<void>;
    fetchStatus: FETCH_STATUS;
    formStateElements: {
      isSubmitSuccessful: boolean;
      isSubmitted: boolean;
      isSubmitting: boolean;
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  openNavWarningModal: (params: { destination: string }) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  closeNavWarningModal: () => {},
  formState: {
    isSubmitted: false,
    isSubmitting: false,
    isSubmitSuccessful: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormState: (params: {
    isSubmitted: boolean;
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }) => {},
});

export function useFormNavigationContextElements() {
  const [hasFormBeenTouched, setHasFormBeenTouched] = useState(false);
  const [navWarningModalState, setNavWarningModalState] = useState(false);
  const [destination, setDestination] = useState('');
  const [handleSubmit, setHandleSubmit] = useState<() => Promise<void>>(() => Promise.resolve());
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.FULFILLED);
  const [formState, setFormState] = useState({
    isSubmitted: false,
    isSubmitting: false,
    isSubmitSuccessful: false,
  });
  const openNavWarningModal = (params: { destination: string }) => {
    setDestination(params.destination);
    setNavWarningModalState(true);
  };
  const closeNavWarningModal = () => {
    setNavWarningModalState(false);
  };
  const updateValidationStatus = (params: {
    handleSubmit: () => () => Promise<void>;
    fetchStatus: FETCH_STATUS;
    formStateElements: {
      isSubmitSuccessful: boolean;
      isSubmitted: boolean;
      isSubmitting: boolean;
    };
  }) => {
    setFetchStatus(params.fetchStatus);
    setFormState({
      isSubmitSuccessful: params.formStateElements.isSubmitSuccessful,
      isSubmitted: params.formStateElements.isSubmitted,
      isSubmitting: params.formStateElements.isSubmitting,
    });
    setHandleSubmit(params.handleSubmit);
  };

  return {
    hasFormBeenTouched,
    navWarningModalState,
    destination,
    handleSubmit,
    fetchStatus,
    formState,
    updateValidationStatus,
    setHasFormBeenTouched,
    openNavWarningModal,
    closeNavWarningModal,
    setFetchStatus,
    setFormState,
  };
}
