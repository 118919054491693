import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  R1InterviewsService,
  CandidateInterviewsSummaryStatus,
  R2InterviewsService,
} from 'src/Services/API';

import { QueryKeys } from '../types';

interface MutationParams {
  id: string;
  status: CandidateInterviewsSummaryStatus;
  comment: string;
}

export const useCloseInterviewByTT = (
  extraOptions?: UseMutationOptions<void, AxiosError, MutationParams, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, status, comment }: MutationParams) => {
      if (
        status === CandidateInterviewsSummaryStatus['R2 en cours'] ||
        status === CandidateInterviewsSummaryStatus['R2 terminé']
      ) {
        await R2InterviewsService.r2InterviewsControllerCloseCandidateR2ByTt({
          r2Id: id,
          body: { comment },
        });
      } else if (
        status === CandidateInterviewsSummaryStatus['R2 à faire'] ||
        status === CandidateInterviewsSummaryStatus['R1 en cours'] ||
        status === CandidateInterviewsSummaryStatus['R2 en planification'] ||
        status === CandidateInterviewsSummaryStatus['R2 planifié']
      ) {
        await R1InterviewsService.r1InterviewsControllerCloseCandidateR1ByTt({
          r1Id: id,
          body: { comment },
        });
      } else {
        throw new Error('Invalid operation');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateInterviewsIdAndStatus],
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchInterviews] });
        toast.success('Le refus du TT a bien été pris en compte');
      },
      onError: () => {
        toast.error("Erreur lors de l'envoi de la donnée");
      },
      ...extraOptions,
    }
  );
};
