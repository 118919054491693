import { Step, StepLabel, Stepper } from '@mui/material';
import {
  Button,
  FetchButton,
  WithLightTitle,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCreateCandidate } from 'src/Hooks/Candidates/useCreateCandidate';
import { useFetchCandidateConflicts } from 'src/Hooks/Candidates/useFetchCandidateConflicts';
import { useUpdateCandidate } from 'src/Hooks/Candidates/useUpdateCandidate';
import { useFetchDiplomasList } from 'src/Hooks/Diplomas/useFetchDiplomasList';
import { useFetchAllHabilitations } from 'src/Hooks/Habilitations/useFetchAllHabilitations';
import { useFetchLanguagesAsLanguage } from 'src/Hooks/Languages/useFetchLanguagesAsLanguage';
import { useFetchCities } from 'src/Hooks/Locations/UseFetchCities';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { useFetchQualificationsList } from 'src/Hooks/Qualifications/useFetchQualificationsList';
import { useFetchOtherSkills } from 'src/Hooks/Repositories/useFetchOtherSkills';
import { useFetchSkillsList } from 'src/Hooks/Skills/useFetchSkillsList';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Candidate, Habilitation, LanguageLevel } from 'src/Services/API';
import {
  mergeFetchStatusWithFormState,
  severalToFetchStatus,
  toFetchStatus,
} from 'src/Services/ReactQuery';

import { AddNewTalentStepperItems, AddNewTalentSteps } from '../AddNewTalent.types';
import { levenshteinDistance } from '../utils';

import AddNewTalentTextInputs from './AddNewTalentTextInputs/AddNewTalentTextInputs.component';
import ConfirmationStep from './ConfirmationStep/ConfirmationStep.component';
import ConflictsStep from './ConflictsStep/ConflictsStep.component';
import styles from './FormAddNewTalent.module.scss';
import {
  FormSteps,
  Props,
  formAddNewTalentSchema,
  formAddNewTalentSchemaType,
  formFields,
} from './FormAddNewTalent.types';
import IdentityStep from './IdentityStep/IdentityStep.component';
import SelectCity from './SelectCity/SelectCity.component';
import SelectDiplomas from './SelectDiplomas/SelectDiplomas.component';
import SelectFormations from './SelectFormations/SelectFormations.component';
import SelectHardSkills from './SelectHardSkills/SelectHardSkills.component';
import SelectLanguage from './SelectLanguages/SelectLanguages.component';
import SelectQualifications from './SelectQualifications/SelectQualifications.component';
import SelectSoftSkills from './SelectSoftSkills/SelectSoftSkills.component';

const textInputAddressData: {
  name: keyof formAddNewTalentSchemaType;
  placeholder: string;
}[] = [
  { name: 'apartmentNumber', placeholder: 'étage / num appartement' },
  { name: 'buildingName', placeholder: 'nom du bâtiment' },
  { name: 'road', placeholder: 'numéro et voie, allée, rue, avenue...' },
  { name: 'locality', placeholder: 'lieu-dit, boîte postale' },
];

const FormAddNewTalent = ({ parsedDocument, step, setStep, formStep, setFormStep }: Props) => {
  const [biggestStep, setBiggestStep] = useState<FormSteps>(FormSteps.Identity);
  const [matchedCandidates, setMatchedCandidates] = useState<Candidate[]>([]);

  const { openCandidateFile } = useOpenCandidateFile();
  const languagesData = useFetchLanguagesAsLanguage();
  const otherSkills = useFetchOtherSkills();
  const formationsData = useFetchAllHabilitations();
  const createCandidate = useCreateCandidate({
    onError: (...args) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [error] = args as any;
      const data = error?.response.data;
      if ([512, 514].includes(data.statusCode)) {
        toast.warning(data.message);
        setFormStep(FormSteps.Confirmation);
      } else {
        toast.error(data.message);
      }
    },
  });
  const updateCandidate = useUpdateCandidate({
    onError: (...args) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [error] = args as any;
      const data = error?.response.data;
      if ([512, 514].includes(data.statusCode)) {
        toast.warning(data.message);
      } else {
        toast.error(data.message);
      }
    },
  });
  const createCandidateStatus = toFetchStatus({
    ...createCandidate,
    defaultValue: FETCH_STATUS.PENDING,
  });
  const updateCandidateStatus = toFetchStatus({
    ...updateCandidate,
    defaultValue: FETCH_STATUS.PENDING,
  });

  // fetch in every change in step
  const qualificationsData = useFetchQualificationsList(
    parsedDocument?.qualifications?.map(qualification => qualification.name ?? '') ?? []
  );
  const diplomaData = useFetchDiplomasList(
    parsedDocument?.diplomas?.map(diploma => diploma.name ?? '') ?? []
  );

  const citiesData = useFetchCities(
    parsedDocument?.address?.zipCode ?? parsedDocument?.address?.city ?? '-'
  );

  const defaultCity = parsedDocument?.address?.city
    ? citiesData?.data?.reduce((closestCity, currentCity) => {
        return levenshteinDistance(parsedDocument.address?.city ?? '', closestCity.label ?? '') <
          levenshteinDistance(parsedDocument.address?.city ?? '', currentCity.label ?? '')
          ? closestCity
          : currentCity;
      }, citiesData?.data[0])
    : citiesData?.data?.[0];

  const defaultQualifications = qualificationsData?.data?.map((qualification, index) => {
    return {
      selectedItem: {
        id:
          qualification.matchingQualifications.length > 0
            ? qualification.matchingQualifications[0].qualificationId ?? ''
            : '',
        label:
          qualification.matchingQualifications.length > 0
            ? qualification.matchingQualifications[0].label ?? 'non trouvée'
            : 'non trouvée',
      },
      main: false,
      parsedDocumentLabel: qualification.keyword,
      uniqueKey: index,
    };
  });

  const defaultDiplomas = parsedDocument?.diplomas?.map((candidateDiploma, index) => {
    const matchedDiploma = diplomaData?.data?.find(
      diploma => candidateDiploma.name === diploma.parsedDocumentLabel
    );
    return matchedDiploma
      ? {
          selectedItem: { id: matchedDiploma.id, label: matchedDiploma.label },
          date: candidateDiploma.startDate?.length
            ? new Date(candidateDiploma.startDate)
            : undefined,
          obtained: true,
          main: false,
          parsedDocumentLabel: matchedDiploma.parsedDocumentLabel,
          uniqueKey: index,
        }
      : {
          selectedItem: {
            id: '',
            label: 'non trouvé',
          },
          date: candidateDiploma.startDate?.length
            ? new Date(candidateDiploma.startDate)
            : undefined,
          obtained: true,
          main: false,
          parsedDocumentLabel: candidateDiploma.name,
          uniqueKey: index,
        };
  });

  const hardSkillsData = useFetchSkillsList(
    parsedDocument?.skills?.hardSkills.map(skill => skill.name ?? '') ?? []
  );

  const matchedHardSkillsSet = new Set<string>();
  const defaultHardSkills =
    parsedDocument?.skills?.hardSkills
      .map(candidateHardSkill => {
        const matchedHardSkill = hardSkillsData.data?.find(
          hardSkill =>
            candidateHardSkill.name === hardSkill.parsedDocumentLabel &&
            !matchedHardSkillsSet.has(hardSkill.id)
        );
        matchedHardSkill?.id && matchedHardSkillsSet.add(matchedHardSkill?.id);
        return {
          selectedItem: {
            id: matchedHardSkill?.id ?? '',
            label: matchedHardSkill?.label,
          },
          parsedDocumentLabel: matchedHardSkill?.parsedDocumentLabel,
        };
      })
      .filter(skill => skill !== undefined) ?? [];

  const matchedSoftSkillsSet = new Set();
  const defaultSoftSkills =
    parsedDocument?.skills?.softSkills.map(candidateSoftSkill => {
      const matchedSoftSkill = otherSkills.data?.find(
        otherSkill =>
          otherSkill.label
            ?.toLowerCase()
            .includes(candidateSoftSkill?.name?.toLocaleLowerCase() ?? '') &&
          !matchedSoftSkillsSet.has(otherSkill.id)
      );
      matchedSoftSkill?.id && matchedSoftSkillsSet.add(matchedSoftSkill?.id);
      return matchedSoftSkill
        ? {
            selectedItem: matchedSoftSkill,
            parsedDocumentLabel: candidateSoftSkill?.name,
          }
        : {
            selectedItem: {
              label: 'non trouvée',
              id: '',
            },
            parsedDocumentLabel: candidateSoftSkill?.name,
          };
    }) ?? [];

  const matchedLanguagesSet = new Set<string>();
  const defaultLanguages = parsedDocument?.skills?.languages.map((candidateLanguage, index) => {
    const matchedLanguage = languagesData.data?.find(
      language =>
        language.label
          ?.toLowerCase()
          .includes(candidateLanguage?.name?.toLocaleLowerCase() ?? '') &&
        !matchedLanguagesSet.has(language.id)
    );

    matchedLanguage?.id && matchedLanguagesSet.add(matchedLanguage?.id);

    return matchedLanguage
      ? {
          selectedItem: {
            id: matchedLanguage.id ?? '',
            label: matchedLanguage.label ?? '',
          },
          parsedDocumentLabel: candidateLanguage?.name,
          uniqueKey: index,
          level: { key: candidateLanguage?.level, value: candidateLanguage?.level },
        }
      : {
          selectedItem: {
            label: 'non trouvée',
            id: '',
          },
          parsedDocumentLabel: candidateLanguage?.name,
          uniqueKey: index,
          level: { key: candidateLanguage?.level, value: candidateLanguage?.level },
        };
  });

  const matchedFormationsSet = new Set<string>();
  const defaultFormations = parsedDocument?.formations?.map((candidateFormation, index) => {
    const possibleFormations: Habilitation[] | undefined = candidateFormation?.name
      ?.toUpperCase()
      ?.startsWith('CACES')
      ? formationsData?.data?.filter(formation => formation?.label?.startsWith('CACES')) ?? []
      : formationsData?.data;
    const matchedFormation = candidateFormation?.name
      ? possibleFormations?.reduce((closestFormation, currentFormation) => {
          return matchedFormationsSet.has(currentFormation.id) ||
            levenshteinDistance(candidateFormation.name ?? '', closestFormation.label ?? '') <
              levenshteinDistance(candidateFormation.name ?? '', currentFormation.label ?? '')
            ? closestFormation
            : currentFormation;
        }, possibleFormations[0])
      : undefined;

    matchedFormation?.id && matchedFormationsSet.add(matchedFormation?.id);

    return matchedFormation
      ? {
          selectedItem: {
            label: matchedFormation.label ?? '',
            id: matchedFormation.id ?? '',
          },
          uniqueKey: index,
          parsedDocumentLabel: candidateFormation?.name,
        }
      : {
          selectedItem: { label: 'non trouvée', id: '' },
          parsedDocumentLabel: candidateFormation?.name,
          uniqueKey: index,
        };
  });

  const { control, getValues, setValue, watch, handleSubmit, trigger, formState } =
    useFormWithZodResolver({
      schema: formAddNewTalentSchema,
      defaultValues: {
        gender: '',
        firstName: parsedDocument?.personal?.firstName ?? '',
        name: parsedDocument?.personal?.name ?? '',
        email: parsedDocument?.personal?.email ?? '',
        phone: parsedDocument?.personal?.phone ?? '',
        contractAccepted: [],
        birthCity: {},
        birthCountry: {},
        birthDepartment: '',
        nationality: {},
        // address fields are not disponible yet
        apartmentNumber: parsedDocument?.address?.apartmentNumber ?? '',
        road: parsedDocument?.address?.road ?? '',
        city: defaultCity ?? undefined,
        agencyId: '',
        languages: [],
        diplomas: [],
        hardSkills: [],
        softSkills: [],
        qualifications: [],
        formations: [],
      },
    });
  const matchingCandidates = useFetchCandidateConflicts(
    watch('firstName'),
    watch('name'),
    watch('email')
  );
  const handleSubmitForm = handleSubmit(
    async () => {
      if (watch('qualifications').filter(qualification => qualification?.main).length !== 1) {
        toast.error('veuillez renseigner la qualification principale');
        return;
      }
      if (watch('diplomas').filter(diploma => diploma?.main).length !== 1) {
        toast.error('veuillez renseigner le diplôme principal');
        return;
      }
      const matchedCandidates = (await matchingCandidates.refetch()).data;
      if ((matchedCandidates?.length ?? 0) > 0) {
        setStep(AddNewTalentSteps.Conflicts);
        setMatchedCandidates(matchedCandidates ?? []);
        setFormStep(FormSteps.Conflicts);
      } else {
        await createCandidateRequest();
      }
    },
    () => {
      if (watch('qualifications').filter(qualification => qualification?.main).length !== 1)
        toast.error('veuillez renseigner la qualification principale');
      else if (watch('diplomas').filter(diploma => diploma?.main).length !== 1)
        toast.error('veuillez renseigner le diplôme principal');
      else toast.error('erreur lors de la validation');
    }
  );

  const formatCreateCandidateBody = (values: formAddNewTalentSchemaType) => {
    return {
      ...values,
      address: values.road,
      birthDate: values?.birthDate,
      habilitationIds: values.formations.map(formation => formation.selectedItem.id),
      birthCity: values?.birthCity?.label,
      birthDepartment: values?.birthDepartment?.length ? values?.birthDepartment : undefined,
      nationality: values?.nationality?.id,
      birthCountry: values?.birthCountry?.id?.toString(),
      city: values?.city?.label ?? '',
      zipCode: values?.city?.zipCode ?? '',
      inseeCode: values?.city?.inseeCode ?? '',
      country: values?.country?.length ? values?.country : undefined,
      languages: values?.languages?.map(language => {
        return {
          ...language.selectedItem,
          label: language.selectedItem?.label ?? '',
          level: (language.level?.key as LanguageLevel) ?? '',
        };
      }),
      qualificationId:
        values.qualifications.find(qualification => qualification.main)?.selectedItem?.id ?? '',
      qualifications: values.qualifications.map(qualification => {
        return {
          id: qualification.selectedItem.id,
          main: qualification.main,
        };
      }),
      hardSkills: values.hardSkills.map(hardSkill => {
        return {
          id: hardSkill.selectedItem.id,
          label: hardSkill.selectedItem.label,
        };
      }),
      diplomas: values.diplomas.map(({ main, date, obtained, selectedItem }) => {
        return { date, main, obtained, id: selectedItem.id, label: selectedItem?.label ?? '' };
      }),
      softSkills: values.softSkills.map(softSkill => softSkill.selectedItem),
      formations: values.formations.map(formation => formation.selectedItem),
    };
  };

  const formValidationByStep = useCallback(
    async (formStep: FormSteps) => {
      if (!(formStep === FormSteps.Address || formStep === FormSteps.Identity)) return true;
      const isValid = await trigger(formFields[formStep]);
      if (!isValid) {
        toast.error('erreur lors de la validation');
      }
      return isValid;
    },
    [trigger]
  );

  const createCandidateRequest = async (values?: formAddNewTalentSchemaType) => {
    if (!values) values = watch();
    const createCandidateBody = formatCreateCandidateBody(values);
    createCandidate.mutate(createCandidateBody);
  };
  const updateCandidateRequest = async (candidateId: string) => {
    const createCandidateBody = formatCreateCandidateBody(watch());
    updateCandidate.mutate({ candidateId: candidateId, body: createCandidateBody });
  };

  useEffect(() => {
    if (
      createCandidateStatus === FETCH_STATUS.FULFILLED
      //  ||updateCandidateStatus === FETCH_STATUS.FULFILLED
    ) {
      setFormStep(FormSteps.Confirmation);
      setStep(AddNewTalentSteps.Conflicts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCandidateStatus, setFormStep, updateCandidateStatus]);

  useEffect(() => {
    if (biggestStep < formStep && formStep === FormSteps.Address) {
      setValue('city', defaultCity);
      setBiggestStep(formStep);
    }
    if (biggestStep < formStep && formStep === FormSteps.Experiences) {
      setValue('languages', defaultLanguages ?? []);
      setValue('diplomas', defaultDiplomas ?? []);
      setValue('qualifications', defaultQualifications ?? []);
      setValue('hardSkills', defaultHardSkills);
      setValue('softSkills', defaultSoftSkills);
      setValue('formations', defaultFormations ?? []);
      setBiggestStep(formStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguages, formStep]); //TODO use refetch instead of biggestStep

  const validationButton = useMemo(() => {
    if (formStep === FormSteps.Address) {
      return (
        <FetchButton
          fetchStatus={severalToFetchStatus([
            qualificationsData,
            diplomaData,
            otherSkills,
            hardSkillsData,
            languagesData,
            formationsData,
          ])}
          title="suivant"
          className={styles.nextButton}
          onClick={async () => {
            (await formValidationByStep(formStep)) && setFormStep(formStep + 1);
          }}
        />
      );
    } else if (formStep === FormSteps.Experiences) {
      return (
        <FetchButton
          title="suivant"
          fetchStatus={mergeFetchStatusWithFormState(toFetchStatus(createCandidate), formState)}
          onClick={handleSubmitForm}
        />
      );
    } else if (formStep < FormSteps.Experiences) {
      return (
        <Button.Primary
          onClick={async () => {
            (await formValidationByStep(formStep)) && setFormStep(formStep + 1);
          }}
          className={styles.nextButton}
        >
          suivant
        </Button.Primary>
      );
    }
  }, [
    createCandidate,
    diplomaData,
    formState,
    formStep,
    formValidationByStep,
    formationsData,
    handleSubmitForm,
    hardSkillsData,
    languagesData,
    otherSkills,
    qualificationsData,
    setFormStep,
  ]);

  return (
    <div className={styles.container}>
      <>
        <div className={styles.formContainer}>
          <div className={styles.stepperContainer}>
            <Stepper activeStep={step} alternativeLabel className={styles.stepper}>
              {AddNewTalentStepperItems.map((item, index) => {
                return (
                  <Step key={item.name} completed={index < step}>
                    <StepLabel>{item.title}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {formStep === FormSteps.Identity && (
            <IdentityStep control={control} trigger={trigger} watch={watch} />
          )}
          {formStep === FormSteps.Address && (
            <div className={styles.formAddressContainer}>
              <div className={styles.title}>vérification des informations adresse 2/3</div>
              {parsedDocument?.address?.rawName && (
                <div className={styles.detectedAddress}>
                  <WithLightTitle
                    title={'adresse détecté'}
                    titleClassName={styles.detectedAddressTitle}
                  >
                    <p>{parsedDocument?.address.rawName}</p>
                  </WithLightTitle>
                  <Flux className={styles.flux} />
                </div>
              )}
              <AddNewTalentTextInputs control={control} textInputData={textInputAddressData} />

              <WithLightTitle title={'ville'} className={styles.city}>
                <SelectCity control={control} watch={watch} trigger={trigger} name={'city'} />
              </WithLightTitle>
            </div>
          )}
          {formStep === FormSteps.Experiences && (
            <div className={styles.formExperiencesContainer}>
              <div className={styles.experiencesTitle}>
                vérification des expériences et formations 3/3
              </div>
              <SelectQualifications control={control} watch={watch} />
              <SelectDiplomas control={control} watch={watch} />
              <SelectFormations
                control={control}
                formations={formationsData.data ?? []}
                watch={watch}
              />
              <SelectLanguage
                control={control}
                languages={languagesData.data ?? []}
                watch={watch}
              />
              <SelectHardSkills control={control} watch={watch} />
              <SelectSoftSkills
                control={control}
                watch={watch}
                otherSkills={otherSkills.data ?? []}
              />
            </div>
          )}
          {formStep === FormSteps.Conflicts && (
            <ConflictsStep
              watch={watch}
              setFormStep={setFormStep}
              createCandidate={() => createCandidateRequest(getValues())}
              replaceCandidate={candidateId => {
                updateCandidateRequest(candidateId);
              }}
              matchedCandidates={matchedCandidates}
            />
          )}
          {formStep === FormSteps.Confirmation && (
            <ConfirmationStep
              onClose={() => {
                openCandidateFile({ candidateId: createCandidate.data });
              }}
            />
          )}
        </div>
        {formStep !== FormSteps.Confirmation && (
          <div className={styles.formFooter}>
            <div className={styles.separator} />
            <div className={styles.formButtons}>
              {formStep !== FormSteps.Identity && (
                <Button.Secondary
                  onClick={() => {
                    setFormStep(formStep - 1);
                  }}
                >
                  retour
                </Button.Secondary>
              )}
              {validationButton}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default FormAddNewTalent;
