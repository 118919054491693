import { FETCH_STATUS } from 'src/Redux/Types';

export function toFetchStatus(query: {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  isFetching?: boolean;
  defaultValue?: FETCH_STATUS;
}) {
  if (query.isSuccess) {
    return FETCH_STATUS.FULFILLED;
  }
  if (query.isLoading) {
    return FETCH_STATUS.PENDING;
  }
  if (query.isFetching) {
    return FETCH_STATUS.PENDING;
  }
  if (query.isError) {
    return FETCH_STATUS.REJECTED;
  }
  return query?.defaultValue ? query?.defaultValue : FETCH_STATUS.FULFILLED;
}

export function mergeFetchStatus(...statuses: FETCH_STATUS[]): FETCH_STATUS {
  if (statuses.includes(FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }
  if (statuses.includes(FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}

export function mergeSeveralFetchStatus(statusArray: FETCH_STATUS[]): FETCH_STATUS {
  if (statusArray.includes(FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }
  if (statusArray.includes(FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}

export function mergeFetchStatusWithFormState(
  fetchStatus: FETCH_STATUS,
  formState: { isSubmitting: boolean; isSubmitSuccessful: boolean; isSubmitted: boolean }
): FETCH_STATUS {
  return formState.isSubmitting
    ? FETCH_STATUS.PENDING
    : !formState.isSubmitSuccessful && formState.isSubmitted
    ? FETCH_STATUS.REJECTED
    : fetchStatus;
}

export function severalToFetchStatus(
  queries: {
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
    isFetching?: boolean;
    defaultValue?: FETCH_STATUS;
  }[]
): FETCH_STATUS {
  return mergeSeveralFetchStatus(queries.map(query => toFetchStatus(query)));
}
