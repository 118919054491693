import { WithLightTitle, AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useCallback, useState } from 'react';

import { useFetchHabilitations } from 'src/Hooks/Habilitations';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectHabilitations.types';

const SelectHabilitations = ({ setHabilitations, selectedHabilitations }: Props) => {
  const [keyword, setKeyword] = useState('');
  const mutation = useFetchHabilitations();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <WithLightTitle title="ou rechercher parmi toutes les habilitations">
      <AutoSuggest
        id="habilitations"
        value={selectedHabilitations ?? []}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          setKeyword(word);
          word !== '' && throttledMethod(word);
        }}
        onChange={habilitations => {
          setHabilitations([...habilitations]);
          setKeyword('');
        }}
        searchResults={keyword !== '' ? mutation.data ?? [] : []}
        placeholder="1 caractère minimum"
        fetchStatus={toFetchStatus(mutation)}
      />
    </WithLightTitle>
  );
};

export default SelectHabilitations;
