import { Button, Animation } from '@randstad-lean-mobile-factory/react-components-core';
import {
  MinusBar,
  AddCrossPartial,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import styles from './SearchResultsFilter.module.scss';
import { Props } from './SearchResultsFilter.types';

const SearchResultsFilter = ({ label, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.filterLabel}>{label}</div>
        <Button.Tertiary className={styles.iconButton} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <MinusBar className={styles.icon} />
          ) : (
            <AddCrossPartial className={styles.icon} />
          )}
        </Button.Tertiary>
      </div>
      <Animation.Unroll visible={isOpen}>{children}</Animation.Unroll>
    </>
  );
};

export default SearchResultsFilter;
