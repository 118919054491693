import {
  Loader,
  SegmentedMenu,
  Animation,
  SimplifiedHeader,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  GoBackArrow,
  IllusEscalierYellow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DetailButton from 'src/Components/DetailButton';
import { useFetchCandidateBirthInfoFromURL } from 'src/Hooks/Candidates/useFetchCandidateBirthInfoFromURL';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { useFetchCandidateSkillsFromURL } from 'src/Hooks/Candidates/useFetchCandidateSkillsFromURL';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { getEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateFile.module.scss';
import { navigation } from './CandidateFile.types';
import CandidateFileForm from './CandidateFileForm';
import Sidebar from './Sidebar';

const CandidateFile = () => {
  const history = useHistory();
  const params = useParams<{
    firstSection: string;
    candidateId: string;
  }>();
  const candidateDetailsQuery = useFetchCandidateDetailsFromURL();
  const candidateBirthInfoQuery = useFetchCandidateBirthInfoFromURL();
  const candidateSkillsQuery = useFetchCandidateSkillsFromURL();
  const candidateInterviewsIdAndStatusQuery = useFetchCandidateInterviewsIdAndStatus(
    params.candidateId
  );
  const fetchStatus = mergeSeveralFetchStatus([
    toFetchStatus(candidateBirthInfoQuery),
    toFetchStatus(candidateDetailsQuery),
    toFetchStatus(candidateSkillsQuery),
    toFetchStatus(candidateInterviewsIdAndStatusQuery),
  ]);
  const [simplifiedHeader, setSimplifiedHeader] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      {!simplifiedHeader && (
        <div className={styles.topBar}>
          <button className={styles.goBack} onClick={() => history.push('/results')}>
            <GoBackArrow />
            <p className={styles.goBackText}>retour aux résultats</p>
          </button>
          <DetailButton
            candidateId={params.candidateId}
            candidateType={candidateDetailsQuery.data?.statusCode}
            candidateInterviewsIdAndStatus={candidateInterviewsIdAndStatusQuery.data}
            candidateIdFetchStatus={fetchStatus}
            pageActionButton
          />
        </div>
      )}
      {!simplifiedHeader && (
        <div className={styles.tabsContainer}>
          <SegmentedMenu
            controls={Object.entries(navigation)}
            selected={params.firstSection}
            keyValueExtractor={([section, { name }]) => ({ key: section, value: name })}
            onSelectionChange={([section]) => {
              history.push(
                getEditionRoute({
                  candidateId: params.candidateId,
                  section,
                })
              );
            }}
          />
        </div>
      )}
      {simplifiedHeader && (
        <SimplifiedHeader
          fields={Object.entries(navigation).map(item => {
            return item[1].name;
          })}
          selectedkey={Object.entries(navigation).map(item => {
            return item[0];
          })}
          selected={params.firstSection}
          onSelectionChange={section => {
            history.push(
              getEditionRoute({
                candidateId: params.candidateId,
                section,
              })
            );
          }}
        />
      )}
      <div className={styles.tabContainer}>
        <div className={styles.sideBarContainer}>
          <Animation.Unroll visible={candidateDetailsQuery.data !== undefined}>
            <div className={styles.candidateName}>
              <p>vous êtes sur le profil de :</p>
              {`${candidateDetailsQuery.data?.firstName ?? ''} ${
                candidateDetailsQuery.data?.name ?? ''
              }`}
            </div>
            <div className={styles.candidateContract}>{`${
              candidateDetailsQuery.data?.statusCode ?? 'type de contrat non renseigné'
            } | ${pluralFormat(candidateDetailsQuery.data?.nbHoursWorked ?? 0, 'heure')} `}</div>
          </Animation.Unroll>
          <Sidebar />
        </div>
        {fetchStatus === FETCH_STATUS.PENDING && (
          <div className={styles.loader}>
            <Loader heightInRem={8} />
          </div>
        )}

        {fetchStatus === FETCH_STATUS.REJECTED && (
          <div className={styles.errorContainer}>
            <div className={styles.errorYellowLadder}>
              <IllusEscalierYellow />
            </div>
            <p className={styles.errorTitle}>erreur serveur, connexion perdue</p>
            <p className={styles.errorDescription}>
              dès que vous retrouverez la connexion, vous pourrez recharger la page
            </p>
          </div>
        )}

        {fetchStatus === FETCH_STATUS.FULFILLED && (
          <CandidateFileForm
            simplifiedHeader={simplifiedHeader}
            setSimplifiedHeader={setSimplifiedHeader}
          />
        )}
      </div>
    </div>
  );
};

export default CandidateFile;
