import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { useFetchCandidateBusinesses } from 'src/Hooks/Candidates/useFetchCandidateBusinesses';
import { CandidateDetailedBusiness } from 'src/Services/API';

export const useFetchCandidateBusinessesFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateDetailedBusiness[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateBusinesses(candidateId, extraOptions);
};
