import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './DrivingLicenceCard.module.scss';
import { Props } from './DrivingLicenceCard.types';

const DrivingLicenceCard = ({ drivingLicence, icon, selected, onClick }: Props) => (
  <div className={classnames(styles.container, { [styles.selected]: selected })} onClick={onClick}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.licenceInformation}>
      <div className={classnames(styles.identifier, { [styles.selectedText]: selected })}>
        {drivingLicence.identifier}
      </div>
      <div className={styles.label}>{drivingLicence.label}</div>
    </div>
    {selected && <Check className={styles.checkIcon} />}
  </div>
);

export default DrivingLicenceCard;
