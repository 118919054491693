/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CandidatesService {
  /**
   *
   */
  static candidatesControllerAddNewTalentCvParser(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JSONData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/addNewCandidate';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCvCompany(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cvcompany';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCvPerso(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cvperso';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerCreateCandidate(
    params: {
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: CreateCandidateBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidate(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: CreateCandidateBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidate(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Candidate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetConflictCandidates(
    params: {
      /**  */
      brandCode?: string;
      /**  */
      firstName: string;
      /**  */
      lastName: string;
      /**  */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Candidate[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/conflict';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        firstName: params['firstName'],
        lastName: params['lastName'],
        email: params['email'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetDeserializedFavorites(
    params: {
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: FavoritesDeserializationBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Candidate[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/favorites';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetBirthInformations(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateBirthInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/birthinfo';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateDocuments(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateDocument[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/documents';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLastContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateContract[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contract';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateSkills(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateSkillsEntity> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/skills';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidateSkills(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: CandidateSkillsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/skills';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateTests(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateTests[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/tests';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateETests(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateETests[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/etests';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetLastYearCandidateContractsInformations(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateContractInformations> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contractsInformation';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateExperiences(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateExperience[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/experiences';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateExperiencesByCompany(
    params: {
      /**  */
      id: string;
      /**  */
      getAllExperiences?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateAllExperiencesByCompany> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/experiences/byCompany';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { getAllExperiences: params['getAllExperiences'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidateExternalExperience(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CandidateExperienceBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/externalExperience';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateAgencies(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
      /**  */
      isCdi?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateAgencyDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/agencies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'], isCDI: params['isCdi'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateDiplomas(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateDiplomaDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/diplomas';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateBusiness(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateDetailedBusiness[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/business';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateSkilldetails(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CandidateSkillDetailsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SkillDetailsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/skilldetails';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetQualifications(
    params: {
      /**  */
      keywords: any | null[];
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QualificationMatchFromKeyword[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keywords: params['keywords'],
        societyCode: params['societyCode'],
        brand: params['brand'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BusinessService {
  /**
   *
   */
  static businessControllerGetBusinesses(options: IRequestOptions = {}): Promise<Business[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/business';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoryService {
  /**
   *
   */
  static citiesControllerGetCities(
    params: {
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<City[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static diplomasControllerGetDiplomas(
    params: {
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Diploma[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/diplomas';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static diplomasControllerGetDiplomasList(
    params: {
      /**  */
      keywords: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiplomaWithLabel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/diplomas/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keywords: params['keywords'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static drivingLicencesControllerGetDrivingLicences(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DrivingLicence[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/driving-licences';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static habilitationsControllerGetHabilitations(
    params: {
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Habilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/habilitations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static habilitationsControllerGetAllHabilitations(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Habilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/habilitations/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static habilitationsControllerGetCaces(options: IRequestOptions = {}): Promise<CACESCategory[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/habilitations/caces';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static habilitationsControllerGetElectricHabilitations(
    options: IRequestOptions = {}
  ): Promise<ElectricVoltage[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/habilitations/electricHabilitations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static languagesControllerGetLanguages(
    params: {
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Language[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsControllerGetSkills(
    params: {
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Skill[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsControllerGetSkillsList(
    params: {
      /**  */
      keywords: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SkillWithLabel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keywords: params['keywords'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerSearchCompany(
    params: {
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: SearchCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetDepartments(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/departments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetWorkTeams(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyWorkTeam[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/workteams';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConsultantService {
  /**
   *
   */
  static consultantsControllerGetAgencies(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/{id}/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static consultantsControllerGetConsultant(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Consultant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultant/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsService {
  /**
   *
   */
  static documentsControllerGetDocument(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Document> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CandidateService {
  /**
   *
   */
  static matchingControllerSearchCandidates(
    params: {
      /**  */
      brandCode?: string;
      /** requestBody */
      body?: MatchingCriteria;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatchingResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/matching';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static matchingControllerGetCriteriaFromEdp(
    params: {
      /**  */
      brandCode: string;
      /**  */
      positionStudyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Criteria> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/matching/edp-criteria';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        positionStudyId: params['positionStudyId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static matchingControllerGetCriteriaFromMission(
    params: {
      /**  */
      brandCode: string;
      /**  */
      missionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Criteria> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/matching/mission-criteria';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'], missionId: params['missionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class R1InterviewsService {
  /**
   *
   */
  static r1InterviewsControllerCreateCandidateR1(
    params: {
      /**  */
      candidateId: string;
      /** requestBody */
      body?: CreateCandidateR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/candidate/{candidateId}';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerUpdateCandidateR1(
    params: {
      /**  */
      r1Id: string;
      /** requestBody */
      body?: UpdateCandidateR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerGetR1(
    params: {
      /**  */
      r1Id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerDeleteR1(
    params: {
      /**  */
      r1Id: string;
      /** requestBody */
      body?: DeleteR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerCloseCandidateR1(
    params: {
      /**  */
      r1Id: string;
      /** requestBody */
      body?: CloseCandidateR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}/close';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerReopenR1(
    params: {
      /**  */
      r1Id: string;
      /** requestBody */
      body?: ReopenR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}/reopen';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerGetCandidateR1Pdf(
    params: {
      /**  */
      r1Id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/pdfR1/{R1Id}/';
      url = url.replace('{R1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r1InterviewsControllerCloseCandidateR1ByTt(
    params: {
      /**  */
      r1Id: string;
      /** requestBody */
      body?: CloseInterviewByTTBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r1-interviews/{r1Id}/closeByTTRefusal';
      url = url.replace('{r1Id}', params['r1Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoriesService {
  /**
   *
   */
  static repositoriesControllerGetR1AssessmentQuestions(
    options: IRequestOptions = {}
  ): Promise<R1AssessmentQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/assessment/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetR1ExperienceExchangeQuestions(
    options: IRequestOptions = {}
  ): Promise<InterviewsExchangeQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/r1experience/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetR1StrengthQuestions(
    options: IRequestOptions = {}
  ): Promise<InterviewsExchangeQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/r1strength/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetR2ExchangeQuestions(
    options: IRequestOptions = {}
  ): Promise<InterviewsExchangeQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/r2exchange/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetR2ProjectionsQuestions(
    options: IRequestOptions = {}
  ): Promise<InterviewsExchangeQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/r2projection/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetR2ValidationQuestions(
    options: IRequestOptions = {}
  ): Promise<InterviewsExchangeQuestion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/r2projection/validationQuestions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetInhouseCgc(options: IRequestOptions = {}): Promise<CGC[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/CGC';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetSmic(options: IRequestOptions = {}): Promise<SMIC> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/smic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetBusinessQualifications(
    params: {
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
      /**  */
      businessIds: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/jobQualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        societyCode: params['societyCode'],
        brand: params['brand'],
        businessIds: params['businessIds'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetDepartments(
    options: IRequestOptions = {}
  ): Promise<Department[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCountries(options: IRequestOptions = {}): Promise<Country[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetNationalities(
    options: IRequestOptions = {}
  ): Promise<Nationality[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/nationalities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerUploadAndStoreInterviewsGoalsCsvFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/interviewsGoalCSVFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCdiGoals(
    params: {
      /** requestBody */
      body?: GetCDIGoalsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CDIGoals[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/cdiGoals';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCdiGoalsAdministrators(
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/cdiGoalsAdministrators';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetOtherSkills(options: IRequestOptions = {}): Promise<Skill[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/otherSkills';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgenciesService {
  /**
   *
   */
  static agenciesControllerGetSignedCdiCount(
    params: {
      /** requestBody */
      body?: GetSignedCDICount;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/signedCDICount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class R2InterviewsService {
  /**
   *
   */
  static r2InterviewsControllerCreateCandidateR2(
    params: {
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/candidate/{candidateId}';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerGetR2(
    params: {
      /**  */
      r2Id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerUpdateCandidateR2(
    params: {
      /**  */
      r2Id: string;
      /** requestBody */
      body?: UpdateCandidateR2Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerDeleteR2(
    params: {
      /**  */
      r2Id: string;
      /** requestBody */
      body?: DeleteR2Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerCloseCandidateR2(
    params: {
      /**  */
      r2Id: string;
      /** requestBody */
      body?: CloseCandidateR2Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateR2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}/close';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerReturnProcessToAgency(
    params: {
      /**  */
      r2Id: string;
      /** requestBody */
      body?: ReopenR1Body;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}/reopenR1';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerGetCandidateR2Pdf(
    params: {
      /**  */
      r2Id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/pdfR2/{R2Id}/';
      url = url.replace('{R2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static r2InterviewsControllerCloseCandidateR2ByTt(
    params: {
      /**  */
      r2Id: string;
      /** requestBody */
      body?: CloseInterviewByTTBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/r2-interviews/{r2Id}/closeByTTRefusal';
      url = url.replace('{r2Id}', params['r2Id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InterviewsService {
  /**
   *
   */
  static interviewsControllerGetInterviewsSummary(
    params: {
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateInterviewsSummary> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/interviews/candidate/{candidateId}/status-ids';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static interviewsControllerGetInterviews(
    params: {
      /** requestBody */
      body?: GetInterviewsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateInterviewsSummary[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/interviews/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static interviewsControllerGetInterviewsStats(
    params: {
      /** requestBody */
      body?: GetInterviewsStatsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InterviewsStatistics> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/interviews/statistics';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static interviewsControllerGetOpenInterviewsStats(
    params: {
      /** requestBody */
      body?: GetInterviewsStatsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OpenInterviewsStats> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/interviews/statistics/opencount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static interviewsControllerGetInterviewsStatsPerAgency(
    params: {
      /** requestBody */
      body?: GetInterviewsStatsPerAgencyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InterviewsStatsPerAgency[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/interviews/statistcs/agencies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MissionsService {
  /**
   *
   */
  static missionsControllerGetMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionWithoutSchedule> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerGetMissionsWithoutSchedule(
    params: {
      /** requestBody */
      body?: SearchMissionsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionWithoutSchedule[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerAddCandidates(
    params: {
      /** requestBody */
      body?: AssociateMissionsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/addCandidates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EstablishmentsService {
  /**
   *
   */
  static establishmentsControllerGetConsultantEstablishments(
    options: IRequestOptions = {}
  ): Promise<Establishments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/establishments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerGetExportData(
    params: {
      /** requestBody */
      body?: GetInterviewsExportParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActionsService {
  /**
   *
   */
  static actionsControllerGetActionById(
    params: {
      /**  */
      actionId: string;
      /**  */
      actionsIds: any | null[];
      /**  */
      brandCode: string;
      /**  */
      r1InterviewId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Action> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{actionId}';
      url = url.replace('{actionId}', params['actionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        actionsIds: params['actionsIds'],
        brandCode: params['brandCode'],
        r1InterviewId: params['r1InterviewId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actionsControllerGetActionsByIds(
    params: {
      /**  */
      actionsIds: any | null[];
      /**  */
      brandCode: string;
      /**  */
      r1InterviewId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Action[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        actionsIds: params['actionsIds'],
        brandCode: params['brandCode'],
        r1InterviewId: params['r1InterviewId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actionsControllerPostCreateAction(
    params: {
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Action[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static actionsControllerPostCancelAction(
    params: {
      /** requestBody */
      body?: UpdateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SmsService {
  /**
   *
   */
  static smsControllerSendSms(
    params: {
      /** requestBody */
      body?: SendSMSBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ResumeGeneratorService {
  /**
   *
   */
  static resumeGeneratorControllerGetCandidateResume(
    params: {
      /**  */
      candidateId: string;
      /** requestBody */
      body?: CandidateResumeBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resume-generator/candidateResume/{candidateId}';
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface Result {
  /**  */
  phone_number?: string;

  /**  */
  street?: string;

  /**  */
  city?: string;

  /**  */
  zip_code?: string;

  /**  */
  last_name?: string;

  /**  */
  first_name?: string;

  /**  */
  email?: string;

  /**  */
  languages_level?: string[];

  /**  */
  languages?: string[];

  /**  */
  skills?: string[];

  /**  */
  address?: string;

  /**  */
  propertyNumber?: string;

  /**  */
  education_end_date?: string[];

  /**  */
  education_degree?: string[];

  /**  */
  education_class?: string[];

  /**  */
  education_institute?: string[];

  /**  */
  education_location?: string[];

  /**  */
  education_start_date?: string[];

  /**  */
  driving_licence?: boolean;

  /**  */
  experience_job?: string[];

  /**  */
  experience_company?: string[];

  /**  */
  experience_end_date?: string[];

  /**  */
  experience_location?: string[];

  /**  */
  experience_start_date?: string[];
}

export interface Content {
  /**  */
  snapshots: object[];

  /**  */
  result: Result;
}

export interface JSONData {
  /**  */
  content: Content;

  /**  */
  fileName: string;

  /**  */
  clientId: string;
}

export interface DiplomaDto {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  date?: Date;

  /**  */
  obtained?: boolean;

  /**  */
  main?: boolean;
}

export interface LanguageDto {
  /**  */
  level: LanguageLevel;

  /**  */
  id: string;

  /**  */
  label: string;
}

export interface DrivingLicenceDto {
  /**  */
  id: string;

  /**  */
  obtentionDate?: Date;

  /**  */
  validityEndDate?: Date;
}

export interface QualificationDto {
  /**  */
  id: string;

  /**  */
  main?: boolean;
}

export interface SoftSkillDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface HardSkillDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CreateCandidateBody {
  /**  */
  gender: string;

  /**  */
  name: string;

  /**  */
  firstName: string;

  /**  */
  phone: string;

  /**  */
  email: string;

  /**  */
  address: string;

  /**  */
  inseeCode: string;

  /**  */
  zipCode: string;

  /**  */
  city?: string;

  /**  */
  qualificationId: string;

  /**  */
  contractAccepted: string[];

  /**  */
  memo?: string;

  /**  */
  socialSecurityId?: string;

  /**  */
  socialSecurityKey?: string;

  /**  */
  birthCity?: string;

  /**  */
  birthDepartment?: string;

  /**  */
  birthCountry?: string;

  /**  */
  birthName?: string;

  /**  */
  birthDate?: Date;

  /**  */
  agencyId?: string;

  /**  */
  origin?: string;

  /**  */
  country?: string;

  /**  */
  nationality?: string;

  /**  */
  habilitationIds?: string[];

  /**  */
  diplomas?: DiplomaDto[];

  /**  */
  languages?: LanguageDto[];

  /**  */
  drivingLicences?: DrivingLicenceDto[];

  /**  */
  domainIds?: string[];

  /**  */
  qualifications?: QualificationDto[];

  /**  */
  softSkills?: SoftSkillDto[];

  /**  */
  hardSkills?: HardSkillDto[];
}

export interface OSMCandidateModelCandidateDtoMainAgencyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMCandidateModelCandidateDtoAssesmentDto {
  /**  */
  date?: string;

  /**  */
  author?: string;

  /**  */
  comment?: string;
}

export interface OSMCommonModelDateDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMRepositoriesModelQualificationRepositoryDtoQualificationBusinessIdDto {
  /**  */
  businessId?: string;

  /**  */
  id?: string;

  /**  */
  jobCenterBusinessCode?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  professionAndSocioProfessionalCategoryId?: string;
}

export interface Candidate {
  /**  */
  gender: string;

  /**  */
  name: string;

  /**  */
  firstName: string;

  /**  */
  phone1: string;

  /**  */
  phone2: string;

  /**  */
  email: string;

  /**  */
  address: string;

  /**  */
  address0?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  inseeCode: string;

  /**  */
  zipCode: string;

  /**  */
  city?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  qualificationId: string;

  /**  */
  contractAccepted: string[];

  /**  */
  nbHoursWorked: number;

  /**  */
  nbMissions: number;

  /**  */
  nbCompanies: number;

  /**  */
  skillUri: string;

  /**  */
  levelOfStudy: string;

  /**  */
  agenciesUri: string;

  /**  */
  mainAgency?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  statusCode: string;

  /**  */
  flagAcceptSMSMission: boolean;

  /**  */
  flagAcceptNotifMission?: boolean;

  /**  */
  flagAcceptEmailMission?: boolean;

  /**  */
  dateAcceptationCguAppli?: Date;

  /**  */
  candidateType?: string;

  /**  */
  assesment?: OSMCandidateModelCandidateDtoAssesmentDto;

  /**  */
  flagAcceptSMS?: boolean;

  /**  */
  facebookApplication?: boolean;

  /**  */
  smartphoneApplication?: boolean;

  /**  */
  conveyed?: boolean;

  /**  */
  lastMissionAgencyId?: string;

  /**  */
  countryId?: string;

  /**  */
  isBorderWorker?: boolean;

  /**  */
  domicilePaySendingFlag?: boolean;

  /**  */
  dependantsNumber?: number;

  /**  */
  birthName?: string;

  /**  */
  temporaryNumber?: string;

  /**  */
  disabilityWorkerConsentStatus?: string;

  /**  */
  mobility?: string[];

  /**  */
  availabilityForConsultantDate?: OSMCommonModelDateDto;

  /**  */
  newGraduate?: boolean;

  /**  */
  registrationDate?: OSMCommonModelDateDto;

  /**  */
  creator?: string;

  /**  */
  id: string;

  /**  */
  qualification?: OSMRepositoriesModelQualificationRepositoryDtoQualificationBusinessIdDto;
}

export interface FavoritesDeserializationBody {
  /**  */
  ids: string[];
}

export interface OSMCandidateModelCandidateDtoBirthDepartmentDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelCandidateDtoBirthCountryDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelCandidateDtoNationalityDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateBirthInfo {
  /**  */
  socialSecurityId?: string;

  /**  */
  socialSecurityKey?: string;

  /**  */
  birthDate?: OSMCommonModelDateDto;

  /**  */
  birthCity?: string;

  /**  */
  birthDepartment?: OSMCandidateModelCandidateDtoBirthDepartmentDto;

  /**  */
  birthCountry?: OSMCandidateModelCandidateDtoBirthCountryDto;

  /**  */
  nationality?: OSMCandidateModelCandidateDtoNationalityDto;
}

export interface CandidateDocument {
  /**  */
  id?: string;

  /**  */
  title?: string;

  /**  */
  type?: string;

  /**  */
  attachmentDate?: Date;
}

export interface OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMContratModelDtoEndorsementModalityTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMContratModelDtoEndorsementForCandidateDto {
  /**  */
  id?: string;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  clientValidationDate?: OSMCommonModelDateDto;

  /**  */
  status?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  modalityType?: OSMContratModelDtoEndorsementModalityTypeDto;
}

export interface CandidateContract {
  /**  */
  companyName?: string;

  /**  */
  missionId?: string;

  /**  */
  contractId?: string;

  /**  */
  endorsements?: OSMContratModelDtoEndorsementForCandidateDto[];

  /**  */
  statusCode?: string;

  /**  */
  statusLabel?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  missionStartDate?: Date;

  /**  */
  missionActualEndDate?: Date;
}

export interface OSMRepositoriesModelSkillRepositoryDtoSkillDto {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface OSMRepositoriesModelDomainRepositoryDtoDomainDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface LanguageEntity {
  /**  */
  level: LanguageLevel;

  /**  */
  id: string;

  /**  */
  label: string;
}

export interface DiplomasEntity {
  /**  */
  obtained?: boolean;

  /**  */
  main?: boolean;

  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  date?: Date;
}

export interface DrivingLicenceEntity {
  /**  */
  label?: string;

  /**  */
  id: string;

  /**  */
  obtentionDate?: Date;

  /**  */
  validityEndDate?: Date;
}

export interface QualificationEntity {
  /**  */
  businessId?: string;

  /**  */
  jobCenterBusinessCode?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  professionAndSocioProfessionalCategoryId?: string;

  /**  */
  id: string;

  /**  */
  main?: boolean;
}

export interface Job {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  contractCount: number;

  /**  */
  companyCount: number;

  /**  */
  qualifications: string[];
}

export interface CandidateSkillsEntity {
  /**  */
  businessSkills?: OSMRepositoriesModelSkillRepositoryDtoSkillDto[];

  /**  */
  habilitations?: OSMRepositoriesModelSkillRepositoryDtoSkillDto[];

  /**  */
  domains?: OSMRepositoriesModelDomainRepositoryDtoDomainDto[];

  /**  */
  languages?: LanguageEntity[];

  /**  */
  diplomas?: DiplomasEntity[];

  /**  */
  drivingLicences?: DrivingLicenceEntity[];

  /**  */
  qualifications?: QualificationEntity[];

  /**  */
  jobs?: Job[];
}

export interface CandidateSkillsDto {
  /**  */
  habilitationIds?: string[];

  /**  */
  diplomas?: DiplomaDto[];

  /**  */
  languages?: LanguageDto[];

  /**  */
  drivingLicences?: DrivingLicenceDto[];

  /**  */
  domainIds?: string[];

  /**  */
  qualifications?: QualificationDto[];
}

export interface OSMCandidateModelCandidateTestDtoCandidateTestTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelCandidateTestDtoCandidateTestGoalDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateTests {
  /**  */
  type?: OSMCandidateModelCandidateTestDtoCandidateTestTypeDto;

  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  goal?: OSMCandidateModelCandidateTestDtoCandidateTestGoalDto;

  /**  */
  agencyId?: string;

  /**  */
  completionRate?: string;

  /**  */
  completedOn?: OSMCommonModelDateDto;
}

export interface OSMCandidateModelETestingTestsDtoGoalDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateETests {
  /**  */
  testType?: string;

  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  goal?: OSMCandidateModelETestingTestsDtoGoalDto;

  /**  */
  agencyId?: string;

  /**  */
  completionRate?: string;

  /**  */
  completedOn?: OSMCommonModelDateDto;
}

export interface CompanyInformations {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CandidateContractInformations {
  /**  */
  nbContracts?: number;

  /**  */
  nbPaidHours?: number;

  /**  */
  firstContractStartDate?: Date;

  /**  */
  companies?: CompanyInformations[];
}

export interface OSMCandidateModelInternalExperiencesDtoInternalExperienceServiceDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelInternalExperiencesDtoInternalExperienceQualificationDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelInternalExperiencesDtoInternalExperienceSalaryDto {
  /**  */
  endorsementId?: string;

  /**  */
  salary?: number;
}

export interface OSMCandidateModelInternalExperiencesDtoInternalExperienceContractTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelInternalExperiencesDtoInternalExperienceEndReasonDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CandidateExperience {
  /**  */
  contractId?: string;

  /**  */
  service?: OSMCandidateModelInternalExperiencesDtoInternalExperienceServiceDto;

  /**  */
  qualification?: OSMCandidateModelInternalExperiencesDtoInternalExperienceQualificationDto;

  /**  */
  salaries?: OSMCandidateModelInternalExperiencesDtoInternalExperienceSalaryDto[];

  /**  */
  contractType?: OSMCandidateModelInternalExperiencesDtoInternalExperienceContractTypeDto;

  /**  */
  endReason?: OSMCandidateModelInternalExperiencesDtoInternalExperienceEndReasonDto;

  /**  */
  companyName?: string;

  /**  */
  companyId?: string;

  /**  */
  duration?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  isInternalExperience?: boolean;
}

export interface SortedCandidateExperiences {
  /**  */
  internalExperiences: CandidateExperience[];

  /**  */
  externalExperiences: CandidateExperience[];
}

export interface CandidateExperienceWithCompany {
  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  experiencesByCompany: SortedCandidateExperiences;
}

export interface CandidateAllExperiencesByCompany {
  /**  */
  experiences: CandidateExperienceWithCompany[];
}

export interface CandidateExternalExperience {
  /**  */
  contractTypeId: ContractTypeIdEnum;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  companyName: string;

  /**  */
  qualificationId: string;
}

export interface CandidateExperienceBody {
  /**  */
  experiences?: CandidateExternalExperience[];
}

export interface CandidateAgencyDto {
  /**  */
  main: boolean;

  /**  */
  agencyUri: string;

  /**  */
  agencyId?: string;

  /**  */
  agencyName?: string;

  /**  */
  inscriptionDate?: Date;

  /**  */
  lastContactDate?: Date;

  /**  */
  cdiAgency?: boolean;
}

export interface CandidateDiplomaDto {
  /**  */
  diplomaId: string;

  /**  */
  formationName: string;

  /**  */
  obtentionDate?: Date;

  /**  */
  validityDate?: Date;
}

export interface BusinessSkills {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  typeCode?: string;

  /**  */
  typeCodeLabel: string;
}

export interface OSMRepositoriesModelQualificationsMetiersRepositoryDtoCompetencesDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface QualificationBusinessSkills {
  /**  */
  qualificationId?: string;

  /**  */
  businessId?: string;

  /**  */
  shortLabel?: string;

  /**  */
  label?: string;

  /**  */
  businessSkills?: OSMRepositoriesModelQualificationsMetiersRepositoryDtoCompetencesDto[];

  /**  */
  main?: boolean;
}

export interface CandidateDetailedBusiness {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  candidateBusinessesSkills?: BusinessSkills[];

  /**  */
  candidateQualifications?: QualificationBusinessSkills[];
}

export interface CandidateSkillDetailsBody {
  /**  */
  qualificationId: string;
}

export interface OSMCandidateModelBusinessSkillsDetailsDtoSectorsDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCandidateModelBusinessSkillsDetailsDtoBusinessSkillsDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface SkillDetailsResponse {
  /**  */
  nbContracts?: number;

  /**  */
  nbCompanies?: number;

  /**  */
  sectors?: OSMCandidateModelBusinessSkillsDetailsDtoSectorsDto[];

  /**  */
  businessSkills?: OSMCandidateModelBusinessSkillsDetailsDtoBusinessSkillsDto[];

  /**  */
  otherSkills?: string;
}

export interface QualificationMatchFromKeyword {
  /**  */
  keyword: string;

  /**  */
  matchingQualifications: QualificationBusinessSkills[];
}

export interface Business {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  isActive?: boolean;
}

export interface City {
  /**  */
  uId?: string;

  /**  */
  insee?: string;

  /**  */
  zipCode?: string;

  /**  */
  name?: string;
}

export interface SearchCompaniesBody {
  /**  */
  nameOrSiretContains: string;

  /**  */
  agencies?: string[];

  /**  */
  perimeter?: EnumSearchCompaniesBodyPerimeter;

  /**  */
  isActive?: boolean;
}

export interface OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface OSMCompanyModelSearchDtoVisitDto {
  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  endDate?: OSMCommonModelDateDto;
}

export interface OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface CompanySearchResult {
  /**  */
  societyStatus?: string;

  /**  */
  solvency?: SolvencyEnum;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  lastVisit?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  hasFrameworkAgreement?: boolean;

  /**  */
  delegationPotential?: number;

  /**  */
  externalCode?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  timeTrackingTool?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;
}

export interface CompanyService {
  /**  */
  active: boolean;

  /**  */
  serviceId?: string;

  /**  */
  name?: string;
}

export interface OSMServiceModelDtoServiceBasicDto {
  /**  */
  serviceId?: string;

  /**  */
  name?: string;
}

export interface OSMCompanyModelCommonDtoCandidateQualificationBusinessDto {
  /**  */
  businessId?: string;

  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;
}

export interface CompanyWorkTeam {
  /**  */
  workTeamId?: string;

  /**  */
  positionCode?: string;

  /**  */
  positionTitle?: string;

  /**  */
  serviceId?: string;

  /**  */
  service?: OSMServiceModelDtoServiceBasicDto;

  /**  */
  qualification?: OSMCompanyModelCommonDtoCandidateQualificationBusinessDto;

  /**  */
  description?: string;

  /**  */
  isActive?: boolean;

  /**  */
  candidatesUri?: string;

  /**  */
  agencyId?: string;
}

export interface OSMConsultantModelDtoBrandDto {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface OSMConsultantModelDtoSpecialityDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMConsultantModelDtoConsultantAgencyByCompany {
  /**  */
  societyId?: string;

  /**  */
  brand?: OSMConsultantModelDtoBrandDto;

  /**  */
  brandCodeApiHeader?: string;

  /**  */
  agencies?: string[];

  /**  */
  specialities?: OSMConsultantModelDtoSpecialityDto[];
}

export interface ConsultantPerimeter {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  branchId?: string;

  /**  */
  regionId?: string;

  /**  */
  zoneId?: string;

  /**  */
  defaultAgencyId?: string;

  /**  */
  defaultCompanyId?: string;

  /**  */
  companies?: OSMConsultantModelDtoConsultantAgencyByCompany[];
}

export interface Consultant {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;
}

export interface Diploma {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface DiplomaWithLabel {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  parsedDocumentLabel: string;
}

export interface OSMDocumentModelDtoDocumentDto {
  /**  */
  id?: string;

  /**  */
  title?: string;

  /**  */
  type?: string;

  /**  */
  file?: string;

  /**  */
  attachmentDate?: OSMCommonModelDateDto;
}

export interface Document {
  /**  */
  document?: OSMDocumentModelDtoDocumentDto;
}

export interface DrivingLicence {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface Habilitation {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface CACESCategory {
  /**  */
  categoryLabel: string;

  /**  */
  categoryId: string;

  /**  */
  caces: Habilitation[];
}

export interface Current {
  /**  */
  electricHabilitation: Habilitation;

  /**  */
  key: string;
}

export interface ElectricHabilitationsCategory {
  /**  */
  categoryLabel: string;

  /**  */
  categoryId: string;

  /**  */
  currents: Current[];
}

export interface ElectricVoltage {
  /**  */
  currentId: string;

  /**  */
  value: string;

  /**  */
  levels: ElectricHabilitationsCategory[];
}

export interface Language {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  isTopLanguage: boolean;
}

export interface Skill {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface SkillWithLabel {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  parsedDocumentLabel: string;
}

export interface MatchingCriteriaLocation {
  /**  */
  zipCode: string;

  /**  */
  radius: number;
}

export interface MatchingCriteriaItem {
  /**  */
  wishPriority: WishPriority;

  /**  */
  identifier: string;
}

export interface MatchingCriteriaAgency {
  /**  */
  wishPriority: WishPriority;

  /**  */
  agencies: string[];
}

export interface MatchingCriteria {
  /**  */
  candidateStatuses?: CandidateStatuses[];

  /**  */
  availabilityDate?: Date;

  /**  */
  missionStartDate?: Date;

  /**  */
  missionEndDate?: Date;

  /**  */
  location?: MatchingCriteriaLocation;

  /**  */
  diplomas?: MatchingCriteriaItem[];

  /**  */
  languages?: MatchingCriteriaItem[];

  /**  */
  habilitations?: MatchingCriteriaItem[];

  /**  */
  skills?: MatchingCriteriaItem[];

  /**  */
  qualifications?: MatchingCriteriaItem[];

  /**  */
  service?: MatchingCriteriaItem;

  /**  */
  company?: MatchingCriteriaItem;

  /**  */
  potentialPositions?: string[];

  /**  */
  agencies?: MatchingCriteriaAgency;

  /**  */
  keywords?: MatchingCriteriaItem[];

  /**  */
  drivingLicences?: MatchingCriteriaItem[];

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;
}

export interface MatchingResult {
  /**  */
  candidateStatus?: string;

  /**  */
  nbCompany?: number;

  /**  */
  nbMissions?: number;

  /**  */
  nbHours?: number;

  /**  */
  scoring?: number;

  /**  */
  cvUri?: string;

  /**  */
  origin?: string;

  /**  */
  status?: string;

  /**  */
  availabilityDate?: Date;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  civility?: string;

  /**  */
  idProfil?: string;

  /**  */
  candidateId?: string;

  /**  */
  zipCode?: string;

  /**  */
  city?: string;

  /**  */
  mainQualification?: OSMRepositoriesModelQualificationRepositoryDtoQualificationBusinessIdDto;

  /**  */
  qualificationAsked?: OSMRepositoriesModelQualificationRepositoryDtoQualificationBusinessIdDto;
}

export interface MatchingCriteriaItemWithLabel {
  /**  */
  wishPriority: WishPriority;

  /**  */
  identifier: string;

  /**  */
  label?: string;
}

export interface MatchingCriteriaLocationWithLabel {
  /**  */
  zipCode: string;

  /**  */
  radius: number;

  /**  */
  label?: string;
}

export interface MatchingCriteriaLanguageWithLabel {
  /**  */
  wishPriority: WishPriority;

  /**  */
  identifier: string;

  /**  */
  label?: string;

  /**  */
  isTopLanguage?: boolean;
}

export interface MatchingCriteriaItemWithLabelWithoutWeight {
  /**  */
  identifier: string;

  /**  */
  label?: string;
}

export interface Criteria {
  /**  */
  candidateStatuses: CandidateStatuses[];

  /**  */
  availabilityDate?: Date;

  /**  */
  qualifications: MatchingCriteriaItemWithLabel[];

  /**  */
  skills: MatchingCriteriaItemWithLabel[];

  /**  */
  location?: MatchingCriteriaLocationWithLabel;

  /**  */
  diplomas: MatchingCriteriaItemWithLabel[];

  /**  */
  drivingLicences: MatchingCriteriaItemWithLabel[];

  /**  */
  languages: MatchingCriteriaItemWithLabel[];

  /**  */
  habilitations: MatchingCriteriaItemWithLabel[];

  /**  */
  caces: MatchingCriteriaLanguageWithLabel[];

  /**  */
  company?: MatchingCriteriaItemWithLabel;

  /**  */
  service?: MatchingCriteriaItemWithLabel;

  /**  */
  potentialPositions: MatchingCriteriaItemWithLabelWithoutWeight[];

  /**  */
  keywords: MatchingCriteriaItemWithLabel[];
}

export interface CreateCandidateR1Body {
  /**  */
  agencyName: string;
}

export interface MaxMobility {
  /**  */
  maxMobilityDistance?: number;

  /**  */
  maxMobilityTime?: number;
}

export interface IdLabel {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface CandidateR1Projections {
  /**  */
  mobility?: IdLabel[];

  /**  */
  formation?: IdLabel[];

  /**  */
  other?: string;

  /**  */
  evolution?: IdLabel[];
}

export interface HoursPriority {
  /**  */
  hours: R1Hours;

  /**  */
  priority: R1Priority;
}

export interface InterviewsSectorsPriority {
  /**  */
  sector: InterviewsSectors;

  /**  */
  priority: R1Priority;
}

export interface R1AssessmentResponse {
  /**  */
  id: number;

  /**  */
  label: string;

  /**  */
  value?: number;
}

export interface R1Assessment {
  /**  */
  responses: R1AssessmentResponse[];
}

export interface TextInterviewsExchangeResponse {
  /**  */
  id: number;

  /**  */
  label: string;

  /**  */
  value?: string;
}

export interface InterviewsExchange {
  /**  */
  responses: TextInterviewsExchangeResponse[];
}

export interface CandidateR1ProfessionalExchange {
  /**  */
  importantNeeds?: string[];

  /**  */
  experienceExchange?: InterviewsExchange;
}

export interface KeywordListInterviewsExchangeResponse {
  /**  */
  id: number;

  /**  */
  label: string;

  /**  */
  keywords?: string[];
}

export interface KeywordListInterviewsExchange {
  /**  */
  responses: KeywordListInterviewsExchangeResponse[];
}

export interface SelectedJob {
  /**  */
  sectors: InterviewsSectors[];

  /**  */
  id: string;
}

export interface R2PlanningEvent {
  /**  */
  r2PlanningContactChannel?: R2PlanningContactChannel;

  /**  */
  r2PlanningEventStatus?: R2PlanningEventStatus;

  /**  */
  actionId?: string;
}

export interface CandidateR1 {
  /**  */
  status: InterviewStatus;

  /**  */
  supervisorAnswer?: YesNoUnknown;

  /**  */
  R1Id: string;

  /**  */
  r2InterviewId?: string;

  /**  */
  candidateId: string;

  /**  */
  version?: number;

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate?: Date;

  /**  */
  agencyName: string;

  /**  */
  cgcId?: string;

  /**  */
  consultantId: string;

  /**  */
  hasVehicle?: boolean;

  /**  */
  hasNationalMobility?: boolean;

  /**  */
  maxMobility?: MaxMobility;

  /**  */
  candidateAgreement?: boolean;

  /**  */
  workingEnvironment?: string;

  /**  */
  currentSituation?: string;

  /**  */
  projections?: CandidateR1Projections;

  /**  */
  hoursPriorities?: HoursPriority[];

  /**  */
  sectorPriorities?: InterviewsSectorsPriority[];

  /**  */
  assessment?: R1Assessment;

  /**  */
  recruiterComment?: string;

  /**  */
  supervisorComment?: string;

  /**  */
  keyPoints?: string;

  /**  */
  professionalExperienceExchange?: CandidateR1ProfessionalExchange;

  /**  */
  personalStrengthExchange?: KeywordListInterviewsExchange;

  /**  */
  cdiStrengthExchange?: InterviewsExchange;

  /**  */
  choiceDate?: Date;

  /**  */
  plannedGMMRAmount?: number;

  /**  */
  habilitations?: IdLabel[];

  /**  */
  diplomas?: DiplomasEntity[];

  /**  */
  languages?: LanguageDto[];

  /**  */
  jobs?: Job[];

  /**  */
  selectedJobs?: SelectedJob[];

  /**  */
  closedBy?: string;

  /**  */
  mainQualificationId?: string;

  /**  */
  lastYearContractsInfo?: CandidateContractInformations;

  /**  */
  experiences?: CandidateExperience[];

  /**  */
  r2PlanningEvents?: R2PlanningEvent[];
}

export interface UpdateCandidateR1Body {
  /**  */
  supervisorAnswer?: YesNoUnknown;

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate?: Date;

  /**  */
  agencyName: string;

  /**  */
  cgcId?: string;

  /**  */
  hasVehicle?: boolean;

  /**  */
  hasNationalMobility?: boolean;

  /**  */
  candidateAgreement?: boolean;

  /**  */
  workingEnvironment?: string;

  /**  */
  currentSituation?: string;

  /**  */
  maxMobility?: MaxMobility;

  /**  */
  projections?: CandidateR1Projections;

  /**  */
  hoursPriorities?: HoursPriority[];

  /**  */
  sectorPriorities?: InterviewsSectorsPriority[];

  /**  */
  assessment?: R1Assessment;

  /**  */
  recruiterComment?: string;

  /**  */
  supervisorComment?: string;

  /**  */
  keyPoints?: string;

  /**  */
  professionalExperienceExchange?: CandidateR1ProfessionalExchange;

  /**  */
  personalStrengthExchange?: KeywordListInterviewsExchange;

  /**  */
  cdiStrengthExchange?: InterviewsExchange;

  /**  */
  choiceDate?: Date;

  /**  */
  plannedGMMRAmount?: number;

  /**  */
  selectedJobs?: SelectedJob[];

  /**  */
  r2PlanningEvents?: R2PlanningEvent[];
}

export interface CloseCandidateR1Body {
  /**  */
  supervisorAnswer?: YesNoUnknown;

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate?: Date;

  /**  */
  agencyName: string;

  /**  */
  cgcId?: string;

  /**  */
  hasVehicle?: boolean;

  /**  */
  hasNationalMobility?: boolean;

  /**  */
  candidateAgreement?: boolean;

  /**  */
  workingEnvironment?: string;

  /**  */
  currentSituation?: string;

  /**  */
  maxMobility?: MaxMobility;

  /**  */
  projections?: CandidateR1Projections;

  /**  */
  hoursPriorities?: HoursPriority[];

  /**  */
  sectorPriorities?: InterviewsSectorsPriority[];

  /**  */
  assessment?: R1Assessment;

  /**  */
  recruiterComment?: string;

  /**  */
  supervisorComment?: string;

  /**  */
  keyPoints?: string;

  /**  */
  professionalExperienceExchange?: CandidateR1ProfessionalExchange;

  /**  */
  personalStrengthExchange?: KeywordListInterviewsExchange;

  /**  */
  cdiStrengthExchange?: InterviewsExchange;

  /**  */
  choiceDate?: Date;

  /**  */
  plannedGMMRAmount?: number;

  /**  */
  selectedJobs?: SelectedJob[];

  /**  */
  r2PlanningEvents?: R2PlanningEvent[];

  /**  */
  habilitations?: IdLabel[];

  /**  */
  diplomas?: DiplomasEntity[];

  /**  */
  languages?: LanguageDto[];

  /**  */
  jobs?: Job[];

  /**  */
  brandCode?: string;

  /**  */
  mainQualificationId?: string;

  /**  */
  lastYearContractsInfo?: CandidateContractInformations;

  /**  */
  experiences?: CandidateExperience[];
}

export interface DeleteR1Body {
  /**  */
  id?: string;
}

export interface ReopenR1Body {
  /**  */
  comment: string;
}

export interface CloseInterviewByTTBody {
  /**  */
  comment: string;

  /**  */
  brandCode?: string;
}

export interface R1AssessmentQuestion {
  /**  */
  id: number;

  /**  */
  label: string;
}

export interface InterviewsExchangeQuestion {
  /**  */
  questionType?: AnswerTypeEnum;

  /**  */
  placeHolder?: string;

  /**  */
  label: string;

  /**  */
  id: number;
}

export interface CGC {
  /**  */
  id?: string;

  /**  */
  societyId?: string;

  /**  */
  brandCode?: string;

  /**  */
  label?: string;

  /**  */
  isOpen?: boolean;
}

export interface OSMRepositoriesModelSmicRepositoryDtoSmicDto {
  /**  */
  hourlyAmount?: number;

  /**  */
  monthlyAmount?: number;

  /**  */
  annualAmount?: number;
}

export interface SMIC {
  /**  */
  smic: OSMRepositoriesModelSmicRepositoryDtoSmicDto;
}

export interface Qualification {
  /**  */
  businessId?: string;

  /**  */
  id?: string;

  /**  */
  jobCenterBusinessCode?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  professionAndSocioProfessionalCategoryId?: string;
}

export interface Department {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface Country {
  /**  */
  id?: number;

  /**  */
  label?: string;

  /**  */
  inseeCode?: string;

  /**  */
  alpha2Id?: string;

  /**  */
  alpha3Id?: string;
}

export interface Nationality {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  alpha2Id?: string;
}

export interface GetCDIGoalsParams {
  /**  */
  agencyIds: string[];
}

export interface CDIGoals {
  /**  */
  societyId: string;

  /**  */
  brandId: string;

  /**  */
  regionId: string;

  /**  */
  zoneId: string;

  /**  */
  agencyId: string;

  /**  */
  agencyName: string;

  /**  */
  cdiGoals?: string;
}

export interface GetSignedCDICount {
  /**  */
  agencyIds?: string[];

  /**  */
  startDate?: Date;
}

export interface R1Data {
  /**  */
  gmmr?: number;

  /**  */
  interviewDate?: Date;
}

export interface CandidateR2Projections {
  /**  */
  evolution?: IdLabel[];

  /**  */
  formation?: IdLabel[];

  /**  */
  otherProjections?: InterviewsExchange;
}

export interface InterviewsValidationResponse {
  /**  */
  id: number;

  /**  */
  label: string;

  /**  */
  value: boolean;
}

export interface CDIValidationElements {
  /**  */
  comment?: string;

  /**  */
  responses: InterviewsValidationResponse[];
}

export interface R2Tests {
  /**  */
  hasTests: boolean;

  /**  */
  comment?: string;
}

export interface ProfessionalProjections {
  /**  */
  scoring?: ScoringEnum;

  /**  */
  projections?: string;

  /**  */
  comment?: string;
}

export interface Validation {
  /**  */
  validation?: YesNoChoicesEnum;

  /**  */
  comment?: string;

  /**  */
  gmmr?: number;

  /**  */
  isCDI?: boolean;
}

export interface CandidateR2 {
  /**  */
  status: InterviewStatus;

  /**  */
  R2Id?: string;

  /**  */
  r1InterviewId: string;

  /**  */
  r1Data: R1Data;

  /**  */
  candidateId: string;

  /**  */
  cgcId: string;

  /**  */
  version?: number;

  /**  */
  consultantId: string;

  /**  */
  agencyId: string;

  /**  */
  hasVehicle: boolean;

  /**  */
  maxMobility: MaxMobility;

  /**  */
  hoursPriorities: HoursPriority[];

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate: Date;

  /**  */
  exchanges?: InterviewsExchange;

  /**  */
  projections?: CandidateR2Projections;

  /**  */
  validationElements?: CDIValidationElements;

  /**  */
  isEligibleToFreeJobs?: boolean;

  /**  */
  tests?: R2Tests;

  /**  */
  professionalProjections?: ProfessionalProjections;

  /**  */
  validation?: Validation;

  /**  */
  jobs?: Job[];

  /**  */
  selectedJobIds?: string[];

  /**  */
  mainQualificationId?: string;

  /**  */
  eTests?: CandidateETests[];

  /**  */
  exemptionComment?: string;

  /**  */
  exemptionMakerName?: string;
}

export interface UpdateCandidateR2Body {
  /**  */
  exchanges?: InterviewsExchange;

  /**  */
  projections?: CandidateR2Projections;

  /**  */
  evolution?: IdLabel[];

  /**  */
  validation?: Validation;

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate: Date;

  /**  */
  isEligibleToFreeJobs?: boolean;

  /**  */
  validationElements?: CDIValidationElements;

  /**  */
  tests?: R2Tests;

  /**  */
  professionalProjections?: ProfessionalProjections;

  /**  */
  hoursPriorities?: HoursPriority[];

  /**  */
  maxMobility?: MaxMobility;

  /**  */
  brandCode?: string;

  /**  */
  responses?: Job[];

  /**  */
  selectedJobIds?: string[];

  /**  */
  mainQualificationId?: string;
}

export interface DeleteR2Body {
  /**  */
  id?: string;
}

export interface CloseCandidateR2Body {
  /**  */
  exchanges?: InterviewsExchange;

  /**  */
  projections?: CandidateR2Projections;

  /**  */
  evolution?: IdLabel[];

  /**  */
  validation?: Validation;

  /**  */
  interviewDate?: Date;

  /**  */
  hiringDate: Date;

  /**  */
  isEligibleToFreeJobs?: boolean;

  /**  */
  validationElements?: CDIValidationElements;

  /**  */
  tests?: R2Tests;

  /**  */
  professionalProjections?: ProfessionalProjections;

  /**  */
  hoursPriorities?: HoursPriority[];

  /**  */
  maxMobility?: MaxMobility;

  /**  */
  brandCode?: string;

  /**  */
  responses?: Job[];

  /**  */
  selectedJobIds?: string[];

  /**  */
  mainQualificationId?: string;

  /**  */
  eTests?: CandidateETests[];

  /**  */
  closedBy?: string;

  /**  */
  exemptionComment?: string;

  /**  */
  exemptionMakerName?: string;
}

export interface InterviewSummary {
  /**  */
  interviewId?: string;

  /**  */
  hiringDate?: Date;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;
}

export interface InterviewCandidate {
  /**  */
  id: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface CandidateInterviewsSummary {
  /**  */
  status: CandidateInterviewsSummaryStatus;

  /**  */
  agencyId: string;

  /**  */
  cgcId?: string;

  /**  */
  r1Interview?: InterviewSummary;

  /**  */
  r2Interview?: InterviewSummary;

  /**  */
  candidate?: InterviewCandidate;

  /**  */
  isCDI?: boolean;
}

export interface GetInterviewsParams {
  /**  */
  statuses?: CandidateInterviewsSummaryStatus[];

  /**  */
  agencyIds: string[];

  /**  */
  startDate: Date;

  /**  */
  isEligibleToFreeJobs?: boolean;

  /**  */
  hideCDICandidates?: boolean;

  /**  */
  brandCode?: string;
}

export interface GetInterviewsStatsParams {
  /**  */
  startDate: Date;

  /**  */
  agencyIds: string[];
}

export interface R1InterviewTypeStatistics {
  /**  */
  status: R1StatusForStats;

  /**  */
  count: number;
}

export interface R2InterviewTypeStatistics {
  /**  */
  status: R2StatusForStats;

  /**  */
  count: number;
}

export interface InterviewsStatistics {
  /**  */
  r1Interviews: R1InterviewTypeStatistics[];

  /**  */
  r2Interviews: R2InterviewTypeStatistics[];
}

export interface OpenInterviewsStats {
  /**  */
  openR1Count: number;

  /**  */
  openR2Count: number;
}

export interface GetInterviewsStatsPerAgencyParams {
  /**  */
  agencyIds: string[];

  /**  */
  exportYear?: number;
}

export interface InterviewsStatsPerAgency {
  /**  */
  agencyId: string;

  /**  */
  closedR1WithinMonth: number;

  /**  */
  closedR2WithinMonth: number;

  /**  */
  toDoR1: number;

  /**  */
  toDoR2: number;

  /**  */
  closedR2: number;
}

export interface OSMMissionsWithoutScheduleModelDtoOutputCompanyDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  siret?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoServiceDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoQualificationDto {
  /**  */
  id?: string;

  /**  */
  shortLabel?: string;

  /**  */
  label?: string;
}

export interface OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMMissionsWithoutScheduleModelDtoOutputCandidateDto {
  /**  */
  id?: string;

  /**  */
  candidateAdvancement?: string;

  /**  */
  companyAdvancement?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;
}

export interface OSMCommonModelLocationModelOutputLocationDto {
  /**  */
  address?: string;

  /**  */
  cityName?: string;

  /**  */
  zipCode?: string;

  /**  */
  inseeCode?: string;
}

export interface MissionWithoutSchedule {
  /**  */
  missionId?: string;

  /**  */
  numberPositions?: number;

  /**  */
  company?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  service?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  qualification?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  statutMissionId?: string;

  /**  */
  salesPhase?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  agencyId?: string;

  /**  */
  candidates?: OSMMissionsWithoutScheduleModelDtoOutputCandidateDto[];

  /**  */
  dPAEAgencyId?: string;

  /**  */
  modificationDate?: OSMCommonModelDateDto;

  /**  */
  workLocation?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;
}

export interface SearchMissionsParams {
  /**  */
  qualificationId: string;

  /**  */
  companyId: string;

  /**  */
  agencies: string[];
}

export interface ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCandidateDto {}

export interface AssociateMissionsParams {
  /**  */
  missionIds: string[];

  /**  */
  qualificationId: string;

  /**  */
  candidates: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCandidateDto[];

  /**  */
  brandCode: string;
}

export interface OSMEstablishmentModelDtoAgencyDto {
  /**  */
  agencyId?: string;

  /**  */
  agencyTitle?: string;
}

export interface OSMEstablishmentModelDtoZoneDto {
  /**  */
  zoneId?: string;

  /**  */
  zoneTitle?: string;

  /**  */
  agencies?: OSMEstablishmentModelDtoAgencyDto[];
}

export interface OSMEstablishmentModelDtoServiceDto {
  /**  */
  serviceId?: string;

  /**  */
  serviceTitle?: string;
}

export interface OSMEstablishmentModelDtoBranchDto {
  /**  */
  regionId?: string;

  /**  */
  regionTitle?: string;

  /**  */
  zones?: OSMEstablishmentModelDtoZoneDto[];

  /**  */
  services?: OSMEstablishmentModelDtoServiceDto[];
}

export interface Establishments {
  /**  */
  regions?: OSMEstablishmentModelDtoBranchDto[];
}

export interface GetInterviewsExportParams {
  /**  */
  consultantMail: string;

  /**  */
  startDate: Date;

  /**  */
  agencyIds: string[];
}

export interface OSMActionModelDtoCompanyDto {
  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface OSMActionModelDtoContactDto {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  contactId?: string;
}

export interface OSMActionModelDtoCandidateDto {
  /**  */
  candidateId?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone1?: string;

  /**  */
  phone2?: string;

  /**  */
  email?: string;
}

export interface OSMActionModelDtoActorDto {
  /**  */
  name?: string;

  /**  */
  id?: string;
}

export interface OSMActionModelDtoAttachedDocumentDto {
  /**  */
  attachementDate?: Date;

  /**  */
  attachedBy?: string;

  /**  */
  attachementDocumentId?: string;

  /**  */
  title?: string;

  /**  */
  fileName?: string;

  /**  */
  fileType?: string;

  /**  */
  binaryFile?: string;
}

export interface OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto {
  /**  */
  label?: string;

  /**  */
  isActive?: boolean;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelActionStateRepositoryDtoActionStateDto {
  /**  */
  label?: string;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  isActive?: boolean;
}

export interface OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMRepositoriesModelGoalRepositoryDtoGoalDto {
  /**  */
  label?: string;

  /**  */
  id?: string;
}

export interface OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto {
  /**  */
  familyGoals?: OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto;

  /**  */
  goals?: OSMRepositoriesModelGoalRepositoryDtoGoalDto[];
}

export interface Action {
  /**  */
  actionId?: string;

  /**  */
  company?: OSMActionModelDtoCompanyDto;

  /**  */
  contacts?: OSMActionModelDtoContactDto[];

  /**  */
  candidate?: OSMActionModelDtoCandidateDto;

  /**  */
  actors?: OSMActionModelDtoActorDto[];

  /**  */
  attachedDocuments?: OSMActionModelDtoAttachedDocumentDto[];

  /**  */
  type?: OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto;

  /**  */
  status?: OSMRepositoriesModelActionStateRepositoryDtoActionStateDto;

  /**  */
  target?: OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto;

  /**  */
  startDate?: OSMCommonModelDateDto;

  /**  */
  startTime?: number;

  /**  */
  endDate?: OSMCommonModelDateDto;

  /**  */
  endTime?: number;

  /**  */
  agencies?: string[];

  /**  */
  goalsByFamily?: OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto[];

  /**  */
  report?: string;

  /**  */
  creationDate?: Date;

  /**  */
  syncGoogleCal?: boolean;

  /**  */
  appointment?: boolean;

  /**  */
  seqAdress?: string;

  /**  */
  competitionIdentification?: boolean;

  /**  */
  title?: string;

  /**  */
  isEditable?: boolean;
}

export interface CreateActionBody {
  /**  */
  status?: EnumEntityActionStatus;

  /**  */
  targetId: string;

  /**  */
  targetName?: string;

  /**  */
  report?: string;

  /**  */
  title?: string;

  /**  */
  type: EnumCreateActionBodyType;

  /**  */
  agencyId: string;

  /**  */
  startDate: Date;

  /**  */
  endDate?: Date;

  /**  */
  brandCode: string;

  /**  */
  subType?: string;

  /**  */
  duration?: number;

  /**  */
  syncGoogleCal?: boolean;

  /**  */
  r2AppointmentStartDate?: Date;

  /**  */
  r2AppointmentEndDate?: Date;
}

export interface UpdateActionBody {
  /**  */
  actionId: string;

  /**  */
  brandCode: string;
}

export interface SendSMSBody {
  /**  */
  target: EnumSendSMSBodyTarget;

  /**  */
  message?: string;

  /**  */
  missionId?: string;

  /**  */
  recipientIds?: string[];

  /**  */
  object?: string;

  /**  */
  product: string;

  /**  */
  agencyId?: string;

  /**  */
  recipientType: EnumSendSMSBodyRecipientType;
}

export interface ExperienceData {
  /**  */
  label: string;

  /**  */
  companyName: string;

  /**  */
  qualifications: string[];

  /**  */
  firstDate: string;

  /**  */
  lastDate: string;

  /**  */
  nbContracts: string;

  /**  */
  duration: string;

  /**  */
  isInternalExperience: boolean;
}

export interface CandidateData {
  /**  */
  identity: boolean;

  /**  */
  address: boolean;

  /**  */
  contactInfo: boolean;

  /**  */
  name: boolean;

  /**  */
  availability: boolean;

  /**  */
  other: boolean;

  /**  */
  otherValue: string;
}

export interface CandidateResumeBody {
  /**  */
  qualifications: string[];

  /**  */
  businessesSkills: string[];

  /**  */
  behaviourSkills: string[];

  /**  */
  experiences: ExperienceData[];

  /**  */
  experienceTitle: string;

  /**  */
  diplomas: string[];

  /**  */
  tests: string[];

  /**  */
  habilitations: string[];

  /**  */
  drivingLicences: string[];

  /**  */
  employeeData: CandidateData;

  /**  */
  description: string;

  /**  */
  brandCode: string;

  /**  */
  osmoseTitle: string;

  /**  */
  osmoseUpload: boolean;
}

export enum LanguageLevel {
  'Bilingual' = 'Bilingual',
  'CommonPractice' = 'CommonPractice',
  'OccasionalPractice' = 'OccasionalPractice',
  'None' = 'None',
}

export enum ContractTypeIdEnum {
  'Stage' = 'Stage',
  'CDD' = 'CDD',
  'CDI' = 'CDI',
  'Intérim' = 'Intérim',
}
export enum EnumSearchCompaniesBodyPerimeter {
  'Collaborateur' = 'Collaborateur',
  'Agence' = 'Agence',
  'Zone' = 'Zone',
  'Branche' = 'Branche',
  'Societe' = 'Societe',
  'Partenaire' = 'Partenaire',
  'PartenaireSociete' = 'PartenaireSociete',
}
export enum SolvencyEnum {
  'Green' = 'Green',
  'Orange' = 'Orange',
  'Red' = 'Red',
  'Black' = 'Black',
  'None' = 'None',
}

export enum CandidateStatuses {
  'INT' = 'INT',
  'CAE' = 'CAE',
  'CDI' = 'CDI',
  'CVT' = 'CVT',
  'CAN' = 'CAN',
}

export enum WishPriority {
  'Souhaite' = 'Souhaite',
  'Important' = 'Important',
  'Obligatoire' = 'Obligatoire',
}

export enum InterviewStatus {
  'en cours' = 'en cours',
  'accepté' = 'accepté',
  'refusé' = 'refusé',
  'refusé par intérimaire' = 'refusé par intérimaire',
  'validé par dérogation' = 'validé par dérogation',
}

export enum YesNoUnknown {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}

export enum R1Hours {
  'travail semaine' = 'travail semaine',
  'travail samedi' = 'travail samedi',
  'travail dimanche' = 'travail dimanche',
  'travail weekend' = 'travail weekend',
  'travail en journée' = 'travail en journée',
  'travail de nuit' = 'travail de nuit',
  'travail en équipes continues' = 'travail en équipes continues',
  'travail en équipes discontinues' = 'travail en équipes discontinues',
}

export enum R1Priority {
  'Favorable' = 'Favorable',
  'Defavorable' = 'Defavorable',
  'Neutre' = 'Neutre',
}

export enum InterviewsSectors {
  'automobile' = 'automobile',
  'commerce et service' = 'commerce et service',
  'logistique' = 'logistique',
  'hôtellerie restauration' = 'hôtellerie restauration',
  'agro-alimentaire' = 'agro-alimentaire',
  'BTP' = 'BTP',
  'pharmaceutique' = 'pharmaceutique',
  'autre' = 'autre',
}

export enum R2PlanningContactChannel {
  'contact par téléphone' = 'contact par téléphone',
  'contact par sms' = 'contact par sms',
}

export enum R2PlanningEventStatus {
  'en attente de réponse' = 'en attente de réponse',
  'relance' = 'relance',
  'rendez-vous validé' = 'rendez-vous validé',
  'pas présenté' = 'pas présenté',
}

export enum AnswerTypeEnum {
  'LIST' = 'LIST',
  'TEXT' = 'TEXT',
}

export enum ScoringEnum {
  'High' = 'High',
  'Medium' = 'Medium',
  'Low' = 'Low',
  'Unknown' = 'Unknown',
}

export enum YesNoChoicesEnum {
  'oui' = 'oui',
  'non' = 'non',
}

export enum CandidateInterviewsSummaryStatus {
  'R1 en cours' = 'R1 en cours',
  'R2 en cours' = 'R2 en cours',
  'R2 à faire' = 'R2 à faire',
  'R2 en planification' = 'R2 en planification',
  'R2 planifié' = 'R2 planifié',
  'R2 terminé' = 'R2 terminé',
  'R1 refusé' = 'R1 refusé',
  'R2 refusé' = 'R2 refusé',
  'refusé par intérimaire' = 'refusé par intérimaire',
  'validé par dérogation' = 'validé par dérogation',
}

export enum R1StatusForStats {
  'en cours' = 'en cours',
  'accepté sans r2' = 'accepté sans r2',
  'refusé' = 'refusé',
  'accepté avec un r2' = 'accepté avec un r2',
  'refusé par intérimaire' = 'refusé par intérimaire',
  'validé par dérogation' = 'validé par dérogation',
}

export enum R2StatusForStats {
  'en cours' = 'en cours',
  'accepté' = 'accepté',
  'refusé' = 'refusé',
  'refusé par intérimaire' = 'refusé par intérimaire',
  'validé par dérogation' = 'validé par dérogation',
}

export enum EnumEntityActionStatus {
  'FINISHED' = 'FINISHED',
  'IN_PROGRESS' = 'IN_PROGRESS',
}
export enum EnumCreateActionBodyType {
  'ACTION_CANDIDATE_CALL' = 'ACTION_CANDIDATE_CALL',
  'ACTION_CANDIDATE_SMS' = 'ACTION_CANDIDATE_SMS',
}
export enum EnumSendSMSBodyTarget {
  'CUSTOM' = 'CUSTOM',
  'OUTSIDE' = 'OUTSIDE',
}
export enum EnumSendSMSBodyRecipientType {
  'TT' = 'TT',
  'CLI' = 'CLI',
  'EXTERNAL' = 'EXTERNAL',
}
