import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCDIGoals = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);

  return useQuery(
    [QueryKeys.fetchCDIGoals, agencyIds],
    async () =>
      await RepositoriesService.repositoriesControllerGetCdiGoals({ body: { agencyIds } }),
    {
      refetchOnWindowFocus: false,
      enabled: agencyIds !== undefined && agencyIds.length > 0,
      cacheTime: 1000 * 3600 * 24,
      staleTime: 1000 * 3600 * 24,
    }
  );
};
