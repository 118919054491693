import * as z from 'zod';

import {
  LanguageLevel,
  InterviewsSectors,
  R1Hours,
  R1Priority,
  WishPriority,
} from 'src/Services/API';

export const candidateProfileSchema = z.object({
  languages: z.array(
    z.object({
      identifier: z.string(),
      label: z.string().optional(),
      icon: z.any(),
      level: z.nativeEnum(LanguageLevel).optional(),
      wishPriority: z.nativeEnum(WishPriority).optional(),
      isTopLanguage: z.boolean().optional(),
    })
  ),
  diploma: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      date: z.date().optional(),
      obtained: z.boolean().optional(),
      main: z.boolean().optional(),
    })
  ),
  jobs: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      contractCount: z.number(),
      companyCount: z.number(),
      qualifications: z.array(z.string()),
    })
  ),
  selectedJobs: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      contractCount: z.number(),
      companyCount: z.number(),
      qualifications: z.array(z.string()),
    })
  ),
  otherJobs: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      contractCount: z.number(),
      companyCount: z.number(),
      qualifications: z.array(z.string()),
    })
  ),
  mainQualification: z.string().optional(),
  habilitations: z.array(z.object({ id: z.string(), label: z.string() })),
  hasVehicle: z.boolean(),
  hasNationalMobility: z.boolean(),
  maxMobility: z
    .object({
      maxMobilityDistance: z
        .string()
        .refine(item => {
          return (
            item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= 0)
          );
        }, 'La distance doit être un nombre supérieur à 0')
        .optional(),
      maxMobilityTime: z
        .string()
        .refine(item => {
          return (
            item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= 0)
          );
        }, 'Le temps doit être un nombre supérieur à 0')
        .optional(),
    })
    .optional(),
  candidateAgreement: z.boolean(),
  workingEnvironment: z.string().optional(),
  currentSituation: z.string().optional(),
  hoursPriorities: z.array(
    z.object({
      hours: z.nativeEnum(R1Hours),
      priority: z.nativeEnum(R1Priority),
    })
  ),
  sectors: z.array(
    z.object({
      priority: z.nativeEnum(R1Priority),
      sector: z.nativeEnum(InterviewsSectors),
    })
  ),
});

export type CandidateProfileSchemaType = z.infer<typeof candidateProfileSchema>;
export type KeyOfCandidateProfileSchemaTypeQuestion = keyof Pick<
  CandidateProfileSchemaType,
  'workingEnvironment' | 'currentSituation'
>;
