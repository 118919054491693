import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InterviewsStatsState, STATS_SEARCH_PERIOD } from './Types';

const initialState: InterviewsStatsState = {
  searchPeriod: STATS_SEARCH_PERIOD.THREE_MONTH,
  statsSelectedAgencies: [],
};

export const { reducer: interviewsStatsReducer, actions: interviewsStatsActions } = createSlice({
  name: 'interviewsStats',
  initialState,
  reducers: {
    setStatsSearchPeriod: (state, action: PayloadAction<STATS_SEARCH_PERIOD>) => {
      state.searchPeriod = action.payload;
    },
    setStatsSelectedAgencies: (state, action: PayloadAction<string[]>) => {
      state.statsSelectedAgencies = action.payload;
    },
  },
});
