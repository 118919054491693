import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../RootReducer';

import { MissionsState } from './Types';

const getMissionsState = (state: RootState) => state.missions;

export const getSelectedMissions = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.selectedMissions
);

export const getSelectedCompany = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.selectedCompany
);

export const getSelectedQualification = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.selectedQualification
);
