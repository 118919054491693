import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ConsultantPerimeter, ConsultantService } from 'src/Services/API';

import useAuth from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchPerimeter = (
  extraOptions: UseQueryOptions<unknown, unknown, ConsultantPerimeter, QueryKeys[]>
) => {
  const { isAuthenticated } = useAuth();
  return useQuery(
    [QueryKeys.fetchPerimeter],
    () => {
      return ConsultantService.consultantsControllerGetAgencies();
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
