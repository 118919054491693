import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCandidateStatuses,
  getResultFilters,
  getSearchMode,
  hasFilter,
} from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { EnumFilterResultWorkingHours } from 'src/Redux/Search/Types';
import { CandidateStatuses } from 'src/Services/API';

import SearchResultsSidebar from './SearchResultsSidebar.component';

const MemoSearchResultsSidebar = React.memo(SearchResultsSidebar);

const EnhancedSearchResultsSidebar = () => {
  const dispatch = useDispatch();
  const candidateStatuses = useSelector(getCandidateStatuses);
  const _hasFilter = useSelector(hasFilter);
  const resultFilter = useSelector(getResultFilters);
  const searchMode = useSelector(getSearchMode);
  const setCandidateStatuses = useCallback(
    (candidateStatus: CandidateStatuses) => {
      dispatch(searchActions.setCandidateStatuses(candidateStatus));
    },
    [dispatch]
  );
  const removeCandidateStatuses = useCallback(
    (candidateStatus: CandidateStatuses) => {
      dispatch(searchActions.removeCandidateStatuses(candidateStatus));
    },
    [dispatch]
  );
  const resetCandidateStatuses = useCallback(() => {
    dispatch(searchActions.resetCandidateStatuses());
  }, [dispatch]);
  const addWorkingHoursFilter = useCallback(
    (workingHoursId: EnumFilterResultWorkingHours) => {
      dispatch(searchActions.addWorkingHoursFilter(workingHoursId));
    },
    [dispatch]
  );
  const removeWorkingHoursFilter = useCallback(
    (workingHoursId: EnumFilterResultWorkingHours) => {
      dispatch(searchActions.removeWorkingHoursFilter(workingHoursId));
    },
    [dispatch]
  );
  const resetWorkingHoursFilter = useCallback(() => {
    dispatch(searchActions.resetWorkingHoursFilter());
  }, [dispatch]);
  return (
    <MemoSearchResultsSidebar
      candidateStatuses={candidateStatuses}
      hasFilter={_hasFilter}
      resultFilters={resultFilter}
      searchMode={searchMode}
      setCandidateStatuses={setCandidateStatuses}
      removeCandidateStatuses={removeCandidateStatuses}
      resetCandidateStatuses={resetCandidateStatuses}
      addWorkingHoursFilter={addWorkingHoursFilter}
      removeWorkingHoursFilter={removeWorkingHoursFilter}
      resetWorkingHoursFilter={resetWorkingHoursFilter}
    />
  );
};

export default EnhancedSearchResultsSidebar;
