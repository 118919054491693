import {
  Button,
  ModalDeprecated,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useRef } from 'react';

import { IllusWarning } from 'src/Assets';

import styles from './DocumentSupressionModal.module.scss';
import { Props } from './DocumentSupressionModal.types';
const DocumentSupressionModal = ({ onSuccess }: Props) => {
  const innerRef = useRef<PopupActions>(null);
  return (
    <ModalDeprecated
      title="êtes vous sûr de supprimer le document ?"
      trigger={
        <Button.Secondary
          className={styles.removeButton}
          leftIcon={<Trashcan />}
          text="supprimer le document"
        />
      }
      ref={innerRef}
      footerActionsRight={[
        <Button.Secondary onClick={() => innerRef?.current?.close()}>annuler</Button.Secondary>,
        <Button.Primary onClick={() => onSuccess()}>supprimer</Button.Primary>,
      ]}
      icon={<IllusWarning />}
    >
      <p>les données saisies seront perdues</p>
    </ModalDeprecated>
  );
};

export default DocumentSupressionModal;
