import { ServerErrorRed } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Loader } from '@randstad-lean-mobile-factory/react-components-core';

import { usePreviewCandidateResume } from 'src/Hooks/Candidates/usePreviewCandidateResume';

import styles from './Overview.module.scss';
import { OverviewProps } from './Overview.types';

export const Overview = ({ candidateId, watch }: OverviewProps) => {
  const formState = watch();

  const { data: pdfUrl, isLoading, refetch } = usePreviewCandidateResume(candidateId, formState);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>aperçu du cv</div>
      {isLoading ? (
        <Loader heightInRem={2} darkMode />
      ) : !pdfUrl ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          <div>
            désolé, une erreur s'est produite
            <Button onClick={() => refetch()}>réessayer</Button>
          </div>
        </div>
      ) : (
        <iframe src={pdfUrl + '#toolbar=0'} title="CV Randstad" className={styles.pdfPreview} />
      )}
    </div>
  );
};
