import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateR1WithClosed, useFetchCandidateR1 } from './useFetchCandidateR1';

export const useFetchCandidateR1FromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateR1WithClosed, string[]>
) => {
  const id = useParams<{ id: string }>().id;
  return useFetchCandidateR1(id, extraOptions);
};
