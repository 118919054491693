import { SidebarMenu } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useContext } from 'react';
import { Redirect, Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { FETCH_STATUS } from 'src/Redux/Types';

import { getNavigationStructure } from '../CandidateRecruitingProcess.types';

import styles from './RecruitingSideBar.module.scss';

const RecruitingSideBar = () => {
  const history = useHistory();
  const params = useParams<{
    firstSection: 'R1' | 'R2';
    secondSection: string;
    id?: string;
    candidateId?: string;
  }>();
  const recruitingStatusQuery = useFetchCandidateInterviewsIdAndStatus(params.candidateId ?? '');
  const formStatusContext = useContext(FormStatusContext);
  return (
    <div className={styles.container}>
      <div className={styles.linkContainer}>
        <Router history={history}>
          <Switch>
            {Object.values(getNavigationStructure(recruitingStatusQuery.data)).map(node => (
              <Route key={node.name} path={`${node.path}(.*)`}>
                <Route exact path={node.path}>
                  <Redirect to={`${params.id}/${node.default}`} />
                </Route>
                <SidebarMenu
                  controls={node.routes}
                  keyValueExtractor={route => {
                    return {
                      key: route.name,
                      value: route.linkTo,
                    };
                  }}
                  onItemClick={item => {
                    const isCurrentRoute = item.linkTo.includes(params.secondSection);
                    if (formStatusContext.hasFormBeenTouched && !isCurrentRoute) {
                      formStatusContext.openNavWarningModal({ destination: item.linkTo });
                      formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
                    } else {
                      history.push(item.linkTo);
                    }
                  }}
                  selectedMenuValue={params.secondSection}
                />
              </Route>
            ))}
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default RecruitingSideBar;
