import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Consultant, ConsultantService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchConsultantInfo = (
  id?: string,
  extraOptions?: UseQueryOptions<unknown, unknown, Consultant, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchConsultantInfo, id ?? ''],
    () => {
      return id
        ? ConsultantService.consultantsControllerGetConsultant({ id })
        : Promise.reject('No id');
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
