import React from 'react';

import { LanguageLevel } from 'src/Services/API';

import BadgePicker from '../BadgePicker';

import styles from './LevelPicker.module.scss';
import { Props } from './LevelPicker.types';

const LevelPicker = ({ selectedLevel, onChange, skip, disabled }: Props) => {
  const items = [
    {
      key: LanguageLevel.OccasionalPractice,
      value: 'Pratique occasionelle',
      className: styles.optional,
    },
    {
      key: LanguageLevel.CommonPractice,
      value: 'Pratique courante',
      className: styles.important,
    },
    {
      key: LanguageLevel.Bilingual,
      value: 'Bilingue',
      className: styles.mandatory,
    },
  ];

  return (
    <BadgePicker
      disabled={disabled}
      selectedItem={selectedLevel as string}
      items={items.filter(item => !skip?.includes(item.key))}
      onChange={key => onChange(key as LanguageLevel)}
    />
  );
};

export default LevelPicker;
