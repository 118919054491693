const RADIAN = Math.PI / 180;
export const renderPieCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  labelClassName,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
  labelClassName: string;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      className={labelClassName}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

export const renderPercentBarCustomizedLabel = ({
  x,
  y,
  value,
  labelClassName,
  width,
}: {
  x: number;
  y: number;
  width: number;
  value: number;
  labelClassName: string;
}) => {
  return (
    <text
      className={labelClassName}
      x={x + width / 2}
      y={y}
      dy={32}
      fill="white"
      textAnchor="middle"
    >
      {value ? `${value}%` : ''}
    </text>
  );
};
