import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './CandidateResultsLoader.module.scss';

const CandidateResultsLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="4.5rem" width="50%" uniqueKey="hours">
      <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
    </ContentLoader>
    <ContentLoader height="4.5rem" width="100%" uniqueKey="service">
      <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="1%" y="30" rx="4" ry="4" width="90%" height="10" />
    </ContentLoader>
    <ContentLoader height="4.5rem" width="100%" uniqueKey="qualification">
      <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="1%" y="30" rx="4" ry="4" width="80%" height="10" />
    </ContentLoader>
    <ContentLoader height="6.5rem" width="100%" uniqueKey="commentary">
      <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
      <rect x="1%" y="30" rx="4" ry="4" width="100%" height="10" />
      <rect x="1%" y="50" rx="4" ry="4" width="100%" height="10" />
      <rect x="1%" y="70" rx="4" ry="4" width="100%" height="10" />
      <rect x="1%" y="90" rx="4" ry="4" width="100%" height="10" />
    </ContentLoader>
  </div>
);

export default CandidateResultsLoader;
