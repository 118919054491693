import classnames from 'classnames';
import React from 'react';

import styles from './StatsDisplayer.module.scss';
import { Props } from './StatsDisplayer.types';

const StatsDisplayer = ({ title, statToDisplay, textClassName, childNode }: Props) => (
  <div className={styles.statsSummary}>
    <p className={styles.summaryLabel}>{title}</p>
    <div className={classnames(styles.summaryNumber, textClassName)}>{statToDisplay}</div>
    {childNode}
  </div>
);

export default StatsDisplayer;
