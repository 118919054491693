import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { QualificationMatchFromKeyword, QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualificationsList = (
  keywords: string[],
  extraOptions?: UseQueryOptions<unknown, unknown, QualificationMatchFromKeyword[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchQualificationsList],
    async () => {
      return await QualificationsService.qualificationsControllerGetQualifications({
        keywords,
      });
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
