import { ToggleSwitch } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './ToggleSwitchWithDisplayMode.module.scss';
import { OwnProps } from './ToggleSwitchWithDisplayMode.types';

const ToggleSwitchWithDisplayMode = ({ isDisplayMode, toggleLabel, ...props }: OwnProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{toggleLabel}</div>
      {isDisplayMode ? <div>{props.checked ? 'oui' : 'non'}</div> : <ToggleSwitch {...props} />}
    </div>
  );
};
export default ToggleSwitchWithDisplayMode;
