import { DocumentFile, Refresh } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React from 'react';
import ContentLoader from 'react-content-loader';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchDocument } from 'src/Hooks/Candidates';
import { useFetchCandidateDocumentsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDocumentsFromURL';

import styles from './Documents.module.scss';

const Documents = () => {
  const documentsQuery = useFetchCandidateDocumentsFromURL();
  const { mutate, isLoading, variables } = useFetchDocument();
  return (
    <div>
      <div className={styles.title}>liste des documents rattachés au dossier</div>
      {documentsQuery.isLoading &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="document">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {documentsQuery.isError && (
        <ErrorMessage message="une erreur est survenue dans la récupération des documents" />
      )}
      {documentsQuery.isSuccess &&
        documentsQuery.data?.map(document => (
          <div className={styles.documentItem} key={document.id}>
            <DocumentFile />
            <div
              className={styles.documentName}
              onClick={() => mutate({ documentId: document.id ?? '' })}
            >
              {document.type}
            </div>
            {isLoading && variables?.documentId === document.id && (
              <Refresh className={styles.loading} />
            )}
            <div className={styles.uploadDate}>{`uploadé le ${moment(
              document.attachmentDate
            ).format('L')}`}</div>
          </div>
        ))}
    </div>
  );
};

export default Documents;
