import { useQuery } from '@tanstack/react-query';

import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAllHabilitations = () => {
  return useQuery([QueryKeys.fetchAllHabilitations], async () => {
    return await RepositoryService.habilitationsControllerGetAllHabilitations();
  });
};
