import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConsultantPerimeter } from 'src/Services/API';

import { PerimeterState } from './Types';

const initialState: PerimeterState = {
  brandCode: '001VB',
};

export const { reducer: perimeterReducer, actions: perimeterActions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setPerimeter: (state, action: PayloadAction<ConsultantPerimeter>) => {
      state.perimeter = {
        ...action.payload,
        defaultAgencyId: action.payload.companies
          ?.flatMap(company => company.agencies ?? [])
          .includes(action.payload.defaultAgencyId ?? '')
          ? action.payload.defaultAgencyId
          : action.payload.companies?.[0].agencies?.[0] ?? '',
      };
      state.currentBrand =
        action.payload.companies?.find(company => company.brand?.name === 'Randstad') ??
        action.payload.companies?.[0];
    },
  },
});
