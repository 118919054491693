import { IllusRLServiceSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasCompany, hasService } from 'src/Redux/Search/Selectors';

import styles from './ServiceButton.module.scss';
const ServiceButton = () => {
  const _hasCompany = useSelector(hasCompany);
  const _hasService = useSelector(hasService);
  const serviceModal = useModalWithLocation('service');
  return (
    <div>
      <CriteriaButton
        label="services"
        icon={<IllusRLServiceSmall className={styles.icon} />}
        selected={_hasService}
        disabled={!_hasCompany}
        onClick={() => serviceModal.open()}
      />
    </div>
  );
};

export default ServiceButton;
