import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SmsService, SendSMSBody } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useSendSMS = (
  extraOptions?: UseMutationOptions<void, AxiosError, SendSMSBody, unknown>
) => {
  return useMutation(
    [MutationKeys.sendSMS],
    async (body: SendSMSBody): Promise<void> => {
      const smsResponse = await SmsService.smsControllerSendSms({
        body,
      });
      return smsResponse;
    },
    {
      onSuccess: () => {
        toast.success('Votre SMS a été envoyé avec succès');
      },
      onError: () => {
        toast.error("Une erreur a eu lieu pendant l'envoi du SMS");
      },
      ...extraOptions,
    }
  );
};
