import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { Toast } from 'src/Assets';

import styles from './ConfirmationStep.module.scss';
import { Props } from './ConfirmationStep.types';

const ConfirmationStep = ({ onClose }: Props) => {
  return (
    <div className={styles.confirmationContainer}>
      <div className={styles.titleConfirmation}> confirmation </div>
      <Toast className={styles.confirmationIcon} />
      <div className={styles.optionsContainer}>
        Nouveau talent ajouté !!
        <div className={styles.optionsButtonsContainer}>
          <Button.Primary
            onClick={() => {
              onClose();
            }}
            className={styles.closeButton}
          >
            fermer
          </Button.Primary>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStep;
