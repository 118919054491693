import { UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { CandidateDocument } from 'src/Services/API';

import { useFetchCandidateDocuments } from './useFetchCandidateDocuments';

export const useFetchCandidateDocumentsFromURL = (
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateDocument[], string[]>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  return useFetchCandidateDocuments(candidateId, extraOptions);
};
