import { LanguageCriteria } from 'src/Redux/Search/Types';

export enum CriteriaType {
  weight,
  level,
}

export interface Props {
  nested: boolean;
  open: boolean;
  onClose: () => void;
  criteriaType: CriteriaType;
  languages: LanguageCriteria[];
  onLanguageChange: (languages: LanguageCriteria[]) => void;
}
