import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFirstName, getLastName } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import { NameSearch } from './NameSearch.component';

const MemoNameSearch = React.memo(NameSearch);

const EnhancedNameSearch = () => {
  const dispatch = useDispatch();
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const onFirstNameChange = useCallback(
    (value: string) => dispatch(searchActions.setFirstName(value)),
    [dispatch]
  );
  const onLastNameChange = useCallback(
    (value: string) => dispatch(searchActions.setLastName(value)),
    [dispatch]
  );
  return (
    <MemoNameSearch
      firstName={firstName}
      lastName={lastName}
      onFirstNameChange={onFirstNameChange}
      onLastNameChange={onLastNameChange}
    />
  );
};

export default EnhancedNameSearch;
