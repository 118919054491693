import React from 'react';

import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';

import BirthInformations from './BirthInformations';
import styles from './Identity.module.scss';
import Qualifications from './Qualifications';
import Visualization from './Visualization';

const Identity = () => {
  const candidate = useFetchCandidateDetailsFromURL().data;
  return (
    <div>
      <Visualization candidate={candidate} />
      <BirthInformations />
      <div className={styles.separator} />
      <Qualifications mainQualificationId={candidate?.qualificationId ?? ''} />
    </div>
  );
};

export default Identity;
