import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { RepositoriesService, Skill } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchOtherSkills = (
  extraOptions?: UseQueryOptions<unknown, unknown, Skill[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.fetchOtherSkills],
    async () => {
      const otherSkills = await RepositoriesService.repositoriesControllerGetOtherSkills();
      const results = otherSkills.map(skill => {
        return {
          id: skill.id ?? '',
          label: skill.label,
        };
      });
      return results;
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
