export enum STATS_SEARCH_PERIOD {
  ONE_WEEK = '1 semaine',
  ONE_MONTH = '1 mois',
  THREE_MONTH = '3 mois',
  SIX_MONTH = '6 mois',
  ONE_YEAR = '1 an',
  START_OF_YEAR = "le début de l'année",
}
export interface InterviewsStatsState {
  searchPeriod: STATS_SEARCH_PERIOD;
  statsSelectedAgencies: string[];
}
