import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CloseCandidateR1Body, R1InterviewsService, YesNoUnknown } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCloseCandidateR1 = (
  id: string,
  extraOptions?: UseMutationOptions<unknown, AxiosError, CloseCandidateR1Body, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.closeCandidateR1, id],
    async (candidateR1: CloseCandidateR1Body) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      candidateR1.lastYearContractsInfo?.companies?.map(company => {
        delete company.name;
        return company;
      });
      await R1InterviewsService.r1InterviewsControllerCloseCandidateR1({
        r1Id: id,
        body: {
          ...candidateR1,
          brandCode: currentBrand?.brandCodeApiHeader,
        },
      });
      if (candidateR1.supervisorAnswer === YesNoUnknown.Yes) {
        ReactGA.event('Formulaire R1 validé', {
          brandCode: currentBrand?.brandCodeApiHeader,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      }
    },
    { ...extraOptions }
  );
};
