import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { IdLabel, RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchHabilitationsAndDiploma = (
  extraOptions?: UseMutationOptions<IdLabel[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchHabilitationsAndDiploma],
    async (keyword: string) => {
      const [habilitations, diploma] = await Promise.all([
        RepositoryService.habilitationsControllerGetAllHabilitations({
          keyword,
        }),
        RepositoryService.diplomasControllerGetDiplomas({ keyword }),
      ]);
      const results = habilitations
        .concat(diploma)
        .sort((a, b) => (a.label && b.label && a.label < b.label ? -1 : 1));
      return results;
    },
    { ...extraOptions }
  );
};
