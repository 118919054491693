import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect } from 'react';
import { Router, Switch, useHistory, useLocation } from 'react-router-dom';

import { useWatchForFlashURLParam } from 'src/Hooks/Flash/useWatchForFlashURLParam';
import history from 'src/Utils/history';

import PrivateRoute from './Components/PrivateRoute';
import CandidateResults from './Containers/CandidateResults';
import CandidateResume from './Containers/CandidateResume';
import MainPage from './Containers/MainPage/MainPage.component';
import AvailabilityModal from './Containers/Modals/AvailabilityModal';
import CACESModal from './Containers/Modals/CACESModal';
import CompanyModal from './Containers/Modals/CompanyModal';
import CriteriaLanguageModal from './Containers/Modals/CriteriaLanguageModal';
import DiplomaModal from './Containers/Modals/DiplomaModal';
import DrivingLicenceModal from './Containers/Modals/DrivingLicenceModal';
import HabilitationModal from './Containers/Modals/HabilitationModal';
import R1ReassignCGCModal from './Containers/Modals/InterviewsModals/R1ReassignCGCModal';
import KeywordModal from './Containers/Modals/KeywordModal';
import LocationModal from './Containers/Modals/LocationModal';
import PotentialPositionModal from './Containers/Modals/PotentialPositionModal';
import QualificationModal from './Containers/Modals/QualificationModal';
import ServiceModal from './Containers/Modals/ServiceModal';
import SkillModal from './Containers/Modals/SkillModal';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import useAuth from './Hooks/Authentication/useAuth';
import { useWatchForEdpURLParam } from './Hooks/EDP/useWatchForEdpURLParam';
import { LocationState, ModalRoute } from './Hooks/Modals';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors/security';

const modalRouteMap: ModalRoute[] = [
  { route: 'availability', component: AvailabilityModal },
  { route: 'qualification', component: QualificationModal },
  { route: 'skill', component: SkillModal },
  { route: 'location', component: LocationModal },
  { route: 'diploma', component: DiplomaModal },
  { route: 'driving-licence', component: DrivingLicenceModal },
  { route: 'language', component: CriteriaLanguageModal },
  { route: 'habilitation', component: HabilitationModal },
  { route: 'company', component: CompanyModal },
  { route: 'service', component: ServiceModal },
  { route: 'potential-position', component: PotentialPositionModal },
  { route: 'reassignCgc/:id', component: R1ReassignCGCModal },
  { route: 'keyword', component: KeywordModal },
  { route: 'caces', component: CACESModal },
  {
    route: 'candidate/:candidateId/resume/:firstSection?/:secondSection?',
    component: CandidateResume,
  },
  { route: 'results', component: CandidateResults },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push('/');
  }

  return (
    <>
      <Switch location={background || location}>
        <PrivateRoute path="/" component={MainPage} />
      </Switch>

      {modalRouteMap.map(modalInfo => (
        <PrivateRoute
          key={modalInfo.route}
          path={`/${modalInfo.route}`}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}
      <ToastContainer position="bottom-right" />
    </>
  );
}

function App() {
  const { getAccessTokenSilently, logout } = useAuth();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useEffect(() => setupAPI(), []);
  useInitializeAnalytics();
  useWatchForEdpURLParam();
  useWatchForFlashURLParam();
  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
    </div>
  );
}

export default App;
