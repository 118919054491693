import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { R2InterviewsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useGenerateR2Pdf = (extraOptions?: UseMutationOptions<Buffer, unknown, unknown>) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useMutation(
    [MutationKeys.generateR2Pdf],
    async (r2Id: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const positionStudy = await R2InterviewsService.r2InterviewsControllerGetCandidateR2Pdf(
        { r2Id },
        { responseType: 'arraybuffer' }
      );
      return positionStudy;
    },
    {
      ...extraOptions,
    }
  );
};
