export enum DOCUMENT_STATUS {
  PENDING = 0,
  IDENTITY = 1,
  ADDRESS = 2,
  EXPERIENCES = 3,
  FINISHED = 4,
}
export interface Props {
  status: DOCUMENT_STATUS | undefined;
}
