import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import { LanguageLevel, WishPriority } from 'src/Services/API';

import LevelPicker from '../LevelPicker';
import RadiusPicker from '../RadiusPicker';
import WeightPicker from '../WeightPicker';

import styles from './CriteriaCard.module.scss';
import { Props } from './CriteriaCard.types';

const CriteriaCard = (props: Props) => (
  <div className={classnames(styles.container, props.className)}>
    <div className={styles.leftSide}>
      <div>{props.icon}</div>
      <div className={styles.text}>
        <div className={styles.title}>{props.title}</div>
        {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
      </div>
    </div>
    <div className={styles.rightSide}>
      {props.weight && props.onWeightChange && (
        <WeightPicker
          skip={props.notMandatory ? [WishPriority.Obligatoire] : undefined}
          selectedWeight={props.weight}
          onChange={props.onWeightChange}
        />
      )}
      {props.radius && props.onRadiusChange && (
        <RadiusPicker onChange={props.onRadiusChange} selectedRadius={props.radius} />
      )}
      {props.level && props.onLevelChange && (
        <LevelPicker
          skip={props.notMandatory ? [LanguageLevel.OccasionalPractice] : undefined}
          selectedLevel={props.level}
          onChange={props.onLevelChange}
        />
      )}
      {props.onDelete && (
        <Button.Tertiary className={styles.trashIcon} onClick={props.onDelete}>
          <Trashcan />
        </Button.Tertiary>
      )}
    </div>
  </div>
);

export default CriteriaCard;
