import { WithLightTitle, AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { LanguageCriteria } from 'src/Redux/Search/Types';

import { Props } from './SelectLanguage.types';

const SelectLanguage = ({ setLanguages, selectedLanguages, languages }: Props) => {
  const [keyword, setKeyword] = useState('');
  const researchedLanguages = languages.filter(x =>
    x.label?.toLowerCase().includes(keyword.toLowerCase())
  );

  return (
    <WithLightTitle title="ou rechercher une autre langue">
      <AutoSuggest
        id="langues"
        value={selectedLanguages ?? []}
        keyValueExtractor={(searchResult: LanguageCriteria) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={setKeyword}
        onChange={languages => {
          setLanguages([...languages]);
          setKeyword('');
        }}
        searchResults={researchedLanguages}
        placeholder="1 caractère minimum"
      />
    </WithLightTitle>
  );
};
export default SelectLanguage;
