import { IllusRLQualificationSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasQualifications } from 'src/Redux/Search/Selectors';

import styles from './QualificationButton.module.scss';

const QualificationButton = () => {
  const _hasQualifications = useSelector(hasQualifications);
  const qualificationModal = useModalWithLocation('qualification');
  return (
    <div>
      <CriteriaButton
        label="qualifications"
        icon={<IllusRLQualificationSmall className={styles.icon} />}
        selected={_hasQualifications}
        onClick={() => qualificationModal.open()}
      />
    </div>
  );
};

export default QualificationButton;
