import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getStatsSelectedAgencies, getStatsSearchPeriod } from 'src/Redux/InterviewsStats/Selector';
import { InterviewsService } from 'src/Services/API';

import { QueryKeys } from '../types';

import { searchDateMapping } from './useFetchInterviewsStatistics';

export const useFetchOpenInterviewsStatistics = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const searchPeriod = useSelector(getStatsSearchPeriod);
  return useQuery(
    [QueryKeys.fetchOpenInterviewsStatistics, agencyIds, searchPeriod],
    async () => {
      const searchDate = searchDateMapping[searchPeriod];
      const openInterviewsStats =
        await InterviewsService.interviewsControllerGetOpenInterviewsStats({
          body: {
            agencyIds: agencyIds,
            startDate: searchDate,
          },
        });

      return openInterviewsStats;
    },
    { enabled: agencyIds !== undefined }
  );
};
