import * as z from 'zod';

import { Job } from 'src/Services/API';

export interface OnValidateParams {
  jobs: Job[];
  job: Job;
  mainQualification: string;
}

export interface Props {
  selectedJobs: Job[];
  mainQualification?: string;
  onValidate: (params: OnValidateParams) => void;
  jobToModify?: Job;
  trigger: JSX.Element;
}

export const jobModalSchema = (newJob: boolean) =>
  z.object({
    currentJob: z.object({ id: z.string().optional(), label: z.string().optional() }),
    currentQualifications: z.array(z.string()).refine(item => {
      return !(newJob && item.length === 0);
    }, 'Vous devez sélectionner au moins une qualification pour ajouter un métier'),
    currentMainQualification: z.string().optional(),
  });
