import { IllusRLCalendarSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasAvailabilityDate } from 'src/Redux/Search/Selectors';

import styles from './AvailabilityButton.module.scss';

const AvailabilityButton = () => {
  const _hasAvailabilityDate = useSelector(hasAvailabilityDate);
  const availabilityModal = useModalWithLocation('availability');
  return (
    <div>
      <CriteriaButton
        label="disponibilités"
        icon={<IllusRLCalendarSmall className={styles.icon} />}
        selected={_hasAvailabilityDate}
        onClick={() => availabilityModal.open()}
      />
    </div>
  );
};

export default AvailabilityButton;
