import { Checkbox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { useFetchQualificationsWithThrottle } from 'src/Hooks/Qualifications/useFetchQualifications';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import ParsedDocumentLabel from '../../Components/ParsedDocumentLabel/ParsedDocumentLabel.component';
import RemoveItem from '../../Components/RemoveItem/RemoveItem.component';

import styles from './SelectQualification.module.scss';
import { Props } from './SelectQualification.types';

const SelectQualification = ({ control, index, watch }: Props) => {
  const qualificationsController = useController({
    control,
    name: 'qualifications',
  });
  const watchQualifications = watch('qualifications');
  const qualification = watchQualifications[index];
  const [keyword, setKeyword] = useState('');
  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  const qualificationThrottledMutationData = qualificationThrottledMutation?.data?.map(
    qualification => {
      return { id: qualification.identifier, label: qualification.label };
    }
  );
  return (
    <div className={styles.container}>
      {qualification.parsedDocumentLabel && (
        <ParsedDocumentLabel
          title={'qualification détectée'}
          label={qualification.parsedDocumentLabel}
          titleClassName={styles.parsedDocumentLabelTitle}
          containerClassName={styles.parsedDocumentLabelContainer}
        />
      )}
      <WithLightTitle
        title={
          qualification.parsedDocumentLabel
            ? 'compétence équivalente osmose proposée'
            : `qualification ajoutée #${index + 1}`
        }
        className={styles.qualificationTitle}
      >
        <div className={styles.qualificationContainer}>
          <div className={styles.qualificationComboBox}>
            <ComboBox
              id="qualification"
              control={control}
              name={`qualifications.${index}.selectedItem`}
              keyValueExtractor={(searchResult: { id: string; label?: string }) => ({
                key: searchResult.id,
                value: searchResult.label ?? '',
              })}
              onSearch={(word: string) => {
                setKeyword(word);

                word.length >= 3 &&
                  (word !== qualification.selectedItem?.label ||
                    qualification.selectedItem?.label?.length === 0) &&
                  qualificationThrottledMutation.mutate(word);
              }}
              disableClearable
              minLengthToSearch={3}
              searchResults={
                keyword.length >= 3
                  ? qualificationThrottledMutationData?.filter(
                      item =>
                        !watchQualifications.some(
                          otherQualification => otherQualification.selectedItem.id === item.id
                        )
                    ) ?? []
                  : []
              }
              placeholder="3 caractères minimum"
              fetchStatus={
                keyword.length < 3
                  ? FETCH_STATUS.FULFILLED
                  : toFetchStatus(qualificationThrottledMutation)
              }
              className={styles.comboBox}
            />
            <RemoveItem
              onClick={() => {
                qualificationsController.field.onChange(
                  watchQualifications.filter((_, idx) => idx !== index)
                );
              }}
              className={styles.removeItem}
            />
          </div>
          <div className={styles.toggleSwitchContainer}>
            <WithLightTitle title="qualification principale" className={styles.mainQualification}>
              <div className={styles.mainQualificationCheckBox}>
                <Checkbox
                  checked={watchQualifications[index].main}
                  label="définir comme qualification principale"
                  onChange={check =>
                    qualificationsController.field.onChange(
                      watchQualifications.map((qualification, idx) => {
                        return { ...qualification, main: check ? idx === index : false };
                      })
                    )
                  }
                />
              </div>
            </WithLightTitle>
          </div>
        </div>
      </WithLightTitle>
      <div className={styles.separator} />
    </div>
  );
};

export default SelectQualification;
