import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { MatchingCriteriaLocationWithLabel } from 'src/Services/API';
import { RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchLocations = (
  extraOptions?: UseMutationOptions<MatchingCriteriaLocationWithLabel[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchLocations],
    async (keyword: string) => {
      const locations = await RepositoryService.citiesControllerGetCities({
        keyword,
      });
      const results = locations.map(location => {
        return {
          zipCode: location.zipCode ?? '',
          label: location.name,
          inseeCode: location.insee ?? '',
          radius: 0,
        };
      });
      return results;
    },
    { ...extraOptions }
  );
};
