import React from 'react';

import LanguageModal from 'src/Components/LanguageModal';
import { CriteriaType } from 'src/Components/LanguageModal/LanguageModal.types';

import { Props } from './R1LanguageModal.types';

const R1LanguageModal = ({ open, onClose, languages, onLanguageChange }: Props) => {
  return (
    <LanguageModal
      nested
      open={open}
      onClose={onClose}
      criteriaType={CriteriaType.level}
      languages={languages}
      onLanguageChange={onLanguageChange}
    />
  );
};

export default R1LanguageModal;
