import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCompany } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import CompanyModal from './CompanyModal.component';

const MemoCompanyModal = React.memo(CompanyModal);

const EnhancedCompanyModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector(getCompany);
  const onCompanyChange = useCallback(
    (company: MatchingCriteriaItemWithLabel) => {
      dispatch(searchActions.setCompany(company));
      dispatch(searchActions.removeService());
      dispatch(searchActions.setPotientialPositions([]));
    },
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return <MemoCompanyModal company={company} onCompanyChange={onCompanyChange} onClose={onClose} />;
};

export default EnhancedCompanyModal;
