import { classToPlain } from 'class-transformer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { LocationState } from 'src/Hooks/Modals';
import { useFetchPerimeter } from 'src/Hooks/Perimeter/useFetchPerimeter';
import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { interviewsStatsActions } from 'src/Redux/InterviewsStats/Slice';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import history from 'src/Utils/history';

import AddNewTalent from '../AddNewTalent/AddNewTalent.component';
import CandidateFile from '../CandidateFile';
import CandidateRecruitingProcess from '../CandidateRecruitingProcess';
import CandidateResults from '../CandidateResults';
import CandidateResume from '../CandidateResume';
import Home from '../Home';
import InterviewDashboard from '../InterviewDashboard';

import styles from './MainPage.module.scss';
import TopBar from './TopBar';

const MainPage = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const statsUnits = useSelector(getStatsSelectedAgencies);
  useFetchPerimeter({
    onSuccess: perimeter => {
      if (perimeter?.companies?.length === 0) {
        logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI });
      }
      dispatch(perimeterActions.setPerimeter(classToPlain(perimeter)));
      if (statsUnits.length === 0) {
        dispatch(
          interviewsStatsActions.setStatsSelectedAgencies(
            perimeter.defaultAgencyId ? [perimeter.defaultAgencyId] : []
          )
        );
      }
    },
  });
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <div className={styles.container}>
      <TopBar history={history} />
      <Router history={history}>
        <Switch location={background || location}>
          <PrivateRoute exact path={match.path} component={Home} />
          <PrivateRoute exact path="/results" component={CandidateResults}></PrivateRoute>
          <PrivateRoute exact path="/interviews/:dashboardMenu" component={InterviewDashboard} />
          <PrivateRoute exact path="/new-talent" component={AddNewTalent} />
          <PrivateRoute
            exact
            path="/candidate/:candidateId?/file/:firstSection?/:secondSection?"
            component={CandidateFile}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/candidate/:candidateId?/recruit/:firstSection?/:id?/:secondSection?"
            component={CandidateRecruitingProcess}
          />
          <PrivateRoute
            exact
            path="/candidate/:candidateId?/resume/:firstSection?/:secondSection?"
            component={CandidateResume}
          ></PrivateRoute>
        </Switch>
      </Router>
    </div>
  );
};

export default MainPage;
