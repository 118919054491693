import {
  HorizontalCardWithTitle,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useParams } from 'react-router';

import { useFetchCandidateExperiencesByCompany } from 'src/Hooks/Candidates/useFetchCandidateExperiencesByCompany';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './Background.module.scss';
import {
  EXPERIENCE_TYPE,
  GroupedExperience,
  SORT_OPTIONS,
  SORT_ORDER,
  backgroundMenuLabel,
} from './Background.types';
import SortOptions from './SortOptions';
import { getSubtitles, getTitleValue, groupExperiences, totalDurationInHours } from './utils';

const Background = () => {
  const [experienceType, setExperienceType] = useState<EXPERIENCE_TYPE>(
    EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
  );

  const [sortOption, setSortOption] = useState<SORT_OPTIONS>(SORT_OPTIONS.NAME);
  const [sortOrder, setSortOrder] = useState<SORT_ORDER>(SORT_ORDER.ASCENDING);

  const { candidateId } = useParams<{ candidateId: string }>();
  const candidateExperiencesQuery = useFetchCandidateExperiencesByCompany(candidateId, true);
  const fetchStatus = toFetchStatus(candidateExperiencesQuery);

  const experiences = candidateExperiencesQuery.data;

  const { labels, groupedExperiences } =
    groupExperiences(
      experiences ?? { experiences: [] },
      experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES,
      sortOption,
      sortOrder
    ) ?? [];
  const totalHours = groupedExperiences.reduce(
    (acc: number, cur: GroupedExperience) => acc + totalDurationInHours(cur.experiences),
    0
  );

  const startDates = groupedExperiences
    .reduce((startDates: Date[], groupedExperience) => {
      const experienceStartDates = groupedExperience.experiences.map(
        experience => new Date(experience?.startDate ?? '')
      );
      return [...startDates, ...experienceStartDates];
    }, [])
    .sort((a, b) => a.getTime() - b.getTime());
  const firstDate = startDates.length > 0 ? startDates[0] : new Date();
  const lastDate = startDates.length > 0 ? startDates[startDates.length - 1] : new Date();

  return (
    <div>
      <SegmentedControl
        controls={Object.values(EXPERIENCE_TYPE)}
        selected={experienceType}
        getValue={item => backgroundMenuLabel[item]}
        onSelectionChange={item => {
          setExperienceType(item);
        }}
        className={styles.segmentedControl}
        labelClassName={styles.segmentedLabels}
      />
      <div className={styles.optionsContainer}>
        <p className={styles.optionsText}>
          {getTitleValue({ startDates, totalHours, experienceType, lastDate, firstDate })}
        </p>
        <SortOptions
          selectedSortOption={sortOption}
          setSelectedSortOption={setSortOption}
          selectedSortOrder={sortOrder}
          setSelectedSortOrder={setSortOrder}
        />
      </div>
      <div className={styles.experiencesContainer}>
        {fetchStatus === FETCH_STATUS.PENDING ? (
          <>
            <ContentLoader height="2.5rem" width="100%" uniqueKey="candidate-experience">
              <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
            </ContentLoader>
            {new Array(5).fill(null).map((_, idx) => (
              <ContentLoader
                key={idx}
                height="5.75rem"
                width="100%"
                uniqueKey="candidate-experience"
              >
                <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
              </ContentLoader>
            ))}
          </>
        ) : (
          labels?.map(label => (
            <>
              {experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES &&
                sortOption !== SORT_OPTIONS.HOURS && (
                  <div className={styles.groupTitle} key={label}>
                    {label}
                  </div>
                )}
              {groupedExperiences
                .filter(experience => experience.label === label)
                .map(experience => (
                  <HorizontalCardWithTitle
                    key={`${experience.companyName} - ${experience.experiences[0].contractId}`}
                    title={experience.companyName ?? ''}
                    titleClassname={styles.horizontalCardTitle}
                    className={styles.horizontalCard}
                    subtitles={getSubtitles(experience.experiences)}
                  />
                ))}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default Background;
