import { z } from 'zod';

export const formSchema = z.object({
  qualifications: z
    .array(z.string())
    .min(1, { message: 'il faut choisir au moins une qualification' }),
  skills: z.object({
    businessSkills: z.array(z.string()),
    behaviourSkills: z.array(z.string()),
  }),
  experiences: z.object({
    title: z.string(),
    experiences: z.array(
      z.object({
        label: z.string(),
        companyName: z.string(),
        qualifications: z.array(z.string()),
        firstDate: z.string(),
        lastDate: z.string(),
        nbContracts: z.string(),
        duration: z.string(),
        isInternalExperience: z.boolean(),
      })
    ),
  }),
  degrees: z.object({
    diplomas: z.array(z.string()),
    tests: z.array(z.string()),
    habilitations: z.array(z.string()),
    drivingLicences: z.array(z.string()),
  }),
  employee: z
    .object({
      name: z.boolean(),
      identity: z.boolean(),
      address: z.boolean(),
      contactInfo: z.boolean(),
      availability: z.boolean(),
      other: z.boolean(),
      otherValue: z.string(),
      isAnonymityConfirmed: z.boolean(),
    })
    .refine(employee => !(employee.other && !employee.otherValue), {
      path: ['otherValue'],
      message: 'veuillez indiquer les informations complémentaires à ajouter à la synthèse',
    })
    .refine(
      employee => {
        const isCvAnonymous = !employee.address && !employee.contactInfo && !employee.identity;
        if (!isCvAnonymous && !employee.isAnonymityConfirmed) return false;
        return true;
      },
      { path: ['isAnonymityConfirmed'] }
    ),
  description: z.string(),
  overview: z.string().optional(),
});

export type FormStep = keyof z.infer<typeof formSchema>;

export const formSteps = [
  'qualifications',
  'skills',
  'experiences',
  'degrees',
  'employee',
  'description',
  'overview',
] as FormStep[];
