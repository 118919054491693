import { ComboBox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';

import { useFetchCompaniesWithThrottle } from 'src/Hooks/Company';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { CompanySearchResult, Props } from './SelectCompany.types';

const SelectCompany = ({ selectedCompany, setCompany }: Props) => {
  const [keyword, setKeyword] = useState('');
  const companiesThrottledMutation = useFetchCompaniesWithThrottle();
  return (
    <WithLightTitle
      title={`${
        keyword !== '' && companiesThrottledMutation.data
          ? companiesThrottledMutation.data.length
          : '-'
      } comptes disponibles`}
    >
      <ComboBox
        id="comptes"
        value={selectedCompany}
        minLengthToSearch={2}
        withSubLabel
        keyValueExtractor={(searchResult: CompanySearchResult) => ({
          key: searchResult.identifier,
          value: searchResult.label
            ? `${searchResult.label} (${searchResult.siret ?? 'siret inconnu'})`
            : '',
          subValue: `${searchResult.companyAddress?.postalCode} - ${searchResult.companyAddress?.city}`,
        })}
        onSearch={(word: string) => {
          setKeyword(word);
          word !== '' && companiesThrottledMutation.mutate(word);
        }}
        onChange={company => {
          if (company !== null) {
            setCompany(company);
          }
          setKeyword('');
        }}
        searchResults={keyword !== '' ? companiesThrottledMutation.data ?? [] : []}
        placeholder="2 caractères minimum"
        fetchStatus={toFetchStatus(companiesThrottledMutation)}
      />
    </WithLightTitle>
  );
};

export default SelectCompany;
