import * as z from 'zod';

export const candidateStrengthSchema = z.object({
  strength: z.object({
    textResponses: z.array(
      z.object({
        id: z.number(),
        label: z.string(),
        value: z.string().optional(),
      })
    ),
    listResponses: z.array(
      z.object({
        id: z.number(),
        label: z.string(),
        keywords: z.array(z.string()).optional(),
        key: z.string().optional(),
      })
    ),
  }),
});

export type CandidateStrengthSchemaType = z.infer<typeof candidateStrengthSchema>;
export type KeyOfCandidateStrengthSchemaTypeQuestion = keyof CandidateStrengthSchemaType;
