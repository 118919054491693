import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import LanguageModal from 'src/Components/LanguageModal';
import { CriteriaType } from 'src/Components/LanguageModal/LanguageModal.types';
import { getLanguages } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel, WishPriority } from 'src/Services/API';

const CriteriaLanguageModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const languages = useSelector(getLanguages);
  const onLanguageChange = useCallback(
    (languages: MatchingCriteriaItemWithLabel[]) => dispatch(searchActions.setLanguages(languages)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <LanguageModal
      open
      nested
      onClose={onClose}
      criteriaType={CriteriaType.weight}
      languages={languages}
      onLanguageChange={languages =>
        onLanguageChange(
          languages.map(language => ({
            ...language,
            wishPriority: language.wishPriority ?? WishPriority.Obligatoire,
          }))
        )
      }
    />
  );
};

export default CriteriaLanguageModal;
