import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CandidatesService, CreateCandidateBody } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useCreateCandidate = (
  extraOptions?: UseMutationOptions<string, AxiosError, unknown, unknown>
) => {
  return useMutation(
    [MutationKeys.createCandidate],
    async (body: CreateCandidateBody) => {
      return await CandidatesService.candidatesControllerCreateCandidate({
        body,
      });
    },
    {
      ...extraOptions,
    }
  );
};
