import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { FETCH_STATUS } from 'src/Redux/Types';

import RemoveItem from '../../Components/RemoveItem/RemoveItem.component';

import styles from './SelectSoftSkillComboBox.module.scss';
import { Props } from './SelectSoftSkillComboBox.types';

const SelectSoftSkillComboBox = ({ control, otherSkills, index }: Props) => {
  const softSkillsController = useController({
    control,
    name: 'softSkills',
  });
  const skill = softSkillsController.field.value[index];
  const [keyword, setKeyword] = useState('');
  const researchedSkills = otherSkills.filter(
    x =>
      x.label?.toLowerCase().includes(keyword.toLowerCase()) &&
      !softSkillsController?.field?.value?.some(softSkill => softSkill.selectedItem.id === x.id)
  );
  return (
    <div className={styles.container}>
      <div className={styles.skillContainer}>
        <WithLightTitle title={'compétence comportementale détectée'}>
          <p>{skill.parsedDocumentLabel}</p>
        </WithLightTitle>
        <Flux className={styles.flux} />

        <WithLightTitle
          title={`compétence comportementale #${index + 1}`}
          className={styles.comboBoxTitle}
        >
          <div className={styles.comboBoxContainer}>
            <ComboBox
              id="softSkills"
              control={control}
              name={`softSkills.${index}.selectedItem`}
              keyValueExtractor={searchResult => ({
                key: searchResult.id,
                value: searchResult.label ?? '',
              })}
              placeholder="sélectionner"
              onSearch={word => {
                setKeyword(word);
              }}
              searchResults={researchedSkills ?? []}
              disableClearable
              minLengthToSearch={1}
              className={styles.comboBox}
              fetchStatus={FETCH_STATUS.FULFILLED}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <RemoveItem
              onClick={() => {
                softSkillsController.field.onChange(
                  softSkillsController.field.value.filter((_, idx) => idx !== index)
                );
              }}
            />
          </div>
        </WithLightTitle>
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default SelectSoftSkillComboBox;
