import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getDiplomas } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import DiplomaModal from './DiplomaModal.component';

const MemoDiplomaModal = React.memo(DiplomaModal);

const EnhancedDiplomaModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const diplomas = useSelector(getDiplomas);
  const onDiplomasChange = useCallback(
    (diplomas: MatchingCriteriaItemWithLabel[]) => {
      dispatch(searchActions.setDiplomas(diplomas));
    },
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoDiplomaModal diplomas={diplomas} onDiplomasChange={onDiplomasChange} onClose={onClose} />
  );
};

export default EnhancedDiplomaModal;
