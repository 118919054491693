import {
  Button,
  ComboBox,
  DatePickerInputDeprecated,
  DropDown,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLQualification } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { useFetchCandidateSkillsFromURL } from 'src/Hooks/Candidates/useFetchCandidateSkillsFromURL';
import { ContractTypeIdEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './ExternalExperienceModal.module.scss';
import { experienceModalSchema, Props } from './ExternalExperienceModal.types';

const ExternalExperienceModal = React.forwardRef<PopupActions, Props>(
  ({ onValidate, experienceToModify, onClose, trigger }, ref) => {
    const localRef = React.useRef<PopupActions>(null);
    const {
      data: skillsData,
      isLoading: isLoadingSkills,
      isSuccess: isSkillsSuccess,
      isFetching: isFetchingSkills,
      isError: isSkillsError,
    } = useFetchCandidateSkillsFromURL();
    const candidateQualifications = skillsData?.qualifications?.map(qualification => {
      return { id: qualification.id, label: qualification.label };
    });
    const [keyword, setKeyword] = useState('');
    const fetchStatus = toFetchStatus({
      isLoading: isLoadingSkills,
      isSuccess: isSkillsSuccess,
      isFetching: isFetchingSkills,
      isError: isSkillsError,
    });
    const researchedQualifications = candidateQualifications
      ? candidateQualifications.filter(x => x.label?.toLowerCase().includes(keyword.toLowerCase()))
      : [];
    const { control, handleSubmit, reset, formState, setValue } = useFormWithZodResolver({
      schema: experienceModalSchema,
      defaultValues: {
        currentExperience: { companyName: '' },
      },
    });
    const qualificationController = useController({
      control,
      name: 'currentExperience.qualification',
    });
    const startDateController = useController({ control, name: 'currentExperience.startDate' });
    const endDateController = useController({ control, name: 'currentExperience.endDate' });
    const contractTypeController = useController({
      control,
      name: 'currentExperience.contractType',
    });
    return (
      <ModalDeprecated
        ref={ref ?? localRef}
        onClose={() => {
          reset();
          onClose && onClose();
        }}
        onOpen={() => {
          experienceToModify &&
            setValue('currentExperience', {
              ...experienceToModify,
              companyName: experienceToModify.companyName ?? '',
            });
          experienceToModify?.contractType &&
            contractTypeController.field.onChange(experienceToModify.contractType);
          experienceToModify?.qualification?.id &&
            qualificationController.field.onChange(experienceToModify.qualification);
        }}
        icon={<IllusRLQualification className={styles.modalIcon} />}
        trigger={trigger}
        title="ajouter une expérience"
        footerActionsLeft={[
          <Button.Tertiary
            onClick={() => {
              reset();
            }}
          >
            tout réinitialiser
          </Button.Tertiary>,
        ]}
        footerActionsRight={[
          <Button.Secondary
            onClick={() => {
              onClose ? onClose() : localRef.current?.close();
            }}
          >
            annuler
          </Button.Secondary>,
          <Button.Primary
            onClick={handleSubmit(values => {
              onValidate({
                ...values.currentExperience,
                duration: moment(values.currentExperience.endDate).diff(
                  values.currentExperience.startDate,
                  'months'
                ),
              });
              onClose ? onClose() : localRef.current?.close();
            })}
          >
            valider
          </Button.Primary>,
        ]}
      >
        <WithLightTitle title="nom de l'entreprise (externe)" className={styles.inputContainer}>
          <TextInput
            control={control}
            placeholder="saisissez le nom de l'entreprise"
            name="currentExperience.companyName"
          />
        </WithLightTitle>
        <WithLightTitle title="qualification" className={styles.inputContainer}>
          <ComboBox
            id="qualification"
            value={qualificationController.field.value ?? null}
            keyValueExtractor={(qualification: { id?: string; label?: string }) => ({
              key: qualification.id ?? '',
              value: qualification.label ?? '',
            })}
            placeholder="saisissez une qualification parmi celles du candidat"
            onSearch={setKeyword}
            fetchStatus={fetchStatus}
            searchResults={researchedQualifications}
            onChange={qualification => {
              if (qualification !== null) {
                qualificationController.field.onChange(qualification);
              }
              setKeyword('');
            }}
          />
        </WithLightTitle>
        {qualificationController.field.value?.id === undefined && (
          <div className={styles.errorMessage}>
            {formState.errors?.currentExperience?.qualification?.id?.message}
          </div>
        )}
        <div className={styles.datesContainer}>
          <WithLightTitle title="date de début" className={styles.datesGap}>
            <DatePickerInputDeprecated
              date={startDateController.field.value}
              onSelectDate={startDateController.field.onChange}
            />
          </WithLightTitle>
          <WithLightTitle title="date de fin">
            <DatePickerInputDeprecated
              date={endDateController.field.value}
              onSelectDate={endDateController.field.onChange}
              minDate={startDateController.field.value}
            />
          </WithLightTitle>
        </div>
        {(!endDateController.field.value ||
          !startDateController.field.value ||
          moment(endDateController.field.value).diff(
            moment(startDateController.field.value),
            'months'
          ) < 3) && (
          <div className={styles.errorMessage}>
            {formState.errors?.currentExperience?.endDate?.message}
          </div>
        )}
        <WithLightTitle title="type de contrat" className={styles.inputContainer}>
          <DropDown
            items={
              Object.values(ContractTypeIdEnum).map(item => {
                return { id: item.toString(), label: item.toString() };
              }) ?? []
            }
            keyValueExtractor={(item: { label?: string | undefined; id?: string | undefined }) => {
              return { key: item.id ?? '', value: item.label ?? '' };
            }}
            selectedItem={contractTypeController.field.value}
            onSelectItem={(
              item: { label?: string | undefined; id?: string | undefined } | undefined
            ) => {
              contractTypeController.field.onChange(item);
            }}
            placeholder="sélectionner"
          />
        </WithLightTitle>
        {contractTypeController.field.value?.id === undefined && (
          <div className={styles.errorMessage}>
            {formState.errors?.currentExperience?.contractType?.id?.message}
          </div>
        )}
      </ModalDeprecated>
    );
  }
);
export default ExternalExperienceModal;
