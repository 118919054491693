import { PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchMissionCriteria } from 'src/Hooks/Missions/useFetchMissionCriteria';
import {
  getSelectedCompany,
  getSelectedMissions,
  getSelectedQualification,
} from 'src/Redux/Missions/Selectors';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { canDoCriteriaSearch } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { IdLabel, MissionWithoutSchedule, WishPriority } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import MissionSearchModal from './MissionSearchModal.component';

const MemoMissionSearchModal = React.memo(MissionSearchModal);

const EnhancedMissionSearchModal = () => {
  const dispatch = useDispatch();
  const innerRef = React.useRef<PopupActions>(null);
  const fetchMissionDetail = useFetchMissionCriteria({
    onSuccess: criteria => {
      dispatch(searchActions.setCriterion(criteria));
    },
  });
  const missionDetailFetchStatus = toFetchStatus(fetchMissionDetail);
  const hasCriteria = useSelector(canDoCriteriaSearch);
  const selectedMissions = useSelector(getSelectedMissions);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const onSelectedCompanyChanged = useCallback(
    (company: IdLabel | undefined) => dispatch(missionsActions.setCompany(company)),
    [dispatch]
  );
  const onSelectedQualificationChanged = useCallback(
    (qualification: IdLabel | undefined) =>
      dispatch(missionsActions.setQualification(qualification)),
    [dispatch]
  );
  const onSelectedMissionsChanged = useCallback(
    (missions: MissionWithoutSchedule[]) => {
      dispatch(missionsActions.setMissions(missions));
      if (!hasCriteria && missions?.length > 0) {
        const selectedMission = missions[0];
        if (missions.length === 1) {
          fetchMissionDetail.mutate(selectedMission.missionId ?? '');
        } else if (missions.length > 1 && selectedMission.qualification) {
          dispatch(
            searchActions.setQualifications([
              {
                identifier: selectedMission.qualification.id ?? '',
                label: selectedMission.qualification.label,
                wishPriority: WishPriority.Obligatoire,
              },
            ])
          );
        }
      }
      if (missions.length !== 1 || hasCriteria) {
        innerRef.current?.close();
      }
    },
    [dispatch, fetchMissionDetail, hasCriteria]
  );

  return (
    <MemoMissionSearchModal
      innerRef={innerRef}
      selectedMissions={selectedMissions}
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      setSelectedCompany={onSelectedCompanyChanged}
      setSelectedQualification={onSelectedQualificationChanged}
      setSelectedMissions={onSelectedMissionsChanged}
      missionDetailFetchStatus={missionDetailFetchStatus}
    />
  );
};

export default EnhancedMissionSearchModal;
