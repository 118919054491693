import {
  SimpleBodyBlue,
  SimpleBodyGrey,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './ScoringIndicator.module.scss';
import { Props } from './ScoringIndicator.types';

const MAX_SCORING = 5;
const HEIGHT_LOSS_BY_LEVEL = 3; // in px
const LARGE_HEIGHT_LOSS_BY_LEVEL = 5; // in px

const ScoringIndicator = ({ scoring, isBig }: Props) => {
  return (
    <div className={classnames(styles.container, { [styles.largeContainer]: isBig })}>
      {Array(MAX_SCORING)
        .fill(null)
        .map((_, i) => i + 1)
        .map((level, i) => (
          <div
            key={`scoring-${i}`}
            style={{
              marginTop:
                (MAX_SCORING - level) * (isBig ? LARGE_HEIGHT_LOSS_BY_LEVEL : HEIGHT_LOSS_BY_LEVEL),
            }}
          >
            {level <= scoring ? (
              <SimpleBodyBlue className={classnames({ [styles.largeIcon]: isBig })} />
            ) : (
              <SimpleBodyGrey className={classnames({ [styles.largeIcon]: isBig })} />
            )}
          </div>
        ))}
    </div>
  );
};

export default ScoringIndicator;
