import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getStatsSearchPeriod, getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { STATS_SEARCH_PERIOD } from 'src/Redux/InterviewsStats/Types';
import { InterviewsService, R1StatusForStats, R2StatusForStats } from 'src/Services/API';

import { QueryKeys } from '../types';

export const searchDateMapping: { [key in STATS_SEARCH_PERIOD]: Date } = {
  [STATS_SEARCH_PERIOD.ONE_MONTH]: moment(new Date()).subtract(1, 'month').toDate(),
  [STATS_SEARCH_PERIOD.ONE_WEEK]: moment(new Date()).subtract(1, 'week').toDate(),
  [STATS_SEARCH_PERIOD.ONE_YEAR]: moment(new Date()).subtract(1, 'year').toDate(),
  [STATS_SEARCH_PERIOD.SIX_MONTH]: moment(new Date()).subtract(6, 'months').toDate(),
  [STATS_SEARCH_PERIOD.START_OF_YEAR]: moment(new Date()).startOf('year').toDate(),
  [STATS_SEARCH_PERIOD.THREE_MONTH]: moment(new Date()).subtract(3, 'month').toDate(),
};

export const useFetchInterviewsStatistics = () => {
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const searchPeriod = useSelector(getStatsSearchPeriod);
  return useQuery(
    [QueryKeys.fetchInterviewsStatistics, agencyIds, searchPeriod],
    async () => {
      const searchDate = searchDateMapping[searchPeriod];
      const interviewsStatsArray = await InterviewsService.interviewsControllerGetInterviewsStats({
        body: {
          agencyIds: agencyIds,
          startDate: searchDate,
        },
      });
      const interviewsStats = {
        r1: interviewsStatsArray.r1Interviews.reduce((acc, interview) => {
          acc[interview.status] = interview.count;
          return acc;
        }, {} as { [key in R1StatusForStats]: number }),
        r2: interviewsStatsArray.r2Interviews.reduce((acc, interview) => {
          acc[interview.status] = interview.count;
          return acc;
        }, {} as { [key in R2StatusForStats]: number }),
      };
      return { interviewsStats: interviewsStats, interviewsStatsArray: interviewsStatsArray };
    },
    { enabled: agencyIds !== undefined }
  );
};
