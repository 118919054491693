import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './ComboBoxWithDisplayMode.module.scss';
import { OwnProps } from './ComboBoxWithDisplayMode.types';

function ComboBoxWithDisplayMode<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ isDisplayMode, ...props }: OwnProps<T, Multiple, DisableClearable>) {
  return (
    <>
      {isDisplayMode ? (
        <div className={styles.text}>
          {props.value !== undefined ? (
            props.keyValueExtractor(props.value as T).value
          ) : (
            <p>valeur non renseignée</p>
          )}
        </div>
      ) : (
        <ComboBox {...props} />
      )}
    </>
  );
}

export default ComboBoxWithDisplayMode;
