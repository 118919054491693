import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { WishPriority, RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchDiplomas = (
  extraOptions?: UseMutationOptions<MatchingCriteriaItemWithLabel[], unknown, string>
) => {
  return useMutation(
    [MutationKeys.fetchDiplomas],
    async (keyword: string) => {
      const diplomas = await RepositoryService.diplomasControllerGetDiplomas({
        keyword,
      });
      return diplomas.map(diploma => {
        return {
          identifier: diploma.id ?? '',
          label: diploma.label,
          wishPriority: WishPriority.Important,
        };
      });
    },
    { ...extraOptions }
  );
};
