import * as z from 'zod';

export const r1ReassignCGCModalSchema = z.object({
  cgc: z
    .object({
      id: z.string().optional(),
      label: z.string().optional(),
      societyId: z.string().optional(),
      brandCode: z.string().optional(),
    })
    .optional(),
});
