import { HorizontalCardWithTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import { toDate } from 'src/Utils/moment';

import styles from './CandidateTests.module.scss';
import { Props } from './CandidateTests.types';

const CandidateTests = ({ test }: Props) => {
  const testType = test.type?.label ?? 'validation des compétences';
  const details = `passé le : ${
    test.completedOn ? moment(toDate(test.completedOn)).format('L') : 'Date non définie'
  } | ${test.agencyId} | moyenne : ${test.completionRate?.split('/')[0]}%`;

  return (
    <HorizontalCardWithTitle
      className={styles.testCard}
      title={test.label?.toLocaleLowerCase() ?? ''}
      subtitles={[testType, details]}
      titleClassname={styles.testCardTitle}
      subtitlesClassname={styles.testCardSubtitle}
    />
  );
};

export default CandidateTests;
