import { Loader, SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { SEARCH_MODE } from 'src/Redux/Search/Types';

import CriteriaSearch from '../CriteriaSearch/';
import NameSearch from '../NameSearch';

import styles from './SearchCriteriaSelection.module.scss';
import { Props } from './SearchCriteriaSelection.types';

const searchModeLabel = {
  [SEARCH_MODE.BY_CRITERIA]: 'par famille de critères',
  [SEARCH_MODE.BY_NAME]: 'par nom / prénom',
};

const SearchCriteriaSelection = ({ className, searchMode, loading, changeSearchMode }: Props) => (
  <div className={className}>
    <div className={styles.header}>
      <p className={styles.title}>nouvelle recherche</p>
    </div>
    <div className={styles.separator} />
    <SegmentedControl
      onSelectionChange={searchMode => {
        changeSearchMode(searchMode);
      }}
      selected={searchMode}
      controls={Object.values(SEARCH_MODE)}
      getValue={searchMode => searchModeLabel[searchMode]}
      labelClassName={styles.switchButtonText}
      className={styles.switchButton}
      selectedBackgroundClassName={styles.selectedSwitchButtonBackground}
    />
    {loading ? (
      <Loader heightInRem={8} />
    ) : searchMode === SEARCH_MODE.BY_NAME ? (
      <NameSearch />
    ) : (
      <CriteriaSearch />
    )}
  </div>
);

export default SearchCriteriaSelection;
