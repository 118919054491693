import SkillsByJob from 'src/Containers/CandidateFile/Qualifications/SkillsByJob';
import Tests from 'src/Containers/CandidateFile/Qualifications/Tests';

import Background from './History/Background';
import Diplomas from './Qualifications/Habilitations';
import Agency from './Synthese/Agency';
import Documents from './Synthese/Documents';
import Identity from './Synthese/Identity';

interface CandidateFileNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: { name: string; linkTo: string; component?: () => JSX.Element; path: string }[];
}

type CandidateFileNav = { [key: string]: CandidateFileNavigationNode };

const baseRoute = '/candidate/:candidateId/file';

export const navigation: CandidateFileNav = {
  synthese: {
    name: 'synthèse',
    default: './synthese/identity',
    path: `${baseRoute}/synthese`,
    routes: [
      {
        name: 'identité',
        linkTo: './identity',
        path: `${baseRoute}/synthese/identity`,
        component: Identity,
      },
      {
        name: 'documents',
        linkTo: './documents',
        path: `${baseRoute}/synthese/documents`,
        component: Documents,
      },
      {
        name: "agence d'inscription",
        linkTo: './agency',
        path: `${baseRoute}/synthese/agency`,
        component: Agency,
      },
    ],
  },
  experiences: {
    name: 'expériences',
    default: './experiences/background',
    path: `${baseRoute}/experiences`,
    routes: [
      {
        name: 'expérience professionnelle',
        linkTo: './background',
        path: `${baseRoute}/experiences/background`,
        component: Background,
      },
      {
        name: 'vie intérimaire',
        linkTo: './timeline',
        path: `${baseRoute}/experiences/timeline`,
      },
    ],
  },
  qualifications: {
    name: 'qualifications',
    default: './qualifications/skills',
    path: `${baseRoute}/qualifications`,
    routes: [
      {
        name: 'métiers et compétences',
        linkTo: './skills',
        path: `${baseRoute}/qualifications/skills`,
        component: SkillsByJob,
      },
      {
        name: 'diplômes et habilitations',
        linkTo: './diplomas',
        path: `${baseRoute}/qualifications/diplomas`,
        component: Diplomas,
      },
      {
        name: 'tests',
        linkTo: './tests',
        path: `${baseRoute}/qualifications/tests`,
        component: Tests,
      },
    ],
  },
};
