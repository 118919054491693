import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  R1InterviewsService,
  CandidateInterviewsSummaryStatus,
  R2InterviewsService,
} from 'src/Services/API';

import { QueryKeys } from '../types';

interface MutationParams {
  id: string;
  status: CandidateInterviewsSummaryStatus;
  comment: string;
}

export const useReopenR1 = (
  extraOptions?: UseMutationOptions<void, AxiosError, MutationParams, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, status, comment }: MutationParams) => {
      if (status === CandidateInterviewsSummaryStatus['R2 à faire']) {
        await R1InterviewsService.r1InterviewsControllerReopenR1({
          r1Id: id,
          body: { comment },
        });
      } else if (status === CandidateInterviewsSummaryStatus['R2 en cours']) {
        await R2InterviewsService.r2InterviewsControllerReturnProcessToAgency({
          r2Id: id,
          body: { comment },
        });
      } else {
        throw new Error('Invalid operation');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateInterviewsIdAndStatus],
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchInterviews] });
        toast.success("Le R1 a bien été renvoyé à l'agence");
      },
      onError: () => {
        toast.error("Erreur lors du renvoi du R1 à l'agence");
      },
      ...extraOptions,
    }
  );
};
